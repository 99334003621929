import { createSlice } from '@reduxjs/toolkit';
import { getSlotByServiceWeek } from './slot-action';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const slotSlice = createSlice({
  name: 'slot',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSlotByServiceWeek.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSlotByServiceWeek.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getSlotByServiceWeek.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default slotSlice.reducer;
