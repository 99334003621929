import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { getServices } from "../../features/web/services-action";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import { Container, Card } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import servicesBanner from "../../assets/images/services/Petside-services-banner.jpg";
import ServiceModal from "./ServiceModal";
import img1 from "../../assets/images/services/1b.png";
import img2 from "../../assets/images/services/2b.png";
import img3 from "../../assets/images/services/3b.png";
import img4 from "../../assets/images/services/4b.png";
import img5 from "../../assets/images/services/5b.png";
import img6 from "../../assets/images/services/6b.png";
import img7 from "../../assets/images/services/7b.png";
import img8 from "../../assets/images/services/8b new.png";
import icon from "../../assets/images/icon12.png";
import Rectangle from "../../assets/images/Rectangle.png";
import Ellipse1 from "../../assets/images/Ellipse5.png";
import Ellipse2 from "../../assets/images/Ellipse6.png";
import Ellipse3 from "../../assets/images/Ellipse7.png";
import serviceHeader from "../../assets/images/services/Services_Header.png";
import service1 from "../../assets/images/services/pekingese-puppy-dog-with-stethoscope-near-his-paws-posing 1.png";
import service2 from "../../assets/images/services/shih-tzu-puppy-wearing-orange-bow-cute-doggy-pet-is-standing-isolated-white-background-chrysanthemum-dog-negative-space-insert-your-text-image 1.png";
import service3 from "../../assets/images/services/funny-dog-isolated-white 1.png";
import PetServices from "../homepage/pet_services";

import ServiceBanner1 from "../../assets/images/services/ServiceBanner1.png";
import ServiceBanner2 from "../../assets/images/services/ServiceBanner2.png";
import ServiceBanner3 from "../../assets/images/services/ServiceBanner3.png";

const StyledContainer = styled.div`

.carousel img{
  width : 100%;
  height: 100%;
}

.allpet{
  margin-bottom:15px;
}
.equalHeight {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.equalHeight .card-body {
  flex: 1;
}

.services-slider {
  margin-bottom:2rem;
  width: 100%;
}

.slider_card {
  margin: 0 10px;
}
  .image-container {
    position: relative;
    text-align: center;
    color: white;
    padding: 5px;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .ServiceBanner1 .text-light{
    font-size: 15px;
    width: 35vw;
    color: black;
  }

  .services-slider .care_service {
    padding: 6em 0em 1em 0em;
  }

  .services-slider .care_service h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
  }

  .Dog_Footer {
    position: relative;
    width: 0vw;
    right: 23vh;
    top: -10vh;
    // overflow: hidden;
}
    
  }

  .services-slider .care_service h3 {
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
  }

  .services-slider .slider_content {
    display: flex;
    justify-content: center;
  }

  .services-slider .slider_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 2em 0em;
  }

  .services-slider .slider_card {
    border: 2px solid #55c8db;
    border-radius: 10px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services-slider .slider_card .card-img-top {
    width: 100px;
  }

  .services-slider .slider_card .card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  .services-slider .slider_card .card-title {
    font-weight: 700;
  }

  .services-slider .slider_card .card-link {
    background: #000;
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 5px;
    text-decoration: none;
  }

  // Service Banner:
  
    .ServiceBanner {
      width: 100% !important;
      height: 100% !important;
      margin: auto;
  }

  .ServiceBanner1 {
    // background-image: url(${serviceHeader});
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-size: 100% 100%;
    // background: linear-gradient(180deg, #82E6FE 0%, #FFFFFF 100%);
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 5.25rem;
    left: 10%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
}

  .ServiceBanner1 .carousel-caption h1 {
    width: 50%;
    margin: 0rem;
    font-family: Montserrat Alternates;
    font-size: 3.5vw;
    font-weight: 900;
    line-height: 4.5vw;
    letter-spacing: 2px;
    text-align: left;
  }

  .service_section {
    margin-bottom: 6vw;
  }

  .ServiceBanner1 p {
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 600;
    line-height: 2.5vw;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 5vw;
  }

  .ServiceBanner1 a,
  .ServiceBanner2 a,
  .ServiceBanner3 a {
    width: 20vw;
    font-family: Montserrat;
    font-size: 1vw;
    font-weight: 600;
    line-height: 1vw;
    letter-spacing: 2px;
    text-align: center;
    padding: 1vw 3vw !important;
    border-radius: 0.6vw;
    background: #000000;
    color: #fff !important;
  }

  .ServiceBanner1 .carousel-caption .ser_ban {
    width: 30%;
    padding: 2vw 0em 0em 0em;
    margin: 0;
  }

  .ServiceBanner2 .carousel-caption {
    position: absolute;
    right: -50%;
    left: 10%;
    bottom: 9.25rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
  }

  .ServiceBanner2 p {
    font-size: 2vw;
    font-weight: 600;
    line-height: 2.5vw;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 5vw;
  }

  .ServiceBanner2 .carousel-caption .banner_heading {
    width: 28%;
    // margin: auto;
  }

  .ServiceBanner2 h2 {
    font-family: Montserrat Alternates;
    font-size: 3vw;
    font-weight: 900;
    line-height: 4vw;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 2vw
  }

  .ServiceBanner2 .banner_heading {
    width: 30%
  }

  .ServiceBanner3 .carousel-caption {
    position: absolute;
    right: 10%;
    bottom: 7rem;
    left: 49%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
}

  .ServiceBanner3 .banner_heading {
    display: flex;
    flex-direction: column;
    align-items: end !important;
  }

  .ServiceBanner3 .banner_img {
    height: 60%;
  }

  .ServiceBanner3 h2 {
    font-family: Montserrat Alternates;
    font-size: 3.5vw;
    font-weight: 900;
    line-height: 4.5vw;
    letter-spacing: 2px;
    text-align: right;
    margin-bottom: 1vw;
  }

  .ServiceBanner3 p {
    width:50%;
    font-family: Montserrat;
    font-size: 26px;
    font-weight: 600;
    line-height: 31.69px;
    letter-spacing: 2px;
    text-align: right;
    margin-bottom: 3rem;
  }

  .ServiceBanner3 .BannerImg1 h3 {
    margin-bottom: -5vw;
    margin-left: 3vw;
  }
  
  .ServiceBanner3 .BannerImg1 img {
    align-items: center;
    margin: 0 auto;
    justify-content: flex-end;
    }

  .ServiceBanner3 .BannerImg2 {
        margin-top: 5vw !important;
    }
    
    .ServiceBanner3 .BannerImg3 h3 {
      margin-top: -3vw;
    text-align: right  ;
    }

  @media (max-width: 1200px) {
    .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 3.25rem;
    left: 7%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
  }

  .ServiceBanner2 .carousel-caption {
    position: absolute;
    bottom: 7rem;
    left: 10%;
  }

  .ServiceBanner3 .carousel-caption {
    position: absolute;
    bottom: 2.5rem;
    left: 49%;
  }

}

@media(max-width: 1024px){
  .ServiceBanner2 .carousel-caption {
    position: absolute;
    bottom: 5rem;
    left: 10%;
  }

  .ServiceBanner3 .carousel-caption {
    bottom :4.5rem;
    left: 41%;
  }
  
  .ServiceBanner3 p{
    width:60%;
  }
}


  @media (max-width: 991px) {

    .ServiceBanner {
      height: 400px !important;
      width: 100%;
    }

    .ServiceBanner1 .carousel-caption {
      position: absolute;
      bottom: 4.25rem;
      left: 2%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

   .ServiceBanner2 h2 {
      font-size: 3vw;
      margin-bottom: 4vw;
    }

    .ServiceBanner a {
      padding: 1.2vw 3vw !important;
      width: 20vw;
    }

    .ServiceBanner3 .carousel-caption {
      position: absolute;
      right: 15%;
      bottom: 12.5rem;
      left: 15%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

  }

  @media (max-width: 768px) {
    .service_section {
      margin-bottom: 6vw;
      margin-top: -10vw;
      width: 100%;
    }

    .ServiceBanner {
      height: 280px !important;
    }
      
    .ServiceBanner1 .carousel-caption {
      position: absolute;
      bottom: 0rem;
      left: 6%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner1 .carousel-caption .ser_ban p {
      margin-bottom: 3vw;
    }

    .ServiceBanner a {
      font-size: 1.2vw;
      padding: 1.2vw 2vw !important;
      border-radius: 5px;
    }

    .ServiceBanner2 .carousel-caption {
      position: absolute;
      right: -50%;
      bottom: 1.25rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner2 .carousel-caption h2 {
      font-size: 3vw;
      margin-bottom: 2vw;
    }

    .ServiceBanner3 .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 1rem;
        left: 40%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #000000;
        text-align: center;
    }

    .ServiceBanner3 p {
        width: 60%;
        font-size: 17px;
        font-weight: 600;
        line-height: 25.69px;
        letter-spacing: 2px;
        margin-bottom: 1rem;
    }

  }

@media (max-width: 580px) {
  .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 6rem;
    left: 2%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .ServiceBanner {
    height: 217px !important;
  }
    .ServiceBanner1 .carousel-caption {
      width: 100%;
      position: absolute;
      bottom: 2rem;
      left: 6%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner a {
        font-size: 1.3vw;
        padding: 1.5vw 3vw !important;
        border-radius: 3px;
        width: 25vw;
    }

    .ServiceBanner1 a {
        font-size: 1.1vw !important;
        padding: 1.5vw 2vw !important;
        border-radius: 3px;
        width: 28vw !important;
    }

    .ServiceBanner3 .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 6rem;
        left: 15%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #000000;
        text-align: center;
    }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 10px;
  }

  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 3px;
  }

      .ServiceBanner3 .carousel-caption {
        position: absolute;
        right: 12%;
        bottom: 2rem;
        left: 33%;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #000000;
        text-align: center;
    }

    .ServiceBanner3 p {
        width: 60%;
        font-size: 9px;
        font-weight: 600;
        line-height: 15.69px;
        letter-spacing: 2px;
        margin-bottom: 1rem;
    }
}

@media (max-width: 380px) {

.ServiceBanner1 .carousel-caption {
    bottom: 2.5rem;
}
.ServiceBanner1 .carousel-caption .ser_ban {
    width: 36%;
    padding: 2vw 0em 0em 0em;
    margin: 0;
}

.ServiceBanner1 h1 {
    margin-bottom: 4vw !important;
  }
.ServiceBanner1 p {
    font-size: 2.4vw !important;
  }
.ServiceBanner a {
    font-size: 9px !important;
    padding: 2vw !important;
    border-radius: 3px;
    width: 100% !important;
    margin-left: 0px;
  }

.ServiceBanner2 .carousel-caption {
        right: -13%;
        left: -52%;
        bottom: 1.25rem;
    }
.ServiceBanner2 .carousel-caption h2 {
        margin-bottom: 6vw !important;
    }

.ServiceBanner2 .carousel-caption .banner_heading {
    width: 25%;
    margin: auto;
}

.ServiceBanner3 a {
        font-size: 9px !important;
        padding: 2vw !important;
        border-radius: 3px;
        width: 60% !important;
        margin-left: 0px;
    }

    @media (max-width: 414px), 
        (max-height: 896px) {
        .carousel-item {
          transition: transform 0.7s ease !important; 
        }
}

`;

const AllServices = () => {
  const dispatch = useDispatch();
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const servicesData = useSelector((state) => state.services.data?.rows || []);

  useEffect(() => {
    dispatch(getServices()).catch((error) => console.error("Error:", error));
  }, [dispatch]);
  const handleServiceSelection = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <ArrowRightCircle />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <ArrowLeftCircle />
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768, // Tablets and smaller desktops
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <StyledContainer>
      {/* <div className="container-fluid p-0"> */}
      {/* <div className="ServiceBanner1 d-flex flex-column justify-content-center">
          <h1 className="container">Happy Dogs, Happy Parents !!</h1>
          <div className="service_section row justify-content-center">
            <div className="col-lg-4 col-sm-12 col-md-6 ser_ban m-auto">
              <p className="">
                Checkout our Special Services at Discounted Price !
              </p>
              <a href="/services">Check services</a>
            </div>
            <div className="col-lg-3 m-auto service1">
              <img src={service1} alt="services" />
              <h3>DayCare</h3>
            </div>
            <div className="col-lg-2 m-auto service2">
              <img src={service3} alt="services" />
              <h3>Training</h3>
            </div>
            <div className="col-lg-3 m-auto service3">
              <img src={service2} alt="services" />
              <h3>Walking</h3>
            </div>
          </div>
        </div> */}

      {/* <div className="ServiceBanner1-img">
          <img src={serviceHeader} alt="" layout="fill" objectFit="cover" />
        </div> */}
      {/* 
      <Container className="services-slider">
        <div className="care_service">
          <h2>All Pet</h2>
          <h3>CARE SERVICES</h3>
        </div>
        <div className="slider_content">
          <div className="Dog_Footer">
            <img src={icon} alt="A dog sitting on a couch" />
          </div>
          <div className="slider_container row">
            {servicesData.map(({ description, name, thumbnail, id }, idx) => (
              <Card className="slider_card col-md-3" key={idx} >
                <Card.Img
                  variant="top"
                  src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                  alt={name}
                />
                <Card.Body>
                  <Card.Title>{name}</Card.Title>
                  <Card.Text>{description}</Card.Text>
                  <div onClick={() => handleServiceSelection(id)}>
                    {" "}
                    <ServiceModal />
                  </div>
                </Card.Body>
              </Card>
            ))}
          </div>
        </div>
        <div style={{ display: "none" }}>
          {selectedServiceId && <ServiceModal serviceId={selectedServiceId} />}
        </div>
      </Container> */}

      {/* <Container className="services-slider">
         <div className="care_service">
          <h2 className="allpet">All Pet</h2>
          <h3>CARE SERVICES</h3>
        </div>
       <div className="slider_container row">
      <Slider {...sliderSettings}>
        {servicesData.map(({ description, name, thumbnail, id }, idx) => (
          <div key={idx}>
            <Card className="slider_card equalHeight">
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                alt={name}
              />
              <Card.Body>
                <Card.Title>{name}</Card.Title>
                <Card.Text>{description}</Card.Text>
                <div onClick={() => handleServiceSelection(id)}>
                  <ServiceModal />
                </div>
              </Card.Body>
            </Card>
          </div>
        ))}
      </Slider>
      </div>
      <div style={{ display: "none" }}>
          {selectedServiceId && <ServiceModal serviceId={selectedServiceId} />}
       </div>
    </Container> */}

      <div className="container-fluid p-0 m-0">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="d-flex flex-column justify-content-center ServiceBanner ServiceBanner1">
              <img src={ServiceBanner1} alt="Home Banner 1" />
              <Carousel.Caption>
                <h1 className="container">Happy Dogs, Happy Parents !!</h1>
                <div className="row ser_ban">
                  <p>Checkout our Special Services at Discounted Price !</p>
                  <a href="/services">Check services</a>
                </div>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="d-flex flex-column justify-content-center ServiceBanner ServiceBanner2">
              <img src={ServiceBanner2} alt="Home Banner 2" />
              <Carousel.Caption>
                <div className="row banner_heading">
                  <h2>
                   Nurturing Paws, Loving Hearts :)
                  </h2>
                  <div className="row ser_ban">
                    <p>Checkout our Special Services at Discounted Price !</p>
                    <a href="/services">Check services</a>
                  </div>
                </div>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <div className="d-flex flex-column justify-content-center ServiceBanner ServiceBanner3">
              <img src={ServiceBanner3} alt="Home Banner 3" />
              <Carousel.Caption>
                <div className="d-flex row-lg-4 align-items-center banner_heading">
                  <h2>Making Tails Wag with Delight</h2>
                  <p>Checkout our Special Services at Discounted Price !</p>
                  <a href="/services">Check Services</a>
                </div>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <PetServices header1="All Pet" header2="CARE SERVICES" />
    </StyledContainer>
  );
};

export default AllServices;
