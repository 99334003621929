import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { loginWithOtp, sendOtp } from "../../features/web/web-actions";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import LoginImg from "../../assets/images/Home/Vector.png"
import userIcon from "../../assets/images/user.png";

const StyledContainer = styled.div`
  padding-top: 3em;
  .login {
    border: 1px solid #000;
    border-radius: 10px;
    padding: 2em 4em;
    & label {
      font-weight: 700;
    }
    & button {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 40px;
      border-radius: 5px;
    }
  }
  .login_col {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cn_div {
    display: flex;
    align-items: center;
    gap: 2em;
  }
  .otp {
    display: flex;
    align-items: center;
    gap: 2em;
  }
`;

function Login() {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    contact_no: "",
    otp: "",
  });
  const [errors, setErrors] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validateContactNo = (contact_no) => {
    const errors = {};
    if (contact_no.length ===0) {
      errors.contact_no = "Please enter Mobile Number.";
    }else if (contact_no.length > 10) {
      errors.contact_no = "Please enter exactly 10 digits long.";
    } else if (contact_no.startsWith("0") || contact_no.startsWith("+91")) {
      errors.contact_no = "Please enter exactly 10 digits without 0 And +91";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "contact_no") {
      setErrors(validateContactNo(value));
    }
  };

  const otpHandler = async (e) => {
    e.preventDefault();
    const validationErrors = validateContactNo(formData.contact_no);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const contact_no = formData.contact_no;
      const response = await sendOtp({ contact_no });
      if (response.success) {
        message.success(response.message);
        message.success(`Your OTP is : ${response.data.otp}`);
        if (step === 1) {
          setStep(2);
        }
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const contact_no = formData.contact_no;
      const otp = parseInt(formData.otp);
      const response = await loginWithOtp({ contact_no, otp });

      if (response.success) {
        message.success(response.message);
        setShow(false);
        window.location.reload();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        "An error occurred while processing your request. Please try again later."
      );
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      {isMobile ? (
         <svg onClick={handleShow} className="m-3" xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#000000"><path d="M240.92-268.31q51-37.84 111.12-59.77Q412.15-350 480-350t127.96 21.92q60.12 21.93 111.12 59.77 37.3-41 59.11-94.92Q800-417.15 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 62.85 21.81 116.77 21.81 53.92 59.11 94.92ZM480.01-450q-54.78 0-92.39-37.6Q350-525.21 350-579.99t37.6-92.39Q425.21-710 479.99-710t92.39 37.6Q610-634.79 610-580.01t-37.6 92.39Q534.79-450 480.01-450ZM480-100q-79.15 0-148.5-29.77t-120.65-81.08q-51.31-51.3-81.08-120.65Q100-400.85 100-480t29.77-148.5q29.77-69.35 81.08-120.65 51.3-51.31 120.65-81.08Q400.85-860 480-860t148.5 29.77q69.35 29.77 120.65 81.08 51.31 51.3 81.08 120.65Q860-559.15 860-480t-29.77 148.5q-29.77 69.35-81.08 120.65-51.3 51.31-120.65 81.08Q559.15-100 480-100Zm0-60q54.15 0 104.42-17.42 50.27-17.43 89.27-48.73-39-30.16-88.11-47Q536.46-290 480-290t-105.77 16.65q-49.31 16.66-87.92 47.2 39 31.3 89.27 48.73Q425.85-160 480-160Zm0-350q29.85 0 49.92-20.08Q550-550.15 550-580t-20.08-49.92Q509.85-650 480-650t-49.92 20.08Q410-609.85 410-580t20.08 49.92Q450.15-510 480-510Zm0-70Zm0 355Z"/>
         </svg>
      ) : (
        <button className="custom-btn" onClick={handleShow}>
          Sign In
        </button>
      )}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Login/Sign up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 login_col">
                <div className="login">
                  <form onSubmit={handleSubmit}>
                    {step === 1 && (
                      <>
                        <div className="cn_div">
                          <label htmlFor="">Contact Number</label>
                          <input
                            className="form-control"
                            type="text"
                            name="contact_no"
                            maxLength={10}
                            onKeyDown={(e) => {
                              if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                  e.preventDefault();
                              }
                          }}
                            value={formData.contact_no}
                            onChange={handleChange}
                          />
                        </div>
                        {errors.contact_no && (
                          <div className="error text-danger" >{errors.contact_no}</div>
                        )}
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setStep(1);
                          }}
                          className="m-0"
                        >
                          <u>Edit Number</u>
                        </a>
                        <div className="otp pb-4">
                          <h5 className="pt-2">Verification code</h5>
                          <p className="pb-3">
                            We have sent you a 4 digit code on{" "}
                            {formData.contact_no}
                          </p>
                          <input
                            className="form-control mb-2"
                            type="text"
                            name="otp"
                            value={formData.otp}
                            onChange={handleChange}
                          />
                          <a href="#" className="m-0" onClick={otpHandler}>
                            Resend OTP
                          </a>
                        </div>
                      </>
                    )}
                    {step < 2 && (
                      <button
                        className="custom-btn mt-4"
                        type="button"
                        onClick={otpHandler}
                      >
                        Send OTP
                      </button>
                    )}
                    {step === 2 && (
                      <button type="submit" className="custom-btn">
                        Verify OTP
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Login;
