import React, { useState } from "react";
import styled from "styled-components";
import Location from "./location";
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createEnquiry } from "../../features/web/web-actions";
import { message } from "antd";
import { Envelope, House, Telephone } from "react-bootstrap-icons";
import img1 from "../../assets/images/dogs/contact-bg.jpg";
import add from "../../assets/images/Contact/entypo_address.png";
import contactNumber from "../../assets/images/Contact/fluent_contact-card-28-regular.png";
import email from "../../assets/images/Contact/mdi_email-fast-outline.png";

const StyledContainer = styled.div`
  #map {
    height: 20vh;
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .contact_row h2 {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    line-height: 36.57px;
    letter-spacing: 2px;
    text-align: center;
    padding: 15px;
  }

  .contact_heading span {
    width: 16%;
    text-align: center;
    border-bottom: 5px solid #008db3;
    padding: 10px;
  }

  .contact_heading h5 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    padding: 2em 0em;
  }

  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact_us_form .row {
    border: 2px solid;
    border-radius: 20px;
    padding: 6% 3%;
    margin: 0px;
    // background: #bbcfe7;
  }
  .contact_us_form .row .form-control {
    border: 1px solid #000;
    margin-bottom: 20px;
  }
  // .contact_us h5 {
  //   text-align: center;
  //   font-weight: 600;
  //   padding: 2em 8em;
  // }
  .contact_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact_row h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 1em;
  }
  .contact_us_form a {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 40px;
    border-radius: 5px;
  }
  .form-control{
    box-shadow: #E2FBFF 0px 5px 15px;
  }
  .contact_col {
    // background-image: url(${img1});
    // background-size: 100% 100%;
    // height: 530px;
    // border-radius: 20px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    & p {
      padding-bottom: 20px;
      color: #03434e;
      font-size: 21px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 1em;
    }
    & p a{
      color: #03434e;
      font-size: 21px;
      font-weight: 500 !important;
    }
    & h2 {
      padding-top: 2em;
      text-align: center;
      color: #03434e;
      font-size: 28px;
      font-weight: 700;
    }
    & span {
      background: #fff;
      padding-top: 3px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      text-align: center;
    }
  }

  .Contact-list{
  margin:2em 0em;
  }

  .Contact-list h3 {
    font-weight: 600;
    margin-left: 3vw;
  }

  .Contact-list p {
    text-align: center;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 1em 0px;
    gap: 1em;
  }
  .contact_form {
    & h2 {
      font-size: 28px;
      font-weight: 700;
      color: #114c5f;
      text-align: center;
    }
  }
  .checkbox p {
    font-size: 12px;
    line-height: 12px;
  }
  // .check-col {
  //   display: flex;
  //   flex-direction: column;
  // }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .successMsg {
      border: 4px solid #114c5f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 5em;
      gap: 2em;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
      color: #114c5f;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }

  .custom_btn {
    width: 35%;
    height: 45px;
    font-size: 20px;
    background: black;
    text-align: center;
  }

  @media (max-width: 1400px) {
    .contact_heading h5 {
      font-size: 15px;
    }
  }
  
  @media (max-width: 768px) {
    .contact_col {
      background-size: auto;
      margin-left: 2em;
    }
    .contact_us_form {
      padding: 0px;
      width: 100%;
      margin-left: 8vw;
    }

    .contact-content {
      flex-direction: column;
    }

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
      --bs-gutter-x: 1.8rem;
      --bs-gutter-y: 0;
      width: 100%;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      margin-right: auto;
      margin-left: auto;
      margin-left: -5px;
    }
  }
    @media (max-width: 425px) {
    .msg_div .successMsg {
      padding: 2em 2em;
      gap: 1em;
    }

    .Contact-list h3 {
      font-size: 17px;
    }

    .Contact-list p {
      font-size: 15px;
    }

    .Contact-list p a{
      font-size: 15px;
    }

    .Contact-list img {
      margin-top: -10px;
    }

    .contact_row h1 {
      padding: 0.5rem;
    }

    .contact_col p{
    justify-content: center;
    align-item:center;
    }

    .contact_col{
    text-align: center;
    margin-left: -2rem;
    }

    .contact_form h2 {
      font-size: 22px;
    }

    .contact_us_form .row {
      padding: 28px 12px;
      margin: 0.5rem;
    }

    .contact_us_form .row .form-control {
      margin-bottom: 10px;
    }
    .checkbox {
      padding: 1em 0em;
    }
    .contact_us_form {
        margin-left: 4vw;
    }
  }
`;

function ContactUs() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      if (!values.subject) {
        message.error("Please Enter Subject");
      }
      const response = await createEnquiry({ ...values });
      // console.log("Data : ", response);
      if (response.success) {
        message.success("Your Enquiry has been submitted successfully");
        setIsSubmitted(true);
        resetForm();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required').min(2, "Invalid Name!.").max(30, "Invalid Name!."),
    email: Yup.string()
      .email('Invalid email address').max(40, "Invalid Email!.")
      .required('Email is required'),
    contact_no: Yup.string()
      .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits')
      .test(
        'no-leading-zero',
        'Contact number must not start with 0 or +91',
        value => value && !/^0|(\+91)/.test(value)
      )
      .required('Contact number is required'),
    subject: Yup.string()
      .required('Subject is required'),
    message: Yup.string()
      .required('Message is required'),
    consent: Yup.boolean()
      .oneOf([true], 'Consent is required')
  });

  return (
    <div className="ContactUS_Container">
      <Location />
      <StyledContainer>
        {/* <div className='contact_banner'>
      <img src={img1} width={'100%'}  alt="" />
        </div> */}
        <div className="container contact_us">
          <div className="contact_heading">
            <h5>
              Thank you for your interest in PetSide. We would like to hear from
              you on how we can help. Please take a moment to fill in your query
              and we'll get in touch with <br /> <span>you, soon.</span>
            </h5>
          </div>
          <div className="row contact_row">
            {/* <h1>Contact Information</h1> */}
            {/* <div className="col-md-6 contact_col">
              <h2>Contact Us</h2>
              <p>
                <span>
                  <House />
                </span>{" "}
                Mumbai, Pune, Lonavala
              </p>
              <p>
                <span>
                  <Telephone />
                </span>{" "}
                +91 91367 76162
              </p>
              <p>
                <span>
                  <Telephone />
                </span>{" "}
                +91 91363 65706
              </p>
              <p>
                <span>
                  <Envelope />
                </span>{" "}
                Hello@petside.in
              </p>
            </div> */}
            <h2>CONTACT INFORMATION</h2>
            <div className="contact-content">
              <div className="col-md-4 contact_col">
                <div className="Contact-list">
                  <h3>Address: </h3>
                  <p>
                    <img src={add} alt="Address Icons" /> Mumbai, Pune, Lonavala
                  </p>
                </div>
                <div className="Contact-list">
                  <h3>Contact Number: </h3>
                  <p>
                    <img src={contactNumber} alt="ContactNumber Icons" /> 
                    <a href="tel:+919136776162" className="text-dark" title="Call us at +91 9136776162">+91 91367 76162</a>
                  </p>
                </div>
                <div className="Contact-list">
                  <h3>Email: </h3>
                  <p>
                    <img src={email} alt="Email Icons" />
                    <a href="mailto:Hello@petside.in" className="text-dark" title="Mail us on Hello@petside.in">Hello@petside.in</a>
                  </p>
                </div>
              </div>
              <div className="col-md-8 contact_us_form">
                {isSubmitted ? (
                  <div className="container msg_div">
                    <div className="successMsg">
                      <h2>Thank You for Your Inquiry!</h2>
                      <p>We will get back to you shortly.</p>
                      <a href="/">Return Home</a>
                    </div>
                  </div>
                ) : (
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      contact_no: "",
                      subject: "",
                      message: "",
                      consent: false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="row contact_form">
                          {/* <h2>Reach Out to Us</h2> */}
                          <div className="col-md-6">
                            <label htmlFor="">Name</label> <br />
                            <Field
                              className="form-control"
                              type="text"
                              name="name"
                            />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="">Email</label> <br />
                            <Field
                              className="form-control"
                              type="text"
                              name="email"
                            />
                            <ErrorMessage name="email" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="">Contact No</label> <br />
                            <Field
                              className="form-control"
                              type="text" maxlength={10}
                              name="contact_no"
                              onKeyDown={(e) => {
                                if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                    e.preventDefault();
                                }
                            }}
                            />
                            <ErrorMessage name="contact_no" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="">Subject</label> <br />
                            <Field
                              className="form-control"
                              type="text"
                              name="subject"
                            />
                            <ErrorMessage name="subject" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="">Message</label> <br />
                            <Field
                              className="form-control"
                              as="textarea"
                              name="message"
                            />
                            <ErrorMessage name="message" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-12 check-col">
                            <div className="checkbox">
                              <Field type="checkbox" name="consent" />
                              <p>
                                I consent to my data being stored for
                                administrative purposes.
                              </p>
                            </div>
                              <ErrorMessage name="consent" component="div" className="text-danger" />
                          </div>
                          <div className="col-md-12 check-col">
                            <div className="text-center">
                              <button
                                className="custom_btn"
                                type="submit"
                                disabled={isSubmitting}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                )}
              </Formik>
                )}
            </div>
          </div>
        </div>
    </div>
      </StyledContainer >
    </div >
  );
}

export default ContactUs;
