  import styled from "styled-components";
import more from "../../assets/images/more-arrow.png";
import { NavLink } from "react-bootstrap";
import { useDispatch } from "react-redux";
//import { AppDispatch, RootState } from "@/store";
import { useEffect, useState } from "react";
// import { getBlogs,fetchBlogs} from "../../features/web/web-actions";
import { fetchBlogs} from "../../features/web/web-actions";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const StyledSection = styled.div`
  padding: 20px;
  
  .list-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .image {
    margin-right: 20px; /* Adjust spacing between image and details */
  }
  
  .image img {
    width: 100px; /* Adjust image width as needed */
    height: auto;
    border-radius: 8px; /* Rounded corners */
  }
  
  .details {
    flex: 1; /* Expand to fill remaining space */
  }
  
  .name {
    font-weight: bold;
    font-size: 18px; /* Adjust font size as needed */
    margin-bottom: 5px; /* Add some space below the name */
  }
  
  .date-more {
    display: flex;
    align-items: center;
  }
  
  .date {
    font-size: 14px; /* Adjust font size as needed */
    color: #4CAF50; /* Green color */
    margin-right: 10px; /* Add space between date and "Read More" */
  }
  
  .more {
    background-color: #2196F3; /* Blue color */
    color: white;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  /* Mobile Responsive Adjustments */
  @media screen and (max-width: 768px) {
    .image img {
      width: 80px; /* Adjust image width for smaller screens */
    }
  
    .name {
      font-size: 16px; /* Adjust font size for smaller screens */
    }
  
    .date {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  
    .more {
      font-size: 12px; /* Adjust font size for smaller screens */
      padding: 4px 8px; /* Adjust padding for smaller screens */
    }
  }
  
`;
export const BlogListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  .list-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .image {
      flex: 1 1 40%;
    }
    .details {
      flex: 1 1 60%;

      display: flex;
      flex-direction: column;
      text-align: left;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
      }

      .date-more {
        display: flex;
        flex-direction: column;
      }
    }
  }
  @media (min-width: 425px) {
    flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    .list-item {
      flex-direction: column;
      // justify-content: space-between;
      width: 358px;
      .image {
        overflow: hidden;
        border-radius: 10px;
        min-height: 340px;
        align-items: center;
        display: flex;
        justify-content: center;
        img {
          width: initial;
          max-width: 100%;
        }
      }
      .details {
        // justify-content: space-between;
        .date-more {
          margin-top: 10px;
          flex-direction: row;
          justify-content: space-between;
          .more {
            margin-top: -10px;
          }
        }
      }
    }
  }
`;

export const ReadMore = styled(NavLink)`
  &:after {
    content: "";
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    background: url(${more}) no-repeat;
    top: 10px;
    right: -5px;
  }
`;



export const BlogThumbnail = ({ data }) => {
  const { title, id, profile_url, publish_date , slug } = data;
  return (
    <StyledSection>
    <div className="list-item">
      <div className="image">
        <img src={`${process.env.REACT_APP_API_BASE_URL}/images/${profile_url}`} alt="" />
      </div>
      <div className="details">
        <div className="name body-text bold">{title}</div>
        <div className="date-more">
          <div className="date green">
            {new Date(publish_date).toLocaleDateString("en-IN")}
          </div>
          <ReadMore className="more" href={`../blog/${slug}`}>
            Read More
          </ReadMore>
        </div>
      </div>
    </div>
    </StyledSection>
  );
};

 const Knowledge = () => {
  const dispatch = useDispatch();
  const [news, setNews] = useState([]);
  const [title, setTitle] = useState("");

  useEffect(() => {
      ///setTitle('ALL BLOG LIST');
      dispatch(
        fetchBlogs({
         // catId: categoryId,
          page: 1,
          size: 50,
        })
      ).then((res) => {
        setNews(res.payload.data.rows);
      });
  }, []);
  
  return (
    <StyledSection>
        <Helmet>
        <title>PETSIDE Blog | Medical Trip Blog Your Guide to the Top DOG Facilities and Treatments | PETSIDE</title>
        <meta name="description" content="Discover the insight about Dog blog. Learn about the top treatments  with PESIDE." />
        <link rel="canonical" href="https://petside.in/knowledge/blog" />
        <meta name="keywords" content="" />
      </Helmet> 
      <div className="title blue bold sub-heading">{title}</div>
      <BlogListWrapper>
        {news.map((item, idx) => (
          <BlogThumbnail data={item} key={idx} />
        ))}
      </BlogListWrapper>
    </StyledSection>
  );
 };

export default Knowledge;
