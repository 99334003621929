import styled from "styled-components";
import { NavLink } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { AppDispatch } from "@/store";
import { useEffect, useState } from "react";
import { searchBlogs } from "../../features/web/web-actions";
import { BlogListWrapper, BlogThumbnail } from "../news/Knowledge";
//import { BlogThumbnail } from "../news/Knowledge";

const StyledSection = styled.div`

  background: #f6fffd;
  padding: 2em 0;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination .page-item {
    margin: 0 5px;
  }

  .pagination .page-link {
    color: #114c5f;
    background-color: transparent;
    border: 1px solid #114c5f;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .pagination .page-link:hover {
    background-color: #114c5f;
    color: #fff;
  }

  .pagination .active .page-link {
    background-color: #55c8db;
    color: #fff;
    border-color: #55c8db;
  }

  .slick-slide {
    padding: 0 25px;
    display: flex;
    justify-content: center;
  }

  .bloglist {
    margin: 4rem 2rem 0rem 0rem;
  }

  .card {
    height: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    overflow: hidden;
  }

  .card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  .card-img-top {
    width: 100%;
    height: 230px;
    margin: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-body {
    padding: 15px;
  }

  .card-body p {
    font-size: 14px;
    font-weight: normal;
    height: auto;
    overflow: hidden;
  }

  .card-body a {
    background: #114c5f;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }

  .card-body a:hover {
    background: #0e3f4d;
  }

  .slider-title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  .Dog_Footer2 {
    text-align: end;
  }

  @media screen and (max-width: 768px) {
    .slick-slide {
      padding: 0 15px;
    }

    .card-img-top {
      width: 100%;
      height: auto;
    }

    .card-body p {
      font-size: 12px;
    }

    .card-body a {
      padding: 8px 16px;
    }

    .slider-title {
      font-size: 24px;
    }
  }
`;

const MoreLikeThis = () => {
  const dispatch = useDispatch();
  const [news, setNews] = useState([]);

  useEffect(() => {
    dispatch(
      searchBlogs({
        page: 1,
        size: 3,
       // blogId: 1,
      })
    ).then((res) => {
      setNews(res.payload.data.rows);
    });
  }, []);
  return (
    <StyledSection>
      <div className="container">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div  className="title blue bold sub-heading" style={{ textAlign: "left" }} >
          More Like this
        </div>
        <div className="">
         <NavLink href="../knowledge/blog" > View All &gt;&gt;</NavLink> 
        </div> 
      </div>
      <div className="row pt-5">
        {news.map(
          ({ title, profile_url, slug, id, publish_date }) => (
              <div key={id} className="col-sm-12 col-md-6 col-lg-4 mb-4">
                <div className="card">
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}/images/${profile_url}`}
                    alt={title}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{publish_date}</p>
                    <a href={`/blog/${slug}`} className="btn btn-primary">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            ))}
      </div>
      </div>
    </StyledSection>
  );
};

export default MoreLikeThis;
