import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';


const StyledContainer = styled.div`
body {overflow: hidden; background: #212121}
input {position: absolute; display: none}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.menu {
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

.btn {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
}

.material-icons.md-36 { font-size: 36px; color: #ECEFF1 }

.btn {
  background: #03A9F4;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  
  -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.btn:not(:first-child) {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: -2;
  
  -webkit-transition: all 0.6s cubic-bezier(.87,-.41,.19,1.44);
  transition: all 0.6s cubic-bezier(.87,-.41,.19,1.44);
}

.btn:nth-child(2) {top:0px;-webkit-transition-delay: 0s;transition-delay: 0s}
.btn:nth-child(3) {top:0px;left:0px;-webkit-transition-delay: 0.1s;transition-delay: 0.1s}
.btn:nth-child(4) {left:0px;;-webkit-transition-delay: 0.2s;;transition-delay: 0.2s}
.btn:nth-child(5) {top:0px;left:0px;-webkit-transition-delay: 0.3s;transition-delay: 0.3s}
.btn:nth-child(6) {top:0px;-webkit-transition-delay: 0.4s;transition-delay: 0.4s}
.btn:nth-child(7) {top:0px;left:0px;-webkit-transition-delay: 0.5s;transition-delay: 0.5s}
.btn:nth-child(8) {left:0px;-webkit-transition-delay: 0.6s;transition-delay: 0.6s}
.btn:nth-child(9) {top:0px;left:0px;-webkit-transition-delay: 0.7s;transition-delay: 0.7s}

input#toggle:checked ~ #show-menu .btn:nth-child(2) {
  top:-150px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(3) {
  top:-100px;left:100px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(4) {
  left:150px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(5) {
  top :100px;left:100px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(6) {
  top:150px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(7) {
  top:100px;left:-100px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(8) {
  left:-150px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input#toggle:checked ~ #show-menu .btn:nth-child(9) {
  top:-100px;left:-100px;opacity:1;-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.menuBtn, .closeBtn {
  position: absolute;
  transition: all 0.3s ease;
}

.closeBtn {
  transform: translateY(50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn .menuBtn {
  transform: translateY(-50px);
  opacity: 0;
}

input#toggle:checked ~ #show-menu .btn .closeBtn {
  transform: translateY(0px);
  opacity: 1;
}

`;
const CircularMenu = () => {

  return (
    <StyledContainer>
        <div class="menu">
        <input type="checkbox" id="toggle" />
        <label id="show-menu" for="toggle">
          <div class="btn">
            <svg width="38" height="38" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M23 18H21V11H14V9H22C22.2652 9 22.5196 9.10536 22.7071 9.29289C22.8946 9.48043 23 9.73478 23 10V18Z" fill="white"/>
									<path d="M18 23H10C9.73478 23 9.48043 22.8946 9.29289 22.7071C9.10536 22.5196 9 22.2652 9 22V14C9 13.7348 9.10536 13.4804 9.29289 13.2929C9.48043 13.1054 9.73478 13 10 13H18C18.2652 13 18.5196 13.1054 18.7071 13.2929C18.8946 13.4804 19 13.7348 19 14V22C19 22.2652 18.8946 22.5196 18.7071 22.7071C18.5196 22.8946 18.2652 23 18 23ZM11 21H17V15H11V21Z" fill="white"/>
									<path d="M16 1C15.8229 0.99991 15.649 1.04684 15.496 1.136L3.496 8.136C3.34502 8.2241 3.21978 8.35024 3.13275 8.50184C3.04572 8.65343 2.99995 8.8252 3 9V23C2.99995 23.1748 3.04572 23.3466 3.13275 23.4982C3.21978 23.6498 3.34502 23.7759 3.496 23.864L15.496 30.864C15.649 30.9533 15.8229 31.0003 16 31.0003C16.1771 31.0003 16.351 30.9533 16.504 30.864L27.504 24.447L26.496 22.72L16 28.842L5 22.426V9.575L16 3.158L27 9.574V17H29V9C29.0001 8.8252 28.9543 8.65343 28.8673 8.50184C28.7802 8.35024 28.655 8.2241 28.504 8.136L16.504 1.136C16.351 1.04684 16.1771 0.99991 16 1Z" fill="white"/>
									</svg>
    </div>
    <div class="btn">
      <i class="material-icons md-36">photo_camera</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">photo</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">music_note</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">chat_bubble</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">settings</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">phone</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">cloud</i>
    </div>
    <div class="btn">
      <i class="material-icons md-36">videocam</i>
    </div>
  </label>
</div>
    </StyledContainer>
  );
};

export default CircularMenu;
