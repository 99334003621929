import React, { useEffect, useState } from "react";
import styled from "styled-components";
import productImg from "../../assets/images/drools-sm.PNG";
import deleteImg from "../../assets/images/delete.png";
import {
  fetchProductById,
  getCart,
  updateCart,
} from "../../features/web/web-actions";
import { deleteCart } from "../../features/web/web-actions";
import { useDispatch, useSelector } from "react-redux";

const StyleContainer = styled.div`
   .cart_header h1 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 14%;
    display: block;
    text-wrap: nowrap;
  }
  .chk_btn a {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0px;
  }
  p,
  span {
    font-weight: 600;
  }
  .cart_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .quantity {
    & .btn_div {
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      border-radius: 3px;
      padding: 3px 10px;
      & button {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        padding: 0px;
      }
    }
  }
  @media (max-width: 425px) {
    .cart_header h1 {
      font-weight: 600;
      font-size: 25px;
    }
    .text-end a u {
      font-size: 15px;
    }
    .text-end {
      margin: 1rem;
    }
  }
  @media (max-width: 768px) {
    .cart_div {
      flex-direction: column;
    }

    .cart_div img {
      margin-bottom: 1rem;
    }

    .quantity {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      margin-top: 1rem;
    }
    .price_div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 1rem;
    }

    .price_div p {
      padding-right: 1rem;
    }

    .quantity p,
    .price_div p {
      margin: 0;
    }

    .quantity .btn_div {
      display: flex;
      align-items: center;
    }

    .quantity button {
      margin: 0 0.5rem;
    }
  }
`;

function Cart() {
  const dispatch = useDispatch();
  const getCartData = useSelector((state) => state.cart.data?.rows || []);
  const [productDetails, setProductDetails] = useState([]);
  const [productCount, setProductCount] = useState(0);

  const userInfo =
  localStorage.getItem("userInfo") !== "undefined"
    ? localStorage.getItem("userInfo")
    : null;
let userId = null;
let userData = null;
if (userInfo) {
  userData = JSON.parse(userInfo);
  userId = userData.id;
}

  const handleUpdateCart = (cartId, quantity) => {
    if (quantity <= 0) return; // Prevent setting quantity less than or equal to 0
    dispatch(updateCart(cartId, quantity))
      .then(() => {
        dispatch(getCart(userId)).catch((error) => console.error("Error:", error));
      })
      .catch((error) => {
        console.error("Error updating cart:", error);
      });
  };

  useEffect(() => {
    dispatch(getCart(userId)).catch((error) => console.error("Error:", error));
  }, [dispatch]);

  useEffect(() => {
    if (getCartData.length > 0) {
      const productDetailsWithQuantity = [];
      const fetchProductDetails = async () => {
        for (const item of getCartData) {
          const {
            product_id: productId,
            id: cartId,
            quantity: cartQuantity,
          } = item;
          const product = await dispatch(fetchProductById(productId));
          productDetailsWithQuantity.push({
            ...product.payload.data[0],
            cartId,
            cartQuantity,
          });
        }
        setProductDetails(productDetailsWithQuantity);
      };

      fetchProductDetails();
    } else {
      setProductDetails([]);
    }
  }, [dispatch, getCartData]);

  const handleDelete = (cartId) => {
    dispatch(deleteCart(cartId))
      .then(() => {
        dispatch(getCart(userId)).catch((error) => console.error("Error:", error));
      })
      .catch((error) => {
        console.error("Error deleting from cart:", error);
      });
  };

  return (
    <StyleContainer>
      <div className="container">
        <div className="row">
          <div className="cart_header">
            <h1>Your Cart</h1>
          </div>
          <div className="text-end">
            <a href="/products">
              <u>Continue Shopping</u>
            </a>
          </div>
          {productDetails.length > 0 ? (
            productDetails.map(
              ({ name, PETPRODUCTIMAGE, selling_price, cartId, cartQuantity }, idx) => (
                <div className="col-md-12" key={idx}>
                  <div className="cart_div">
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${PETPRODUCTIMAGE.image}`}
                      width={150}
                      alt=""
                    />
                    <div>
                      <p style={{ width: "16em" }}>{name}</p>
                    </div>
                    <div className="quantity">
                      <p>Quantity</p>
                      <div className="btn_div">
                        <button
                          onClick={() =>
                            handleUpdateCart(cartId, cartQuantity - 1)
                          }
                        >
                          -
                        </button>
                        <span className="fw-bold">{cartQuantity}</span>
                        <button
                          onClick={() =>
                            handleUpdateCart(cartId, cartQuantity + 1)
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="price_div">
                      <p className="">Price </p>
                      <span>Rs. {selling_price * cartQuantity}</span>
                    </div>
                    <div>
                      <img
                        onClick={() => handleDelete(cartId)}
                        width={40}
                        src={deleteImg}
                        alt="delete_icon"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="col-md-12">
              <p>Your cart is empty.</p>
            </div>
          )}
          <hr />
          {productDetails.length > 0 && (
            <div className="text-end chk_btn">
              <a href="/checkout">Checkout</a>
            </div>
          )}
        </div>
      </div>
    </StyleContainer>
  );
}

export default Cart;