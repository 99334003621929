import React from "react";
import styled from "styled-components";
import Header from "../../components/Common/ComponentHeader";
import circle from "../../assets/images/Provider/circle.png"

const StyledContainer = styled.div`
    .ProviderView {
        background: #12D2FF;
        width: 100%;
        // height: 653px;
    }

    .ProviderView .heading {
        font-family: Montserrat;
        font-size: 41.81px;
        font-weight: 700;
        line-height: 50.97px;
        text-align: center;
        color: #FFFFFF;
        padding-top: 40px;
    }

    .ViewList{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .ViewList .content p {
        font-family: Montserrat;
        font-size: 16.31px;
        font-weight: 400;
        line-height: 19.88px;
        text-align: left;
        color: white;
    }

    .ViewList1 .content {
        margin-right: 2rem;
    }


    .headingContent h6 {
        font-family: Montserrat;
        font-size: 52.5px;
        font-style: italic;
        font-weight: 400;
        text-align: center;
    }

    .headingContent p {
        font-family: Montserrat;
        font-size: 16.45px;
        font-style: italic;
        font-weight: 400;
        line-height: 20.06px;
        text-align: right;
    }

    .headingContent {
        margin: 0rem 1.5rem;
    }

    .providerViewContent {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        flex-wrap: wrap;
    }

    .button button {
        font-family: Montserrat;
        font-size: 14.13px;
        font-weight: 700;
        line-height: 17.22px;
        text-align: center;
        background: black;
        border-radius: 8px;
    }

    .Visit h5 {
        font-family: Montserrat;
        font-size: 16.59px;
        font-weight: 400;
        line-height: 20.23px;
        text-align: center;
        color: #ffff;
    }

    .Visit a {
        font-family: Montserrat;
        font-size: 14.25px;
        font-weight: 700;
        line-height: 17.37px;
        text-align: center;
        color: #ffffff;
        padding: 0;
        margin: 0
    }

    @media(max-width: 1200px){
    .ViewList{
        display: flex;
        justify-content: space-between;
        align-items: center;
    } 
  }

    @media(max-width: 991px){
    .ViewList .content p {
        font-size: 14px;
        font-weight: 300;
        line-height: 19.88px;
    }


    .headingContent h6 {
        font-size: 45px;
        font-style: italic;
        font-weight: 400;
    }

    .headingContent p {
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
    }  
  }

    @media(max-width: 769px){
    .ProductViewlist1{
        margin-bottom: 1rem !important;
    } 

    .headingContent {
        margin: 0rem;
    }
  }

    @media(max-width: 580px){

    .ViewList {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .ViewList .content p {
        font-size: 12px;
        font-weight: 300;
        line-height: 19.88px;
    }


    .headingContent h6 {
        font-size: 40px;
        font-style: italic;
        font-weight: 400;
    }

    .headingContent p {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
    } 

    .button button {
        font-size: 13px;
    }

    .Visit {
        padding-bottom: 1rem !important;
    }

    .Visit h5 {
        font-size: 14px;
        font-weight: 400;
    }
  }

    @media(max-width: 400px){

    .ViewList {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .ViewList .content p {
        font-size: 11px;
        font-weight: 300;
        line-height: 19.88px;
    }


    .headingContent h6 {
        font-size: 30px;
        font-style: italic;
        font-weight: 400;
    }

    .headingContent p {
        font-size: 11px;
        font-style: italic;
        font-weight: 400;
    } 
  }

`;

const ProviderView = () => {
    return (
        <StyledContainer>
            <div className="ProviderView">
                <div className="container">
                        <div className="heading my-2">
                        We've been around the block
                        </div>
                    <div className="providerViewContent mt-5">
                        <div className="row d-flex justify-center mb-5 ProductViewlist1">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ViewList ViewList1">
                                <div className="headingContent">
                                    <h6>346</h6>
                                    <p>Pet Provider</p>
                                </div>
                                <div className="content">
                                    <p>Our community of 346 Pet <br /> Providers nationwide are pet <br /> parents, and it shows.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ViewList">
                                <div className="headingContent">
                                    <h6>16M+</h6>
                                    <p>Meals donated</p>
                                </div>
                                <div className="content">
                                    <p>We’ve donated over 16 million meals! <br /> A portion of our proceeds from the <br /> walks you book help to feed shelter <br /> dogs in your area. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-center mb-3">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ViewList">
                                <div className="headingContent headingContent3">
                                    <h6>6</h6>
                                    <p>Cities across India</p>
                                </div>
                                <div className="content">
                                    <p>Pet Providers with Petside have a <br /> trusted record of experience with <br /> a bunch of pet care services, <br /> including Sitting, Walking, Boarding <br /> & Training across Mumbai, Pune, <br /> Bangalore, Ahmedabad, Surat, Delhi.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3 ViewList">
                                <div className="headingContent">
                                    <h6>87%</h6>
                                    <p>5-star reviews</p>
                                </div>
                                <div className="content">
                                    <p>Making Pet Parents happy is what <br /> we do best — 87% of Petside! services <br /> result in a 5-star review. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button text-center pb-5">
                        <a href="#providerForm">
                            <button className="btn btn-dark">Sign Up Now</button>
                        </a>
                    </div>
                    {/* <div className="Visit text-center pb-5">
                        <h5>Have some questions? Visit our 
                            <a href="#">FAQ page</a>
                        </h5>
                    </div> */}
                </div>
            </div>
        </StyledContainer >

    )
}

export default ProviderView;