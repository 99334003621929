import styled from "styled-components";
//import NewsSearch from "./NewsSearch";
import BlogDetail from "./blog-detail";
import { useParams } from "react-router-dom";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import MoreLikeThis from "./MoreLikeThis";
import BlogSlider from "../Blogs/BlogSlider";


const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const Blog = () => {
  const { id = 7, preview } = useParams();
  // const isAuthenticated = useSelector((state) => state);
  // //const { isAuthenticated } = useSelector((state) => state.users);
  // console.log(isAuthenticated,"isAuthenticated")
  //  if (!!preview) {
  //    if (isAuthenticated) {
  //    return (
  //        <BodyWrapper>
  //         {/* <NewsSearch /> */}
  //          <BlogDetail />
  //        </BodyWrapper>
  //      );
  //  }
  //   return <div>Not Authorized</div>;
  //  }
  return (
    <BodyWrapper>
      {/* <NewsSearch /> */}
      <BlogDetail />
      <MoreLikeThis /> 
      {/* <BlogSlider header1="More like this" header2="" /> */}
    </BodyWrapper>
  );
};

export default Blog;
