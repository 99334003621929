import React from 'react'
import ProductListingSlider from '../homepage/ProductListing'
import ProductHeader from './ProductHeader'


function Product() {
  return (
    <div>
        <ProductHeader/>
        <ProductListingSlider/>
        {/* <ProductListingSlider/> */}
        {/* <ProductListingSlider/> */}
        {/* <ProductListingSlider/> */}
    </div>
  )
}

export default Product