import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/Env";


const token =
  localStorage.getItem("token") !== "undefined"
    ? localStorage.getItem("token")
    : null;

const userInfo =
  localStorage.getItem("userInfo") !== "undefined"
    ? localStorage.getItem("userInfo")
    : null;


//Provider registeration Mail trigger
export const registerProvider = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/auth/register`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};

export const servicesBooking = async (postdata) => {
  try {
    console.log(postdata);
    const resp = await fetch(`${API_BASE_URL}/api/pet/service/booking/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    const response = await fetch(`${API_BASE_URL}/api/pet/coupon/remove-coupon`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return resp;
  } catch (error) {
    return error;
  }
};
export const sendOtp = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/auth/otp/register`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};
export const loginWithOtp = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/auth/otp/login`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: { 'Content-Type': 'application/json' }
    }).then((data) => data.json());
    if (resp) {
      // if (rememberMe) {
      console.log(resp);
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("userInfo", JSON.stringify(resp.data.user));
      // }

      return resp;
    }
    return resp;
  } catch (error) {
    return error;
  }
};
export const fetchUserById = createAsyncThunk(
  'user/fetchById',
  async (userId, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/user/view/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user');
      }
      const userData = await response.json();
      return userData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchUserAllDetail = createAsyncThunk(
  'user/profileData',
  async (userId, thunkAPI) => {

    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/user/view/all-user-details/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user');
      }
      const userData = await response.json();
      console.log("User data", userData)
      return userData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchDeliveryAddress = createAsyncThunk(
  'deliveryAddress/fetchById',
  async (userId, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet//deliveryaddress/get/user/${userId}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const deliveryAddressById = await response.json();
      return deliveryAddressById;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getCart = createAsyncThunk(
  "cart/fetch",
  async (userId, thunkAPI) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/pet/cart/get/user/${userId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then((response) => response.json());
      if (success) {
        return data;
      }
      throw new Error("Request failed");
    } catch (error) {
      console.error("Error fetching cart:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createCart = (product_id, quantity, user_id) => {
  return async (dispatch, getState) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/cart/create`, {
        method: 'POST',
        body: JSON.stringify({ product_id, quantity, user_id }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      const cartData = await response.json();

      if (response.ok) { 
        dispatch({ type: 'CREATE_CART_SUCCESS', payload: cartData });
      } else {
        dispatch({ type: 'CREATE_CART_FAILURE', error: cartData.message || 'Failed to create cart' });
      }
      return response; 
    } catch (error) {
      dispatch({ type: 'CREATE_CART_FAILURE', error: error.message });
      throw error; 
    }
  };
};

export const updateCart = (cart_id, quantity) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/cart/update`, {
        method: 'POST',
        body: JSON.stringify({ cart_id, quantity }),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const cartData = await response.json();
      dispatch({ type: 'UPDATE_CART_SUCCESS', payload: cartData });
    } catch (error) {
      dispatch({ type: 'UPDATE_CART_FAILURE', error: error.message });
    }
  };
};

export const deleteCart = (productId) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/cart/delete/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to delete item from cart');
      }
      dispatch({ type: 'DELETE_CART_SUCCESS', productId });
    } catch (error) {
      dispatch({ type: 'DELETE_CART_FAILURE', error: error.message });
    }
  };
};

export const fetchProductById = createAsyncThunk(
  'product/fetchById',
  async (productId, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/product/view/${productId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchServicesById = createAsyncThunk(
  'services/fetchById',
  async (servicesId, thunkAPI) => {
    console.log("here is your service id", servicesId)
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/service/view/${servicesId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      console.log("Here is your PRoduct data ==>", productData)
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchServicesBySlug = createAsyncThunk(
  'services/fetchBySlug',
  async (servicesSlug, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/service/view/slug/${servicesSlug}`);
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createProductOrder = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/order/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};


export const createEnquiry = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/contacts/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};

export const NewsLetterSignup = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/contacts/createNewsLetter`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());
    console.log("response from api", resp)
    return resp;
  } catch (error) {
    console.log("errrrorrrr", error)
    return error;
  }
};

export const OfferSignup = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/contacts/OfferSignup`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());
    console.log("response from api", resp)
    return resp;
  } catch (error) {
    console.log("errrrorrrr", error)
    return error;
  }
};



export const updateUserById = async ({ body, id }) => {
  try {
    const config = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    const res = await fetch(
      `${API_BASE_URL}/api/pet/user/edit/${id}`,
      config
    ).then((data) => data.json());
    return res;
  } catch (error) {
    return error;
  }
};
export const createDeliveryAddress = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/deliveryaddress/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};
export const fetchBlogs = createAsyncThunk(
  'blogs/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/blog/display`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch blogs');
      }
      const blogsData = await response.json();
      return blogsData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchTestimonials = createAsyncThunk(
  'blogs/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/testimonial/display`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch testimonials');
      }
      const blogsData = await response.json();
      return blogsData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchPetProviderData = createAsyncThunk(
  'petProvider/fetchData',
  async ({ lat, lng }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/user/provider/get?lat=${lat}&lng=${lng}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchPackages = createAsyncThunk(
  'packages/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/package/get/home`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch testimonials');
      }
      const packageData = await response.json();
      return packageData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchGalleryPhoto = createAsyncThunk(
  'galleryphoto/fetchAll',
  async (_, thunkAPI) => {
    try {
      // GET  http://localhost:5145/api/pet/galleryphoto/get/home
      const response = await fetch(`${API_BASE_URL}/api/pet/galleryphoto/get/home`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      // console.log("Token: ", token)
      if (!response.ok) {
        throw new Error('Failed to fetch GalleryPhoto!....');
      }
      // console.log("Localhost: ", API_BASE_URL);           // https://admin-uat.petside.in
      const GalleryPhotoData = await response.json();
      // const GalleryPhotoData = await response;
      console.log("GalleryPhotoData: ", GalleryPhotoData);
      // console.log("GalleryPhotoData JSON: ", GalleryPhotoData.json());
      // console.log("Fetch GalleryPhotoData Data: ", typeof (GalleryPhotoData))
      return GalleryPhotoData;
    } catch (error) {
      console.log("Error: ", error)
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchDogMaster = createAsyncThunk(
  'dogs/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/service/booking/get/master/data`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch testimonials');
      }
      const packageData = await response.json();
      return packageData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchCoupons = createAsyncThunk(
  'coupons/fetchAll',
  async (_, thunkAPI) => {
    try {

      const response = await fetch(`${API_BASE_URL}/api/pet/coupon/applied-coupons`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      console.log("Response ---- ", response);

      if (!response.ok) {
        throw new Error('Failed to fetch coupon Data');
      }
      console.log("coupons", coupons)
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const RemoveCoupons = createAsyncThunk(
  'coupons/remove',
  async (id, thunkAPI) => {
    try {
      console.log("button", id)
      const response = await fetch(`${API_BASE_URL}/api/pet/coupon/remove-coupon`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coupon Data');
      }
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const fetchAppliedCoupons = createAsyncThunk(
  'coupons/Appiled',
  async (coupon, thunkAPI) => {
    try {

      const response = await fetch(`${API_BASE_URL}/api/pet/coupon/applied-coupons`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coupon Data');
      }
      const coupons = await response.json();
      console.log("coupons applied data --- ", coupons)
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const ApplyCoupon = createAsyncThunk(
  'coupons/ApplyCoupon',
  async (coupon, thunkAPI) => {
    try {
      console.log("button", coupon);
      const requestBody = {
        coupon: coupon.coupon_code,
        service_id: coupon.service_id
      };

      console.log("requestBody --- ", requestBody);

      const response = await fetch(`${API_BASE_URL}/api/pet/coupon/getbyName`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to fetch coupon data');
      }

      console.log("coupons ---- ", responseData);

      return { message: responseData.message, data: responseData.data };
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const ApplyCouponProduct = createAsyncThunk(
  'coupons/ApplyCouponProduct',
  async (coupon, thunkAPI) => {
    try {
      console.log("button", coupon);
      const requestBody = {
        coupon: coupon.coupon_code,
        service_id: coupon.product_id,
      };

      console.log("requestBody --- ", requestBody);

      const response = await fetch(`${API_BASE_URL}/api/pet/coupon/getbyName`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || 'Failed to fetch coupon data');
      }

      console.log("coupons ---- ", responseData);

      return { message: responseData.message, data: responseData.data };
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  }
);

export const fetchProductBookings = createAsyncThunk(
  'productBookings/fetchById',
  async (id, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet/order/get/${id}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchServicesBookings = createAsyncThunk(
  'servicesBookings/fetchById',
  async (id, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet/service/booking/get/${id}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchPetProfile = createAsyncThunk(
  'servicesBookings/fetchById',
  async (id, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet/userpet/get/${id}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const productData = await response.json();
      return productData;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchDeliveryAddressById = createAsyncThunk(
  'deliveryAddress/fetchById',
  async (id, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet/deliveryaddress/view/${id}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const deliveryAddressById = await response.json();
      return deliveryAddressById;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPetProfile = async (postdata) => {
  try {
    const resp = await fetch(`${API_BASE_URL}/api/pet/userpet/create`, {
      method: "POST",
      body: JSON.stringify(postdata),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then((data) => data.json());

    return resp;
  } catch (error) {
    return error;
  }
};

export const updateUserDeliveryAddressById = async ({ body, id }) => {
  try {
    const config = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    const res = await fetch(
      `${API_BASE_URL}/api/pet/deliveryaddress/edit/${id}`,
      config
    ).then((data) => data.json());
    return res;
  } catch (error) {
    return error;
  }
};

export const updatePetProfile = async ({ body, id }) => {
  try {
    const config = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    const res = await fetch(
      `${API_BASE_URL}/api/pet/userpet/edit/${id}`,
      config
    ).then((data) => data.json());
    return res;
  } catch (error) {
    return error;
  }
};
export const fetchPetProfileById = createAsyncThunk(
  'petProfile/fetchById',
  async (id, thunkAPI) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
      const response = await fetch(`${API_BASE_URL}/api/pet/userpet/view/${id}`, {
        headers
      });
      if (!response.ok) {
        throw new Error('Failed to fetch product');
      }
      const petProfileById = await response.json();
      return petProfileById;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const getBlogById = createAsyncThunk(
  'blog/fetchAll',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/blog/view/${id}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coupon Data');
      }
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getBlogByslug = createAsyncThunk(
  'blog/fetchAll',
  async (slug, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/blog/slug/${slug}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch coupon Data');
      }
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const publishBlog = createAsyncThunk(
  'blog/publishBlog',
  async (id, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/blog/publish/${id}`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch publishBlog Data');
      }
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const searchBlogs = createAsyncThunk(
  'blog/searchBlogs',
  async (data, thunkAPI) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/pet/blog/search?page=1&size=3&requestFetchType=next`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch publishBlog Data');
      }
      const coupons = await response.json();
      return coupons;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);