import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserById, updateUserById } from "../../features/web/web-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const StyledContainer = styled.div`
  .card {
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .time-slot-card {
    border: 1px solid;
    padding: 10px 18px;
    border-radius: 5px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .form-control {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .custom_btn {
    width: 100%;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #008db3;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #114c5f;
    }

    &:disabled {
      background-color: #6c757d;
      cursor: not-allowed;
    }
  }

   .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 1em;
  }


  @media (max-width: 768px) {
  .card{
      margin:0rem;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }

    .hamburger {
      display: block;
    }
  }

`;

function MyProfile(props) {
  const formData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
  };

  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;

  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    user_id = userData.id;
  }

  const dispatch = useDispatch();
  const location = useLocation();
  const [fd, setFd] = useState(formData);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const getUser = useSelector((state) => state.userById.data || []);

  useEffect(() => {
    dispatch(fetchUserById(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getUser && getUser.data) {
      setFd(getUser.data);
    }
  }, [getUser]);

  const handleUserSubmit = (formData, userId) => {
    return updateUserById({
      body: formData,
      id: userId,
    }).then((resp) => {
      return resp;
    });
  };

  const handleSubmit = (formData, { setSubmitting }) => {
    handleUserSubmit(formData, user_id).then((resp) => {
      if (!resp.success) {
        if (resp.responseCode === 422) {
          const newErrors = {};
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        message.success("Profile updated successfully");
        setSubmitting(false);
      }
    });
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = 'First name is required';
    } else if (values.first_name.length < 2) {
      errors.first_name = 'First name must be at least 2 characters';
    } else if (values.first_name.length > 30) {
      errors.first_name = 'First name must be less than 30 characters';
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required';
    } else if (values.last_name.length < 2) {
      errors.last_name = 'First name must be at least 2 characters';
    } else if (values.last_name.length > 30) {
      errors.last_name = 'First name must be less than 30 characters';
    }

    if (!values.email) {
      errors.email = 'Email is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    } else if (values.email.length < 2) {
      errors.email = 'First name must be at least 2 characters';
    } else if (values.email.length > 40) {
      errors.email = 'First name must be less than 40 characters';
    }

    return errors;
  };

  return (
    <StyledContainer>
        {/* <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
          <FontAwesomeIcon icon={faTimes} className="close-btn" onClick={toggleSidebar} />
          <ul className="list-unstyled">
            <li>
              <a href="/auth/profile">Profile Info</a>
            </li>
            <li>
              <a href="/auth/saved-address">Saved Address</a>
            </li>
            <li>
              <a href="/auth/order-history">Order History</a>
            </li>
            <li>
              <a href="/auth/pet-profile">Pet Profile</a>
            </li>
            <li>
              <a href="/contact-us">Contact us</a>
            </li>
            <li onClick={logoutHandler} >
              <a href="/">Logout</a>
            </li>
          </ul>
        </div>
        <FontAwesomeIcon icon={faBars} className="hamburger" onClick={toggleSidebar} /> */}
      <div className="container-fluid">
        <Formik
          initialValues={fd}
          enableReinitialize={true}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="card">
              <h2 className="text-center fw-bold">My Profile</h2>
              <div className="row mb-3">
                <div className="col">
                  <h6>First Name</h6>
                  <Field className="form-control" maxLength={30} type="text" name="first_name" />
                  <ErrorMessage name="first_name" component="div" className="text-danger" />
                </div>
                <div className="col">
                  <h6>Last Name</h6>
                  <Field className="form-control" maxLength={30} type="text" name="last_name" />
                  <ErrorMessage name="last_name" component="div" className="text-danger" />
                </div>
              </div>

              <h6>Email</h6>
              <Field className="form-control" maxLength={40} type="email" name="email" />
              <ErrorMessage name="email" component="div" className="text-danger" />

              <h6>Contact No</h6>
              <Field className="form-control" type="text" name="contact_no" disabled />

              <button
                type="submit"
                disabled={isSubmitting}
                className="custom_btn mt-4"
              >
                Continue
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </StyledContainer>
  );
}

export default MyProfile;
