import React from "react";
import styled from "styled-components";
import providerimg from "../../assets/images/Provider/provider-dog.png"

const StyledContainer = styled.div`
    .container-fluid{
    height: auto;
    padding: 0 0 ;
    max-height: 34rem;
    }
    .main-container{
    height: auto;
    padding: 5rem 12rem;
    background: #DDF9FF;
    }
    .loveDog h1{
    color: #727272;
    font-size: 42.19px;
    font-weight: 700;
    line-height: 51.43px;
    text-align: left;
    }
    .loveDog p{
    width:70%;
    color:#727272;
    font-size: 16.59px;
    font-weight: 400;
    line-height: 20.23px;
    text-align: left;
    margin: 3rem 0rem;
    }
    .loveDog h6{
    font-size: 15.89px;
    font-weight: 700;
    line-height: 19.37px;
    text-align: left;
    }
    .custom-shape-divider-bottom-1722405445 {
    position: relative;
    bottom: 132px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
    }
    .custom-shape-divider-bottom-1722405445 svg {
        position: relative;
        display: block;
        width: calc(106% + 1.3px);
        height: 132px;
        transform: rotateY(180deg);
    }
    .custom-shape-divider-bottom-1722405445 .shape-fill {
        fill: #FFFFFF;
    }

    .Image img {
            width: 275px;
            height: 325px;
        }

    @media(max-width: 1300px){
        .loveDog h1 {
            color: #727272;
            font-size: 36px;
            font-weight: 700;
            line-height: 45px;
            text-align: left;
        }

        .loveDog p{
            width:80%;
            color:#727272;
            font-size: 14px;
            font-weight: 400;
            line-height: 20.23px;
            text-align: left;
            margin: 1.5rem 0rem;
        } 

        .Image img {
            width: 220px;
            height: 270px;
        }

        .container-fluid{
            height: auto;
            padding: 0 0 ;
            max-height: 32rem;
        }
    }

    @media(max-width:1024px){
            .container-fluid{
            max-height: 34rem;
            }

            .Image img {
            width: 250px;
            height: 300px;
            }
    }

    @media(max-width: 960px){
            .container-fluid{
            max-height: 36rem;
            }
    }

    @media(max-width: 936px){
        .main-container {
            height: auto;
            padding: 2rem 6rem;
            background: #DDF9FF;
        }

        .container-fluid{
            max-height: 30rem;
        }
    }

    @media(max-width: 767px){

        .container-fluid{
            height: auto;
            padding: 0 0 ;
            max-height: 88vh;
        }

        .main-container {
            padding: 1rem 2rem;
            height: auto;
        }

        .loveDog {
            margin-top: 1.5rem;
            flex-direction: column-reverse;
            align-items: center;
        }

        .loveDog h1 {
            color: #727272;
            font-size: 30px;
            font-weight: 700;
            line-height: 30px;
            text-align: left;
        }

        .loveDog p{
            width:80%;
            color:#727272;
            font-size: 12px;
            font-weight: 400;
            line-height: 20.23px;
            text-align: left;
            margin: 1.5rem 0rem;
        } 

        .Image {
            margin-bottom: 5rem !important;
        }

        .Image img {
            width: 220px;
            height: 264px;
        }
    }

    @media(max-width: 630px){
        .container-fluid{
            max-height: 96vh;
        }
    }

    @media(max-width: 440px){
        .container-fluid{
            max-height: 104vh;
        }
    }

    
`;

const Lovedogs = () => {   
    return (
    <StyledContainer>
        <div className="container-fluid">
            <div className="main-container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 loveDog text-center loveDog mb-4">
                            <h1>Love pets? become a provider.</h1>
                            <p>Whether you’re a seasoned dog trainer, a skilled groomer, a caring pet sitter, or a knowledgeable veterinarian, Petside India offers the perfect platform to showcase your talents and grow your business. Join us in our mission to provide the best care for pets and make a difference in the lives of pet owners everywhere.</p>
                            {/* <h6>Meet some Provider who provide for Petside!</h6> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 loveDog d-flex justify-content-center Image mb-5">
                            <img src={providerimg} alt="" />
                    </div>
                </div>
            </div>
                <div class="custom-shape-divider-bottom-1722405445">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>    
            </div>
    </StyledContainer>
    )
}

export default Lovedogs;