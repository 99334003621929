import React from "react";
import styled from "styled-components";
import BlogSlider from "./BlogSlider";
import BlogList from "./bloglist";
import BlogHeader from "./BlogHeader"

const StyledContainer = styled.div`

`;

function Blog() {
  return (
    <div>
    <BlogHeader />
    <BlogList /> 
    </div>
  );
}



export default Blog;
