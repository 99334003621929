import styled from "styled-components";

const StyledContainer = styled.div`
background: #E2FBFF;
padding: 0px 0px;
margin-bottom:6rem;

.container-fluid{
    margin-top: 6rem !important;
}
    
.row{
    text-align:center;
    display: flex;
    align-items: center;
}
.col1{
    align-items: center;
    flex-direction: column;
}
.col1 p{
    font-size: 40px;
    font-weight: 400;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: center;
}

    .col2 p{
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 1.5px;
    padding-bottom : 5%;
    text-align: left;
    }

    .col1 span{
        color: #008DB3;
        font-family: "Alex Brush";
        font-size: 55px;
        font-weight: 400;
    }

    .col2 a{
        font-size:15px;
        background: #fff;
        color: #008EB4 !important;
        padding: 1.5% 8%;
        border-radius: 5px;
        margin: 1rem 0em;
    }


@media (max-width: 540px){
    .col1 {
        align-items: flex-start;
    }
}
@media(max-width: 425px){
    
    .col1 span {
         font-size: 50px;
         padding:6px;
     }
     .col1 p {
        font-size: 15px;
        font-weight: 400;
    }
     .col2 p{
        font-size:16px; 
    }
    .container_fluid{
        padding: 2rem 0rem;
    }
}
@media(max-width: 320px){
    .col1 span {
        font-size: 40px;
        font-weight: 400;
        
    }
    .col1 p {
        font-size: 18px;
    }
 }
`;

const Appointment = () => {

  
  return (
    <StyledContainer>
    <div className="container-fluid px-5 py-5">
        <div className="row">
            <div className="col-md-6 ">
                <div className="col1 pl-5">
                <span>Slip, clip & style-</span>
                <p>the art of pet grooming</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="col2">
                <p>From fluff to fabulous, our grooming sessions pamper your pets with love and care, leaving them looking and feeling like the bark or purrfect stars they are!</p>
                          <div style={{textAlign: "left"}}>
                              <a href="/services">Book a service</a>
                          </div>
                </div>
            </div>
        </div>
    </div>
    </StyledContainer>
  );
};

export default Appointment;
