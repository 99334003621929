import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/images/dogs/digital-money_6045630.png'
import img2 from '../../assets/images/dogs/dog-competition_6690136.png'
import img3 from '../../assets/images/dogs/cat_4997575.png'
const StyledContainer = styled.div`
padding-top: 6em;
.wwr h6, .home_about h6{
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008DB3;
    width: 20%;
    display: block;
    text-wrap: nowrap;
}
.wwr p, .home_about p{
    line-height: 2;
}
.wwr , .home_about{
    padding-top: 0em;
}
.booking_steps{
    padding-top: 6em;
    text-align:center;
    & h6{
        font-size: 22px;
        font-weight: 700;
    }
    & img{
        width: 160px;
        padding-top: 2em;
    }
    & p{
        
    }
}
`;
function WhyUs() {
  return (
    <StyledContainer>
        <div className='container'>
            <div className="row home_about">
                <div className="col-md-12">
                   <h6>Why Choose Us</h6>  
                    <p>
                    Are you a working professional /A business owner/a busy pet parent? Then you know, it's not always practical to take the pupper with you everywhere. 
                    After all, a calm, private home is often preferable to a kennel environment, and some owners may even want a sitter to stay at their house so their dog doesn't have to leave the comfort of his familiar surroundings. We have professionals right from CEO's, CXO's, to flight attendants, college student to senior sitters.Oldest being 71!! - - all working for us just out of purrrr love. Not only that, each and every walker and sitter has passed a thorough vetting process that includes a *30 point checklist  before being approved as a # PETSTAR .
                    </p>
                </div>
                {/* <div className='col-md-6'>
                    <img src={img1} style={{width: '500px',height: '540px'}} alt="" />
                </div> */}
            </div>
            <div className="row booking_steps">
                <h6>With Petside, book top rated pet services in 3 simple steps</h6>
                <div className="col-md-4">
                    <img src={img2} alt="" />
                    <h6 style={{color:"#fe646f"}}>Search a sitter</h6>
                    <p> (700+ professional, verified sitters near you)</p>
                </div>
                <div className="col-md-4">
                    <img src={img3} alt="" />
                    <h6 style={{color:"#35d175"}}>Schedule</h6>
                    <p> (pet sitting, training, walking, daycare, Drop-in visits & more services)</p>
                </div>
                <div className="col-md-4">
                    <img src={img1} alt="" />
                    <h6 style={{color:"#ffd119"}}>Book</h6>
                    <p> (hassle free) </p>
                </div>
            </div>
        </div>
    </StyledContainer>
  )
}

export default WhyUs