import React from "react";
import styled from "styled-components";
// import img1 from "../../assets/images/services/Petside-about-us-banner.jpg";
import img1 from "../../assets/images/about/left-dog.png";
import img2 from "../../assets/images/about/right-dog.png";
import AboutBanner from "../../assets/images/about/Banner_About.png";
import img3 from "../../assets/images/services/Petside-about-us-image.jpg";
import icon from "../../assets/images/icon11.png";
import ig from "../../assets/images/footer/prime_instagram.png";
import fb from "../../assets/images/footer/ic_round-facebook.png";
import yt from "../../assets/images/footer/mingcute_youtube-line.png";

const StyledContainer = styled.div`

.about_section {
  // width: 100%;
  margin: 0;
  padding: 0;
}

  // .about_us_banner .banner{
  //   // background: url(${AboutBanner});
  //   width: 100%;
  //   height: 602px;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   overflow: hidden;
  //   font-weight: 800;
  //   text-align: center;
  //   align-content: center;
  //   color: black;
  // }

  .about_us_banner {
    padding: 0;
    margin: 0;
  }

  .about_us_banner .banner {
    position: relative;
    overflow: hidden;
  }

  .about_us_banner .banner img {
    width: 100%;
    height: 598px;
    object-fit: cover;
  }

  .buttons {
    position: absolute;
    top: 58%;
    left: 20%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    // color: white;
    z-index: 0;
  }


  .about_us_banner .banner .buttons{
    & a{
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 18.29px;
        letter-spacing: 2px;
        text-align: center;
        background: #000000;
        color: #fff;
        padding: 1vw 4vw;
        border-radius: 5px;
        margin-left: 15vw;
        margin-top: 18vh;
    }
  }

// About Section:

  .about_us_heading {
    padding: 1em 0em;
  }

  .about_us_heading h4 {
    font-family: Montserrat;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 36.57px;
    letter-spacing: 2px;
    text-align: left;
    // margin-left: 10px;
  }

  .about_us_heading h5 {
    border-bottom: 4px solid #12D2FF;
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
    text-align: left;
  }
    
    
    .about_heading {
      text-align: justify;
      // width: 60vw;
      margin: auto;
      padding: 10px;
    }
    
    .Dog_Img
    {
      position: relative;
      top: 18vh;
      right: -5vw;
    }
    
    .about_us_info{
        padding: 10px 0px;
    }
    
    .about_us_info .row {
      display: flex;
      align-items: center;
      justify-content: start;
    }
    // .about_heading h2 {
    // width: 528px;
    // height: 68px;
    // }
}

.about_heading p {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: justified;
}

.about_us_info h3 {
padding-top: 20px;
font-size: 28px;
font-weight: 600;
line-height: 34.13px;
letter-spacing: 2px;
text-align: left;
}

.about_us_info h4 {
    font-size: 20px;
    font-weight: 700;
    color: #008DB3;
    margin-top: 20px;
}


.about_content_img{
  // width: 75%;
  margin: auto;
  // border-radius: 75px;
  // box-shadow: 10px 10px #55c8d89e;
  border: none;
  width: 394.22px;
  Height: 521px;
}

.about_img img {
  // width: 394.22px;
  // Height: 521px;
  border-radius: 30px;
}

.about_banner {
  border-radius: 20px;
  margin-right: auto;
  // position: relative;
  // z-index: 10;
  // left: -6%;
}

.about_icon h4 {
    // width: 639px;
    height: 43px;
    // padding: 20px;
    color: #12D2FF;
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
    // text-align: left;
}

.social_icon img {
    height: 30px;
    width: 30px;
    margin: 0 10px;
}


  .banner .buttons a{
    font-size: 13px;
  }

  @media (max-width: 768px) {
    .about_us_banner .banner img {
      width: 100%;
      height: 400px;
    }
  }

  @media (max-width: 768px) {
    .dog_image {
      display: none;
    }
      
    .about-section {
    max-width: 100%;
    }

    .about_us_banner .banner .buttons a {
    padding: 1.5vw 5vw;
    }

    .banner img {
      height: 280px !important;
    }
  }

  @media(max-width: 480px){
    .banner img {
      height: 217px !important;
    }
  }

  @media(max-width: 540px){
       .about_us_banner .banner {
        flex-wrap: wrap;
    }
  }

  @media (max-width: 400px) {
    .about_content_img {
      width: 250px;
      height: 300px
    }
  }

  @media (max-width: 383px) {
    .firstHeading{
      margin-bottom: 40px !important;
    }
  }

`;

function AboutUs() {
  return (
    <StyledContainer>
      {/* About US Banner */}
      <div className="container-fluid about_us_banner">
        <div className="banner">
          <img src={AboutBanner} alt="About Us Banner" />
          <div className="buttons">
            <a href="services">Check Services</a>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <div className="container about_us_info">
        <div className="about_section d-flex justify-content-center">
          <div className="Dog_Img">
            <img className="dog_image" src={icon} alt="" />
          </div>
          <div className="about-section row">
            <div className="col-lg-6 about_img">
              <div className="about_content_img">
                <img
                  className="about_banner"
                  src={img3}
                  alt=""
                  width="100%"
                  height="auto"
                />
              </div>
            </div>
            <div className="col-lg-6 about_heading">
              <div className="about_us_heading">
                <h4 className="">Know</h4>
                <h5 className="">ABOUT US</h5>
              </div>

              <div className="sub_heading">
                <h3 className="firstHeading">
                  Essential Components of Pet Care
                </h3>
                <p className="mb-2">
                  Pets can be wonderful companions, providing not only love and
                  companionship but also numerous physical and mental health
                  benefits. Here are some aspects of pet care that highlight the
                  positive impact pets can have on our lives.
                </p>
                <h3>9 Years of Work Experience</h3>
                <p>
                  Pet care is a multifaceted and rewarding experience that
                  involves various aspects of nurturing, companionship, and
                  responsibility. Here's a breakdown of the typical experiences
                  associated with caring for a pet
                </p>
                <h3>Cornified and Expert Groomers</h3>
                <p>
                  Pet care groomers play a crucial role in maintaining the
                  health and well-being of pets through grooming services.
                </p>

                <div className="about_icon">
                  <h4 className="">Follow Us</h4>
                  <div className="social_icon">
                    <img src={ig} alt="" />
                    <img src={fb} alt="" />
                    <img src={yt} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
}

export default AboutUs;
