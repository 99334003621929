import React from 'react';
import styled from 'styled-components';
import Whatsapp from "../../assets/images/whatsapp.png";
import Telephone from "../../assets/images/telephone.png";

const StyledContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  .whatsappBtn, .telephoneBtn {
    display: block;
    width: 50px; 
    height: 50px;
    cursor: pointer;
  }

  .whatsappBtn img, .telephoneBtn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

function CallingButtons() {
  return (
    <StyledContainer>
      <div>
        <a className='telephoneBtn' href="tel:+919136776162"><img src={Telephone} alt="Telephone" /></a>
      </div>
      <div>
        <a className='whatsappBtn' href="https://wa.me/+919136776162" target="_blank" rel="noopener noreferrer"><img src={Whatsapp} alt="WhatsApp" /></a>
      </div>
    </StyledContainer>
  );
}

export default CallingButtons;
