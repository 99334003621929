import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchPackages } from "../../features/web/web-actions";
import ServiceModal from './ServiceModal';

const StyledContainer = styled.div`
  .sp_row .card-body {
    background: #e2fbff;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .card-img-top{
    height: 190px;
  }
  .sp_row .order_now {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 9px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .sp_row h6 {
    color: #114c5f;
    font-weight: 700;
  }
  .sp_row h5,
  li {
    font-weight: 700;
  }
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px
     solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
  }
  .sp_btm_head h5 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  .sp_btm_head p {
    text-align: justify;
    padding: 1em 4em;
  }
  @media (max-width: 425px) {
    .sp_head h2 {
      margin: 1rem !important;
      margin-bottom: 2rem !important;
    }
    .sp_btm_head {
      font-size: 15px;
    }
  }
`;

function SpecialPackage() {
  const dispatch = useDispatch();
  const [getPackageData, setPackageData] = useState([]);
  const packageData = useSelector((state) => state.packages.data);
  console.log("package data", packageData);

  useEffect(() => {
    dispatch(fetchPackages());
    const dt = dispatch(fetchPackages());
    console.log("Data: ", dt);
  }, [dispatch]);

  useEffect(() => {
    if (packageData) {
      setPackageData(Object.values(packageData));
    }
  }, [packageData]);

  const { id, title, support, visit, walks, spa} = getPackageData;

  return (
    <StyledContainer>
      <div className="container py-5">
        <div className="sp_head">
          <h2>
            {" "}
            <span style={{ "font-weight": "700" }}>Our Special</span> <br />
            <span> Packages & Plans </span>
          </h2>
        </div>
        <div className="sp_btm_head">
          <h5>Our Pet Care Bundle</h5>
          <p>
          Our dedicated team of pet care professionals is driven by a genuine passion 
          for animals and a commitment to ensuring their well-being.
          </p>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 sp_row">
          {getPackageData[2] &&
            getPackageData[2].map(
              (
                { title, support, visits, walks, spa, price, thumbnail, tag },
                idx
              ) => (
                <div key={idx} className="col">
                  <div className="card">
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                      // src={img1}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-body">
                      <h5 className="card-title">{title}</h5>
                      <h6>Rs. {price}</h6>
                      <ul>
                        <li>{support}</li>
                        <li>{visits} Vet Visits</li>
                        <li>{walks} Dog Walks</li>
                        <li>{spa} Dog Spa</li>
                      </ul>
                    </div>
                    <div className="order_now">Order Now</div>
                    {/* <ServiceModal className="order_now" bgColor="#000000"  serviceId={id} />   */}
                  </div>
                </div>
              )
            )}

          {/* {console.log("title Data: ", getPackageData[2])} */}
        </div>
      </div>
    </StyledContainer>
  );
}

export default SpecialPackage;
