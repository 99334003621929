import React, { useState} from "react";
import styled from "styled-components";
import HeaderImage from "../../assets/images/Provider/Background.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FileInput from "../../components/Common/FileInput";
import { message } from "antd";
import 'bootstrap/dist/css/bootstrap.min.css';
import { registerProvider } from "../../features/web/web-actions";
import provider1 from "../../assets/images/Provider/provider1.png";
import provider2 from "../../assets/images/Provider/provider21.png";
import provider3 from "../../assets/images/Provider/provider3.png";
import provider4 from "../../assets/images/Provider/provider41.png";
import provider5 from "../../assets/images/Provider/provider51.png";

const StyledContainer = styled.div`
    .row{
        --bs-gutter-x: 0rem;
    }
    .form-control{
    margin:5px 0px;
    }

    .field-group{
    margin: 10px 0px;
    }

    .fluid-container{
        height: 75rem;
        // max-height:165vh;
    }

    button{
        background-color: #114C5F;
    }

    .field_error{
    font-size: 14px;
    color:red;
    }

    .bg_img {
        // background-image: url(${props => props.bgImage});
        background-size: contain;
        // background-repeat: repeat;
        // height: 152vh;
        background-image: linear-gradient(to right,  #00b6d1, #0057a5 );
    }

    .form_content{
    position:relative;
    z-index: 1;
    background-color: white;
    padding:20px;
    box-shadow: 4px 4px 4px 0px #00000040;
    }

    .custom-shape-divider-bottom-1722409066 {
        position: relative;
        bottom: 145px;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }

    .custom-shape-divider-bottom-1722409066 svg {
        position: relative;
        display: block;
        width: calc(100% + 1.3px);
        height: 149px;
        transform: rotateY(180deg);
    }

    .custom-shape-divider-bottom-1722409066 .shape-fill {
        fill: #DDF9FF;
    }

    .bannercontent{
    display: flex;
    align-items: center;
    flex-direction: column;
    // justify-content: center;
    padding: 4rem;
    }

    .bannercontent h1{
    color:#ffffff;
    font-size: 55.31px;
    font-weight: 700;
    line-height: 84px;
    text-align: left;
    }

    .bannercontent h4{
    color:#ffffff;
    font-family: Montserrat;
    font-size: 26.48px;
    font-weight: 500;
    line-height: 39px;
    text-align: left;
    margin: 2rem 0rem;
    }

    .bannercontent img{
    margin:5px;
    }

 @media(max-width: 991px){
    .fluid-container{
        height: auto;
        max-height: 112rem;
    }
 }

 @media (max-width: 913px) {
    .fluid-container {
        height: auto;
        max-height: 120rem;
    }
}

 @media(max-width: 768px){
    .fluid-container{
        height: auto;
        max-height: 130rem;
    }
 }

 @media(max-width: 645px){
    .fluid-container{
        height: auto;
        max-height: 138rem;
    }
 }

 @media(max-width: 598px){
    .fluid-container{
        height: auto;
        max-height: 128rem;
    }

    .bannercontent h1{
    font-size: 45.31px;
    font-weight: 700;
    line-height: 60px;
    }

    .bannercontent h4{
    font-size: 22.48px;
    font-weight: 500;
    line-height: 39px;
    margin: 1rem 0rem;
    }
 }

  @media(max-width: 550px){
    .fluid-container{
        height: auto;
        max-height: 132rem;
    }
}

  @media(max-width: 511px){
    .fluid-container{
        height: auto;
        max-height: 136rem;
    }
}

 @media(max-width: 456px){
    .fluid-container{
        height: auto;
        max-height: 114rem;
    }

    .bannercontent{
    padding:2rem 2rem;
    }

    .bannercontent h1{
    font-size: 35.31px;
    font-weight: 700;
    line-height: 50px;
    }

    .bannercontent h4{
    font-size: 18.48px;
    font-weight: 500;
    line-height: 25p;
    margin: 1rem 0rem;
    }
    .bannercontent img{
    width: 61px;
    height: 60px
    }
 }

  @media(max-width: 418px){
    .fluid-container{
        height: auto;
        max-height: 120rem;
    }
}
  @media(max-width: 395px){
    .fluid-container{
        height: auto;
        max-height: 122rem;
    }
}
  @media(max-width: 363px){
    .fluid-container{
        height: auto;
        max-height: 124rem;
    }
}
`;

const ProviderForm = () => {
    // State variable to track the selected service type
    const [serviceType, setServiceType] = useState("");
    const [hasCertification, setHasCertification] = useState('');
    const handleCertificationChange = (e) => {
        console.log(e);
        setHasCertification(e.target.value);
    };
    // Function to handle the select change and update the state variable
    const handleServiceTypeChange = (e) => {
        console.log(e, "e");
        const selectedService = e.target.value;
        setServiceType(selectedService); // Update the service type state
        //handleChange(e); // Propagate the change to the parent component
    };
    const [step, setStep] = useState(1);
    const [getPolygonCoordinates, setPolygonCoordinates] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const formData = {
        first_name: "",
        last_name: "",
        email: "",
        contact_no: "",
        password: "",
        role_id: 3,
        provider_service_type: "",
        user_comapany_details: [
            {
                company_name: "",
                company_phone: "",
                email: "",
                select_zone: "",
                address: "",
                pincode: "",
                image_url: [],
            },
        ],
        users_contact_info: [
            {
                name: "",
                phone: "",
                email: "",
                address: "",
                address1: "",
                city: "",
                state: "",
                pincode: "",
                profile_picture: [],
            },
        ],
        users_business_information: [
            {
                account_number: "",
                ifsc_code: "",
                bank_name: "",
                user_name_as_per_bank: "",
                identity_type: "",
                identity_number: "",
                image_url: [],
            },
        ],
        expertise: [
            {
                experience: "",
                handling_aggressive: "",
                size_handling: "",
                dogs_and_cats: "",
                profile_description: "",
                certifications_and_accolades: "",
                certifications_and_accolades_image_url: "",
                pet_care_services: "",
                rates_and_cost: "",
                payment_options: "",
                certifications_and_accolades: "",
                property_pictures: [],
                videos: [],
            },
        ],
        users_address_info: [],
    };

    // Validation Schema for Step 1
    const validationSchemaStep1 = Yup.object().shape({
        first_name: Yup.string()
            .min(2, "First name must be at least 2 characters")
            .max(30, "First name cannot exceed 30 characters")
            .required("First name is required"),
        last_name: Yup.string()
            .min(2, "Last name must be at least 2 characters")
            .max(30, "Last name cannot exceed 30 characters")
            .required("Last name is required"),
        contact_no: Yup.string()
            .required("Phone number is required")
            .matches(/^[1-9][0-9]{9}$/, "Please enter exactly 10 digits without 0 or +91"),
        email: Yup.string()
            .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid Email Address!.')
            .max(50, "Email cannot exceed 50 characters")
            .required("Email is required"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters")
            .max(15, "Password must be at max 15 characters")
            .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
            .matches(/[a-z]/, "Password must contain at least one lowercase letter")
            .matches(/[0-9]/, "Password must contain at least one number")
            .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
            .required("Password is required"),
        provider_service_type: Yup.string().required("Service type is required"),
        users_contact_info: Yup.array().of(
            Yup.object().shape({
                address: Yup.string()
                    .min(2, "Address must be at least 2 characters")
                    .max(150, "Address cannot exceed 150 characters")
                    .required("Address is required"),
                city: Yup.string().required("City is required").min(2, "City must be at least 2 characters").max(50, "City cannot exceed 50 characters"),
                state: Yup.string().required("State is required").min(2, "State must be at least 2 characters").max(50, "State cannot exceed 50 characters"),
                pincode: Yup.string()
                    .length(6, "Pincode must be exactly 6 digits")
                    .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits")
                    .required("Pincode is required"),
            }),
        ),
        expertise: Yup.array().of(
            Yup.object().shape({
                experience: Yup.string()
                    .required("Experience is required"),
            }),
        ),
    });

    const expertiseSchema = Yup.object().shape({
        experience: Yup.string().required("Experience is required"),
        handling_aggressive: Yup.string().required("This field is required"),
        size_handling: Yup.string().required("Selection of Size is required"),
        dogs_and_cats: Yup.string().required(
            "Comfortable with both dogs and cats is required"
        ),
        profile_description: Yup.string().required(
            "Profile description is required"
        ),
    });

    const validationSchemaStep3 = Yup.object().shape({
        expertise: Yup.array()
            .of(expertiseSchema)
            .required("At least one expertise object is required")
            .min(1, "At least one expertise object must be present"),
    });

    const nextStep = () => {
        setStep(step + 1);
    };

    const previousStep = () => {
        setStep(step - 1);
    };

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        values.users_address_info = getPolygonCoordinates
            ? getPolygonCoordinates
            : null;
        registerProvider({ ...values }).then((resp) => {
            if (!resp.success) {
                console.log("response from if", resp.message);
                message.error(resp.message);
                if (resp.responseCode === 422) {
                    const newErrors = {};
                    Object.entries(resp.data).forEach(([key, value]) => {
                        newErrors[key] = value[0];
                    });
                }
                window.scrollTo(0, 0);
            } else {
                console.log("Response: ", resp);
                message.success(resp.message);
                setIsSubmitted(true);
                resetForm();
                window.location.href = "https://provider.petside.in/admin/auth/login";
            }
        });
        setSubmitting(false);
    };

    return (
        <StyledContainer bgImage={HeaderImage}>
            <div className="fluid-container">
                <div className="bg_img">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 bannercontent">
                            <h1>
                                Partner with Petside India for dog training, grooming, sitting, and vet services!
                            </h1>
                            <h4>Join our community of over 346 providers nationwide :)</h4>
                            <span>
                                <img src={provider1} alt="Pet Provider image 1" />
                                <img src={provider2} alt="Pet Provider image 2" />
                                <img src={provider3} alt="Pet Provider image 3" />
                                <img src={provider4} alt="Pet Provider image 4" />
                                <img src={provider5} alt="Pet Provider image 5" />
                            </span>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div id="form_content" className=" col-10 offset-1 col-lg-8 offset-lg-2 rf-form form_content my-4">
                            <div className="multi-step-form">
                                <Formik
                                    initialValues={formData}
                                    onSubmit={handleSubmit}
                                    validationSchema={
                                        step === 1
                                            ? validationSchemaStep1
                                            : step === 2
                                                ? validationSchemaStep3
                                                : validationSchemaStep3
                                    }
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isValid,
                                        setFieldValue,
                                        dirty,
                                        validateForm,
                                        setTouched,
                                    }) => (
                                            <Form onSubmit={handleSubmit} className="customform" id="providerForm">
                                            {step === 1 && (
                                                <>
                                                    <h2>General Information</h2>
                                                    <div className="field-row">
                                                        <div className="field-group">
                                                            <label>First Name</label>
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="first_name" maxlength={30}
                                                                onChange={handleChange}
                                                                placeholder="Enter the first name"
                                                            />
                                                            <ErrorMessage name="first_name" component="div" className="field_error" />
                                                        </div>

                                                        <div className="field-group">
                                                            <label>Last Name</label>
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="last_name" maxlength={30}
                                                                onChange={handleChange}
                                                                placeholder="Enter the last name"
                                                            />
                                                            <ErrorMessage name="last_name" component="div" className="field_error" />
                                                        </div>
                                                    </div>

                                                    <div className="field-row">
                                                        <div className="field-group">
                                                            <label>Phone</label>
                                                            <Field
                                                                className="form-control"
                                                                type="text"
                                                                name="contact_no" maxlength={10}
                                                                onChange={handleChange}
                                                                placeholder="Enter the Contact Number"
                                                            />
                                                            <ErrorMessage name="contact_no" component="div" className="field_error" />
                                                        </div>

                                                        <div className="field-group">
                                                            <label>Email</label>
                                                            <Field
                                                                className="form-control"
                                                                type="email"
                                                                name="email" maxlength={40}
                                                                onChange={handleChange}
                                                                placeholder="Enter the Email"
                                                            />
                                                            <ErrorMessage name="email" component="div" className="field_error" />
                                                        </div>
                                                    </div>
                                                    <label>Password</label>
                                                    <Field
                                                        className="form-control"
                                                        type="password"
                                                        name="password" maxlength={15}
                                                        onChange={handleChange}
                                                        placeholder="Create a Password"
                                                    />
                                                    <ErrorMessage name="password" component="div" className="field_error" />

                                                    <label>Address</label>
                                                    <Field
                                                        className="form-control"
                                                        id=""
                                                        cols="28"
                                                        rows="3" maxlength={150}
                                                        name="users_contact_info[0].address"
                                                        onChange={handleChange}
                                                        placeholder="Address"
                                                    ></Field>
                                                    <ErrorMessage name="users_contact_info[0].address" component="div" className="field_error" />
                                                    <div>
                                                        <div className="field-row">
                                                            <div className="field-group">
                                                                <label for="city">Choose a city</label>
                                                                <select id="city"
                                                                    className="form-control" maxlength={50}
                                                                    name="users_contact_info[0].city"
                                                                    onChange={handleChange}>
                                                                    <option selected disabled value="">Select City</option>
                                                                    <option value="Mumbai">Mumbai</option>
                                                                    <option value="Pune">Pune</option>
                                                                    <option value="Delhi">Delhi</option>
                                                                    <option value="Hyderabad">Hyderabad</option>
                                                                    <option value="Bangalore">Bangalore</option>
                                                                </select>
                                                                <ErrorMessage name="users_contact_info[0].city" component="div" className="field_error" />
                                                            </div>
                                                            <div className="field-group">
                                                                <label>Sub Area</label>
                                                                <Field
                                                                    className="form-control"
                                                                    type="text" maxlength={50}
                                                                    name="users_contact_info[0].state"
                                                                    onChange={handleChange}
                                                                    placeholder="Enter sub area"
                                                                />
                                                                <ErrorMessage name="users_contact_info[0].state" component="div" className="field_error" />
                                                            </div>
                                                        </div>
                                                        <label>Pincode </label>
                                                        <Field
                                                            className="form-control"
                                                            type="text"
                                                            name="users_contact_info[0].pincode"
                                                            onChange={handleChange} maxlength={6}
                                                            placeholder="e.g. 401107"
                                                        />
                                                        <ErrorMessage name="users_contact_info[0].pincode" component="div" className="field_error" />
                                                        <label htmlFor="">Profile Picture</label>
                                                        <FileInput
                                                            className="form-control"
                                                            name={`users_contact_info[0].profile_picture`}
                                                            data={{
                                                                maincategory: "product",
                                                                subcategory: "images",
                                                            }}
                                                            // placeholder="Drop a profile picture"
                                                            required
                                                        />
                                                        <ErrorMessage name="users_contact_info[0].profile_picture" component="div" className="field_error" />
                                                        <br></br>
                                                        <h2>Service Information</h2>
                                                        <label>Select service type</label>
                                                        <div className="pb-3">
                                                            <select
                                                                className="form-select"
                                                                name="provider_service_type"
                                                                onChange={(e) => {
                                                                    handleServiceTypeChange(e);
                                                                    handleChange(e);
                                                                }} >
                                                                <option value="" disabled selected>Select Your Service</option>
                                                                <option value="Pet Boarding"> Pet Boarding</option>
                                                                <option value="Dog Walking">Dog Walking</option>
                                                                <option value="Pet Sitting">Pet Sitting</option>
                                                                <option value="Pet Grooming"> Pet Grooming </option>
                                                                <option value="Dog Training">Dog Training</option>
                                                                <option value="Day Care">Day Care</option>
                                                                <option value="Drop In visit">Drop In visit </option>
                                                                <option value="Therapy Sessions">  Therapy Sessions
                                                                </option>
                                                            </select>
                                                            <ErrorMessage name="provider_service_type" component="div" className="field_error" />
                                                        </div>

                                                        <label>
                                                            How many years of experience do you have <br /> in
                                                            pet care?
                                                        </label>
                                                        <select className="form-select" name="expertise[0].experience" onChange={handleChange}>
                                                            <option disabled selected value="">Select Your Experience</option>
                                                            <option value="0">0 years</option>
                                                            <option value="1-2">1-2 years</option>
                                                            <option value="3-5">3-5 years</option>
                                                            <option value="6-10">6-10 years</option>
                                                            <option value="10+">10+ years</option>
                                                        </select>
                                                        <ErrorMessage name="expertise[0].experience" component="div" className="field_error" />

                                                        {step === 2 &&
                                                            errors &&
                                                            errors.expertise &&
                                                            errors.expertise[0].experience && (
                                                                <div className="field_error">
                                                                    {errors.expertise[0].experience}
                                                                </div>
                                                            )}
                                                    </div>
                                                    <br />
                                                </>
                                            )}


                                            <button
                                                type="button"
                                                onClick={previousStep}
                                                className="previous-button"
                                                style={{
                                                    display: step > 1 ? "inline-block" : "none",
                                                }}
                                            >
                                                Previous
                                            </button>
                                            {step < 1 && (
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        validateForm().then((errors) => {
                                                            console.log(errors, "errors");
                                                            if (Object.keys(errors).length === 0) {
                                                                nextStep();
                                                            } else {
                                                                setTouched({
                                                                    first_name: true,
                                                                    last_name: true,
                                                                    contact_no: true,
                                                                    email: true,
                                                                    password: true,
                                                                });
                                                            }
                                                        });
                                                    }}
                                                >
                                                    Next
                                                </button>
                                            )}
                                            {step === 1 && <button type="submit">Finish</button>}
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div></div>
                    </div>
                </div>
                    <div class="custom-shape-divider-bottom-1722409066">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
                        </svg>
                    </div>
            </div>
        </StyledContainer>
    );
}

export default ProviderForm;
