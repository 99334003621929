import React from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";

const StyledContainer = styled.div`
  margin: 5rem 0rem;
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #Fff;
    width: 16%;
    display: block;
    text-wrap: nowrap;
    margin-left: 2em;
    margin-bottom: 2em;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #fff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }
  .accordion-item {
    color: var(--bs-accordion-color);
    background-color: #e2fbff;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }
  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }
`;

const Servicefaq = ({ slug }) => {
    const faqData = {
        "dog-training": [
            {
                question: "What age should I start training my dog?",
                answer: "It's best to start training your dog as early as 8 weeks old. Early training helps to instill good behaviors from the beginning."
            },
            {
                question: "How long does it take to train a dog?",
                answer: "Training duration varies depending on the dog's breed, age, and temperament. Basic obedience training typically takes about 6-8 weeks."
            },
            {
                question: "What training methods do you use?",
                answer: "We use positive reinforcement techniques, including treats, praise, and play, to encourage good behavior and make training enjoyable for your dog."
            },
            {
                question: "Do you offer private training sessions?",
                answer: "Yes, we offer both group classes and private training sessions to accommodate different needs and schedules."
            },
            {
                question: "Can you help with specific behavioral issues?",
                answer: "Absolutely! Our trainers are experienced in addressing a range of behavioral issues, including aggression, anxiety, and leash pulling."
            }
        ],
        "grooming": [
            {
                question: "How often should I groom my pet?",
                answer: "Grooming frequency depends on your pet's breed, coat type, and lifestyle. Generally, dogs should be groomed every 4-6 weeks, while cats can be groomed less frequently."
            },
            {
                question: "What grooming services do you offer?",
                answer: "We offer baths, haircuts, nail trimming, ear cleaning, and more. Our grooming packages can be customized to suit your pet's needs."
            },
            {
                question: "Do you use pet-safe products?",
                answer: "Yes, we use high-quality, pet-safe shampoos, conditioners, and grooming products to ensure the health and safety of your pet."
            },
            {
                question: "How long does a grooming session take?",
                answer: "The duration of a grooming session varies depending on the services required and the size of your pet, but typically it takes between 1-2 hours."
            },
            {
                question: "Can I stay with my pet during the grooming session?",
                answer: "For the safety and comfort of all pets, we recommend owners do not stay during the grooming session. However, you are welcome to drop off and pick up your pet."
            }
        ],
        "pet-sitting": [
            {
                question: "What does a pet sitter do?",
                answer: "Our pet sitters provide feeding, watering, playtime, exercise, and administer medications as needed. They also ensure your pet's living area is clean and comfortable."
            },
            {
                question: "How do I know my pet is safe with the sitter?",
                answer: "All our sitters are thoroughly background-checked, trained, and experienced in pet care. We also provide regular updates and photos of your pet."
            },
            {
                question: "Can you care for pets with special needs?",
                answer: "Yes, our sitters are trained to handle pets with special needs, including administering medications and following specific care routines."
            },
            {
                question: "How long can a pet sitting session last?",
                answer: "We offer flexible sitting durations, from a few hours to overnight stays, depending on your needs."
            },
            {
                question: "What areas do you cover for pet sitting services?",
                answer: "We offer pet sitting services in [list the specific areas or cities]. Please contact us to check availability in your location."
            }
        ],
        "home-boarding": [
            {
                question: "What is included in pet boarding?",
                answer: "Our boarding services include feeding, exercise, playtime, and plenty of attention from our trained staff. Your pet will stay in a comfortable and safe environment."
            },
            {
                question: "What should I bring for my pet's stay?",
                answer: "Please bring your pet's regular food, any medications, and familiar items like toys or blankets to help them feel at home."
            },
            {
                question: "How do you ensure my pet's safety during their stay?",
                answer: "Our boarding facilities are secure and monitored. We also perform regular health checks and provide a clean and safe environment for all pets."
            },
            {
                question: "Can I visit my pet during their stay?",
                answer: "Yes, you can visit your pet during their stay. Please arrange a visit time with our staff to ensure your pet's routine is not disrupted."
            },
            {
                question: "Do you board pets with special needs?",
                answer: "Yes, we accommodate pets with special needs. Please provide detailed instructions on your pet's care requirements when booking."
            }
        ],
        "daycare": [
            {
                question: "What activities are included in pet daycare?",
                answer: "Our daycare program includes supervised playtime, socialization with other pets, exercise, and rest periods to keep your pet engaged and happy."
            },
            {
                question: "Is daycare suitable for all pets?",
                answer: "Daycare is suitable for most pets, but we assess each pet's temperament and needs to ensure a safe and enjoyable environment for all participants."
            },
            {
                question: "What are the drop-off and pick-up times for daycare?",
                answer: "Daycare hours are typically from [start time] to [end time]. Please check with us for specific times and policies."
            },
            {
                question: "Do you offer half-day daycare options?",
                answer: "Yes, we offer both full-day and half-day daycare options to accommodate your schedule and your pet's needs."
            },
            {
                question: "How do you ensure my pet's safety in daycare?",
                answer: "Our trained staff supervise all activities, and we maintain a safe, clean, and secure environment. We also perform regular health checks and ensure all pets are up-to-date on vaccinations."
            }
        ],
        "dog-walking": [
            {
                question: "What does a typical dog walking session include?",
                answer: "A typical dog walking session includes a walk tailored to your dog's energy level and needs, playtime, basic training reinforcement, and plenty of attention. We ensure your dog gets exercise, mental stimulation, and socialization during each walk."
            },
            {
                question: "How long are the dog walking sessions?",
                answer: "Our standard dog walking sessions are 30 minutes, but we also offer longer sessions of 45 minutes or 1 hour to accommodate your dog's exercise needs. Custom durations can be arranged based on your specific requirements."
            },
            {
                question: "Are your dog walkers trained and insured?",
                answer: "Yes, all our dog walkers are professionally trained, background-checked, and insured. We prioritize the safety and well-being of your pet and ensure our walkers are knowledgeable and trustworthy."
            },
            {
                question: "Do you walk multiple dogs at the same time?",
                answer: "We offer both individual and group walks. If your dog enjoys socializing with other dogs, they can be part of a small group walk. For dogs that prefer solo time, individual walks are available to cater to their comfort and needs."
            },
            {
                question: "What if the weather is bad on the day of the walk?",
                answer: "In case of extreme weather conditions (heavy rain, storms, or extreme heat), we will adjust the walk to ensure your dog's safety. This may include shorter outdoor time or engaging indoor activities. We will communicate any changes with you in advance."
            }
        ]
    };


    const faqs = faqData[slug] || [];

    if (!faqs || faqs.length === 0) {
        return;
    }

    return (
        <StyledContainer>
            <div className="container pt-2 pb-5">
                <div className="sp_head">
                    <h2>FAQs</h2>
                </div>

                <Accordion defaultActiveKey="0">
                    {faqs.map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>
                                <p>{faq.answer}</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </div>
        </StyledContainer>
    );
};

export default Servicefaq;
