import styled from "styled-components";
import { NavLink } from "react-bootstrap";
import band_img from "../../assets/images/Home/offerband_img.png"

const StyledContainer = styled.div`

    .offer-card{
        width:80%;
        background: #FFE6B4;
        border-radius: 10px !important;
        border: none;
    }
    .promo-text{
    margin:1rem 0rem 0rem 1rem;
    font-size: 30px;
    font-style: italic;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 2px;
    }
    .promo-text p{
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 2px;
    }
    .code{
    color: #FC5486;
    font-size: 35px;
    font-weight: 600;
    line-height: 42.67px;
    letter-spacing: 2px;
    text-align: left;
    }

    .discount{
    color: #12D2FF;
    font-size: 40px;
    font-weight: 600;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: center;
    }
    
    .promo_section{
        padding: 0em 0em 3em 0em;
    }

    .serice-card{
        background: #E0F7FA;
        border-radius: 0 !important;
        border: none;
    }

    .offer-img{
    object-fit: none;
    overflow: visible;
    position: absolute;
    right: -2px;
    top: -18px;
    }
   @media (max-width: 1400px) and (min-width:1200px){
        .promo-text{
            h1{
                font-size: 2rem;    
            }
            h5{
                font-size: 1.1rem;
            }
        }
        
         .offer-img{
            object-fit: none;
            overflow: visible;
            position: absolute;
            right: -2px;
            top: -27px;
            }

             .promo-text p{
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 37px;
            letter-spacing: 2px;
            }

             .promo-text > p + p{
             padding-top:7px !important;
            }

             .code{
            font-size: 30px;
            }

            .discount{
            font-size: 30px;
            }
    }
   @media (max-width: 1200px){
        .promo-text{
            h1{
                font-size: 2rem;    
            }
            h5{
                font-size: 1.1rem;
            }
        }
        
         .offer-img{
            object-fit: none;
            overflow: visible;
            position: absolute;
            right: -2px;
            top: 10px;
            }

             .promo-text p{
            font-size: 21px;
            font-style: normal;
            font-weight: 500;
            line-height: 37px;
            letter-spacing: 2px;
            }

             .promo-text > p + p{
             padding-top:7px !important;
            }

             .code{
            font-size: 30px;
            }

            .discount{
            font-size: 30px;
            }
    }
    @media (min-width: 1px) and (max-width: 1199px) {
        .promo-text{
            h1{
                font-size: 1.8rem;    
            }
            h5{
                font-size: 1rem;
            }
        }

        .offer-card{
            margin-bottom: 5%
        }
    }
    @media (max-width: 768px) {
       
        .promo_section{
            padding-top: 1em;
        }
      }
    
     @media (max-width: 426px) {

        .promo_section{
            padding-top: 1em;
        }

        .offer-card{
            width: 100%;
            margin: 0 auto;
            background: #FFE6B4;
            border-radius: 10px !important;
            border: none;
        }

        
        .card-body{
        padding: 10px;
        }

        .promo-text{
        margin: 10px;
        }
       
        .promo-text h4{
            margin:0px;
            font-size: 12px !important;
            font-style: italic;
            font-weight: 400;
            line-height: 14.63px;
            letter-spacing: 0.7106px;
            text-align: left;
        }

        .promo-text p{
            font-size: 12px;
            font-weight: 400;
            line-height: 14.63px;
            letter-spacing: 0.7106px;
            text-align: left;
        }

        .code{
            font-size: 18px;
            font-weight: 600;
            line-height: 21.94px;
            letter-spacing: 0.7106px;
        }

        .discount{
            font-size: 18px;
        }
        
        .promo-text > p + p{
             padding:0px 7px !important;
        }

        .offer-img{
            object-fit: fill !important;
            width: 156px;
            position: absolute;
            right: 0px;
            top: 52px;
        }
    }


    @media (max-width: 375px) {

        .col-sm-3{
            width: 25%; !important;
         }

         .col-sm-9{
            width: 75% !important;
        }

        .card{
            width: 366px;
            height:105px;
        }

        .card-body{
        padding: 10px;
        }

        .promo-text{
        margin: 0px;
        }

        .promo-text h4{
            margin:0px;
            font-size: 12px !important;
            font-style: italic;
            font-weight: 400;
            line-height: 14.63px;
            letter-spacing: 0.7106px;
            text-align: left;
        }

        .promo-text p{
            font-size: 12px;
            font-weight: 400;
            line-height: 14.63px;
            letter-spacing: 0.7106px;
            text-align: left;
        }

        .code{
            font-size: 18px;
            font-weight: 600;
            line-height: 21.94px;
            letter-spacing: 0.7106px;
        }

        .discount{
            font-size: 18px;
        }
        
        .promo-text > p + p{
             padding:0px 7px !important;
        }

        .offer-img{
        object-fit:fill !important;
        width: 145px;
        position: absolute;
        right: 0px;
        top: 37.3px;
        }

        .offer-card{
        margin:0px !important;
        }
    }
`;


const OfferBand = () => {
    return (
      <StyledContainer>
       
            <section className="promo_section mt-5">
                <div className="mb-3 container ">
                    <div className="row ">
                        <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center rounded">
                            <div className="card offer-card bg-black text-white ">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-9 col-md-12 col-lg-7 col-xl-8">
                                            <div className="promo-text">
                                                <h4>Save Big Now:</h4>
                                                <p>Enter Code <span className="code">#SPAW</span> at Checkout!</p>
                                                <p className="pt-3">Get Flat <span className="discount">35%</span> Discount</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 d-md-none d-lg-block col-md-12 col-lg-5 col-xl-4">
                                                <img src={band_img} className="offer-img" alt="offer-promo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
     
      </StyledContainer>
    );
  };


export default OfferBand;
