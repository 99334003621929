import { configureStore } from "@reduxjs/toolkit";
import servicesSlice from "./features/web/services-slice";
import usersSlice from "./features/web/users-slice";
import servicesBookingSlice from "./features/web/servicesBooking-slice";
import servicesProductSlice from "./features/web/product-slice";
import cartSlice from "./features/web/cart-slice";
import slotSlice from "./features/web/slot-slice";
import productByIdSlice from "./features/web/productById-slice";
import serviceByIdSlice from "./features/web/serviceById-slice";
import userByIdSlice from "./features/web/userById-slice";
import ProfileDetailSlice from "./features/web/profiledetail-slice"
import deliveryAddressSlice from "./features/web/deliveryAddress-slice";
import blogSlice from "./features/web/blog-slice";
import testimonialsSlice from "./features/web/testimonials-slice";
import providerSlice from "./features/web/provider-slice";
import packagesSlice from "./features/web/packages-slice";
import GalleryPhotoSlice from "./features/web/galleryphoto-slice";
import dogMasterSlice from "./features/web/dogMaster-slice";
import couponsSlice from "./features/web/coupons-slice";
import serviceBookingSlice from "./features/web/serviceBooking-slice";
import productBookingSlice from "./features/web/productBooking-slice";
import petProfileSlice from "./features/web/petProfile-slice";
import deliveryAddressByIdSlice from "./features/web/deliveryAddressById-slice";
import petProfileByIdSlice from "./features/web/petProfileById-slice";
import serviceBySlugSlice from "./features/web/serviceBySlug-slice";
export const store = configureStore({
  reducer: {
    services: servicesSlice,
    users: usersSlice,
    servicesBooking: servicesBookingSlice,
    product: servicesProductSlice,
    cart: cartSlice,
    productById: productByIdSlice,
    userById: userByIdSlice,
    userProfileDetail: ProfileDetailSlice,
    deliveryAddress: deliveryAddressSlice,
    deliveryAddressById: deliveryAddressByIdSlice,
    servicesById: serviceByIdSlice,
    serviceBySlug: serviceBySlugSlice,
    fetchBlogs: blogSlice,
    fetchTestimonial: testimonialsSlice,
    petProvider: providerSlice,
    packages: packagesSlice,
    galleryphoto: GalleryPhotoSlice,
    dogMasterData: dogMasterSlice,
    coupons: couponsSlice,
    serviceBookings: serviceBookingSlice,
    productBookings: productBookingSlice,
    petProfiles: petProfileSlice,
    petProfileById: petProfileByIdSlice,
    slot: slotSlice
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
