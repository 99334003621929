import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/blog-img.png";
import Accordion from "react-bootstrap/Accordion";

const StyledContainer = styled.div`
margin: 2em 0em;
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #Fff;
    width: 16%;
    display: block;
    text-wrap: nowrap;
    margin-left: 2em;
    margin-bottom: 2em;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #fff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-item {
    color: var(--bs-accordion-color);
    background-color: #e2fbff;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}
`;

function ProviderFaq() {
  return (
    <StyledContainer>
      <div className="container pt-2 pb-5">
        <div className="sp_head">
          <h2>FAQ’s</h2>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Q1. How do I become a provider with Petside India?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.Becoming a provider with Petside India is simple. Visit our website, fill out the application form, and submit your credentials. Our team will review your application, and once approved, you can start offering your services on our platform.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Q2. What services can I offer through Petside India?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.As a provider, you can offer a variety of pet care services including dog training, grooming, pet sitting, pet boarding, and veterinary care. We also welcome specialists in other pet-related fields to join our platform.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Q3. How does Petside India help me grow my business?</Accordion.Header>
            <Accordion.Body>
            <p>Ans. Petside India connects you with a large community of pet owners looking for reliable services. Our platform increases your visibility, helps you manage bookings and payments, and provides marketing support to attract more clients.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Q4. What are the benefits of partnering with Petside India?</Accordion.Header>
            <Accordion.Body>
            <p>Ans. Partnering with Petside India offers numerous benefits, including access to a broad client base, enhanced professional credibility, and a user-friendly platform that streamlines your business operations. You’ll also benefit from our trusted reputation and marketing efforts.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Q5. How are payments processed through Petside India?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.Payments are securely processed through our platform. Clients pay for services directly through the Petside India website or app, and providers receive payments in their accounts after the completion of the services. We ensure timely and hassle-free transactions.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </StyledContainer>
  );
}

export default ProviderFaq;
