import React from "react";
import styled from "styled-components";
import AboutBanner from "../../assets/images/about/Banner_About.png";
import img3 from "../../assets/images/services/Petside-about-us-image.jpg";
import icon from "../../assets/images/icon11.png";
import ig from "../../assets/images/footer/prime_instagram.png";
import fb from "../../assets/images/footer/ic_round-facebook.png";
import yt from "../../assets/images/footer/mingcute_youtube-line.png";

const StyledContainer = styled.div`
  .about_us_banner {
    padding: 0;
    margin: 0;
  }

  .about_us_banner .banner {
    position: relative;
    overflow: hidden;
  }

  .about_us_banner .banner img {
    width: 100%;
    height: 598px;
    // object-fit: cover;
  }

  .buttons {
    position: absolute;
    top: 58%;
    left: 20%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    // color: white;
    z-index: 0;
  }

  .about_us_banner .banner .buttons {
    & a {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 700;
      line-height: 18.29px;
      letter-spacing: 2px;
      text-align: center;
      background: #000000;
      color: #fff;
      padding: 1vw 4vw;
      border-radius: 5px;
      margin-left: 15vw;
      margin-top: 18vh;
    }
  }

  .Dog_Img {
    position: relative;
    top: 36vh;
    right: -2vw;
  }

  .about_us_heading h4 {
    font-size: 28px;
    font-weight: 400;
    line-height: 36.57px;
    letter-spacing: 2px;
    text-align: left !important;
  }

  .about_us_heading h5 {
    border-bottom: 4px solid #12d2ff;
    width: 250px;
    font-size: 30px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
    text-align: left;
  }

  .about_heading {
    text-align: justify;
    margin: auto;
    padding: 10px;
  }

  .about-data h3 {
    padding: 30px 0px 0px 0px;
    font-size: 28px;
    font-weight: 600;
    line-height: 34.13px;
    letter-spacing: 2px;
    text-align: left;
  }

  .about-data p {
    padding-top: 10px;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: left;
  }

  .about_icon {
    padding-top: 30px;
  }

  .about_icon h4 {
    color: #12d2ff;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
  }

  .social_icon img {
    height: 30px;
    width: 30px;
    margin: 15px 10px;
  }

  .about_content_img {
    margin: auto;
    border: none;
    width: 394.22px;
    height: 521px;
  }

  .about_img img {
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }

  @media (max-width: 1440px) {
    .Dog_Img {
      position: relative;
      top: 40vh;
      right: -3vw;
    }
  }

  @media (max-width: 1024px) {
    .Dog_Img {
      position: relative;
      top: 38vh;
      right: 5vw;
    }
  }

  @media (max-width: 991px) {
    .banner img {
      height: 400px !important;
    }

    .Dog_Img {
      position: relative;
      top: 6vh;
      right: -4vw;
    }

    .about_us_heading {
      margin-top: 5vw;
    }
  }

  @media (max-width: 768px) {
    .Dog_Img {
      display: none;
    }

    .banner img {
      height: 280px !important;
    }

    .about_content_img {
      margin-top: 2rem;
    }
  }

  @media (max-width: 480px) {
    .banner img {
      height: 217px !important;
    }

    .about_us_banner .banner .buttons a {
      font-size: 13px !important;
      line-height: 0px;
      padding: 6px 12px !important;
      border-radius: 5px;
    }

    .about_content_img {
      padding-top: 2rem;
      margin: auto;
      border: none;
      width: 260px;
      height: auto;
    }

    .about-data p {
      padding-top: 10px;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 1px;
      text-align: left;
    }
  }
`;

const Whoweare = () => {
  return (
    <StyledContainer>
      <div className="container-fluid about_us_banner">
        <div className="banner">
          <img src={AboutBanner} alt="About Us Banner" />
          <div className="buttons">
            <a href="services">Check Services</a>
          </div>
        </div>
      </div>
      <div className="container px-4">
        <div className="Dog_Img">
          <img className="dog_image" src={icon} alt="" />
        </div>
        <div className="row d-flex">
          <div className="col-lg-6 col-md-12 col-sm-12 about_img align-self-center">
            <div className="about_content_img ">
              <img
                className="about_banner"
                src={img3}
                alt=""
                width="100%"
                height="auto"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
            <div className="about_us_heading">
              <h4 className="">Know</h4>
              <h5 className="">ABOUT US</h5>
            </div>

            <div className="about-data">
              <h3 className="firstHeading">Our Mission</h3>
              <p className="mb-2">
                At Petside India, we are dedicated to enhancing the lives of pets and their owners through comprehensive and compassionate care. Our mission is to provide top-quality services that cater to every aspect of pet ownership, ensuring that every pet is happy, healthy, and well-cared for.
              </p>
              <h3>Our Commitment to Pet Care</h3>
              <p>
                We understand that pets are more than just animals; they are family members. Our team of experienced and passionate professionals is committed to providing the highest standard of care for your pets. We believe in building trust and lasting relationships with both pets and their owners.
              </p>
              <h3>Our Team</h3>
              <p>
                Our team consists of certified trainers, experienced groomers, compassionate sitters, and knowledgeable staff, all dedicated to the well-being of your pets. With years of experience in the pet care industry, we bring expertise and a love for animals to everything we do.
              </p>
              <h3>Our Promise</h3>
              <p>
                At Petside India, we promise to treat your pets as if they were our own. We are here to support you in every step of your pet ownership journey, providing the care, love, and attention your pets deserve.
              </p>
              <h6 className="mt-3">Petside India – Where Every Pet is Family!</h6>

              <div className="about_icon">
                <h4 className="">Follow Us</h4>
                <div className="social_icon">
                  <img src={ig} alt="" />
                  <img src={fb} alt="" />
                  <img src={yt} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Whoweare;
