import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/petside-logo.png";
import provider from "../../assets/images/footer/animal-sanctuary_6581981 1.png";
import userIcon from "../../assets/images/user.png";
import ig from "../../assets/images/footer/prime_instagram.png";
import fb from "../../assets/images/footer/ic_round-facebook.png";
import yt from "../../assets/images/footer/mingcute_youtube-line.png";
import { Button, Dropdown, Menu } from "antd";
import Login from "../../pages/Auth/Login";

const StyledContainer = styled.div`
.sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 3;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 1em;
  }

  .custom-btn {
      display: none !important;
    }

    .boarding a,
    li a {
        font-size: 18px !important;
    }

    li h2 {
        font-size: 20px;
        font-weight: 600;
    }

    hr {
        margin: 1rem auto;
        color: inherit;
        border: 0;
        border-top: var(--bs-border-width) solid;
        opacity: .25;
        width: 80%;
    }

  @media (max-width: 991px) {
  .custom-btn {
      display: block !important;
    }
  }


  @media (max-width: 768px) {
  .card{
      margin:0rem;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }

    .hamburger {
      display: block;
    }
  }

`;

function MobileNavbar(props) {
    const userInfo =
        localStorage.getItem("userInfo") !== "undefined"
            ? localStorage.getItem("userInfo")
            : null;

    let user_id = null;
    let isUserLoggedIn = null;
    if (userInfo) {
        const userData = JSON.parse(userInfo);
        user_id = userData.id;
        isUserLoggedIn = userData.contact_no;
    }

    const logoutHandler = () => {
        localStorage.clear();
        window.location.reload();
    };

    const menu = (
        <Menu className="remove-print">
            <Menu.Item key="1">
                <a href="/auth/profile">My Profile</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href="/auth/saved-address">Saved Address</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a href="/auth/order-history">Service History</a>
            </Menu.Item>
            <Menu.Item key="4">
                <a href="/auth/product-history">Product History</a>
            </Menu.Item>
            <Menu.Item key="5">
                <a href="/auth/pet-profile">Pet Profile</a>
            </Menu.Item>
            <Menu.Item key="6" onClick={logoutHandler}>
                <a href="/">Logout</a>
            </Menu.Item>
        </Menu>
    );

    const dispatch = useDispatch();
    const location = useLocation();
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    return (
        <StyledContainer>
            <div className="d-flex remove-print">
                <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                    <FontAwesomeIcon icon={faTimes} className="close-btn" onClick={toggleSidebar} />
                    <ul className="list-unstyled">
                        <li>
                            <div className="col-lg-3 col-md-4 text-center provider">
                                <div className="margins">
                                    <img src={provider} alt="" />
                                    <h6 className="text-light">Become a Provider</h6>
                                </div>
                            </div>
                        </li>
                        <hr />
                        <li>
                            <a href="/provider">Register Here </a>
                        </li>
                        <li>
                            <a href="https://provider.petside.in/">Provider Login</a>
                        </li>
                        <li>
                            <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="/terms-conditions">Terms & Conditions</a>
                        </li>
                        <hr />
                        <li>
                            <div className="col-lg-3 col-md-4">
                                <div className="boarding pb-3">
                                    <h2 className="text-light">Contact Us</h2>
                                    <a href="tel:+919136776162" className="text-dark" title="Call us at +91 9136776162">+91 91367 76162</a>
                                </div>
                                <h2 className="text-light">Follow on socials</h2>
                                <div className="social_icon m-auto d-flex flex-direction-column justify-content-center text-light">
                                    <a href="https://www.instagram.com/petside.in/">
                                        <img src={ig} alt="instagram-icon" />
                                    </a>
                                    <a href="https://www.facebook.com/petsideindia/">
                                        <img src={fb} alt="facebook-icon" />
                                    </a>
                                    <a href="https://www.youtube.com/@Petside">
                                        <img src={yt} alt="youtube-icon" />
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-flex col-12 col-sm-12 remove-print">
                    
                    <Nav
                        className="col-12 col-sm-12 d-flex flex-direction-column align-items-center justify-content-between NarbarContent remove-print"
                        style={{ maxHeight: "100%" }}
                        navbarScroll
                    >
                        <FontAwesomeIcon icon={faBars} className="hamburger col-sm-1 d-block d-lg-none" onClick={toggleSidebar} />

                        <Navbar.Brand href="/" className="col-lg-2 col-5 col-sm-6 d-block d-lg-none">
                            <img
                                src={logo}
                                alt="Logo"
                                className="header_logo"
                                width="130"
                                height="55"
                            />
                        </Navbar.Brand>

                        {isUserLoggedIn ? (
                            <Dropdown overlay={menu} placement="bottomRight">
                                <svg className="m-3 d-block d-lg-none" xmlns="http://www.w3.org/2000/svg" height="34px" viewBox="0 -960 960 960" width="34px" fill="#000000"><path d="M240.92-268.31q51-37.84 111.12-59.77Q412.15-350 480-350t127.96 21.92q60.12 21.93 111.12 59.77 37.3-41 59.11-94.92Q800-417.15 800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 62.85 21.81 116.77 21.81 53.92 59.11 94.92ZM480.01-450q-54.78 0-92.39-37.6Q350-525.21 350-579.99t37.6-92.39Q425.21-710 479.99-710t92.39 37.6Q610-634.79 610-580.01t-37.6 92.39Q534.79-450 480.01-450ZM480-100q-79.15 0-148.5-29.77t-120.65-81.08q-51.31-51.3-81.08-120.65Q100-400.85 100-480t29.77-148.5q29.77-69.35 81.08-120.65 51.3-51.31 120.65-81.08Q400.85-860 480-860t148.5 29.77q69.35 29.77 120.65 81.08 51.31 51.3 81.08 120.65Q860-559.15 860-480t-29.77 148.5q-29.77 69.35-81.08 120.65-51.3 51.31-120.65 81.08Q559.15-100 480-100Zm0-60q54.15 0 104.42-17.42 50.27-17.43 89.27-48.73-39-30.16-88.11-47Q536.46-290 480-290t-105.77 16.65q-49.31 16.66-87.92 47.2 39 31.3 89.27 48.73Q425.85-160 480-160Zm0-350q29.85 0 49.92-20.08Q550-550.15 550-580t-20.08-49.92Q509.85-650 480-650t-49.92 20.08Q410-609.85 410-580t20.08 49.92Q450.15-510 480-510Zm0-70Zm0 355Z"/>
                                </svg>
                            </Dropdown>
                        ) : (
                            <Login />
                        )}
                    </Nav>
                </div>
            </div>
        </StyledContainer>
    );
}

export default MobileNavbar;
