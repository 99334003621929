import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPetProviderData } from "../../features/web/web-actions";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dummyImg from '../../assets/images/about/why-choose-us.png'
import { GeoAlt } from "react-bootstrap-icons";

const StyledContainer = styled.div`
.profile_section{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px 0px;
}
background: #F6FFFD;
margin: 2em 0em;
.sp_row{
    width: 70em !important;
}
  .sp_row .card-body {
    background: #e2fbff;
    & p{
        font-size: 13px;
        padding-bottom: 5px;
    }
  }
  .sp_row .card-body a{
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0px;
  }
  .sp_row .order_now {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 9px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .sp_row h6 {
    color: #114c5f;
    font-weight: 700;
  }
  .sp_row h5,
  li {
    font-weight: 700;
  }
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
    margin-left: 2em;
    margin-bottom: 2em;
  }
  .sp_btm_head h5 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  .sp_btm_head p {
    text-align: justify;
    padding: 1em 4em;
  }
  .slider_card{
    flex-direction: column !important;
    border-radius: 5px;
    padding: 10px 0px;
}
.slider_card a{
    background: #114C5F;
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 5px;
}
.slider_card p{
    font-weight: 700;
    height: 24px;
}
.heart-section{
  display: flex;
  gap: 7em;
}
.slider_card img{
    width: none;
    
.slick-slide{
    width: 220px !important;
    margin: 0 30px;
    height: auto !important;
}
`;
const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

function ProviderByLocation() {
  const dispatch = useDispatch();
  const [getProviderData, setProviderData] = useState([]);
  const queryParams = new URLSearchParams(window.location.search);
  const lat = queryParams.get("lat");
  const lng = queryParams.get("lng");
  const providerData = useSelector((state) => state.petProvider?.data);
  useEffect(() => {
    dispatch(fetchPetProviderData({ lat, lng }));
  }, [dispatch, lat, lng]);
  useEffect(() => {
    if (providerData && providerData.data) {
      setProviderData(providerData.data.rows);
    }
  }, [providerData]);
  let slidesToShow = 3;
  if (getProviderData && getProviderData.length <= 2) {
    slidesToShow = getProviderData.length;
  }
  return (
    <StyledContainer>
    <div className="container">
    <div className="sp_head pt-4">
         <h2>
         Recommended Experts  for Your Pet
         </h2>
       </div>
       <div className="slider_content">
       {getProviderData.length > 0 ? (
       <Slider {...settings} slidesToShow={slidesToShow}>
           {getProviderData.map(({ first_name, UserCompanyDeatils,provider_service_type, email, last_name, slug, id }, idx) => (
             <div className="row row-cols-1 row-cols-md-3 g-4 sp_row pb-5">
                 <div className="col">
             <div key={id} className="card">
               {/* <img src={`${process.env.REACT_APP_API_BASE_URL}/images/${profile_url}`} width={200} height={200} className="card-img-top" alt="..." /> */}
               <div className="profile_section">
               <img src={dummyImg} width={100} height={100} alt="" />
                <div>
                    <h6>{first_name}  {last_name}</h6>
                    <h6>{email}</h6>
                </div>
               </div>
               <div className="card-body">
                <p>{provider_service_type}</p>
                 <h6 className="card-title"><GeoAlt/>{UserCompanyDeatils[0]['address']}</h6>
               </div>
             </div>
           </div>
           </div>
           ))}
         </Slider>
         ) : (
            <p className="text-center fw-bold pb-5">No service providers found in your area.</p>
          )}
       </div>
     </div>
   </StyledContainer>
  );
}

export default ProviderByLocation;
