import { createSlice } from '@reduxjs/toolkit';
import { fetchPetProviderData } from './web-actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const petProviderSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPetProviderData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPetProviderData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPetProviderData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default petProviderSlice.reducer;
