import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PencilSquare } from "react-bootstrap-icons";
import { fetchServicesBookings } from "../../features/web/web-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams } from "react-router-dom";
import logo from "../../assets/images/petside-logo.png";

const StyledContainer = styled.div`

.theme-invoice-1 {
    background-color: white;
    -webkit-box-shadow: #e6e6e6 0px 0px 14px 3px;
    box-shadow: #e6e6e6 0px 0px 14px 3px;
}

.theme-invoice-1 .invoice-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.details-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    gap: calc(15px +(50 - 15)*((100vw - 320px) /(1920 - 320)));
}

.address-box {
    background-color: #f7f7f7;
}

  .card {
    // overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    // height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    width: 95%;
    border-collapse: collapse;
    margin: 20px auto;
}

  .table th,
  .table td {
    padding: 12px;
    text-align: center; /* Center align content */
    vertical-align: middle; /* Vertically center content */
    border-bottom: 1px solid #dee2e6;
  }

    .address-box ul {
        list-style-type: none;
    }

    .address-box ul li h6 {
        color: #008DB3;
    }

    .address-box ul li h6 span {
        color: black;
    }

  th {
    background-color: #0dcaf1;
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .action-column {
    text-align: center;
    color: #007bff;
    cursor: pointer;
  }

  .action-column:hover {
    color: #0056b3;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .invoice-footer button {
    background: black;
    padding: 10px 30px;
    font-weight: 700;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px 0px;
      height: auto;
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
      

    .hamburger {
      display: block;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }
  }

    @media print{
        .remove-print {
            display: none !important;
        }
    }

`;

function ServiceInvoice() {
    const { service_invoice_id } = useParams();

    const userInfo = localStorage.getItem("userInfo") !== "undefined"
        ? localStorage.getItem("userInfo")
        : null;

    let user_id = null;
    if (userInfo) {
        const userData = JSON.parse(userInfo);
        user_id = userData.id;
    }

    const dispatch = useDispatch();
    const [getServiceBookings, setServiceBookings] = useState([]);
    const [getServiceDetails, setServiceDetails] = useState([]);
    const getServiceBooking = useSelector(
        (state) => state.serviceBookings.data || []
    );
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        dispatch(fetchServicesBookings(user_id));
    }, [dispatch, user_id]);

    useEffect(() => {
        if (getServiceBooking && getServiceBooking.data) {
            const bookings = getServiceBooking.data.rows;

            console.log("All Booking Data: ", bookings);
            console.log("Service Order Id: ", service_invoice_id);

            const selectedBooking = bookings.find(booking => booking.id === Number(service_invoice_id));

            if (selectedBooking) {
                setServiceDetails(selectedBooking);
                setServiceBookings(selectedBooking.PETSERVICE || []);
            } else {
                console.log("No matching booking found.");
            }
        } else {
            console.log("Error: Booking data not available");
        }
    }, [getServiceBooking, service_invoice_id]);

    console.log("All Service Details : ", getServiceDetails);
    console.log("Service Booking : ", getServiceBookings);

    const logoutHandler = () => {
        localStorage.clear();
        window.location.reload();
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handlePrint = () => {
        // Get elements that you want to hide during printing
        const elementsToHide = document.querySelectorAll('.remove-print');
    
        // Hide elements
        elementsToHide.forEach(element => {
            element.style.display = 'none';
        });
    
        // Trigger the print dialog
        window.print();
    
        // Restore elements after printing
        elementsToHide.forEach(element => {
            element.style.display = '';
        });
    };

    // Function to format the date
    function formatDate(dateString) {
        // Check if dateString is defined and not empty
        if (!dateString) {
            return 'Invalid Date'; // or any default value you prefer
        }

        // Extract the date part
        const dateParts = dateString.split('-');
        
        // Ensure the date string is in the expected format
        if (dateParts.length < 3) {
            return 'Invalid Date'; // or handle differently if needed
        }

        const [year, month, day] = dateParts.slice(0, 3);
        const date = new Date(`${year}-${month}-${day}`);
        
        // Array of weekday names
        const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        
        // Format the date
        return `${weekdays[date.getDay()]} ${year}-${month}-${day}`;
    }

    return (
        <StyledContainer>
            <div className="container-fluid">
                <div className="theme-invoice-1 card">
                    <div className="invoice-wrapper">
                        <div className="invoice-header p-5">
                            <div className="header-image">
                                <img
                                    src={logo}
                                    alt="Logo"
                                    className="img-fluid header_logo"
                                    width="130"
                                    height="55"
                                />
                            </div>
                            <div className="header-content">
                                <h3>Invoice</h3>
                            </div>
                        </div>
                        <div className="invoice-body">
                            <div className="col-12 row details-box justify-content-around">
                                <div className="address-box col-6">
                                    <ul>
                                        <li>{getServiceDetails.address}</li>
                                    </ul>
                                </div>
                                <div className="address-box col-6">
                                    <ul>
                                        <li className="theme-color">
                                            <h6>Issue Date:
                                                <span className="text-content">
                                                    {" "} {formatDate(getServiceDetails?.order_id)}
                                                </span> 
                                            </h6>
                                        </li>
                                        <li className="theme-color">
                                            <h6>Invoice No:
                                                <span className="text-content">
                                                    {" "}{getServiceDetails.order_id}
                                                </span> 
                                            </h6>
                                        </li>
                                        <li className="theme-color">
                                            <h6>Email:
                                                <span className="text-content">
                                                    {" "}{getServiceDetails.billing_email}
                                                </span> 
                                            </h6>
                                        </li>
                                        <li className="theme-color">
                                            <h6>Payment Method:
                                                <span className="text-content">
                                                    {" "}{getServiceDetails.payment_mode}
                                                </span> 
                                            </h6>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table col-8">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Service Name</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>{getServiceBookings.name || getServiceBookings.meta_title || getServiceBookings.meta_keyword || null}</td>
                                        <td>{getServiceDetails.total}</td>
                                    </tr>
                            </tbody>
                        </Table>
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td className="text-end fw-semibold">
                                        <h6>Total</h6>
                                    </td>
                                    <td className="fw-semibold">
                                        <h6>₹{getServiceDetails.total}</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div className="invoice-footer text-end mx-2 remove-print">
                        <button className="btn btn-dark" onClick={handlePrint}>Print</button>
                    </div>
                </div>
            </div>
        </StyledContainer>
    );
}

export default ServiceInvoice;
