import React from "react";
import styled from "styled-components";
import Accordion from "react-bootstrap/Accordion";

const StyledContainer = styled.div`
  margin: 2em 0;
  
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #fff;
    width: 16%;
    display: block;
    text-wrap: nowrap;
    margin-left: 2em;
    margin-bottom: 2em;
  }

  .accordion-button:not(.collapsed) {
    color: #114c5f; /* Active text color */
    background-color: #e2fbff; /* Active background color */
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }

  .accordion-item {
    color: #333; /* Default text color */
    background-color: #e2fbff; /* Background color for accordion items */
    border: var(--bs-accordion-border-width) solid #ccc; /* Border color */
  }

  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: #114c5f; /* Default text color */
    text-align: left;
    background-color: #fff; /* Default background color */
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }

  .accordion-body {
    font-size: 1rem;
    color: #333; /* Text color in body */
  }
`;

const cardData = [
  {
    title: "What does Petside India's pet insurance cover?",
    text: "Petside India's pet insurance covers a wide range of medical expenses, including terminal illnesses, surgeries, treatments, pre-hospitalization and post-hospitalization expenses, chronic conditions, emergency care, behavioral therapy, and alternative treatments. We also provide coverage for death and funeral costs to help you manage the financial aspects during difficult times."
  },
  {
    title: "Are pre-existing conditions covered by Petside India's pet insurance?",
    text: 'Unfortunately, pre-existing conditions are not covered by our pet insurance plans. A pre-existing condition is any illness or injury that your pet has shown signs of or been diagnosed with before the start of the insurance policy. We recommend enrolling your pet as early as possible to ensure they receive comprehensive coverage.'
  },
  {
    title: "How do I make a claim with Petside India's pet insurance?",
    text: "Making a claim with Petside India is straightforward. Simply complete the claim form available on our website and submit it along with relevant veterinary records and receipts. Our claims team will review your submission and process the claim promptly, ensuring you receive reimbursement for covered expenses quickly."
  },
  {
    title: "Can I customize my pet insurance plan?",
    text: "Yes, Petside India offers customizable pet insurance plans to suit your specific needs and budget. You can choose the level of coverage, deductible, and reimbursement rate that works best for you and your pet. This flexibility ensures that you have the right protection for your pet’s unique health requirements."
  },
  {
    title: "Does pet insurance cover routine care and vaccinations?",
    text: "Routine care and vaccinations are not typically covered under standard pet insurance plans. However, Petside India offers optional wellness add-ons that can cover routine care, including vaccinations, flea and tick prevention, and annual health check-ups. These add-ons help you manage the cost of maintaining your pet’s overall health."
  },
  {
    title: 'How soon can I use the insurance after purchasing a policy?',
    text: "There is a waiting period before coverage begins after purchasing a policy. For accidents, the waiting period is typically a few days, while for illnesses, it may be a few weeks. Specific waiting periods will be detailed in your policy documents. This waiting period ensures that there is no immediate risk when you start your coverage."
  },
];

function Faq() {
  return (
    <StyledContainer>
      <div className="container pt-2 pb-5">
        <div className="sp_head">
          <h2>FAQ’s</h2>
        </div>

        <Accordion defaultActiveKey="0">
          {cardData.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
              <Accordion.Header>{item.title}</Accordion.Header>
              <Accordion.Body>
                <p>{item.text}</p>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </StyledContainer>
  );
}

export default Faq;
