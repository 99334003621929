import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/about/vaccination.svg";
import img2 from "../../assets/images/about/neutritional.svg";
import img3 from "../../assets/images/about/pet-insurance.svg";
import img4 from "../../assets/images/about/dental.svg";
import img5 from "../../assets/images/about/vet2.png";
import Header from "../../components/Common/ComponentHeader";

const StyledContainer = styled.div`


.why_choose_us .row{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0px;
}
.choose_card{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 2em;
    // border: 1px solid #008DB3;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px;
}
.choose_card img{
    width: 80px;
    height: 80px;
    margin:1em;
}

.choose_card h5{
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 700;
  line-height: 30.48px;
  letter-spacing: 2px;
  text-align: left;
}

.choose_card p{
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.29px;
  letter-spacing: 2px;
  text-align: left;

}

.chooseus_image{
  width: 40vw;
  height: 668.6px;
  display: block;
  // margin-left: 4vw !important;
  margin: auto;
}

@media(max-width: 991px){
  .chooseus_image {
    width: 100%;
    height: 668.6px;
    display: block;
    margin: auto;
    margin-left: 0;
}

}

@media(max-width: 768px){

    .Choose_img{
        // margin-left: -17vh;
        display: none;
    }

    .why_choose_us h2 {
      width: 50%;
    }

}
@media(max-width: 540px){
    .about_us_banner .banner {
     flex-wrap: wrap;
 }
 img{
     width:100%
 }
}

@media(max-width: 428px){
  .ms-5{
  margin:0px !important;
  }

  .choose_card h5 {
    font-size: 20px;
  }
    
  .choose_card p {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight:500;
  }

  .choose_card{
    gap: 0.75em;
  }



}
`;

function WhyChooseUs({header1, header2}) {
  return (
    <StyledContainer>
      <div className="container why_choose_us py-4">
        <div className="container row justify-content-center">
          <div className=" col-sm-12 col-md-12 col-lg-7 Choose_heading">
            <div className="ms-5">
              <Header header1={header1} header2={header2} />
            </div>
            <div className="choose_list">
              <div className="choose_card">
                <img src={img1} alt="" />
                <div>
                  <h5>Pet Registration</h5>
                  <p>
                    Registered pets are easier to identify and return home if
                    they ever get lost.{" "}
                  </p>
                </div>
              </div>
              <div className="choose_card">
                <img src={img2} alt="" />
                <div>
                  <h5>Free Meal Plans</h5>
                  <p>
                    Our team of nutrition experts provides guidance on the best
                    food choices for your pet’s age, breed, and health
                    condition.{" "}
                  </p>
                </div>
              </div>
              <div className="choose_card">
                <img src={img3} alt="" />
                <div>
                  <h5>Pet Insurance</h5>
                  <p>
                    Financially secure your pet’s health care needs, ensuring
                    they receive the best medical treatment without delay.{" "}
                  </p>
                </div>
              </div>
              <div className="choose_card">
                <img src={img4} alt="" />
                <div>
                  <h5>Free Pet Care Products</h5>
                  <p>
                    Enjoy exclusive access to new and innovative pet care
                    products as part of our commitment to providing the best for
                    your pet.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-5 Choose_img">
            <img className="chooseus_image" width={"100%"} src={img5} alt="" />
          </div>
        </div>
      </div>
    </StyledContainer>
  );
}

export default WhyChooseUs;
