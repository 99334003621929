import { createSlice } from '@reduxjs/toolkit';
import { fetchUserAllDetail } from './web-actions';

const initialState = {
    data: null,
    loading: false,
    error: null,
};

const ProfileDetailSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAllDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserAllDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchUserAllDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default ProfileDetailSlice.reducer;