import React from 'react';
import './InsuranceSliderStyle.css';

const Card = ({ icon, label }) => {
  return (
    <div className="slider_insurance_card ">
      {/* <img src={icon} alt={label} className="card-icon" /> */}
      <p>{label}</p>
    </div>
  );
};

export default Card;
