import { createSlice } from '@reduxjs/toolkit';
import { fetchServicesBySlug } from './web-actions';



const initialState = {
  data: null,
  loading: false,
  error: null,
};

const servicesBySlugSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServicesBySlug.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServicesBySlug.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchServicesBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default servicesBySlugSlice.reducer;
