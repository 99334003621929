import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PencilSquare, Plus } from "react-bootstrap-icons";
import { fetchPetProfile } from "../../features/web/web-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const StyledContainer = styled.div`
  .card {
    overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .table th,
  .table td {
    padding: 12px;
    text-align: center; /* Center align content */
    vertical-align: middle; /* Vertically center content */
    border-bottom: 1px solid #dee2e6;
  }

  th {
    background-color: #0dcaf1;
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .action-column {
    text-align: center;
    color: #007bff;
    cursor: pointer;
  }

  .action-column:hover {
    color: #0056b3;
  }

  .time-slot-card {
    border: 1px solid;
    padding: 10px 18px;
    border-radius: 5px;
    margin-left: 30px;
    cursor: pointer;
  }

  .profile_div {
    height: 60px;
    width: 60px;
    border-radius: 50%;
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .pet-btn {
    display: flex;
    align-items: end;
    justify-content: end;
    padding-top: 15px;

    & a {
      background: #114c5f;
      border-radius: 5px;
      color: #fff;
      padding: 5px 10px;
      text-decoration: none;
      display: flex;
      align-items: center;
    }
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px 0px;
      height: auto;
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .hamburger {
      display: block;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }
  }
`;

function PetProfile() {
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    user_id = userData.id;
  }
  const dispatch = useDispatch();
  const [getPetProfiles, setPetProfiles] = useState([]);
  const getPetProfileData = useSelector(
    (state) => state.petProfiles.data || []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchPetProfile(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getPetProfileData && getPetProfileData.data) {
      setPetProfiles(getPetProfileData.data.rows);
    }
  }, [getPetProfileData]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <StyledContainer>
      <div className="container-fluid">
        <div className="card">
          <div className="pet-btn">
            <a href="/auth/add-pet">
              <Plus /> Add Pet Profile
            </a>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Profile</th>
                <th>Pet Type</th>
                <th>Pet Name</th>
                <th>Breed</th>
                <th>Age</th>
                <th>Weight</th>
                <th>Gender</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getPetProfiles.map((e) => (
                <tr key={e.id}>
                  <td>{e.id}</td>
                  <td>
                    <img
                      className="profile_div"
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${e.profile_image}`}
                      alt={`${e.name}'s profile`}
                    />
                  </td>
                  <td>{e.type}</td>
                  <td>{e.name}</td>
                  <td>{e.breed}</td>
                  <td>{e.age}</td>
                  <td>{e.weight}</td>
                  <td>{e.sex}</td>
                  <td className="text-center text-primary">
                    <a href={`/auth/edit-pet/profile/${e.id}`}>
                      <PencilSquare />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </StyledContainer>
  );
}

export default PetProfile;
