import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
.pro_header .filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pro_header h1{
text-align:center;
padding: 20px 0px ;
font-size: 32px;
font-weight: 700;

}
.pro_header h2{
    font-weight: 700;
    padding-top:1em;
}
.pro_header h3 {
    text-align:center;
    font-weight: 700;
    font-size: 28px;
    color:#008DB3;
}
@media (max-width: 425px){
    .pro_header h1{
     font-size: 25px;
     font-weight:700;
  }
    .pro_header h2{
        font-weight: 600;
        font-size: 18px;
    }
  }
`;

function ProductHeader() {
  return (
    <StyledContainer>
        <div className="container pro_header">

        <div>
            <h1>Customised & Tailored Products For Furry Friends</h1>
        </div>
        <div className='filters'>
            <h2>Upto 25% OFF on all Products</h2>
            <p>Filters </p>
        </div>
        <hr />
        <div>
            <h3>Products</h3>
        </div>
        </div>
    </StyledContainer>
  )
}

export default ProductHeader