import React from 'react';
import './SuccessPage.css'; // Import the CSS file for styling
import failedImage from "../../assets/images/paymentError.png";



const SuccessPage = () => {
    const Retry = () => {
        // Redirect to the contact page
        window.history.go(-2);
      };
      const returnHome = () => {
        // Redirect to the contact page
        window.location.href = "/";
      };
  return (
    <div className="success-container">
      <img src={failedImage} alt="Thank You Image" />
      <h1>ERROR!!</h1>
      <p style={{ fontFamily: 'Montserrat', fontSize: '30px', color: '#000000' , fontWeight: '600', lineHeight: '20px' }}>Your Payment has not been Processed.</p><br/>
      <p style={{ fontFamily: 'Montserrat', fontSize: '25px', color: '#000000' ,fontWeight: '500', lineHeight: '30px' }}>We're sorry, but we couldn't process your <br/> payment at this time.</p><br/>
      <div className="buttons">
        <button className="buttons-button" onClick={() => Retry()}>Retry Payment</button>
        <button className="buttons-button"  onClick={() => returnHome()}>Return Home</button>
      </div>
    </div>
  );
}

export default SuccessPage;
