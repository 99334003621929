import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { getSlotByServiceWeek } from "../../features/web/slot-action";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { message } from "antd";
import { Display } from "react-bootstrap-icons";

const StyledContainer = styled.div`
  .time-slot-card {
    border: 1px solid;
    padding: 10px 18px;
    border-radius: 5px;
    margin-left: 30px;
    cursor: pointer;
    margin: auto auto 9px;
  }
  .time-slot-card.selected {
    background-color: #114c5f;
    color: #fff;
  }
  @media (max-width: 768px) {
    .time-slot-card {
      border: 1px solid;
      padding: 10px 18px;
      border-radius: 5px;
      margin-left: 30px;
      cursor: pointer;
      display: inline-flex;
      margin-top: 1em !important;
    }
  }
`;

const StyledDatePickerWrapper = styled.div`
  display: block;
  .react-datepicker-wrapper {
    display: block;
    width: 100%;
  }
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  .form-control {
    width: 100%;
  }
`;

const TimeSlotSelector = ({ id }) => {
  const dispatch = useDispatch();
  const slotData = useSelector((state) => state.slot.data);

  const [morningSlot, setMorningSlot] = useState([]);
  const [eveningSlot, setEveningSlot] = useState([]);
  const [afternoonSlot, setAfternoonSlot] = useState([]);

  useEffect(() => {
    dispatch(getSlotByServiceWeek({ id: id })).then((res) => {
      setMorningSlot(res.payload.morningSlot);
      setEveningSlot(res.payload.eveningSlot);
      setAfternoonSlot(res.payload.afternoonSlot);
    });
  }, [dispatch, id]);

  const [selectedTime, setSelectedTime] = useState(null);

  const handleSelectTime = (time) => {
    setSelectedTime(time);
  };

  return (
    <StyledContainer>
      {morningSlot.length > 0 &&
        afternoonSlot.length > 0 &&
        eveningSlot.length > 0 && (
          <div>
            <h6 className="pt-4 pb-3">Select Time Slot</h6>
            <div className="pb-4">
              {selectedTime && <h6> Selected Time : {selectedTime}</h6>}
            </div>
            <div className="time-slot-container">
              <div className="row row-cols-12 row-cols-md-3 g-4">
                {morningSlot.length > 0 && (
                  <div>
                    <label
                      className="row row-cols-12"
                      style={{ marginLeft: "8px" }}
                    >
                      Morning Slot
                    </label>
                    {morningSlot.map((time, index) => (
                      <div
                        key={index}
                        className={`col time-slot-card ${selectedTime === time ? "selected" : ""
                          }`}
                        onClick={() => handleSelectTime(time)}
                      >
                        {time}
                      </div>
                    ))}
                  </div>
                )}

                {afternoonSlot.length > 0 && (
                  <div>
                    <label
                      className="row row-cols-12"
                      style={{ marginLeft: "8px" }}
                    >
                      Mid-Day Slot
                    </label>
                    {afternoonSlot.map((time, index) => (
                      <div
                        key={index}
                        className={`col time-slot-card ${selectedTime === time ? "selected" : ""
                          }`}
                        onClick={() => handleSelectTime(time)}
                      >
                        {time}
                      </div>
                    ))}
                  </div>
                )}

                {eveningSlot.length > 0 && (
                  <div>
                    <label
                      className="row row-cols-12"
                      style={{ marginLeft: "8px" }}
                    >
                      Evening Slot
                    </label>
                    {eveningSlot.map((time, index) => (
                      <div
                        key={index}
                        className={`col time-slot-card ${selectedTime === time ? "selected" : ""
                          }`}
                        onClick={() => handleSelectTime(time)}
                      >
                        {time}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </StyledContainer>
  );
};

function SlotModal(props) {
  let id = props.slotId ? props.slotId : 1;
  const [formData, setFormData] = useState({
    selectedDate: null, // Initialize as null for DatePicker
    selectedTime: "",
  });
  const [show, setShow] = useState(false);
  const [isDateTimeSelected, setIsDateTimeSelected] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  useEffect(() => {
    setMinDate(getTomorrowDate());
    setMaxDate(getMaxDate());
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getTomorrowDate = () => {
    const today = new Date();
    today.setDate(today.getDate() + 1);
    return today;
  };

  const getMaxDate = () => {
    const today = new Date();
    today.setFullYear(today.getFullYear() + 1);
    return today;
  };

  const handleChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedDate: date,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { selectedDate } = formData;
    let selectedTime = "00:00"; // Default value

    const selectedTimeElement = document.querySelector(".selected");
    if (selectedTimeElement) {
      selectedTime = selectedTimeElement.textContent;
    }

    if (validateDate(selectedDate) && selectedTime) {
      props.onSlotSubmit({
        selectedDate: selectedDate.toISOString().split('T')[0], // Convert to string
        selectedTime
      });
      setShow(false);
      setIsDateTimeSelected(true);
    } else {
      message.error("Please select Valid Date and time.");
    }
  };

  const validateDate = (date) => {
    return date >= minDate && date <= maxDate;
  };

  const buttonText = isDateTimeSelected ? "Edit Date & Time" : "Select Date & Time";

  return (
    <>
      <button type="button" className="custom_btn" onClick={handleShow}>
        {buttonText}
      </button>

      <Modal centered scrollable show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isDateTimeSelected ? "Edit Date & Time" : "Select Date & Time"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Start Date</h6>
          <StyledDatePickerWrapper>
            <DatePicker
              selected={formData.selectedDate}
              onChange={handleChange}
              minDate={minDate}
              maxDate={maxDate}
              dateFormat="yyyy-MM-dd"
              className="form-control"
              placeholderText="YYYY-MM-DD"
            />
          </StyledDatePickerWrapper>

          <TimeSlotSelector id={id} />
          <button type="button" className="custom_btn mt-4" onClick={handleSubmit}>
            Continue
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SlotModal;
