import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../components/Common/ComponentHeader";
import expimage from "../../../src/assets/images/joy.PNG";

const StyledContainer = styled.div`
  .card {
    border: 0px;
    background-color: black;
    color: white;
    border-radius: 15px;
  }

  .card-header {
    border-radius: 15px;
    background: transparent;
  }

  .card-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    letter-spacing: 2px;
    text-align: left;
  }

  .card-text {
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
    line-height: 14.63px;
    letter-spacing: 2px;
    text-align: left;
  }

  .card-sellingprice{
  font-size: 25px;
  font-weight: 700;
  line-height: 30.48px;
  letter-spacing: 2px;
  text-align: right;
  }

  .card-baseprice{
  font-size: 15px;
  font-weight: 300;
  line-height: 18.29px;
  letter-spacing: 2px;
  text-align: right;
  }

  .card-tandc{
    margin-top:30%;
    font-size: 10px;
    font-weight: 400;
    line-height: 9.75px;
    letter-spacing: 2px;
    text-align: right;

  }

  .btn-dark {
    color: black;
    background-color: white;
    padding: 10px 30px;
    font-size: 11px;
    font-weight: 600;
    line-height: 12.19px;
    letter-spacing: 2px;
    text-align: left;
  }

  .slick-slide {
    padding: 15px 15px;
    transition: all 0.4s ease-in-out;
  }

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

.slick-slide:nth-of-type(3n+1) .card {
  background-color: #d9d9d9;
  color: black;
}

.slick-slide:nth-of-type(3n+1) .btn-dark {
  background-color: black;
  color: white;
}

.slick-slide:nth-of-type(3n+2) .card {
  background-color: #91e8ff;
  color: black;
}

.slick-slide:nth-of-type(3n+2) .btn-dark {
  background-color: black;
  color: white;
}

.slick-slide:nth-of-type(3n) .card {
  background-color: black;
}

.slick-slide:nth-of-type(3n) .btn-dark {
  background-color: white;
  color: black;
}

  @media (max-width: 1400px) and (min-width:1200px){
    .slick-slide{
      width:260px !important;
      padding: 10px !important;
    }

    .btn-dark{
    padding: 10px 18px;
    }
  }

@media (max-width: 768px) {
  .card{
    width:210px !important;
    height:200 !important;
  }
  .card-header{
  height:90px !important;
  }
  img{
  height: 90px !important;
  object-fit: cover;
  }

  .card-title{
  font-size: 13px;
  font-weight: 700;
  line-height: 15.85px;
  letter-spacing: 1.375px;
  text-align: left;
  }

  .card-text{
  font-size: 9px;
  font-style: italic;
  font-weight: 300;
  line-height: 10.97px;
  letter-spacing: 1.375px;
  text-align: center;
  }

  .btn-dark {
    color: black;
    background-color: white;
    padding: 4px 10px;
    font-size: 9px;
    font-weight: 600;
    line-height: 12.19px;
    letter-spacing: 2px;
    text-align: left;
  }

  .card-sellingprice{
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  letter-spacing: 1.375px;
  text-align: right;
  }

  .card-baseprice{
  font-size: 10px;
  font-weight: 300;
  line-height: 12.19px;
  letter-spacing: 1.375px;
  text-align: center;
  }

  .card-tandc{
  font-size: 6px;
  font-weight: 400;
  line-height: 7.31px;
  letter-spacing: 1.375px;
  text-align: center;
  }
}

@media (max-width: 426px) {
  .slick-slide.slick-active.slick-center.slick-current{
    width:228px !important;
  }
}

@media (max-width: 376px) {
  .slick-slide.slick-active.slick-center.slick-current{
    // padding: 0px !important;
  }

  .slick-slide.slick-active.slick-center.slick-current{
    width:228px !important;
  }
}
`;

const OfferSlider = ({ header1, header2 }) => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20px",
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    //when working on this comonent parsr the price to float and Add a toLocalString('en-IN') to add the formatting
    <StyledContainer>
      <div className="container my-4">
        <div>
          <Header header1={header1} header2={header2} />
        </div>
        <div className="CardSlider">
          <Slider {...sliderSettings} className="mx-2">
            <div className="card" style={{ width: "10rem" }}>
              <div className="card-header border-0">
                <img src={expimage} width="100%" height="130px" alt="" />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <h5 className="card-title">Grooming</h5>
                    <p className="card-text">free for today</p>
                    <button type="button" className="btn-dark mt-3">
                      Book Now
                    </button>
                  </div>
                  <div className="col-5">
                    <h5 className="card-sellingprice">Rs. 0</h5>
                    <p className="card-baseprice" style={{textDecoration:"line-through"}}>Rs. 400</p>
                    
                    <p className="card-tandc">T&C's apply</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "10rem" }}>
              <div className="card-header border-0">
                <img src={expimage} width="100%" height="130px" alt="" />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <h5 className="card-title">Grooming</h5>
                    <p className="card-text">free for today</p>
                    <button type="button" className="btn-dark mt-3">
                      Book Now
                    </button>
                  </div>
                  <div className="col-5">
                    <h5 className="card-sellingprice">Rs. 0</h5>
                    <p className="card-baseprice" style={{textDecoration:"line-through"}}>Rs. 400</p>
                    
                    <p className="card-tandc">T&C's apply</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "10rem" }}>
              <div className="card-header border-0">
                <img src={expimage} width="100%" height="130px" alt="" />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <h5 className="card-title">Grooming</h5>
                    <p className="card-text">free for today</p>
                    <button type="button" className="btn-dark mt-3">
                      Book Now
                    </button>
                  </div>
                  <div className="col-5">
                    <h5 className="card-sellingprice">Rs. 0</h5>
                    <p className="card-baseprice" style={{textDecoration:"line-through"}}>Rs. 400</p>
                    
                    <p className="card-tandc">T&C's apply</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card" style={{ width: "10rem" }}>
              <div className="card-header border-0">
                <img src={expimage} width="100%" height="130px" alt="" />
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <h5 className="card-title">Grooming</h5>
                    <p className="card-text">free for today</p>
                    <button type="button" className="btn-dark mt-3">
                      Book Now
                    </button>
                  </div>
                  <div className="col-5">
                    <h5 className="card-sellingprice">Rs. 0</h5>
                    <p className="card-baseprice" style={{textDecoration:"line-through"}}>Rs. 400</p>
                    <p className="card-tandc">T&C's apply</p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </StyledContainer>
  );
};

export default OfferSlider;
