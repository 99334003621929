import { createSlice } from '@reduxjs/toolkit';
import { fetchGalleryPhoto } from './web-actions';


const initialState = {
    data: [],
    loading: false,
    error: null,
};

const GalleryPhotoSlice = createSlice({
    name: 'galleryphoto',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGalleryPhoto.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGalleryPhoto.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchGalleryPhoto.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default GalleryPhotoSlice.reducer;
