import React from "react";
import AboutUs from ".";
import Whoweare from "./Whoweare";
import ProductListingSlider from "../homepage/ProductListing";
import ServicesSlider from "../services/Services";
import PremiumPetServices from "../homepage/PremiumPet";
import WhyChooseUs from "./WhyChooseUs";
import PetServices from "../homepage/pet_services";
import ServicePackage from "../services/servicepackage";
import PackageSubscription from "../homepage/package";

function AboutRow() {
  return (
    <div>
      {/* <AboutUs /> */}
      <Whoweare />
      <PetServices header1="Shop Our" header2="SERVICES" />
      <WhyChooseUs header1="Why" header2="Choose Us?" />
      {/* <PackageSubscription header1="Discounts On" header2="SUBSCRIPTIONS" /> */}
      <ServicePackage header1="Discounts On" header2="SUBSCRIPTIONS" slug="dog-training" />
      {/* <PetServices header1="Top Selling" header2="SERVICES" /> */}
      {/* <ServicesSlider /> */}
      {/* <PremiumPetServices isCompact={true}/> */}
      <ProductListingSlider header1="Pet" header2="PRODUCTS" />
    </div>
  );
}

export default AboutRow;
