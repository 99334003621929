import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchDeliveryAddress } from "../../features/web/web-actions";
import { PencilSquare } from "react-bootstrap-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const StyledContainer = styled.div`
  .card {
    overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .table th,
  .table td {
    padding: 12px;
    text-align: center; /* Center align content */
    vertical-align: middle; /* Vertically center content */
    border-bottom: 1px solid #dee2e6;
  }

  th {
    background-color: #0dcaf1;
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .action-column {
    text-align: center;
    color: #007bff;
    cursor: pointer;
  }

  .action-column:hover {
    color: #0056b3;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px 0px;
      height: auto;
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .hamburger {
      display: block;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }
  }
`;

function SavedAddress() {
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    user_id = userData.id;
  }
  const dispatch = useDispatch();
  const [getDelAdd, setDelAdd] = useState([]);
  const getDeliveryAddress = useSelector(
    (state) => state.deliveryAddress.data || []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchDeliveryAddress(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getDeliveryAddress && getDeliveryAddress.data) {
      setDelAdd(getDeliveryAddress.data.rows);
    }
  }, [getDeliveryAddress]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <StyledContainer>
      <div className="container-fluid">
        <div className="card">
          <h2 className="text-center fw-bold">Address</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Address Type</th>
                <th>House No</th>
                <th>Address</th>
                <th>Landmark</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getDelAdd.map((e) => (
                <tr key={e.id}>
                  <td>{e.id}</td>
                  <td>{e.type}</td>
                  <td>{e.house_no}</td>
                  <td>{e.address}</td>
                  <td>{e.landmark}</td>
                  <td className="text-center text-primary">
                    <a href={`/auth/address/edit/${e.id}`}>
                      <PencilSquare />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </StyledContainer>
  );
}

export default SavedAddress;
