import React from 'react';
import styled from 'styled-components';

const StyledHeaderContainer = styled.div`
    margin-left: 10%;
    margin-bottom: 3%;
`;

const StyledHeader1 = styled.h2`
    margin-bottom:0px;
    font-size: 30px;
    font-weight: 400;
    line-height: 36.57px;
    letter-spacing: 2px;
    text-align:left;

    @media (max-width: 426px) {
        margin-bottom:5px;
        font-size: 22px;
        line-height: 27px;
    }

    @media (max-width: 376px) {
        margin-bottom:5px;
        font-size: 16px;
        line-height: 14.63px;
    }
`;

const StyledHeader2 = styled.h3`
    border-bottom: 5px solid #12D2FF;
    width: fit-content;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
    text-align:left;

    @media (max-width: 426px) {
        font-size: 25px;
        line-height: 32px;
    }

    @media (max-width: 376px) {
        font-size: 20px;
        line-height: 18.29px;
    }
`;

const Header = ({ header1, header2 }) => {
    return (
        <StyledHeaderContainer>
            <StyledHeader1>{header1}</StyledHeader1>
            <StyledHeader2>{header2}</StyledHeader2>
        </StyledHeaderContainer>
    );
}

export default Header;
