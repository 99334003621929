import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/Env";

export const getSlotByServiceWeek = createAsyncThunk(
  "servicesSlot/fetch",
  async ({id}) => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/pet/service/booking/slot/${id}`
      ).then((response) => response.json());
      if (success) {
        return data;
      }
      throw new Error("Request failed");
    } catch (error) {
      console.error("Error fetching services:", error);
      throw error;
    }
  }
);