import React from 'react'
import styled from 'styled-components'
import img2 from '../../assets/images/dogs/blue-bg-dog.jpg'
import img1 from '../../assets/images/dogs/pink-bg-dog.jpg'
const StyledContainer = styled.div`
.wwr h6, .home_about h6{
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008DB3;
    width: 20%;
    display: block;
    text-wrap: nowrap;
}
.wwr p, .home_about p{
    line-height: 2;
}
.wwr , .home_about{
    padding-top: 6em;
}
@media(max-width: 768px){
    .home_about img, .wwr img{
        width: 100%;
    }
}
`;
function WhoWeAre() {
  return (
    <StyledContainer>
        <div className='container'>
            <div className="row home_about">
                <div className="col-md-6 ">
                   <h6>Hey There!</h6>  
                    <p>
                    Greetings from www.petside.in team! <br /> 
                    Hope you are doing well. <br /> <br />
                    We'd love for you to read our story and be a part of journey in any way possible by forwarding this message to Dog owners, cat owners and Pet lovers! <br /> <br />
                    Be it a pet parent(Dog owners,Cat owners) who can now avail our services in Mumbai, Navi Mumbai, Thane(Pune coming soon) to Pet lovers who can become a pet boarder, dog walker, pet sitter or canine trainer (the best job and something that is stress free, therapeutic and the best side gig ever for pet lovers!) And earn by spending time with pets; We have something for everyone! <br /> <br />
                    But before you do that, here's a little something about us:
                    </p>
                </div>
                <div className='col-md-6'>
                    <img src={img1}  alt="" />
                </div>
            </div>
        <div className='wwr'>
            <div className="row">
                <div className="col-md-6">
                    <img src={img2}  alt="" />
                </div>
                <div className="col-md-6">
                    <h6>Who we are</h6>
                    <p>  
                        We, PETSIDE, is India's first-ever pet care specialists with the largest network of top rated pet services across Mumbai, making us the #1 platform in this arena. We are solving a global problem with our handpicked Petstars, fully insured, state-of-the-art services, 24x7 live support and so much more which makes us the ONE STOP DESTINATION for all your canine needs. We make pet parenting easier altogether! 
                    </p>
                </div>
            </div>
        </div>
        </div>
    </StyledContainer>
  )
}

export default WhoWeAre