import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/homepage/home';
import HomeLayout from './layouts/HomeLayout';
import ProviderRegistrationForm from './pages/ProviderRegistrationForm';
import AboutRow from './pages/AboutUs/About';
import ContactUs from './pages/Contact-us/ContactUs';
import Product from './pages/Products/Product';
import Services from './pages/services';
import Blogs from './pages/Blogs/Blog'
import MapInit from './pages/map/MapInit';
import ServicesDetailsPage from './pages/services/ServicesDetailsPage';
import Cart from './pages/Cart/Cart';
import MainBlogs from './pages/Blogs/index';
import KnowledgeMain from "./pages/news";
import Success from './pages/PaymentSuccess/Success';
import Failed from './pages/PaymentSuccess/Failed';
import CheckoutForm from './pages/Cart/CheckoutForm';
import Profile from './pages/Profile/Profile';
import ProviderByLocation from './pages/ProviderListing/ProviderByLocation';
import ProfileLayout from './layouts/ProfileLayout';
import SavedAddress from './pages/Profile/Address';
import OrderHistory from './pages/Profile/OrderHistory';
import ServiceInvoice from './pages/Profile/ServiceInvoice';
import ProductHistory from './pages/Profile/ProductHistory';
import ProductInvoice from './pages/Profile/ProductInvoice';
import ProductDetails from './pages/Profile/ProductDetails';
import PetProfile from './pages/Profile/PetProfile';
import PetProfileForm from './pages/Profile/AddPetProfile';
import EditAddress from './pages/Profile/EditAddress';
import EditPetProfile from './pages/Profile/EditPetProfile';
import TermsConditions from './pages/Terms/TermsConditions';
import PrivacyPolicy from './pages/Terms/privacy';
import ServicesDetailsPageSlug from './pages/services/ServicesDetailsPageSlug';
import TherapyServicesPage from './pages/services/TherapyServicePage';
import Insurance from './pages/insurance';
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
            <Route path="success" element={<Success />} />
            <Route path="failed" element={<Failed />} />
            <Route path="" element={<HomeLayout />}>
            <Route index element={<Home />} />
            <Route path="about-us" element={<AboutRow />} />
            <Route path="contact-us" element={<ContactUs />} />
            <Route path="products" element={<Product />} />
            <Route path="services" element={<Services />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="services/:id" element={<ServicesDetailsPage />} />
            <Route path="service/:slug" element={<ServicesDetailsPageSlug />} />
            <Route path="service/therapy-sessions" element={<TherapyServicesPage />} />
            <Route path="provider" element={<ProviderRegistrationForm />} />
            <Route path="map" element={<MapInit />} />
            <Route path="cart" element={<Cart />} />
            <Route path="blog/:slug/:preview?" element={<MainBlogs />} />
            <Route path="knowledge/blog" element={<KnowledgeMain />} />
            {/* <Route path="blog" element={<MainBlogs />} /> */}
            <Route path="my-profile" element={<Profile />} />
            <Route path="checkout/:id" element={<CheckoutForm />} />
            <Route path="checkout" element={<CheckoutForm />} />
            <Route path="services-location" element={<ProviderByLocation />} />
            <Route path="terms-conditions" element={<TermsConditions />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="pet-insurance" element={<Insurance />} />
            </Route>
            <Route path="auth/*" element={<ProfileLayout />}>
            <Route path="profile" element={<Profile />} />
            <Route path="saved-address" element={<SavedAddress />} />
            <Route path="address/edit/:id" element={<EditAddress />} />
            <Route path="product-history" element={<ProductHistory />} />
            <Route path="product-invoice/:product_invoice_id" element={<ProductInvoice />} />
            <Route path="product-details/:product_order_id" element={<ProductDetails />} />
            <Route path="order-history" element={<OrderHistory />} />
            <Route path="service-invoice/:service_invoice_id" element={<ServiceInvoice />} />
            <Route path="pet-profile" element={<PetProfile />} />
            <Route path="add-pet" element={<PetProfileForm />} />
            <Route path="edit-pet/profile/:id" element={<EditPetProfile />} />
            </Route>
          <Route path="*" element={<Navigate to="" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
