import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Container, Card, OverlayTrigger, Popover } from "react-bootstrap";
import fbone from "../../assets/images/mdi_bone.png";
import { fetchTestimonials } from "../../features/web/web-actions";
import cust1 from "../../assets/images/ana.PNG";
import { useDispatch, useSelector } from "react-redux";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import star from "../../assets/images/star.svg"
import filledstar from "../../assets/images/filled_star.svg"

const StyledContainer = styled.div`
  body {
    background-color: #4A148C;
  }

  .testimonial_header h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
  }

  .customer_review {
    padding: 2em 1em;
  }

  .customer_review h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    width: 100%;
  }

  .testimonial_header h3 {
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: fit-content;
  }

  .padding {
    padding: 5rem;
  }

  @media (max-width: 991.98px) {
    .padding {
      padding: 1.5rem;
    }
  }

  @media (max-width: 767.98px) {
    .padding {
      padding: 1rem;
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #d2d2dc;
    border-radius: 11px;
    box-shadow: 0px 0px 5px 0px rgba(161, 163, 164, 1);
    width: 350px;
    height: 250px; /* Reduced fixed height */
    margin: auto;
  }

  .card .card-body {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .card-title img {
    width: 40px;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }

  h4 {
    line-height: 0.2 !important;
  }

  .profile {
    margin-top: 25px;
    margin-left: 11px;
  }

  .profile-pic {
    width: 58px;
  }

  .cust-name {
    font-size: 18px;
  }

  .cust-profession {
    font-size: 14px;
  }

  .bone {
    display: flex;
    align-items: center;
  }

  .bone img {
    width: 20px;
    margin-right: 5px;
  }

  .bone p {
    margin-left: 10px;
    font-size: 0.875rem;
  }

  .slick-slide {
    margin: 10px;
  }

  .read-more,
  .read-less {
    color: blue;
    cursor: pointer;
  }
`;


// const StyledContainer = styled.div`
// .testimonial_header h2 {
//   font-size: 30px;
//   font-weight: 600;
//   line-height: 0;
// }

// .testimonial_header h3 {
//   font-size: 35px;
//   font-weight: 700;
//   border-bottom: 5px solid #008db3;
//   width: 25%;
//   display: block;
//   text-wrap: nowrap;
// }

// .bone {
//   display: flex;
//   align-items: center;
//   justify-content: center; /* Adjust this property */
//   // padding-top: 5%;
// }

// .bone img {
//   margin-right: 5px; /* Add a margin to separate the images */
// }

// .container-fluid {
//   margin-top: 2em;
//   background: #ffffff;
// }

// .customer_review {
//   padding: 2em 1em;
// }

// .slick-slide {
//   padding: 15px 15px;
// }

// .slick-list {
//   padding: 0 -15px;
// }

// .slick-slide img {
//   margin: auto;
//   max-width: 100%; /* Ensure the image doesn't exceed its container */
// }

// .card {
//     width: 300px;
//     text-align: center;
//     border: none;
//     color: #fff;
//     border-radius: 30px 30px 0 0;
//     box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
// }

// .customer_review h4 {
//   font-size: 35px;
//   font-weight: 700;
//   margin: 0;
//   // border-bottom: 5px solid #008db3;
//   width: 20%;
//   // display: block;
// }

// .card-img-top {
//   width: 100%;
//   border-radius: 30px 30px 0 0; /* Rounded corners only at the top */
//   height: auto;
// }

// .card-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
// }

// .card-title {
//   font-weight: 700;
// }

// .customer_review .card-body {
//   background: #000;
//   color: #fff;
//   text-align: center;
// }

// .customer_review .bone {
//   display: flex;
//   align-items: center;
//   justify-content: space-evenly;
//   padding-top: 5%;
// }

// .card-body p {
//   text-align: none;
// }

// @media (max-width: 768px) {
//   .slick-slide {
//     padding: 15px 15px;
//     width: 360px;
//   }
// }
// @media (min-width: 320px) and (max-width: 540px) {
//   .customer_review h4 {
//     font-size: 24px;
//     margin: 0;
//     border-bottom: 3px solid #008db3;
//     width: 20%;
//     margin-bottom: 10%;
//   }
// }

// `;

console.log("something")

// const settings = {
//   infinite: true,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   nextArrow: (
//     <div>
//       <div className="next-slick-arrow">
//         <ArrowRightCircle />
//       </div>
//     </div>
//   ),
//   prevArrow: (
//     <div>
//       <div className="prev-slick-arrow">
//         <ArrowLeftCircle />
//       </div>
//     </div>
//   ),
//   responsive: [
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//   ],
// };

const TestimonialSlider = () => {
  const dispatch = useDispatch();
  const testimonialData = useSelector(
    (state) => state.fetchTestimonial.data?.data?.rows || []
  );

  useEffect(() => {
    dispatch(fetchTestimonials()).catch((error) =>
      console.error("Error:", error)
    );
  }, [dispatch]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 800,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <ArrowRightCircle />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <ArrowLeftCircle />
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const renderDescriptionPopover = (description) => (
    <Popover id="popover-basic">
      <Popover.Body>{description}</Popover.Body>
    </Popover>
  );

  return (
    <StyledContainer>
      <div className="container customer_review">
        <div className="testimonial_header">
          <h2></h2>
          <h3>Happy Customer</h3>
        </div>
        <div className="Card-list pt-4">
          <Slider {...sliderSettings}>
            {testimonialData.map(({ customer_name, profile_url, description, id }) => (
              <div key={id} className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" alt="quote" />
                  </h4>
                  <div className="template-demo">
                    <p className="card-text">
                      <span>
                        {description.slice(0, 200)}
                        {description.length > 200 && (
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={renderDescriptionPopover(description)}
                          >
                            <span className="read-more">... Read more</span>
                          </OverlayTrigger>
                        )}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-2">
                      <img
                        className="profile-pic"
                        src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                        alt="profile"
                      />
                    </div>
                    <div className="col-sm-10">
                      <div className="profile">
                        <h4 className="cust-name">{customer_name}</h4>
                        <div className="bone">
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <p>5/5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </StyledContainer>
  );
};

// function TestimonialSlider() {
//   const dispatch = useDispatch();
//   const testimonialData = useSelector(
//     (state) => state.fetchTestimonial.data?.data?.rows || []
//   );

//   useEffect(() => {
//     dispatch(fetchTestimonials()).catch((error) =>
//       console.error("Error:", error)
//     );
//   }, [dispatch]);

//   const [showFullDescription, setShowFullDescription] = useState(false);

//   const toggleDescription = () => {
//     setShowFullDescription(!showFullDescription);
//   };


//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//      nextArrow: (
//         <div>
//           <div className="next-slick-arrow">
//             <ArrowRightCircle />
//           </div>
//         </div>
//       ),
//       prevArrow: (
//         <div>
//           <div className="prev-slick-arrow">
//             <ArrowLeftCircle />
//           </div>
//         </div>
//        ),
//     responsive: [
//       {
//         breakpoint: 768, // Tablets and smaller desktops
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 520, // Mobile devices
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           variableWidth: true,
//           adaptiveHeight: true,
//         },
//       },
//     ],
//   };

//   return (
//     <StyledContainer>
//         <div className="container-fluid">
//         <div className="container customer_review">
//         <div className="testimonial_header">
//           <h2></h2>
//           <h3>Happy Customer</h3>
//         </div>
//           <div className="Card-list">
//             <Slider {...sliderSettings}>
//               {testimonialData.map(
//                 ({ customer_name, profile_url, description, id }) => (
//                   <div key={id} className="card">
//                     <img
//                       src={`${process.env.REACT_APP_API_BASE_URL}/images/${profile_url}`}
//                       className="card-img-top"
//                       alt="..."
//                       style={{ height: '180px', objectFit: 'cover' }}
//                     />
//                     <div className="card-body">
//                       <h5 className="card-title">{customer_name}</h5>
//                       <p className="card-text">
//                         <div
//                           className={
//                             description.length > 50 ? "Long_Desc" : "Low_Desc"
//                           }
//                         >
//                           {showFullDescription ? (
//                             <span>
//                               {description}
//                               <span
//                                 className="read-less"
//                                 onClick={toggleDescription}
//                               >
//                                 {" "}
//                                 Read less
//                               </span>
//                             </span>
//                           ) : (
//                             <span>
//                               {description.slice(0, 50)}
//                               {description.length > 50 && (
//                                 <span
//                                   className="read-more"
//                                   onClick={toggleDescription}
//                                 >
//                                   {". "}
//                                   Read more
//                                 </span>
//                               )}
//                             </span>
//                           )}
//                         </div>
//                       </p>
//                       <div className="bone">
//                         <img src={filledstar} alt="" />
//                         <img src={filledstar} alt="" />
//                         <img src={filledstar} alt="" />
//                         <img src={filledstar} alt="" />
//                         <img src={filledstar} alt="" />
//                         <p>5/5</p>
//                       </div>
//                     </div>
//                   </div>
//                 )
//               )}
//             </Slider>
//           </div>
//         </div>
//       </div> 

//   </StyledContainer>
//   );
// }


export default TestimonialSlider;
