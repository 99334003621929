import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel, Button } from 'react-bootstrap';
import NewsletterSignupModal from '../../pages/homepage/newsletter';

const StyledContainer = styled.div`
  .carousel .carousel-inner {
    transition: transform 2s ease-in !important;
    
  }

  .top-carousel .carousel-item {
    height: 39px; 
    font-weight:500;
    overflow: hidden;
    background-color: #91E8FF;
    color: black
  }

  .carousel-item span {
    font-size: 15px;
    margin-right: 5px;
    line-height:18px;
    letter-spacing:1.8px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .carousle-btn {
    display: inline-block;
    padding: 2px 20px;
    font-size: 12px;
    font-weight:600;
    letter-spacing: 1px;
    cursor: pointer;
    border: none;
    border-radius: 2px;
    background-color: #ffffff;
    color: #000000;
  }

  .highlight {
    background-color: transparent;
    font-weight: 700;
    padding: 5px;
  }

  @media (max-width: 768px) {

    .carousel-item span {
      font-size: 12px;
      text-align: center;
      margin-bottom: 5px;
    }

    .carousle-btn {
      padding: 4px 15px;
      font-size: 12px;
    }
    
  }

  @media (max-width: 480px) {
    .top-carousel .carousel-item {
      height: auto;
    }

    .carousel-item span {
    font-size: 9px !important;
    line-height: 10px; 
    font-weight:500;
    }

    .highlight {
    background-color: transparent;
    font-weight: 700;
    padding: 0;
  }

    .carousle-btn {
      padding: 3px 7px;
      font-size: 9px;
    }
  }
`;


const SlimCarousel = () => {
  const [showModal, setShowModal] = useState(false);
  const [buttonId, setButtonId] = useState(null);

  useEffect(() => {
    const isClosed = localStorage.getItem('newsletterClosed') === 'true';
    if (!isClosed) {
      setShowModal(false);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubscribeClick = (id) => {
    localStorage.removeItem('newsletterClosed');
    setButtonId(id);
    setShowModal(true);
  };

  return (
    <StyledContainer>
      <div>
        <Carousel
          controls={false}
          indicators={false}
          interval={3000}
          slide={true}
          className="top-carousel"
        >
          <Carousel.Item>
            <div className="d-flex justify-content-center align-items-center p-2">
              <span className='pe-3'>Get <span className='highlight'>FREE</span> paw cleaner, <span className='highlight'>FREE</span>pet food - T&C apply*</span>
              <Button id='1' variant="black" className='carousle-btn' onClick={() => handleSubscribeClick('1')}>Subscribe</Button>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="d-flex justify-content-center align-items-center p-2">
              <span className='pe-3'>Get <span className='highlight'>FREE </span> Dog training Ebook on subscribing</span>
              <Button id='2' variant="dark" className='carousle-btn' onClick={() => handleSubscribeClick('2')}>Subscribe</Button>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <NewsletterSignupModal isOpen={showModal} onClose={handleCloseModal} buttonId={buttonId} />
    </StyledContainer>
  );
};

export default SlimCarousel;
