import { useState } from 'react';
import { useLocation } from 'react-router-dom'; // Remove this line
import styled from 'styled-components';

const StyledContainer = styled.div`
  .sidebar {
    margin-left: 9em;
    margin-right: 1em;
    height: 84vh;
    background-color: #55C8DB !important;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .nav-link {
    color: #fafafa;
    font-size: 18px;
    font-weight: 600;
    padding: 0.2em 0.7em;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
  }

  .nav-link:hover{
    background-color: #e9eeff;
    color: #000000;
  }

  .nav-link.selected {
    background-color: #e9eeff;
    color: #000000;
    border-radius: 5px
  }

  ul {
    overflow: auto;
    list-style-type: none;
    margin: 0;
    padding: 15px;
    & li {
      list-style: none;
      font-size: 18px;
      font-weight: 600;
      padding: 1em 0em;
    }
  }

  @media (max-width: 768px) {
    ul {
      height: auto;
    }

    .sidebar{
    display:none;
    }
  }

  `;

function ProfileSidebar() {
  const [selectedLink, setSelectedLink] = useState('');
  const location = useLocation();

  const handleNavLinkClick = (link) => {
    setSelectedLink(link);
  };

  return (
    <StyledContainer>
      <div className="sidebar">
        <ul className="list-unstyled">
          <li>
            <a
              href="/auth/profile"
              className={`nav-link ${location.pathname === '/auth/profile' ? 'selected' : ''}`}
              onClick={() => handleNavLinkClick('/auth/profile')}
            >
              Profile Info
            </a>
          </li>
          <li>
            <a
              href="/auth/saved-address"
              className={`nav-link ${location.pathname === '/auth/saved-address' ? 'selected' : ''}`}
              onClick={() => handleNavLinkClick('/auth/saved-address')}
            >
              Saved Address
            </a>
          </li>
          <li>
            <a
              href="/auth/order-history"
              className={`nav-link ${location.pathname === '/auth/order-history' ? 'selected' : ''}`}
              onClick={() => handleNavLinkClick('/auth/order-history')}
            >
              Service History
            </a>
          </li>
          <li>
            <a
              href="/auth/product-history"
              className={`nav-link ${location.pathname === '/auth/product-history' ? 'selected' : ''}`}
              onClick={() => handleNavLinkClick('/auth/product-history')}
            >
              Product History
            </a>
          </li>
          <li>
            <a
              href="/auth/pet-profile"
              className={`nav-link ${location.pathname === '/auth/pet-profile' ? 'selected' : ''}`}
              onClick={() => handleNavLinkClick('/auth/pet-profile')}
            >
              Pet Profile
            </a>
          </li>
        </ul>
      </div>
    </StyledContainer>
  );
}

export default ProfileSidebar;
