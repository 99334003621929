import { createSlice } from '@reduxjs/toolkit';
import { fetchAppliedCoupons } from './web-actions';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const couponSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppliedCoupons.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAppliedCoupons.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchAppliedCoupons.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

console.log(couponSlice);
export default couponSlice.reducer;
