import styled from "styled-components";
import { NavLink } from "react-bootstrap";
import product from "../../assets/images/dogs/dog-right.png";
import services from "../../assets/images/dogs/dog-left.png";

const StyledContainer = styled.div`
    .promo-image{
        position: absolute;
        bottom: 0;
        width: 160px;
    }
    .product-card{
        background: #FFE6B4;
        border-radius: 0 !important;
        border: none;
    }
    .promo_section{
        padding-top: 6em;
    }

    .serice-card{
        background: #E0F7FA;
        border-radius: 0 !important;
        border: none;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
        .promo-text{
            h1{
                font-size: 2rem;    
            }
            h5{
                font-size: 1.1rem;
            }
        }
    }
    @media (min-width: 1px) and (max-width: 1199px) {
        .promo-text{
            h1{
                font-size: 1.8rem;    
            }
            h5{
                font-size: 1rem;
            }
        }
        .promo-image{
            // display: none;
            
        }

        .product-card{
            margin-bottom: 5%
        }
    }
    @media (max-width: 768px) {
        .promo-image{
            position: relative;
            margin: 0 auto;
            width: 160px;
        }
        .promo_section{
            padding-top: 1em;
        }
      }
`;


const PromoCodes = ({ isCompact }) => {
    return (
      <StyledContainer>
        {!isCompact && (
            <section className="promo_section">
                <div className="mb-3 container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card p-2 product-card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                            <div className="promo-text">
                                                <h1>Products Promo</h1>
                                                <h5>10% discount & a free meal plan for first 100 customers on purchasing dog training services</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                            <div className="promo-image">
                                                <img src={product} className="img-fluid product-img" alt="product-promo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="card p-2 serice-card">
                                <div className="card-body p-3">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-8">
                                            <div className="promo-text">
                                                <h1>Service Promo</h1>
                                                <h5>10% discount & a free paw cleaner for first 100 customers on purchasing pet grooming services</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                            <div className="promo-image">
                                                <img src={services} alt="serivce-promo" className="img-fluid" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )}
      </StyledContainer>
    );
  };


export default PromoCodes;
