import React, { useEffect, useState } from "react";
import styled from "styled-components";
import formbg from "../../assets/images/provider-bg.jpg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { message } from "antd";
import { registerProvider } from "../../features/web/web-actions";
import MapInit from "../map/MapInit";
import Map from "../map/MapInit";
import FileInput from "../../components/Common/FileInput";
import * as Yup from "yup";
import googleMap from "../../assets/images/googlemap.gif";
// import 'bootstrap/dist/css/bootstrap.min.css';

const StyleContainer = styled.div`
  .customform {
    width: 600px;
  }
  .field-row {
    display: flex;
    justify-content: space-between;
  }

  .field-group {
    flex: 1;
    margin-right: 10px; /* Adjust as needed for spacing between fields */
    width: 100%;
  }
  .form-attachment {
    border: 2px solid black;
  }
  .multi-step-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .multi-step-form .form-control {
    border: 0.5px solid #000 !important;
    margin-bottom: 20px;
  }

  .multi-step-form h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .multi-step-form label {
    display: block;
    margin-bottom: 10px;
  }

  .multi-step-form input[type="text"],
  .multi-step-form input[type="tel"],
  .multi-step-form input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .multi-step-form button {
    background-color: #114c5f;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  .multi-step-form button:hover {
    background-color: #114c5fb0;
  }

  .multi-step-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .multi-step-form .previous-button {
    margin-right: 10px;
  }

  .rf-step .step-indicator {
    // font-size: 1.5rem;
    // margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4.6em;
  }

  .rf-step .step-indicator .step {
    // display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    text-align: center;
    line-height: 30px;
    // margin-right: 10px;
  }
  .rf-step .step-indicator .step span {
    position: relative;
    left: 2em;
    text-wrap: nowrap;
  }
  .step-desc {
    display: flex;
    text-align: left;
    padding-left: 10px;
  }
  .rf-step .step-indicator .step .desc {
  }

  .rf-step .step-indicator .step.active {
    background-color: #55c8db;
  }

  .rf-step .step-indicator .step.completed {
    background-color: #55c8db;
  }
  .register-form-row {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${formbg});
    background-size: 100%;
    padding: 4em 0em;
    // margin-top: -4em;
  }
  .rf-step {
    border: 1px solid #000;
    height: 100%;
    display: flex;
    align-items: start;
    padding: 5em 0em 0em 2em;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-right: none;
    background: #fff;
  }
  .rf-form {
    border: 1px solid #000;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: #fff;
    padding: 2em 0em;
  }
  .multi-step-form p {
    padding-bottom: 1em;
    padding-top: 2em;
  }
  .field_error {
    margin-top: 3px;
    color: red;
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${formbg});
    padding: 4em 0em;
    background-size: 100%;
    & .successMsg {
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 2em;
      background: #fff;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }

  @media (max-width: 1200px) {
    .rf-form {
      width: 60vw;
    }
  }

  @media (max-width: 1000px) {
    .rf-step {
      width: 28vw;
      font-size: 15px;
    }
    .rf-form {
      width: 70vw;
      // font-size: 18px;
    }
  }

  @media (max-width: 870px) {
    .rf-step {
      width: 28vw;
      font-size: 12px;
    }
    .rf-form {
      width: 70vw;
    }
    .rf-form .field-row {
      flex-direction: column;
    }

    .rf-form form {
      width: 50vw;
    }
  }

  @media (max-width: 768px) {
    .rf-step {
      height: auto;
      padding: 1em 0em 3em 5em;
      display: none; /* Hides the rf-step section */
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-right: 1px solid;
    }

    .register-form-row {
      display: block;
      padding-left: 4vw;
    }

    .rf-form {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      padding: 1em;
      border-radius: 20px;
      margin: auto;
      width: 90vw;
      margin-left: 0vw;
    }

    .rf-form form {
      margin-right: 20vw;
    }

    .rf-form input {
      width: 140% !important;
    }

    .msg_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-image: url(${formbg});
      padding: 4em 0em;
      background-size: 100%;

      & .successMsg {
        padding: 1em 1em;
        gap: 0em;
      }

      & h2 {
        font-size: 18px;
        font-weight: 700;
      }

      & h3 {
        font-size: 16px;
      }

      & a {
        background: #114c5f;
        color: #fff !important;
        padding: 3px 15px;
        border-radius: 5px;
        margin: 0px;
      }
    }
    .field-row {
      flex-direction: column; /* Stack fields vertically on smaller screens */
    }

    .field-group {
      flex: none; /* Reset flex to allow fields to take full width */
      margin-right: 0; /* Remove margin between fields */
    }

    .multi-step-form input[type="text"],
    .multi-step-form input[type="tel"],
    .multi-step-form input[type="email"] {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      font-size: 1rem;
      width: 300px;
      box-sizing: border-box;
    }

    .form-control {
      width: 300px;
    }

    .form-select {
      width: 300px;
    }

    .customform {
      width: 90%;
    }
  }

  @media (max-width: 450px) {
    .form_content {
      width: 92vw;
      margin-left: 0vw;
    }

    .form_content h2 {
      width: 84vw;
    }
  }
`;

const IndividualForm = ({ onStepChange }) => {
  // State variable to track the selected service type
  const [serviceType, setServiceType] = useState("");
  const [hasCertification, setHasCertification] = useState('');
  const handleCertificationChange = (e) => {
    console.log(e);
    setHasCertification(e.target.value);
  };
  // Function to handle the select change and update the state variable
  const handleServiceTypeChange = (e) => {
    console.log(e, "e");
    const selectedService = e.target.value;
    setServiceType(selectedService); // Update the service type state
    //handleChange(e); // Propagate the change to the parent component
  };
  const [step, setStep] = useState(1);
  const [getPolygonCoordinates, setPolygonCoordinates] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    password: "",
    role_id: 3,
    provider_service_type: "",
    user_comapany_details: [
      {
        company_name: "",
        company_phone: "",
        email: "",
        select_zone: "",
        address: "",
        pincode: "",
        image_url: [],
      },
    ],
    users_contact_info: [
      {
        name: "",
        phone: "",
        email: "",
        address: "",
        address1: "",
        city: "",
        state: "",
        pincode: "",
        profile_picture: [],
      },
    ],
    users_business_information: [
      {
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        user_name_as_per_bank: "",
        identity_type: "",
        identity_number: "",
        image_url: [],
      },
    ],
    expertise: [
      {
        experience: "",
        handling_aggressive: "",
        size_handling: "",
        dogs_and_cats: "",
        profile_description: "",
        certifications_and_accolades: "",
        certifications_and_accolades_image_url: "",
        pet_care_services: "",
        rates_and_cost: "",
        payment_options: "",
        certifications_and_accolades: "",
        property_pictures: [],
        videos: [],
      },
    ],
    users_address_info: [],
  };

  // Validation Schema for Step 1
  const validationSchemaStep1 = Yup.object().shape({
    first_name: Yup.string()
      .min(2, "First name must be at least 2 characters")
      .max(30, "First name cannot exceed 30 characters")
      .required("First name is required"),
    last_name: Yup.string()
      .min(2, "Last name must be at least 2 characters")
      .max(30, "Last name cannot exceed 30 characters")
      .required("Last name is required"),
    contact_no: Yup.string()
      .required("Phone number is required")
      .matches(/^[1-9][0-9]{9}$/, "Please enter exactly 10 digits without 0 or +91"),
    email: Yup.string()
      .email("Invalid email address")
      .max(50, "Email cannot exceed 50 characters")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(15, "Password must be at max 15 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(/[@$!%*?&]/, "Password must contain at least one special character (@, $, !, %, *, ?, &)")
      .required("Password is required"),
    provider_service_type: Yup.string().required("Service type is required"),
    users_contact_info: Yup.array().of(
      Yup.object().shape({
        address: Yup.string()
          .min(2, "Address must be at least 2 characters")
          .max(50, "Address cannot exceed 50 characters")
          .required("Address is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        pincode: Yup.string()
          .length(6, "Pincode must be exactly 6 digits")
          .matches(/^[0-9]{6}$/, "Pincode must be exactly 6 digits")
          .required("Pincode is required"),
      }),
    ),
    expertise: Yup.array().of(
      Yup.object().shape({
        experience: Yup.string()
          .required("Experience is required"),
      }),
    ),
  });


  // Corrected validation schema for Step 2
  // const businessInfoSchema = Yup.object().shape({
  //   account_number: Yup.string().required("Account number is required"),
  //   ifsc_code: Yup.string().required("IFSC code is required"),
  //   bank_name: Yup.string().required("Bank name is required"),
  //   user_name_as_per_bank: Yup.string().required("User name as per bank is required"),
  //   identity_type: Yup.string().required("Identity type is required"),
  //   image_url:Yup.array()
  //   .of(Yup.string())
  //   .required("The image URL array is required")
  //   .min(1, "At least one image is required"),
  // });

  // Define the schema for an array of business information objects
  // const validationSchemaStep2 = Yup.object().shape({
  //   users_business_information: Yup.array().of(businessInfoSchema).required("Business information is required"),
  // });

  const expertiseSchema = Yup.object().shape({
    experience: Yup.string().required("Experience is required"),
    handling_aggressive: Yup.string().required("This field is required"),
    size_handling: Yup.string().required("Selection of Size is required"),
    dogs_and_cats: Yup.string().required(
      "Comfortable with both dogs and cats is required"
    ),
    profile_description: Yup.string().required(
      "Profile description is required"
    ),
  });

  const validationSchemaStep3 = Yup.object().shape({
    expertise: Yup.array()
      .of(expertiseSchema)
      .required("At least one expertise object is required")
      .min(1, "At least one expertise object must be present"),
  });

  // const headings = ["Step 1", "Step 2", "Step 3", "Step 4"];
  const headings = [
    "General Information",
    // "Account Information",
    // "Expertise Information",
    // "Address Information",
  ];
  useEffect(() => {
    onStepChange(step);
  }, [step, onStepChange]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };
  const handlePolygonComplete = (coordinates) => {
    setPolygonCoordinates(coordinates);
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.users_address_info = getPolygonCoordinates
      ? getPolygonCoordinates
      : null;
    registerProvider({ ...values }).then((resp) => {
      if (!resp.success) {
        console.log("response from if", resp.message);
        message.error(resp.message);
        if (resp.responseCode === 422) {
          const newErrors = {};
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        console.log(resp);
        setIsSubmitted(true);
        resetForm();
        // navigate("../partner/thank-you");
      }
    });
    setSubmitting(false);
  };

  return (
    <StyleContainer>
      {!isSubmitted && (
        <div className="container-fluid">
            <div className="row ">
            <div className="register-form-row"> 
              <div className="col-6">content</div>
              <div id="form_content" className="col-6 rf-form form_content">
                <div className="multi-step-form">
                  <Formik
                    initialValues={formData}
                    onSubmit={handleSubmit}
                    validationSchema={
                      step === 1
                        ? validationSchemaStep1
                        : step === 2
                        ? validationSchemaStep3
                        : validationSchemaStep3
                    }
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      setFieldValue,
                      dirty,
                      validateForm,
                      setTouched,
                    }) => (
                      <Form onSubmit={handleSubmit} className="customform">
                        {step === 1 && (
                          <>
                            <h2>General Information</h2>
                            <div className="field-row">
                              <div className="field-group">
                                <label>First Name</label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="first_name"
                                  onChange={handleChange}
                                  placeholder="Enter the first name"
                                />
                                <ErrorMessage name="first_name" component="div" className="field_error" />
                              </div>

                              <div className="field-group">
                                <label>Last Name</label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="last_name"
                                  onChange={handleChange}
                                  placeholder="Enter the last name"
                                />
                                <ErrorMessage name="last_name" component="div" className="field_error" />
                              </div>
                            </div>

                            <div className="field-row">
                              <div className="field-group">
                                <label>Phone</label>
                                <Field
                                  className="form-control"
                                  type="text"
                                  name="contact_no"
                                  onChange={handleChange}
                                  placeholder="Enter the Contact Number"
                                />
                                <ErrorMessage name="contact_no" component="div" className="field_error" />
                              </div>

                              <div className="field-group">
                                <label>Email</label>
                                <Field
                                  className="form-control"
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  placeholder="Enter the Email"
                                />
                                <ErrorMessage name="email" component="div" className="field_error" />
                              </div>
                            </div>
                            <label>Password</label>
                            <Field
                              className="form-control"
                              type="password"
                              name="password"
                              onChange={handleChange}
                              placeholder="Create a Password"
                            />
                            <ErrorMessage name="password" component="div" className="field_error" />

                            <label>Address</label>
                            <Field
                              className="form-control"
                              id=""
                              cols="28"
                              rows="3"
                              name="users_contact_info[0].address"
                              onChange={handleChange}
                              placeholder="Address"
                            ></Field>
                            <ErrorMessage name="users_contact_info[0].address" component="div" className="field_error" />
                            <div>
                              <div className="field-row">
                                <div className="field-group">
                                <label for="city">Choose a city</label>
                                <select id="city"  
                                  className="form-control"
                                    name="users_contact_info[0].city"
                                    onChange={handleChange}>
                                    <option selected disabled value="">Select City</option>
                                    <option value="Mumbai">Mumbai</option>
                                    <option value="Pune">Pune</option>
                                    <option value="Delhi">Delhi</option>
                                    <option value="Hyderabad">Hyderabad</option>
                                    <option value="Bangalore">Bangalore</option>
                                </select>
                                <ErrorMessage name="users_contact_info[0].city" component="div" className="field_error" />
                                </div>
                                <div className="field-group">
                                  <label>Sub Area</label>
                                  <Field
                                    className="form-control"
                                    type="text"
                                    name="users_contact_info[0].state"
                                    onChange={handleChange}
                                    placeholder="Enter sub area"
                                  />
                                  <ErrorMessage name="users_contact_info[0].state" component="div" className="field_error" />
                                </div>
                              </div>
                              <label>Pincode </label>
                              <Field
                                className="form-control"
                                type="text"
                                name="users_contact_info[0].pincode"
                                onChange={handleChange}
                                placeholder="e.g. 401107"
                              />
                              <ErrorMessage name="users_contact_info[0].pincode" component="div" className="field_error" />
                              <label htmlFor="">Profile Picture</label>
                              <FileInput
                                className="form-control"
                                name={`users_contact_info[0].profile_picture`}
                                data={{
                                  maincategory: "product",
                                  subcategory: "images",
                                }}
                                // placeholder="Drop a profile picture"
                                required
                              />
                              <ErrorMessage name="users_contact_info[0].profile_picture" component="div" className="field_error" />
                            <br></br>
                            <h2>Service Information</h2>
                            <label>Select service type</label>
                            <div className="pb-3">
                              <select
                                className="form-select"
                                name="provider_service_type"
                                onChange={(e) => {
                                  handleServiceTypeChange(e);
                                  handleChange(e);
                                }} >
                                <option value="" disabled selected>Select Your Service</option>
                                <option value="Pet Boarding"> Pet Boarding</option>
                                <option value="Dog Walking">Dog Walking</option>
                                <option value="Pet Sitting">Pet Sitting</option>
                                <option value="Pet Grooming"> Pet Grooming </option>
                                <option value="Dog Training">Dog Training</option>
                                <option value="Day Care">Day Care</option>
                                <option value="Drop In visit">Drop In visit </option>
                                <option value="Therapy Sessions">  Therapy Sessions
                                </option>
                              </select>
                              <ErrorMessage name="provider_service_type" component="div" className="field_error" />
                            </div>
                           
                          <label>
                              How many years of experience do you have <br /> in
                              pet care?
                          </label>
                          <select  className="form-select" name="expertise[0].experience" onChange={handleChange}>
                            <option disabled selected value="">Select Your Experience</option>
                            <option value="0">0 years</option>
                            <option value="1-2">1-2 years</option>
                            <option value="3-5">3-5 years</option>
                            <option value="6-10">6-10 years</option>
                            <option value="10+">10+ years</option>
                          </select>
                          <ErrorMessage name="expertise[0].experience" component="div" className="field_error" />

                            {step === 2 &&
                              errors &&
                              errors.expertise &&
                              errors.expertise[0].experience && (
                                <div className="field_error">
                                  {errors.expertise[0].experience}
                                </div>
                              )}
                            </div>
                            <br />
                          </>
                        )}

                   
                        <button
                          type="button"
                          onClick={previousStep}
                          className="previous-button"
                          style={{
                            display: step > 1 ? "inline-block" : "none",
                          }}
                        >
                          Previous
                        </button>
                        {step < 1 && (
                          <button
                            type="button"
                            onClick={() => {
                              validateForm().then((errors) => {
                                console.log(errors, "errors");
                                if (Object.keys(errors).length === 0) {
                                  nextStep();
                                } else {
                                  setTouched({
                                    first_name: true,
                                    last_name: true,
                                    contact_no: true,
                                    email: true,
                                    password: true,
                                  });
                                }
                              });
                            }}
                          >
                            Next
                          </button>
                        )}
                        {step === 1 && <button type="submit">Finish</button>}
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSubmitted && (
        <div className="container msg_div">
          <div className="successMsg">
            <h2 className="text-center">
              Thank You for filling out the Provider Form !!
            </h2>
            <h3>We will reach out to you shortly :)</h3>
            <a href="/provider">Back</a>
          </div>
        </div>
      )}
    </StyleContainer>
  );
};

export default IndividualForm;
