import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SlimSlider from "../components/Common/slimslider.jsx"

const HomeLayout = () => {
  return (
    <>
      <div>
      <SlimSlider />
        <header>
          <Header />
        </header>
        <Outlet />
      </div>
        <Footer />
    </>
  );
};

export default HomeLayout;
