import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getServices } from "../../features/web/services-action";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import ServiceModal from "./../services/ServiceModal";
import Header from "../../components/Common/ComponentHeader";
import Bone from "../../assets/images/Home/solar_bone-broken-broken.png";
import Paw from "../../assets/images/Home/paw.png";
import Ball from "../../assets/images/Home/ball.png";
import Belt from "../../assets/images/Home/belt.png";

const StyledContainer = styled.div`
  .card {
    width: 100% !important;
    height: 375px  !important;
    border:none;
    border-radius: 13%;
    position: relative;
    overflow: hidden;
  }

  .card-body {
    padding: 12% 10%;
  }

  .card-title {
    font-size: 25px;
    font-weight: 700;
    line-height: 30.48px;
    letter-spacing: 2px;
    text-align: left;
  }

  .card-text {
    margin-top: 15%;
    font-size: 13.83px;
    font-weight: 500;
    line-height: 16.86px;
    letter-spacing: 1.5368421077728271px;
    text-align: left;
  }

  .card-text + h3 {
    margin-top: 13%;
    margin-bottom: 0.7rem;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 1.5368421077728271px;
    text-align: left;
  }

  p {
    margin-bottom:1.7rem;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.63px;
    letter-spacing: 2px;
    text-align: justify;
  }

  button {
    padding: 8px 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.19px;
    letter-spacing: 1.5px;
    text-align: center;
    justify-self:center;
  }

  .imagecontainer{
  align-self:end;
  }

  .img{
  width: 100%;
  object-fit: cover;
  }

 .slick-slide {
    padding: 15px 15px;
    transition: all 0.4s ease-in-out;
    transform: scale(0.9);
  }

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: cover;
  }

  .slick-center {
    transform: scale(1.1);
  }

.slick-dots {
    display:block !important;
}

.slick-dots li {
  margin: 0 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #D9D9D9;
  transition: background-color 0.3s ease;
}

.slick-dots li.slick-active {
  background-color: black;
  border-radius:15px;
  width:33px;
  height:14px;
}

.slick-dots li button:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
}

.slick-dots li:nth-last-child(n+4) {
  display: none; 
}

  .slick-slide:nth-of-type(3n+1) .card {
    background-color: #d2d2d2;
    color: black;
  }

  .slick-slide:nth-of-type(3n + 1) button {
    background-color: black;
    color: white;
  }

  .slick-slide:nth-of-type(3n + 1) .card::before {
    content: url(${Bone});
    position: absolute;
    top: 50%;
    left: 45%;
    z-index: 2;
  }

  .slick-slide:nth-of-type(3n + 2) .card {
    background-color: #91e8ff;
    color: black;
  }

  .slick-slide:nth-of-type(3n + 2) button {
    background-color: black;
    color: white;
  }

  .slick-slide:nth-of-type(3n + 2) .card::before {
    content: url(${Paw});
    position: absolute;
    top: 25%;
    left: 50%;
    z-index: 2;
  }

  .slick-slide:nth-of-type(3n) .card {
    background-color: black;
    color: white;
  }

  .slick-slide:nth-of-type(3n) .btn {
    background-color: white;
    color: black !important;
  }

  .slick-slide:nth-of-type(3n) .card::before {
    content: url(${Belt});
    position: absolute;
    top: 8%;
    left: 75%;
    z-index: 2;
  }

  @media (max-width: 1280px) {
    .card{
      height:350px !important;
    }

  }

  @media (max-width: 1024px) {
    .card{
      height:270px !important;
    }

    button{
      padding: 7px 10px ;
    }
  }

  @media (max-width: 768px) {
    .card {
      width: 330px;
      height: 235px !important;
    }

    .slick-dots {
        display:block !important;
    }

    .slick-dots li {
      margin: 0 5px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #D9D9D9;
      transition: background-color 0.3s ease;
    }

    .slick-dots li.slick-active {
      background-color: black;
      border-radius:15px;
      width:20px;
      height:10px;
    }

    .slick-dots li button:before {
      content: ''; /* Ensure no text content is shown */
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 426px) {
    .col-sm-6{
  width:50% !important;
  }
    
  .card {
    width: 220px !important;
    height: 230px !important;
    border-radius: 25px;
    }

  .card-title{  
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  letter-spacing: 1.0698px;
  text-align: left;
  }

  .card-text{
  margin-top: 10%;
  font-size: 9px;
  font-weight: 500;
  line-height: 10.97px;
  letter-spacing: 0.8221px;
  text-align: left;
  }

  .card-text + h3{
  margin-top: 10%;
  margin-bottom: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  letter-spacing: 0.8221179842948914px;
  text-align: left;
  }

  p{
  margin-bottom:1.3rem;
  font-size: 8px;
  font-weight: 300;
  line-height: 9.75px;
  letter-spacing: 1.0698795318603516px;
  text-align: justify;
  }


  button{
    padding: 4px 10px;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-align: center;
    justify-self:center;
  }

}

  
  @media (max-width: 376px) {

  .col-sm-6{
  width:50% !important;
  }
    
  .card {
    width: 213px !important;
    height: 222px !important;
    border-radius: 25px;
    }

  .card-title{  
  font-size: 15px;
  font-weight: 700;
  line-height: 18.29px;
  letter-spacing: 1.0698px;
  text-align: left;
  }

  .card-text{
  margin-top: 10%;
  font-size: 9px;
  font-weight: 500;
  line-height: 10.97px;
  letter-spacing: 0.8221px;
  text-align: left;
  }

  .card-text + h3{
  margin-top: 10%;
  margin-bottom: 0.5rem;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  letter-spacing: 0.8221179842948914px;
  text-align: left;
  }

  p{
  margin-bottom:1.3rem;
  font-size: 8px;
  font-weight: 300;
  line-height: 9.75px;
  letter-spacing: 1.0698795318603516px;
  text-align: justify;
  }


  button{
    padding: 4px 10px;
    font-size: 8px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-align: center;
    justify-self:center;
  }

  .slick-dots {
        display:block !important;
    }

    .slick-dots li {
      margin: 0 5px;
      display: inline-block;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: #D9D9D9;
      transition: background-color 0.3s ease;
    }

    .slick-dots li.slick-active {
      background-color: black;
      border-radius:15px;
      width:15.5px;
      height:7px;
    }

    .slick-dots li button:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
    }

}
`;

const Petservices = ({ header1, header2 }) => {
  const dispatch = useDispatch();
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [getservicesData, setservicesData] = useState("");
  const servicesData = useSelector((state) => state.services.data?.rows || []);
  console.log("Service Data: ", servicesData);

  const servicesWithPrices = servicesData.map(service => {
    const priceString = service.SERVICESWEEKWISEPRICEs?.small?.[0]?.price;
    const spotPriceString = service.SERVICESWEEKWISEPRICEs?.small?.[0]?.spot_price;

    const price = priceString ? parseFloat(priceString) : undefined;
    const spot_price = spotPriceString ? parseFloat(spotPriceString) : undefined;

    return {
      ...service,
      price: price !== undefined ? price.toLocaleString('en-IN') : undefined,
      spot_price: spot_price !== undefined ? spot_price.toLocaleString('en-IN') : undefined
    };
  }).filter(service => service.price !== undefined && service.spot_price !== undefined);
    //filters out the services which does not have the price and spot price
  
  console.log("Services with Prices: ", servicesWithPrices);

  useEffect(() => {
    dispatch(getServices()).catch((error) => console.error("Error:", error));
  }, [dispatch]);

  const handleServiceSelection = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "10px",
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.5 19.6667C39.5 30.2442 30.7775 38.8333 20 38.8333C9.22249 38.8333 0.5 30.2442 0.5 19.6667C0.5 9.0891 9.22249 0.5 20 0.5C30.7775 0.5 39.5 9.0891 39.5 19.6667Z" stroke="black"/>
            <path d="M24.6022 19.5024L16.9393 12.2011C16.8114 12.0792 16.6416 12.0112 16.4649 12.0112C16.2883 12.0112 16.1184 12.0792 15.9905 12.2011L15.9823 12.2094C15.9201 12.2684 15.8706 12.3396 15.8367 12.4184C15.8029 12.4972 15.7854 12.5821 15.7854 12.6679C15.7854 12.7537 15.8029 12.8386 15.8367 12.9174C15.8706 12.9963 15.9201 13.0674 15.9823 13.1265L23.1983 20.0015L15.9823 26.8737C15.9201 26.9328 15.8706 27.0039 15.8367 27.0828C15.8029 27.1616 15.7854 27.2465 15.7854 27.3323C15.7854 27.4181 15.8029 27.503 15.8367 27.5818C15.8706 27.6606 15.9201 27.7318 15.9823 27.7909L15.9905 27.7991C16.1184 27.921 16.2883 27.989 16.4649 27.989C16.6416 27.989 16.8114 27.921 16.9393 27.7991L24.6022 20.4979C24.6696 20.4337 24.7232 20.3564 24.7599 20.2709C24.7965 20.1853 24.8154 20.0932 24.8154 20.0001C24.8154 19.907 24.7965 19.8149 24.7599 19.7294C24.7232 19.6438 24.6696 19.5666 24.6022 19.5024Z" fill="black"/>
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.5 19.6667C39.5 30.2442 30.7775 38.8333 20 38.8333C9.22249 38.8333 0.5 30.2442 0.5 19.6667C0.5 9.0891 9.22249 0.5 20 0.5C30.7775 0.5 39.5 9.0891 39.5 19.6667Z" fill="white" stroke="black"/>
            <path d="M13.3978 19.5024L21.0607 12.2011C21.1886 12.0792 21.3584 12.0112 21.5351 12.0112C21.7117 12.0112 21.8816 12.0792 22.0095 12.2011L22.0177 12.2094C22.0799 12.2684 22.1294 12.3396 22.1633 12.4184C22.1971 12.4972 22.2146 12.5821 22.2146 12.6679C22.2146 12.7537 22.1971 12.8386 22.1633 12.9174C22.1294 12.9963 22.0799 13.0674 22.0177 13.1265L14.8017 20.0015L22.0177 26.8737C22.0799 26.9328 22.1294 27.0039 22.1633 27.0828C22.1971 27.1616 22.2146 27.2465 22.2146 27.3323C22.2146 27.4181 22.1971 27.503 22.1633 27.5818C22.1294 27.6606 22.0799 27.7318 22.0177 27.7909L22.0095 27.7991C21.8816 27.921 21.7117 27.989 21.5351 27.989C21.3584 27.989 21.1886 27.921 21.0607 27.7991L13.3978 20.4979C13.3304 20.4337 13.2768 20.3564 13.2401 20.2709C13.2035 20.1853 13.1846 20.0932 13.1846 20.0001C13.1846 19.907 13.2035 19.8149 13.2401 19.7294C13.2768 19.6438 13.3304 19.5666 13.3978 19.5024Z" fill="black"/>
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "75px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 405,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "52px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "45px",
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <StyledContainer>
      <div className="container customer_review py-5 mb-2">
        <div>
          <Header header1={header1} header2={header2} />
       </div>
          {servicesData && !!servicesData.length && (
        <Slider {...sliderSettings}>
          {servicesWithPrices.map(({ thumbnail, name, id, banner_header, price, spot_price, banner_alt }, idx) => (
            <div className="card slider_card my-3" key={idx}>
              <div className="card-body">
                <div className="row">
                  <div className="card-title text-left mb-3">{name}</div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-lg-6 col-md-12">
                    <p className="card-text d-md-none d-xl-block">{banner_header}</p>
                    <h3 className="mb-1">Rs. {price}/-</h3>
                    <p className="mb-3 mt-0" style={{ textDecoration: "line-through" }}>Rs. {spot_price}</p>
                    <div onClick={() => handleServiceSelection(id)}>
                      <ServiceModal />
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-6 d-lg-block d-md-none imagecontainer">
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                      className="img"
                      width={100}
                      alt={banner_alt}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
            )}
      </div>
      <div style={{ display: "none" }}>
        {selectedServiceId && <ServiceModal serviceId={selectedServiceId} />}
      </div>
    </StyledContainer>
  );
};

export default Petservices;
