import React, { useEffect, useState } from "react";
import HomeBanner from "./HomeBanner";
import ServicesSlider from "../services/Services";
import Appointment from "./Appointment";
import PremiumPetServices from "./PremiumPet";
import ProductListingSlider from "./ProductListing";
import HappyCustomers from "./HappyCustomers";
import ServicesByLocation from "../../components/Common/ServicesByLocation";
import WhoWeAre from "./WhoWeAre";
import WhyUs from "./WhyUs";
import PromoCodes from "./Promos";
import OfferBand from "./offerband";
import CollectionPackage from "./Collection";
import Package from "./package";
import OfferSlider from "./offerslider";
import Credebility from "./Credebility";
import TestimonialSlider from "./TestimonialSlider";
import ReviewSlider from "./review";
import NewSlider from "./newReview";
import NewReviewSlider from "./new_design_review";
import NewsletterSignupModal from "./newsletter";
import PetServices from "./pet_services";
import ServicePackage from "../services/servicepackage";
import { Helmet } from "react-helmet";

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hasClosedModal = localStorage.getItem("newsletterClosed") === "true";
    if (!hasClosedModal) {
      setIsModalOpen(true);
    }
  }, []);

  const closeModal = () => {
    localStorage.setItem("newsletterClosed", "true");
    setIsModalOpen(false);
  };

  return (
    <div>
      <Helmet>
        <title>Welcome to PetSide</title>
        <meta name="description" content="At Petside India, where over 45000+ pets have undergone remarkable transformations, our commitment to excellence is not only a promise but a guarantee." />
        <link rel="canonical" href="" />
        <meta name="keywords" content="" />
      </Helmet> 
      <HomeBanner />
      <PetServices header1="Trending" header2="CARE SERVICES" />
      <OfferBand />
      {/* <CollectionPackage header1="Exclusive Customised" header2="COLLECTIONS FOR YOU" /> */}
      {/* <Package header1="Hot Deals On" header2="PACKAGES" /> */}
      <ServicePackage header1="Dog Training" header2="Packages" slug="dog-training" />
      {/* <OfferSlider header1="Discount" header2="OFFERS" /> */}
      <ProductListingSlider header1= "Pet" header2="PRODUCTS" />
      {/* <PetServices header1="Best Selling" header2="SERVICES" /> */}
      <NewReviewSlider header1="Happy" header2="Customers" />
      <NewsletterSignupModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Home;
