import Modal from "react-bootstrap/Modal";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { fetchDogMaster, fetchServicesById } from "../../features/web/web-actions";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ModalStyled = styled.div`
  .card_top {
    display: flex;
    align-items: center;
  }
  .package_info {
    padding: 0px 10px;
    font-weight: 500;
  }
  .selling_price {
    font-weight: 700;
    padding-left: 10px;
  }
  .add_actions {
    background: #114c5f;
    color: #fff !important;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 1em;
  }
  .card-actions {
    border: 2px solid #114c5f;
    border-radius: 5px;
    margin-right: 10px;
    width: 5.1em;
    text-align: center;
  }
  .btn_actions {
    color: #114c5f;
    font-weight: 700;
    background: transparent;
    padding: 5px 10px;
  }
  .selected-card {
    border: 2px solid #55c8db !important;
  }
  .card_col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .custom_btn {
      width: 100%;
      padding: 10px 0;
      font-size: 16px;
    }

    .d-inline-flex {
      flex-direction: column;
      gap: 10px;
    }
  }

  @media (max-width: 480px) {
    .custom_btn {
      font-size: 14px;
    }

    .form-select,
    .form-control {
      font-size: 14px;
    }

    .card_col {
      width: 350px;
      display: flex;
      justify-content: left;
    }
  }
`;

function ServiceDetailModal({ selectedCard, showModal, handleCloseModal }) {
  const [dogMaster, setDogMaster] = useState([]);
  const [formData, setFormData] = useState({
    dog_breed: "",
    dog_size: "",
    pet_sex: "",
    pet_age: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getDogMasterData = useSelector((state) => state.dogMasterData.data || []);

  useEffect(() => {
    dispatch(fetchDogMaster());
  }, [dispatch]);

  useEffect(() => {
    if (getDogMasterData && getDogMasterData.data) {
      setDogMaster(getDogMasterData.data.dog_breed);
    }
  }, [getDogMasterData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pet_age") {
      const age = parseInt(value);
      if (age <= 0 || age > 20) {
        message.error("Please enter a valid age of your pet");
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleViewCart = () => {
    if (selectedCard && formData.dog_breed && formData.dog_size) {
      const data = {
        selectedCard: selectedCard,
        formData: {
          dog_breed: formData.dog_breed,
          dog_size: formData.dog_size,
          pet_age: formData.pet_age,
          pet_sex: formData.pet_sex,
        },
      };
      localStorage.setItem("ServiceID", selectedCard.id);
      navigate(`/services/${selectedCard.id}`, {
        state: { data: data },
      });
      handleCloseModal(); 
    } else {
      message.error("Please select a Service Package, Pet breed, and Pet Size.");
    }
  };

  return (
    <>
      <ModalStyled>
        <Modal centered show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
          <Modal.Title>{selectedCard.week_wise} {selectedCard.title} - {selectedCard.day_wise} {selectedCard.service} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12 login_col">
                  <div className="login">
                    <form>
                      <div className="row">
                        <div className="col-md-6">
                          <label>Select Dog Breed</label>
                          <select
                            className="form-select"
                            name="dog_breed"
                            value={formData.dog_breed}
                            onChange={handleChange}
                            aria-required
                          >
                            <option value="" selected disabled>
                              Select
                            </option>
                            {dogMaster?.map((e, index) => (
                              <option value={e.name} key={index}>{e.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6">
                          <label>Select Dog Size</label>
                          <select
                            className="form-select"
                            name="dog_size"
                            value={formData.dog_size}
                            onChange={handleChange}
                            aria-required
                          >
                            <option value="" disabled selected>
                              Select
                            </option>
                            <option value="Small">Small</option>
                            <option value="Medium">Medium</option>
                            <option value="Large">Large</option>
                          </select>
                        </div>
                        <div className="col-md-6 pt-3">
                          <label>Pet Age</label>
                          <input
                            type="text"
                            name="pet_age"
                            className="form-control"
                            placeholder="Enter your pet age"
                            value={formData.pet_age}
                            onChange={handleChange}
                            aria-required
                          />
                        </div>
                      </div>
                      <button
                        className="custom-btn mt-4"
                        type="button"
                        onClick={handleViewCart}
                        disabled={
                          !formData.dog_breed ||
                          !formData.dog_size ||
                          formData.pet_age === ""
                        }
                      >
                        Continue
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </ModalStyled>
    </>
  );
}

export default ServiceDetailModal;
