import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchGalleryPhoto } from "../../features/web/web-actions";

const StyledContainer = styled.div`
  .sp_row .card-body {
    background: #e2fbff;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .sp_row .order_now {
    background: #000;
    color: #fff;
    text-align: center;
    padding: 9px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .sp_row h6 {
    color: #114c5f;
    font-weight: 700;
  }
  .sp_row h5,
  li {
    font-weight: 700;
  }
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
  }
  .sp_btm_head h5 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
  }
  .sp_btm_head p {
    text-align: justify;
    padding: 1em 4em;
  }

  .sp_row {
    margin-top: 4vh;
  }

  .card {
    border: none;
    // border-radius: -20px;
  }

  .GalleryPhoto-2,
  .GalleryPhoto-7,
  .GalleryPhotoImg-2,
  .GalleryPhotoImg-7 {
    height: 64vh;
  }

  .GalleryPhoto-1,
  .GalleryPhoto-4,
  .GalleryPhoto-5,
  .GalleryPhotoImg-1,
  .GalleryPhotoImg-4,
  .GalleryPhotoImg-5 {
    height: 36vh;
  }

  .GalleryPhoto-3,
  .GalleryPhotoImg-3 {
    height: 35vh;
  }

  .GalleryPhoto-6,
  .GalleryPhotoImg-6 {
    height: 52vh;
    margin-top: -11vh;
  }

  .GalleryPhoto-8,
  .GalleryPhotoImg-8 {
    height: 40vh;
    margin-top: 2vh;
  }

  .GalleryPhoto-9,
  .GalleryPhotoImg-9 {
    height: 49vh;
    margin-top: -2vh;
  }

  .GalleryPhoto-4 {
    margin-top: -22vh;
  }

  .GalleryPhoto-7 {
    margin-top: -20vh;
  }

  @media (max-width: 768px) {
    .card-img-top {
      width: 80%;
      height: 80%;
    }

    .sp_row {
      margin-left: 8vw;
    }

    .GalleryPhoto-2,
    .GalleryPhotoImg-2 {
      margin-top: 0vh;
    }

    .GalleryPhoto-3,
    .GalleryPhotoImg-3 {
      height: 35vh;
      margin-top: -18px;
    }

    .GalleryPhoto-4,
    .GalleryPhotoImg-4 {
      margin-top: 10px;
    }

    .GalleryPhoto-5,
    .GalleryPhotoImg-5 {
      margin-top: 10px;
    }
    .GalleryPhoto-6,
    .GalleryPhotoImg-6 {
      margin-top: 5px;
    }
    .GalleryPhoto-7,
    .GalleryPhotoImg-7 {
      margin-top: 0px;
    }
    .GalleryPhoto-8,
    .GalleryPhotoImg-8 {
      margin-top: -12px;
    }
    .GalleryPhoto-9,
    .GalleryPhotoImg-9 {
      margin-top: -10px;
    }

    .sp_row img {
      width: 100%;
    }
  }

  // @media (max-width: 480px) {
  //   .GalleryList {
  //     display: none !important;
  //   }
  // }

  @media (max-width: 425px) {
    .sp_head h2 {
      margin: 1rem !important;
      margin-bottom: 2rem !important;
    }
    .sp_btm_head {
      font-size: 15px;
    }
  }
`;

function ServiceGalleryPhoto() {
  const dispatch = useDispatch();
  const [getGalleryPhotoData, setGalleryPhotoData] = useState([]);
  const GalleryPhotoData = useSelector((state) => state.galleryphoto.data.data);
  // const GalleryPhotoData = useSelector((state) => state);
  // console.log("State: ", GalleryPhotoData);
  // console.log("GalleryPhoto data: ", GalleryPhotoData);

  useEffect(() => {
    dispatch(fetchGalleryPhoto());
    // const dt = dispatch(fetchGalleryPhoto());
    // console.log("fetchGalleryPhoto Data: ", dt);
  }, [dispatch]);

  useEffect(() => {
    if (GalleryPhotoData) {
      console.log("GalleryPhoto data:", GalleryPhotoData); // Log package data
      // console.log("GalleryPhoto data length:", GalleryPhotoData.data); // Log package data
      setGalleryPhotoData(Object.values(GalleryPhotoData.rows));
    }
  }, [GalleryPhotoData]);

  return (
    <StyledContainer>
      <div className="container py-5 GalleryList d-none d-lg-block d-md-block">
        <div className="sp_head">
          <h2>
            {/* <span style={{"font-weight": "700"}}>Our Special</span> <br />  */}
            <span>
              Our Pet Care <br /> Gallery
            </span>
          </h2>
        </div>
        <div className="row row-cols-1 row-cols-md-3 g-4 sp_row">
          {getGalleryPhotoData &&
            getGalleryPhotoData.map(({ name, imagePath, tag }, idx) => (
              <div key={idx} className={`card GalleryPhoto-${idx + 1}`}>
                <img
                  // src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                  src={`${process.env.REACT_APP_API_BASE_URL}/images/${imagePath}`}
                  alt="Blog"
                  height={"400px"}
                  width={"1300px"}
                  className={`card-img-top GalleryPhotoImg-${idx + 1}`}
                  style={{ objectFit: "cover" }}
                />
              </div>
            ))}
          {/* {console.log("title Data: ", getGalleryPhotoData)} */}
        </div>
      </div>
    </StyledContainer>
  );
}

export default ServiceGalleryPhoto;
