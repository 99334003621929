
import { message } from 'antd';
import React, { useEffect, useState } from 'react';

function Location({ onLocationCapture }) {
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const initMap = () => {
      const defaultLocation = { lat: 19.0760, lng: 72.8777 };
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: defaultLocation,
        zoom: 15,
      });

      // Add marker for the default location
      const marker = new window.google.maps.Marker({
        position: defaultLocation,
        map: map,
      });

      // Notify that the map is loaded and set the location
      setMapLoaded(true);
      // Check if onLocationCapture is a function before calling it
      if (typeof onLocationCapture === 'function') {
        onLocationCapture(defaultLocation);
      } else {
        console.error("onLocationCapture is not a function");
      }
    };

    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`;
      script.defer = true;
      script.async = true;
      window.initMap = initMap;
      document.body.appendChild(script);
    } else {
      initMap();
    }

    return () => {
      window.initMap = null;
    };
  }, [onLocationCapture]);

  return (
    <div id="map" style={{ height: '55vh', width: '100%' }} className='mb-5'></div>
  );
}

export default Location;


