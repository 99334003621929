import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  createProductOrder,
  fetchProductById,
  getCart,
  ApplyCouponProduct,
  RemoveCoupons,
  fetchAppliedCoupons,
  fetchDeliveryAddress,
} from "../../features/web/web-actions";
import productImg from "../../assets/images/drools-sm.PNG";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Login from "../Auth/Login";
import { XCircle } from "react-bootstrap-icons";
import { message } from "antd";

const StyleContainer = styled.div`
.coupon_div {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    & button {
      background: #000;
    }
  }

  .coupon {
    margin: 5px;
    padding: 5px 10px;
    border: 1px dashed #114c5f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    border-radius: 10px;
}

  .multi-step-form {
    // display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .multi-step-form .form-control {
    border: 1px solid #000 !important;
    margin-bottom: 10px;
  }

  .multi-step-form h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .multi-step-form label {
    display: block;
    margin-bottom: 10px;
  }

  .multi-step-form input[type="text"],
  .multi-step-form input[type="tel"],
  .multi-step-form input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
    width: 300px;
    box-sizing: border-box;
  }

  .multi-step-form button {
    background-color: #000;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
  }

  .multi-step-form button:hover {
    background-color: #000000a3;
  }

  .multi-step-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .multi-step-form .previous-button {
    margin-right: 10px;
  }

  .rf-step .step-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8em;
  }

  .rf-step .step-indicator .step {
    // display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    text-align: center;
    line-height: 30px;
    // margin-right: 10px;
  }
  .rf-step .step-indicator .step span {
    position: relative;
    left: 2em;
    // text-wrap: nowrap;
  }
  .step-desc {
    display: flex;
    text-align: left;
    padding-left: 10px;
  }
  .rf-step .step-indicator .step .desc {
  }

  .rf-step .step-indicator .step.active {
    background-color: #55c8db;
  }

  .rf-step .step-indicator .step.completed {
    background-color: #55c8db;
  }
  .checkbox {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  .your_cart_top {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
  }
  .coupon_div {
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
    & button {
      background: #000;
    }
  }
  .sub_div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .tot_div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    & p,
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
  p,
  span {
    font-weight: 600;
  }
  .del_col {
    display: flex;
    align-items: center;
    gap: 2em;
    border: 1px solid #000;
    max-width: 400px;
    padding-left: 2em;
    border-radius: 5px;
  }
  .cart_header h1 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 14%;
    display: block;
    text-wrap: nowrap;
  }
  .pay_with {
    display: flex;
    align-item: center;
    gap: 2em;
    & div {
      display: flex;
      gap: 10px;
    }
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .successMsg {
      border: 4px solid #114c5f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 4em;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
      color: #114c5f;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  .ck_login {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 2em 0em;
    margin-top: 5em;
  }
  @media (max-width: 768px) {
    .msg_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & .successMsg {
        border: 4px solid #114c5f;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        padding: 1em 1em;
        gap: 0em;
      }
      & h2 {
        font-size: 18px;
        font-weight: 700;
        color: #114c5f;
      }
      & a {
        background: #114c5f;
        color: #fff !important;
        padding: 5px 15px;
        border-radius: 5px;
        margin: 0px;
      }
    }
    .rf-step .step-indicator {
      display: flex;
      align-items: baseline;
      flex-direction: column;
      gap: 1em;
    }
  }
`;

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [getProductDetails, setProductDetails] = useState([]);
  const [totalSellingPrice, setTotalSellingPrice] = useState(0);
  const [getDelAdd, setDelAdd] = useState([]);
  const totalAmt = getProductDetails.selling_price;
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let userContact = null;
  let isUserLoggedIn = null;
  let userData = null;
  let user_id = null;

  if (userInfo) {
    userData = JSON.parse(userInfo);
    isUserLoggedIn = userData.contact_no;
    userContact = userData.contact_no;
    user_id = userData.id;
  }


  const getDeliveryAddress = useSelector(
    (state) => state.deliveryAddress.data || []
  );

  useEffect(() => {
    dispatch(fetchDeliveryAddress(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getDeliveryAddress && getDeliveryAddress.data) {
      setDelAdd(getDeliveryAddress.data.rows);
    }
  }, [getDeliveryAddress]);


  const [addressData, setAddressData] = useState({
    shipping_address1: '',
    shipping_country: '',
    shipping_zone: '',
    shipping_city: '',
    shipping_code: ''
  });

  useEffect(() => {
    if (getDelAdd) {
      const selectedAddress = getDelAdd.find(address => address.selected_address === 1);
      if (selectedAddress) {
        setAddressData({
          shipping_address1: `${selectedAddress.house_no} - ${selectedAddress.building_name} - ${selectedAddress.address} - ${selectedAddress.landmark}`,
          shipping_country: selectedAddress.country,
          shipping_zone: selectedAddress.state,
          shipping_city: selectedAddress.city,
          shipping_code: selectedAddress.zip
        });
      }
    }
    console.log(getDelAdd, "getting the delivery address");
  }, [getDelAdd]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value
    });
  };

  const formData = {
    store_url: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile_no: "",
    shipping_firstname: "",
    shipping_last_name: "",
    shipping_company: "petside",
    shipping_address1: "",
    shipping_address2: "",
    shipping_city: "",
    shipping_country: "India",
    shipping_zone: "",
    shipping_method: "UPI",
    shipping_code: "",
    comment: "55",
    sub_total: "",
    total: "",
    order_product: [ ],
    order_total: [
      {
        code: "product buying",
        title: "",
        total: "",
      },
    ],
  };

  const [step, setStep] = useState(1);
  const getCartData = useSelector((state) => state.cart.data?.rows || []);
  // const getProductById = useSelector((state) => state.productById.data || []);
  // useEffect(() => {
  //   if (getProductById && getProductById.data) {
  //     setProductDetails(getProductById.data);
  //   }
  // }, [getProductById]);
  // useEffect(() => {
  //   dispatch(fetchProductById(id));
  // }, [dispatch, id]);

  const validationSchema = Yup.object().shape({
    shipping_address1: Yup.string().required('Address is required').max(350, 'Invalid Address!').min(2, 'Invalid Address!'),
    shipping_zone: Yup.string().required('State is required').max(30, 'Invalid State!').min(2, 'Invalid State!'),
    shipping_city: Yup.string().required('City is required'),
    shipping_code: Yup.string().required('Zipcode is required').max(6, 'Invalid Zipcode!').min(6, 'Invalid Zipcode!'),
    shipping_address2: Yup.string().required('Billing Address is required').max(150, 'Invalid Billing Address!').min(2, 'Invalid Billing Address!'),
    shipping_firstname: Yup.string().required('Billing Name is required').max(40, 'Invalid Billing Name!').min(2, 'Invalid Billing Name!'),
  });

  const headings = ["Address", "Shipping", "Payment"];

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };
  useEffect(() => {
    dispatch(getCart()).catch((error) => console.error("Error:", error));
  }, [dispatch]);

  useEffect(() => {
    if (getCartData.length > 0) {
      const productDetailsWithQuantity = [];
      const fetchProductDetails = async () => {
        for (const item of getCartData) {
          const {
            product_id: productId,
            id: cartId,
            quantity: cartQuantity,
          } = item;
          const product = await dispatch(fetchProductById(productId));
          productDetailsWithQuantity.push({
            ...product.payload.data[0],
            cartId,
            cartQuantity,
          });
        }
        setProductDetails(productDetailsWithQuantity);
      };

      fetchProductDetails();
    }
  }, [dispatch, getCartData]);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Initialize formData within handleSubmit
    const formData = {
      store_url: "",
      firstname: userData.first_name || values.firstname,
      lastname: userData.last_name || values.lastname,
      email: userData.email || values.email,
      mobile_no: userData.contact_no || values.mobile_no,
      shipping_firstname: values.shipping_firstname,
      shipping_last_name: values.shipping_last_name,
      shipping_company: "petside",
      shipping_address1: values.shipping_address1,
      shipping_address2: values.shipping_address2,
      shhipping_country: values.shipping_country,
      shipping_zone: values.shipping_zone,
      shipping_code: values.shipping_code,
      shipping_city: values.shipping_city,
      billing_country: values.shipping_country,
      billing_state: values.shipping_zone,
      shipping_method: "UPI",
      billing_zip: values.shipping_code,
      comment: "55",
      sub_total: totalSellingPrice,
      total: finalPrice,
      order_product: [],
      order_total: [
        {
          code: "product buying",
          title: "",
          total: finalPrice,
        },
      ],
    };
  
    // Populate order_product array
    getProductDetails.forEach(data => {
      formData.order_product.push({
        product_id: data.id,
        name: data.name,
        model: "",  
        quantity: data.cartQuantity,
        price: data.price,
        selling_price: data.selling_price,
        total: data.selling_price, 
        tax: "",    
        reward: ""  
      });
    });

    values.billing_tel = userData.contact_no;
    values.billing_email = userData.email;
    values.billing_city = values.shipping_city;
    values.billing_state = values.shipping_zone;
    values.billing_zip = values.shipping_code;
    values.billing_country = values.shipping_country;
    createProductOrder({ ...formData }).then((resp) => {
      if (!resp.success) {
        if (resp.responseCode === 422) {
          const newErrors = {};
          console.log(formData, "if res =422")
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        const payLink = resp.data.payLink;
        console.log(formData, "if else")
        if (payLink) {
          // window.location.href = payLink;
          console.log(formData, "if Paylink")
          window.open(payLink, "_self");
        }
        setIsSubmitted(true);
        resetForm();
      }
    });
    setSubmitting(false);
  };

  const [appliedCouponId, setAppliedCouponId] = useState(null);
  const couponData = useSelector((state) => state.coupons.data || {});
  const applied = couponData.data || {};
  const couponApplied = applied.coupon_id || 0;
  console.log("couponApplied --- ", couponApplied);
  console.log("Type of applied:", typeof applied);

  useEffect(() => {
    dispatch(fetchAppliedCoupons());
  }, [dispatch]);

  // Accumulate the total of all applied coupons
  const accumulatedTotal = Object.keys(applied).reduce((acc, key) => {
    const coupon = applied;
    if (coupon) {
      // Check the type of the coupon
      console.log("coupon.coupon_type", coupon.coupon_type);
      if (coupon.coupon_type === "percentage") {
        console.log(totalSellingPrice,"Entered");
        // Calculate the percentage value based on product total

        const productTotal = parseFloat(totalSellingPrice);
        console.log("productTotal --- ", totalSellingPrice);
        console.log("Coupon Total ---- ", coupon.total);
        const discount = (
          productTotal *
          (parseFloat(coupon.total) / 100)
        ).toFixed(2); // Calculate the discount
        console.log("discount", discount);
        return discount;
      } else {
        // If it's a fixed amount coupon, add its total
        console.log("amount discount", coupon.total )
        return parseFloat(coupon.total);
      }
    } else {
      return acc;
    }
  }, 0); // Start with a base value of 0

  console.log(accumulatedTotal, "accumulated total somethifn")
  
  let totalPrice = 0;
  // Calculate total selling price
  useEffect(() => {
    getProductDetails.forEach(({ selling_price, cartQuantity }) => {
      totalPrice += selling_price * cartQuantity;
    });
    console.log(finalPrice,"total price from accumulated price")
    setTotalSellingPrice(totalPrice);
  }, [getProductDetails]);

  let finalPrice = totalSellingPrice - accumulatedTotal;
  console.log(finalPrice,"Finalprice after coupon")
  
  useEffect(() => {
    dispatch(fetchAppliedCoupons());
  }, [dispatch]);

  const applyCoupon = ({ coupon_code, product_id }) => {
    // Check if a coupon is already applied
    if (appliedCouponId === null) {
      // Dispatch Redux action to apply the coupon
      dispatch(ApplyCouponProduct({ coupon_code, product_id }))
        .unwrap()
        .then((response) => {
          // Provide user feedback on success
          message.success(response.message || 'Coupon has been applied successfully');
          // Set the applied coupon id in the state
          setAppliedCouponId(response.data.id);
          // Reload the page after a delay of 300 milliseconds
          setTimeout(() => {
            window.location.reload(true);
          }, 300);
        })
        .catch((error) => {
          // Provide user feedback on error
          message.error(error.message);
        });
    } else {
      // Inform the user that another coupon is already applied
      message.warning("Another coupon is already applied.");
    }
  };

  const removeCoupon = (id) => {
    // Dispatch Redux action to remove the coupon
    dispatch(RemoveCoupons(id));
    // Reset the appliedCouponId if the removed coupon is the one applied
    if (appliedCouponId === id) {
      setAppliedCouponId(null);
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 300); // 10 milliseconds
  };

  return (
    <StyleContainer>
      {!isSubmitted && (
        <div className="container">
          <div className="cart_header">
            <h1>Checkout</h1>
          </div>
          <div className="row ">
            <div className="register-form-row">
              <div className="col-md-6 rf-step pt-3">
                <div className="step-indicator">
                  {[...Array(3)].map((_, index) => (
                    <div
                      key={index}
                      className={`step ${step > index ? "completed" : ""} ${step === index + 1 ? "active" : ""
                        }`}
                    >
                      <div className="step-desc">
                        {index + 1 + "."}{" "}
                        <span>
                          {headings[index]} <br />
                        </span>{" "}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-12 rf-form">
                <div className="row">
                  <div className="col-md-7 border-end border-dark">
                    {isUserLoggedIn ? (
                      <div className="multi-step-form">
                        <Formik
                          initialValues={{
                            shipping_address1: addressData.shipping_address1 || '',
                            shipping_country: addressData.shipping_country || '',
                            shipping_zone: addressData.shipping_zone || '',
                            shipping_city: addressData.shipping_city || '',
                            shipping_code: addressData.shipping_code || '',
                            firstname: userData.first_name || '',
                            shipping_last_name: userData.last_name || '',
                            email: userData.email || '',
                            mobile_no: userData.contact_no || '',
                          }}
                          enableReinitialize={true}
                          onSubmit={handleSubmit}
                          validationSchema={validationSchema}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <Form onSubmit={handleSubmit}>
                              {step === 1 && (
                                <>
                                  <div>
                                    <p className="pb-3">Shipping Information</p>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>First Name</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        name="firstname"
                                        onChange={handleChange}
                                        value={values.firstname}
                                      />
                                      <ErrorMessage name="firstname" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>Last Name</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        name="shipping_last_name"
                                        onChange={handleChange}
                                        value={values.shipping_last_name}
                                      />
                                      <ErrorMessage name="shipping_last_name" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>Email</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                      />
                                      <ErrorMessage name="email" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>Contact No</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="tel"
                                        name="mobile_no"
                                        onChange={handleChange}
                                        value={values.mobile_no}
                                      />
                                      <ErrorMessage name="mobile_no" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-12">
                                      <label>Address</label>
                                      <textarea
                                        className="form-control"
                                        cols="30"
                                        maxLength={150}
                                        rows="3"
                                        name="shipping_address1"
                                        value={values.shipping_address1}
                                        onChange={handleChange}
                                      ></textarea>
                                      <ErrorMessage name="shipping_address1" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>Country</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        maxLength={30}
                                        name="shipping_country"
                                        value={values.shipping_country}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name="shipping_country" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>State</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        maxLength={30}
                                        name="shipping_zone"
                                        value={values.shipping_zone}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name="shipping_zone" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>City</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        maxLength={30}
                                        name="shipping_city"
                                        value={values.shipping_city}
                                        onChange={handleChange}
                                      />
                                      <ErrorMessage name="shipping_city" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6">
                                      <label>Zipcode</label>
                                      <input
                                        className="form-control col-md-6"
                                        type="text"
                                        maxLength={6}
                                        name="shipping_code"
                                        value={values.shipping_code}
                                        onChange={handleChange}
                                        onKeyDown={(e) => {
                                          if (
                                            !/[0-9]/.test(e.key) &&
                                            e.key !== 'Backspace' &&
                                            e.key !== 'Delete' &&
                                            e.key !== 'ArrowLeft' &&
                                            e.key !== 'ArrowRight'
                                          ) {
                                            e.preventDefault();
                                          }
                                        }}
                                      />
                                      <ErrorMessage name="shipping_code" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-6 check-col">
                                      <div className="checkbox">
                                        <input type="checkbox" />
                                        <p>Save contact information</p>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )}

                              {step === 2 && (
                                <>
                                  <div className="del_div">
                                    <div className="del_col mb-5">
                                      <div>
                                        <input type="radio" name="delivery_method" />
                                      </div>
                                      <div>
                                        <p>Regular Delivery</p>
                                        <span>4-7 Business Days</span>
                                      </div>
                                    </div>
                                    <div className="del_col">
                                      <div>
                                        <input type="radio" name="delivery_method" />
                                      </div>
                                      <div>
                                        <p>Fast Delivery</p>
                                        <span>1-2 Business Days</span>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </>
                              )}

                              {step === 3 && (
                                <>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <label>Billing Name</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        maxLength={40}
                                        name="shipping_firstname"
                                        onChange={handleChange}
                                        value={values.shipping_firstname}
                                      />
                                      <ErrorMessage name="shipping_firstname" component="div" className="error text-danger" />
                                    </div>
                                    <div className="col-md-12">
                                      <label>Billing Address</label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        maxLength={150}
                                        name="shipping_address2"
                                        onChange={handleChange}
                                        value={values.shipping_address2}
                                      />
                                      <ErrorMessage name="shipping_address2" component="div" className="error text-danger" />
                                    </div>
                                  </div>
                                </>
                              )}
                              {step > 1 && (
                                <button type="button" onClick={previousStep} className="previous-button">
                                  Previous
                                </button>
                              )}
                              {step < 3 && (
                                <button type="button" onClick={nextStep}>
                                  Next
                                </button>
                              )}
                              {step === 3 && (
                                <button type="submit">Pay Rs. {finalPrice} /-</button>
                              )}
                            </Form>
                          )}
                        </Formik>

                      </div>
                    ) : (
                      <>
                        <div className="ck_login">
                          <h6>Account</h6>
                          <p>To book the service, please login or sign up</p>
                          <Login />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-md-5">
                    <p className="pb-5">Your Cart</p>
                    {getProductDetails.map(
                      (
                        {
                          name,
                          PETPRODUCTIMAGE,
                          selling_price,
                          cartId,
                          cartQuantity,
                          quantity,
                        },
                        idx
                      ) => (
                        <div className="your_cart_top">
                          <div>
                            <img
                              width={150}
                              src={`${process.env.REACT_APP_API_BASE_URL}/images/${PETPRODUCTIMAGE.image}`}
                              alt=""
                            />
                          </div>
                          <div>
                            <p>{name}</p>
                            <p>Quantity - {cartQuantity}</p>
                            <span>Rs. {selling_price * cartQuantity}/-</span>
                          </div>
                        </div>
                      )
                    )}
                    <div className="coupon_div">
                          <div className="coupon-box">
                            <h6 className="fw-bold">Coupons</h6>
                          </div>
                          {Object.keys(applied).length > 0 ? (
                            <div key={applied.id} className="coupon">
                              <span>{applied.code}</span>
                              <XCircle
                                onClick={() => removeCoupon(applied.id)}
                                className="text-danger close_btn"
                              />
                            </div>
                          ) : (
                            <div className="coupon-box">
                              <div class="mb-4 row align-items-center">
                                <div class="col-md-9 col-lg-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="coupon_id"
                                    id="coupon_name"
                                    placeholder="Apply Coupon"
                                  />
                                  <p className="error"></p>
                                </div>
                                <div class="col-md-3 col-lg-3">
                                  <button
                                    className="btn custom_btn"
                                    onClick={() =>
                                      applyCoupon({
                                        coupon_code:
                                          document.getElementById("coupon_name").value,
                                          product_id: getProductDetails[0].id,
                                      })
                                    }
                                    style={{ background: "#114C5F" }}
                                    id="CouponSub"
                                  >
                                    Apply
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                    </div>
                    <div>
                      <div className="sub_div">
                        <p>Subtotal</p>
                        <span>Rs. {totalSellingPrice}/-</span>
                      </div>
                      <div className="sub_div">
                        <p>Shipping</p>
                        <span>Rs. 00</span>
                      </div>
                      <div className="sub_div">
                        <p>Coupons</p>
                        {parseFloat(accumulatedTotal) > 0 ? (
                          <span>Rs. - {accumulatedTotal}</span>
                        ) : (
                          <span>Rs. 00</span> // This is shown if there are no coupons
                        )}
                      </div>
                      <hr />
                      <div className="tot_div">
                        <p>Total</p>
                        <span>Rs. {finalPrice}/-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </StyleContainer>
  );
};

export default CheckoutForm;
