import styled from "styled-components";
import DogImg from "../../assets/images/lab-dog.png";
import DogPaw from "../../assets/images/ion_paw-outline.png";

const StyledContainer = styled.div`
  padding: 0px 0px;
  .row {
    display: flex;
    align-items: center;
  }
  .contains {
    padding-top: 15%;
  }
  .col1 {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .col2 {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 2em;
  }
  .col2 a {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .col2 h3 {
    font-size: 25px;
    font-weight: 700;
  }
  .col2 img {
    position: relative;
    left: 17em;
  }
  .food_product {
    padding: 2em 0em;
  }
  .food_product a {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .food_product span {
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 36%;
    display: block;
    text-wrap: nowrap;
  }
  .food_product h5,
  h6 {
    font-size: 30px;
    font-weight: 700;
  }
  .vmbtn {
    display: flex;
    justify-content: end;
  }
  @media (max-width: 768px) {
    .explore-more-img {
      padding-top: 10px;
    }

    .dog-img-footer {
      display: none;
    }
  }
  @media (max-width: 540px) {
    .explore-more-img .dog-img {
      height: auto;
      width: 100%;
    }
    .col2 img {
      position: relative;
      left: 0em;
    }
    .col2 h3 {
      font-size: 22px;
      font-weight: 700;
    }
    .food_product .row {
      margin-left: 10px;
      margin-right: 10px;
    }
    .ps-5 {
      padding-left: 1rem !important;
    }
    .food_product h5,
    h6 {
      font-size: 22px;
    }
    .food_product span {
      font-size: 22px;
    }
    .vmbtn {
      justify-content: flex-start;
    }
  }
  @media (max-width: 320px) {
    .col2 h3 {
      font-size: 18px;
    }
  }
`;

const PremiumPetServices = ({ isCompact }) => {
  return (
    <StyledContainer>
      {!isCompact && (
        <div className="container">
          <div className="row explore-more-img">
            <div className="col-md-6">
              <div className="col1">
                <img className="dog-img img-fluid" src={DogImg} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="contains">
                <div className="col2">
                  <h3>
                    Dive into premium pet products and expert advice for your
                    furry friend.
                  </h3>
                  <p>
                    From grooming essentials to tailored diets, find everything
                    you need to keep your furry friend healthy, happy, and
                    thriving. Explore our range today and treat your pet to the
                    best!
                  </p>
                  <a href="/products">Explore More &gt; </a>
                  <img className="dog-img-footer" src={DogPaw} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="container food_product">
    <div className="row">
        <div className="col-md-6 ps-5">
        <h5>Pet</h5>
        <span>Food Products</span>
        <h6 className="pt-4">Upto 25% OFF on all Products</h6>
        </div>
        <div className="col-md-6 vmbtn">
        <a href="/products">View More &gt; </a>
        </div>
    </div>
    </div> */}
    </StyledContainer>
  );
};

export default PremiumPetServices;
