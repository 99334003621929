import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServiceBanner1 from "../../assets/images/insurance-banner.png";
import leftWing from "../../assets/images/insurance/Happy_Parents_Icon_Left.png.png";
import rightWing from "../../assets/images/insurance/Happy_Parents_Icon_Right.png.png";
import step1 from "../../assets/images/insurance/step1.png";
import step2 from "../../assets/images/insurance/step2.png";
import step3 from "../../assets/images/insurance/step3.png";
import claim1 from "../../assets/images/insurance/claim1.png";
import claim2 from "../../assets/images/insurance/claim2.png";
import claim3 from "../../assets/images/insurance/claim3.png";
import dogLove from "../../assets/images/services/banner.png";
import HeadLine from "../../assets/images/line-45.png";
import NewReviewSlider from "../homepage/new_design_review";
import InsuranceSlider from "./InsuranceSlider";
import InsuranceCardFaq from "./InsuranceCardFaq";

const StyledContainer = styled.div`
.happy_pet{
    background: #12D2FF;
    display: flex;
    align-items: center;
    justify-content: center;
    & h4{
    font-weight:700;
    font-size: 22px;
    color: #fff;
    }
    & P{
    text-align:center;
    color: #fff;
    font-weight:700;
    }
}
    .insurance_step_book{
    padding: 0px 0px 40px 0px;
    & .head{
    text-align:center;
        padding-top: 2em;
    }
        & h1{
        text-align: center;
font-size: 32px;
font-weight: 700;
        }
        & h2{
            text-align: left;
    font-size: 26px;
    font-weight: 800;
        }
    }
.protection{

& .head h2{
text-align: center;
font-size: 32px;
font-weight: 700;
}
}
.claims{
padding: 40px 0px 40px 0px;
& .head h2{
text-align: center;
font-size: 32px;
font-weight: 700;
}
& .card-body{
text-align:left;
}
& h4{
  color: #12D2FF;
  font-weight: 700;
}
}
.quality_care{
& .card{
background: #91E8FF;
border:0px;
border-radius:30px;
padding: 0.5em 0.5em;
 & h5{
  font-weight: 700;
 }
}
}
.carousel img{
  width : 100%;
  height: 602px;
}

.allpet{
  margin-bottom:15px;
}
.equalHeight {
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
}

.equalHeight .card-body {
  flex: 1;
}

.services-slider {
  margin-bottom:2rem;
  width: 100%;
}

.slider_card {
  margin: 0 10px;
}
  .image-container {
    position: relative;
    text-align: center;
    color: white;
    padding: 5px;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .ServiceBanner1 .text-light{
    font-size: 15px;
    width: 35vw;
    color: black;
  }

  .services-slider .care_service {
    padding: 6em 0em 1em 0em;
  }

  .services-slider .care_service h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
  }

  .Dog_Footer {
    position: relative;
    width: 0vw;
    right: 23vh;
    top: -10vh;
    // overflow: hidden;
}
    
  }

  .services-slider .care_service h3 {
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
  }

  .services-slider .slider_content {
    display: flex;
    justify-content: center;
  }

  .services-slider .slider_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    padding: 2em 0em;
  }

  .services-slider .slider_card {
    border: 2px solid #55c8db;
    border-radius: 10px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .services-slider .slider_card .card-img-top {
    width: 100px;
  }

  .services-slider .slider_card .card-body {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  .services-slider .slider_card .card-title {
    font-weight: 700;
  }

  .services-slider .slider_card .card-link {
    background: #000;
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 5px;
    text-decoration: none;
  }

  // Service Banner:
  
    .ServiceBanner {
      width: 100% !important;
      height: 602px !important;
      margin: auto;
  }

  .ServiceBanner1 {
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-size: 100% 100%;
    // background: linear-gradient(180deg, #82E6FE 0%, #FFFFFF 100%);
    overflow: hidden;
    height: 602px;
    width: 100%;
  }

  .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 8.25rem;
    left: 2%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
}

  .ServiceBanner1 .carousel-caption h1 {
    font-family: Montserrat Alternates;
    font-size: 3.5vw;
    font-weight: 900;
    line-height: 4.5vw;
    letter-spacing: 2px;
    text-align: left;
  }

  .service_section {
    margin-bottom: 6vw;
  }

  .ServiceBanner1 p {
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 600;
    line-height: 2.5vw;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 5vw;
  }

  .ServiceBanner1 a,
  .ServiceBanner2 a,{
    width: 20vw;
    font-family: Montserrat;
    font-size: 1vw;
    font-weight: 600;
    line-height: 1vw;
    letter-spacing: 2px;
    text-align: center;
    padding: 1vw 3vw !important;
    border-radius: 0.6vw;
    background: #000000;
    color: #fff !important;
    z-index: 1;
  }

  .ServiceBanner1 .carousel-caption .ser_ban {
    width: 65%;
    padding: 2vw 0em 0em 0em;
    margin: 0;
  }

  .ServiceBanner2 .carousel-caption {
    position: absolute;
    right: -50%;
    bottom: 4.25rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
  }

  .ServiceBanner2 .carousel-caption .banner_heading {
    width: 18%;
    margin: auto;
  }

  .ServiceBanner2 h2 {
    font-family: Montserrat Alternates;
    font-size: 3vw;
    font-weight: 900;
    line-height: 4vw;
    letter-spacing: 2px;
    text-align: left;
    margin-bottom: 2vw
  }

  .ServiceBanner2 .banner_heading {
    width: 30%
  }


  @media (max-width: 1200px) {
    .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 11.25rem;
    left: 2%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
}

  }


  @media (max-width: 991px) {

    .ServiceBanner {
      height: 400px !important;
      width: 100%;
    }

    .ServiceBanner1 .carousel-caption {
      position: absolute;
      bottom: 4.25rem;
      left: 2%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

   .ServiceBanner2 h2 {
      font-size: 3vw;
      margin-bottom: 4vw;
    }

    .ServiceBanner a {
      padding: 1.2vw 3vw !important;
      width: 20vw;
    }


  }

  @media (max-width: 768px) {
    .service_section {
      margin-bottom: 6vw;
      margin-top: -10vw;
      width: 100%;
    }

    .ServiceBanner {
      height: 280px !important;
    }
      
    .ServiceBanner1 .carousel-caption {
      position: absolute;
      bottom: 3rem;
      left: 2%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner1 .carousel-caption .ser_ban p {
      margin-bottom: 3vw;
    }

    .ServiceBanner a {
      font-size: 1.2vw;
      padding: 1.2vw 2vw !important;
      border-radius: 5px;
    }

    .ServiceBanner2 .carousel-caption {
      position: absolute;
      right: -50%;
      bottom: 0.25rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner2 .carousel-caption h2 {
      font-size: 3vw;
      margin-bottom: 2vw;
    }

        .protection h2,
        .claims h2,
        .insurance_step_book h1{
        font-size:22px !important;
        }

  }

@media (max-width: 580px) {
  .ServiceBanner1 .carousel-caption {
    position: absolute;
    bottom: 6rem;
    left: 2%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000000;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .ServiceBanner {
    height: 217px !important;
  }
    .ServiceBanner1 .carousel-caption {
      position: absolute;
      bottom: 2rem;
      left: 2%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

    .ServiceBanner a {
        font-size: 1.3vw;
        padding: 1.5vw 3vw !important;
        border-radius: 3px;
        width: 25vw;
    }

    .ServiceBanner1 a {
        font-size: 1.1vw !important;
        padding: 1.5vw 2vw !important;
        border-radius: 3px;
        width: 28vw !important;
    }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 10px;
  }

  .carousel-indicators [data-bs-target] {
    width: 10px;
    height: 3px;
  }
}

@media (max-width: 380px) {

.ServiceBanner1 .carousel-caption {
    bottom: 2.5rem;
}
.ServiceBanner1 .carousel-caption .ser_ban {
    width: 36%;
    padding: 2vw 0em 0em 0em;
    margin: 0;
}

.ServiceBanner1 h1 {
    margin-bottom: 4vw !important;
  }
.ServiceBanner1 p {
    font-size: 2.4vw !important;
  }
.ServiceBanner a {
    font-size: 9px !important;
    padding: 2vw !important;
    border-radius: 3px;
    width: 100% !important;
    margin-left: 0px;
  }


    @media (max-width: 414px), 
        (max-height: 896px) {
        .carousel-item {
          transition: transform 0.7s ease !important; 
        }

}

`;
function Insurance() {
  return (
    <StyledContainer>
      <div className="container-fluid p-0 m-0">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="d-flex flex-column justify-content-center ServiceBanner ServiceBanner1">
              <img src={ServiceBanner1} alt="Home Banner 1" />
              <Carousel.Caption>
                <h1 className="container">Pet Health Insurance</h1>
                <div className="row ser_ban">
                  <p>Provide Complete Coverage to Safeguard Your Pet</p>
                  <a
                    href="https://www.symboinsurance.com/customer/petinsurance/petside?country=&source=inStore&partnerId=Store1&city=MUMBAI&outletName=STORE1&state=MAHARASHTRA"
                    target="_blank"
                  >
                    Enquire
                  </a>
                </div>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        </Carousel>
        <div className="happy_pet">
          <img src={leftWing} alt="Happy Pet" />
          <div>
            <p>Loved By</p>
            <h4>1000+ Happy Pet Pawrents</h4>
          </div>
          <img src={rightWing} alt="Happy Pet" />
        </div>
        <div className="insurance_step_book">
          <div className="head">
            <h1>India’s #1 Online Pet Insurance</h1>
            <img src={HeadLine} alt="" />
            <p>Get Pet Insurance in 3 Simple Steps -</p>
          </div>
          <div className="container text-center my-3">
            <div className="row justify-content-center">
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="mb-2">
                  <h2>1</h2>
                </div>
                <div className="card border-0" style={{ width: "260px" }}>
                  <img
                    src={step1}
                    className="card-img-top"
                    alt="Enroll your pet"
                  />
                  <div className="card-body">
                    <p className="card-text">
                      Click on the button below.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="mb-2">
                  <h2>2</h2>
                </div>
                <div className="card border-0" style={{ width: "260px" }}>
                  <img
                    src={step2}
                    className="card-img-top"
                    alt="Check your pet plans"
                  />
                  <div className="card-body">
                    <p className="card-text">
                      Fill in your details.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="mb-2">
                  <h2>3</h2>
                </div>
                <div className="card border-0" style={{ width: "260px" }}>
                  <img
                    src={step3}
                    className="card-img-top"
                    alt="Select add-ons for pet"
                  />
                  <div className="card-body">
                    <p className="card-text">
                      Register your pet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                className="btn"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "10px 70px",
                }}
                onClick={() => window.open('https://www.symboinsurance.com/customer/petinsurance/petside?country=&source=inStore&partnerId=Store1&city=MUMBAI&outletName=STORE1&state=MAHARASHTRA', '_blank')}
              >
                Register Now!
              </button>
            </div>
          </div>
        </div>
        <div className="protection">
          <div className="head">
            <h2>
            Coverage options
            </h2>
            <div className='text-center'>
        <img src={HeadLine} alt="" />
      </div>
          </div>
          <div>
            <InsuranceSlider/>
          </div>
        </div>
        <div className="dog_love">
          <img className="img-fluid" src={dogLove} width="100%" alt="" />
        </div>
        <div className="claims">
          <div className="head">
            <h2>
            Why Choose Petside India Pet Insurance?
            </h2>
            <div className='text-center'>
        <img src={HeadLine} alt="" />
      </div>
          </div>
          <div className="container text-center my-3">
            <div className="row justify-content-center">
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="card border-0" style={{ width: "360px" }}>
                  <img
                    src={claim1}
                    className="card-img-top"
                    alt="Enroll your pet"
                  />
                  <div className="card-body">
                    <h4>Comprehensive Coverage</h4>
                    <p className="card-text">
                    Our plans cover a wide range of medical conditions and scenarios, ensuring your pet receives the best possible care.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="card border-0" style={{ width: "360px" }}>
                  <img
                    src={claim2}
                    className="card-img-top"
                    alt="Check your pet plans"
                  />
                  <div className="card-body">
                    <h4>Financial Protection</h4>
                    <p className="card-text">
                    Avoid unexpected expenses and protect your finances with our thorough insurance coverage.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 d-flex flex-column align-items-center">
                <div className="card border-0" style={{ width: "360px" }}>
                  <img
                    src={claim3}
                    className="card-img-top"
                    alt="Select add-ons for pet"
                  />
                  <div className="card-body">
                    <h4>Supportive Care</h4>
                    <p className="card-text">
                    Our team is dedicated to providing compassionate support during difficult times, guiding you through the insurance process with ease.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <button
                className="btn"
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "10px 70px",
                }}
                onClick={() => window.open('https://www.symboinsurance.com/customer/petinsurance/petside?country=&source=inStore&partnerId=Store1&city=MUMBAI&outletName=STORE1&state=MAHARASHTRA', '_blank')}
              >
                Enquire
              </button>
            </div>
          </div>
        </div>
        <div className="quality_care container">
          <div className="row row-cols-1 row-cols-md-2 gy-5 justify-content-center">
            <div className="col d-flex justify-content-center">
              <div className="card" style={{ width: "620px" }}>
                <div className="card-body">
                  <h5 className="card-title">Coverage for Chronic Conditions</h5>
                  <p className="card-text">
                  Chronic conditions such as diabetes, arthritis, and allergies require ongoing care and treatment, which can become expensive over time. Petside India’s pet insurance plans include coverage for chronic conditions, ensuring that your pet has access to necessary treatments, medications, and regular check-ups. By covering these long-term medical needs, our insurance helps you manage the costs associated with maintaining your pet’s health and quality of life.
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="card" style={{ width: "620px" }}>
                <div className="card-body">
                  <h5 className="card-title">Emergency Care and Hospitalization</h5>
                  <p className="card-text">
                  Accidents and sudden illnesses can happen at any time, and emergency care is often both critical and costly. Our pet insurance provides comprehensive coverage for emergency medical care and hospitalization, ensuring that your pet receives prompt and effective treatment. This coverage includes emergency vet visits, diagnostic tests, intensive care, and hospital stays, giving you peace of mind during urgent situations.
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="card" style={{ width: "620px" }}>
                <div className="card-body">
                  <h5 className="card-title">
                  Behavioral Therapy Coverage
                  </h5>
                  <p className="card-text">
                  Behavioral issues such as anxiety, aggression, and obsessive behaviors can significantly impact your pet’s quality of life. Petside India’s pet insurance plans include coverage for behavioral therapy, helping your pet receive professional treatment from qualified behaviorists. This ensures that behavioral issues are addressed effectively, promoting a happier and healthier life for your pet.
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex justify-content-center">
              <div className="card" style={{ width: "620px" }}>
                <div className="card-body">
                  <h5 className="card-title">Alternative and Holistic Treatments</h5>
                  <p className="card-text">
                  Petside India recognizes the benefits of alternative and holistic treatments for various health issues. Our pet insurance includes coverage for therapies such as acupuncture, chiropractic care, hydrotherapy, and herbal medicine. These treatments can complement traditional veterinary care, providing a holistic approach to your pet’s health and well-being.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewReviewSlider header1="Happy" header2="Customers" />
        <InsuranceCardFaq/>
      </div>
    </StyledContainer>
  );
}

export default Insurance;
