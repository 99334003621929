import React, { useEffect, useState } from "react";
import styled from "styled-components";
import formbg from "../../assets/images/provider-bg.jpg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { message } from "antd";
import { registerProvider } from "../../features/web/web-actions";
import MapInit from "../map/MapInit";
import Map from "../map/MapInit";
import FileInput from "../../components/Common/FileInput";
import googleMap from "../../assets/images/googlemap.gif"
import * as Yup from 'yup';

const StyleContainer = styled.div`
  .customform{
    width:600px;
  }
  .field-row {
    display: flex;
    justify-content: space-between;
  }

  .field-group {
    flex: 1;
    margin-right: 10px; /* Adjust as needed for spacing between fields */
    width:100%;
  }
  .form-attachment{
    border: 2px solid black;
  }
  .multi-step-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .multi-step-form .form-control {
    border: 0.5px solid #000 !important;
    margin-bottom: 20px;
  }

  .multi-step-form h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .multi-step-form label {
    display: block;
    margin-bottom: 10px;
  }

  .multi-step-form input[type="text"],
  .multi-step-form input[type="tel"],
  .multi-step-form input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }

  .multi-step-form button {
    background-color: #114c5f;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  .multi-step-form button:hover {
    background-color: #114c5fb0;
  }

  .multi-step-form button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .multi-step-form .previous-button {
    margin-right: 10px;
  }

  .rf-step .step-indicator {
    // font-size: 1.5rem;
    // margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 4.6em;
  }

  .rf-step .step-indicator .step {
    // display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    text-align: center;
    line-height: 30px;
    // margin-right: 10px;
  }
  .rf-step .step-indicator .step span {
    position: relative;
    left: 2em;
    text-wrap: nowrap;
  }
  .step-desc {
    display: flex;
    text-align: left;
    padding-left: 10px;
  }
  .rf-step .step-indicator .step .desc {
  }

  .rf-step .step-indicator .step.active {
    background-color: #55c8db;
  }

  .rf-step .step-indicator .step.completed {
    background-color: #55c8db;
  }
  .register-form-row {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(${formbg});
    background-size: 100%;
    padding: 4em 0em;
    // margin-top: -4em;
  }
  .rf-step {
    border: 1px solid #000;
    height: 100%;
    display: flex;
    align-items: start;
    padding: 5em 0em 0em 2em;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-right: none;
    background: #fff;
  }
  .rf-form {
    border: 1px solid #000;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: #fff;
    padding: 2em 0em;
  }
  .multi-step-form p {
    padding-bottom: 1em;
    padding-top: 2em;
  }
  .field_error {
    margin-top:3px;
    color: red;
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${formbg});
    padding: 4em 0em;
    background-size: 100%;
    & .successMsg {
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 2em;
      background: #fff;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  @media (max-width: 768px) {
  .rf-step {
    height: auto;
    padding: 1em 0em 3em 5em;
    display: none; /* Hides the rf-step section */
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-right: 1px solid;
  }

  .register-form-row {
    display: block;
  }

  .rf-form {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 1em;
  }

  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(${formbg});
    padding: 4em 0em;
    background-size: 100%;

    & .successMsg {
      padding: 1em 1em;
      gap: 0em;
    }

    & h2 {
      font-size: 18px;
      font-weight: 700;
    }

    & h3 {
      font-size: 16px;
    }

    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 3px 15px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  .field-row {
    flex-direction: column; /* Stack fields vertically on smaller screens */
  }
  
  .field-group {
    flex: none; /* Reset flex to allow fields to take full width */
    margin-right: 0; /* Remove margin between fields */
  }

  .multi-step-form input[type="text"],
  .multi-step-form input[type="tel"],
  .multi-step-form input[type="email"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 1rem;
    width: 300px;
    box-sizing: border-box;
  }

  .form-control{
    width:300px;
  }
   
  .form-select{
    width:300px;
  }

  .customform{
    width:100%;
  }
`;


const CompanyForm = ({ onStepChange }) => {
  // State variable to track the selected service type
  const [serviceType, setServiceType] = useState('');

  // Function to handle the select change and update the state variable
  const handleServiceTypeChange = (e) => {
    console.log(e,"e")
    const selectedService = e.target.value;
    setServiceType(selectedService); // Update the service type state
  //  handleChange(e); // Propagate the change to the parent component
  };
  const [step, setStep] = useState(1);
  const [getPolygonCoordinates, setPolygonCoordinates] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formData = {
    first_name: "",
    last_name: "",
    email: "",
    contact_no: "",
    password: "",
    role_id: 3,
    provider_service_type: '',
    user_comapany_details: [
      {
        company_name: "",
        company_phone: "",
        email: "",
        select_zone: "",
        address: "",
        pincode: "",
        image_url: [],
      },
    ],
    users_contact_info: [
      {
            name: "",
            phone: "",
            email: "",
            address: "",
            address1: "",
            city: "",
            state: "",
            pincode: "",
            profile_picture: []
      },
    ],
    users_business_information: [
      {
        account_number: "",
        ifsc_code: "",
        bank_name: "",
        user_name_as_per_bank: "",
        identity_type: "",
        identity_number: "",
        image_url: [],
      },
    ],
    users_address_info:[] ,
  };
    // Validation Schema for Step 1
const validationSchemaStep2 = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  contact_no: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
//  provider_service_type: Yup.string().required("Service type is required"),
});

// Define the schema for an individual company detail
const companyDetailSchema = Yup.object().shape({
  company_name: Yup.string().required("Company name is required"),
  company_phone: Yup.string()
    .matches(/^\d+$/, "Phone number must contain only digits")
    .required("Company phone is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
 // select_zone: Yup.string().required("Zone selection is required"),
  address: Yup.string().required("Address is required"),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits")
    .required("Pincode is required"),
 // image_url: Yup.array()
 ////   .of(Yup.string().url("Invalid image URL"))
  //  .required("Image URLs are required")
  //  .min(1, "At least one image is required"),
});

// Define the schema for an array of company details
const validationSchemaStep1 = Yup.object().shape({
  user_comapany_details: Yup.array()
    .of(companyDetailSchema)
    .required("Company details are required")
});



// Corrected validation schema for Step 2
const businessInfoSchema = Yup.object().shape({
  account_number: Yup.string().required("Account number is required"),
  ifsc_code: Yup.string().required("IFSC code is required"),
  bank_name: Yup.string().required("Bank name is required"),
  user_name_as_per_bank: Yup.string().required("User name as per bank is required"),
  identity_type: Yup.string().required("Identity type is required"),
  image_url:Yup.array()
  .of(Yup.string())
  .required("The image URL array is required")
  .min(1, "At least one image is required"),
});

// Define the schema for an array of business information objects
const validationSchemaStep3 = Yup.object().shape({
  users_business_information: Yup.array().of(businessInfoSchema).required("Business information is required"),
});

const expertiseSchema = Yup.object().shape({
  experience: Yup.string().required("Experience is required"),
  handling_aggressive: Yup.string().required("This field is required"),
  size_handling: Yup.string().required("Size handling field is required"),
  dogs_and_cats: Yup.string().required("Comfortable with both dogs and cats is required"),
  profile_description: Yup.string().required("Profile description is required"),
});

const validationSchemaStep4 = Yup.object().shape({
  expertise: Yup.array()
    .of(expertiseSchema)
    .required("At least one expertise object is required")
    .min(1, "At least one expertise object must be present"),
});
  const headings = [
    "Business Information",
    "Contact Person Info",
    "Account Information",
    "Expertise Information",
    "Address Information",
  ];
  useEffect(() => {
    onStepChange(step);
  }, [step, onStepChange]);
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [step]);
  const nextStep = () => {
    setStep(step + 1);
  };
  const previousStep = () => {
    setStep(step - 1);
  };
  const handlePolygonComplete = (coordinates) => {
    setPolygonCoordinates(coordinates);
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.users_address_info = getPolygonCoordinates ? getPolygonCoordinates : null;
    registerProvider({ ...values }).then((resp) => {
      if (!resp.success) {
        console.log("response from if", resp.message)
        message.error(resp.message);
        if (resp.responseCode === 422) {
          const newErrors = {};
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        setIsSubmitted(true);
        resetForm();
        // navigate("../partner/thank-you");
      }
    });
    setSubmitting(false);
  };

  return (
    <StyleContainer>
       {!isSubmitted && (
      <div className="container-fluid">
        <div className="row ">
          <div className="register-form-row">
            <div className="col-md-3 rf-step">
              <div className="step-indicator">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={`step ${step > index ? "completed" : ""} ${
                      step === index + 1 ? "active" : ""
                    }`}
                  >
                    <div className="step-desc">
                      {index + 1 + "."}{" "}
                      <span>
                        {headings[index]} 
                      </span>{" "}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 rf-form">
              <div className="multi-step-form">
              <Formik initialValues={formData}
                 onSubmit={handleSubmit}
                 validationSchema={
                  step === 1
                    ? validationSchemaStep1
                    : step === 2
                    ? validationSchemaStep2
                    : step === 3
                    ? validationSchemaStep3
                    : validationSchemaStep4
                }
                 >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isValid,
                    setFieldValue,
                    dirty,
                    validateForm,
                    setTouched
                  }) => (
                    <Form onSubmit={handleSubmit}
                    className="customform">
                      {step === 1 && (
                        <>
                          <h2>Business Information</h2>
                          <div className="field-group">
                          <label>Company Name</label>
                          <Field
                          className="form-control"
                            type="text"
                            name="user_comapany_details[0].company_name"
                            onChange={handleChange}
                            placeholder="Enter the Company Name"
                          />
                           {step === 1 && errors && errors.user_comapany_details && errors.user_comapany_details[0].company_name  && (
                              <div className="field_error">
                                {errors.user_comapany_details[0].company_name}
                              </div>
                            )} 
                           </div>
                          {/* {errors.user_comapany_details[0].company_name && touched.user_comapany_details[0].company_name && <div className="field_error" >{errors.user_comapany_details[0].company_name}</div>} */}
                          <div className="field-row">
                            <div className="field-group">
                            <label>Company Phone</label>
                              <Field
                              className="form-control"
                                type="tel"
                                name="user_comapany_details[0].company_phone"
                                onChange={handleChange}
                                placeholder="Enter the Company Phone"
                              />
                              {step === 1 && errors && errors.user_comapany_details && errors.user_comapany_details[0].company_phone  && (
                              <div className="field_error">
                                {errors.user_comapany_details[0].company_phone}
                              </div>
                            )} 
                            </div>
                            <div className="field-group">
                            <label>Company Email</label>
                              <Field
                              className="form-control"
                                type="email"
                                name="user_comapany_details[0].email"
                                onChange={handleChange}
                                placeholder="Enter the Company Email"
                              />
                               {step === 1 && errors && errors.user_comapany_details && errors.user_comapany_details[0].email  && (
                              <div className="field_error">
                                {errors.user_comapany_details[0].email}
                              </div>
                            )} 
                            </div>
                          </div>
                            {/* <label>Select zone</label>
                            <input
                            className="form-control"
                              type="text"
                              name="user_comapany_details[0].select_zone"
                              onChange={handleChange}
                            /> */}
                           <label>Address</label>
                           <textarea className="form-control" id="" cols="30" rows="3"   name="user_comapany_details[0].address"
                            onChange={handleChange}></textarea>
                             {step === 1 && errors && errors.user_comapany_details && errors.user_comapany_details[0].address  && (
                              <div className="field_error">
                                {errors.user_comapany_details[0].address}
                              </div>
                            )} 
                            <label>Pincode </label>
                            <Field
                             className="form-control"
                              type="text"
                              name="user_comapany_details[0].pincode"
                              onChange={handleChange}
                              placeholder="Enter the Pincode"
                            />
                             {step === 1 && errors && errors.user_comapany_details && errors.user_comapany_details[0].pincode  && (
                              <div className="field_error">
                                {errors.user_comapany_details[0].pincode}
                              </div>
                            )} 
                          <div>
                            {/* <p>
                              Image format - jpg png jpeg <br /> gif Image Size
                              - maximum <br /> size 2 MB Image Ratio - 1:1
                            </p> */}
                             <FileInput
                              name={`user_comapany_details[0].image_url`}
                              data={{
                                maincategory: "product",
                                subcategory: "images",
                              }}
                            />
                          </div>
                          <br />
                        </>
                      )}

                      {step === 2 && (
                        <>
                        <h2>Contact Person Information</h2>
                        <label>First Name</label>
                          <Field
                           className="form-control"
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            placeholder="Enter the First Name"
                          />
                        {step === 2 && errors && errors && errors.first_name  && (
                        <div className="field_error">
                        {errors.first_name}
                          </div>
                            )} 
                          <label>Last Name</label>
                          <Field
                           className="form-control"
                            type="text"
                            name="last_name"
                            onChange={handleChange}
                            placeholder="Enter the Last Name"
                          />
                          {step === 2 && errors && errors && errors.last_name  && (
                              <div className="field_error">
                                {errors.last_name}
                              </div>
                            )} 
                          <label>Phone</label>
                          <Field
                           className="form-control"
                            type="text"
                            name="contact_no"
                            onChange={handleChange}
                            placeholder="Enter the Phone"
                          />
                          {step === 2 && errors && errors && errors.contact_no  && (
                              <div className="field_error">
                                {errors.contact_no}
                              </div>
                            )} 
                          <label>Email</label>
                          <Field
                           className="form-control"
                            type="email"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter the Email"
                          />
                           {step === 2 && errors && errors && errors.email  && (
                              <div className="field_error">
                                {errors.email}
                              </div>
                            )} 
                          <label>Password</label>
                          <Field
                           className="form-control"
                            type="password"
                            name="password"
                            onChange={handleChange}
                            placeholder="Enter the Password"
                          />
                            {step === 2 && errors && errors && errors.password  && (
                              <div className="field_error">
                                {errors.password}
                              </div>
                            )} 
                          <label>Address</label>
                          <textarea className="form-control" id="" cols="30" rows="3"   name="users_contact_info[0].address"  placeholder="Enter the Address"
                            onChange={handleChange}></textarea>
                          <div>
                          <label>Pincode </label>
                            <Field
                             className="form-control"
                              type="text"
                              name="users_contact_info[0].pincode"
                              onChange={handleChange}
                              placeholder="Enter the Pincode"
                            />
                            <label>City</label>
                            <Field
                             className="form-control"
                              type="text"
                              name="users_contact_info[0].city" 
                              onChange={handleChange}
                              placeholder="Enter the City"
                            />
                            <label>State</label>
                            <Field
                             className="form-control"
                              type="text"
                              name="users_contact_info[0].state" 
                              onChange={handleChange}
                              placeholder="Enter the State"
                            />
                            {/* <p>
                              Image format - jpg png jpeg <br /> gif Image Size
                              - maximum <br /> size 2 MB Image Ratio - 1:1
                            </p> */}
                            <label htmlFor="">Profile Picture</label>
                             <FileInput
                              name={`users_contact_info[0].profile_picture`}
                              data={{
                                maincategory: "product",
                                subcategory: "images",
                              }}
                            />
                          </div>
                          <br />
                        </>
                      )}

                      {step === 3 && (
                          <>
                          <h2>Account Information</h2>
                          
                          <label>User Name As Per Bank</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="users_business_information[0].user_name_as_per_bank"
                            onChange={handleChange}
                            placeholder="Name of the Account holder"
                          />
                           {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].user_name_as_per_bank  && (
                            <div className="field_error">
                              {errors.users_business_information[0].user_name_as_per_bank}
                            </div>
                          )} 
                          <div className="field-group">
                          <label>Account No</label>
                          <Field
                            className="form-control"
                            type="text"
                            name="users_business_information[0].account_number"
                            onChange={handleChange}
                            placeholder="Enter the Account Number"
                          />
                           {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].account_number  && (
                            <div className="field_error">
                              {errors.users_business_information[0].account_number}
                            </div>
                           )}  
                          </div>
                          
                          <div className="field-row">
                            <div className="field-group">
                            <label>IFSC Code</label>
                            <Field
                              className="form-control"
                              type="text"
                              name="users_business_information[0].ifsc_code"
                              onChange={handleChange}
                              placeholder="Enter the IFSC Code"
                            />
                              {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].ifsc_code  && (
                            <div className="field_error">
                              {errors.users_business_information[0].ifsc_code}
                            </div>
                               )} 
                            </div>
                            <div className="field-group">
                            <label>Bank name</label>
                            <Field
                              className="form-control"
                              type="text"
                              name="users_business_information[0].bank_name"
                              onChange={handleChange}
                              placeholder="Enter the Bank Name"
                            />
                               {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].bank_name  && (
                            <div className="field_error">
                              {errors.users_business_information[0].bank_name}
                            </div>
                          )} 
                            </div>
                          </div>
                          
                          <div className="field-row">
                            <div className="field-group">
                              <label>Identity Type</label>
                            <div className="pb-3">
                              <select
                                className="form-control"
                                name="users_business_information[0].identity_type"
                                onChange={handleChange}
                              >
                                <option value="" disabled selected>Select the identity type</option>
                                <option value="Aadaar Card">Aadaar Card</option>
                                <option value="Voter ID">Voter ID</option>
                              </select>
                            </div>
                            {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].identity_number  && (
                            <div className="field_error">
                              {errors.users_business_information[0].identity_number}
                            </div>
                          )} 
                            </div>
                              <div className="field-group">
                              <label>Identity Number</label>
                                <Field
                                  className="form-control"
                                  type="number"
                                  name="users_business_information[0].identity_number"
                                  onChange={handleChange}
                                  placeholder="Enter the Identity Number"
                                />
                              </div>
                              
                          </div>
                          <div>
                            <label>Identity Image</label>
                            <FileInput
                              name={`users_business_information[0].image_url`}
                              data={{
                                maincategory: "product",
                                subcategory: "images",
                              }}
                            />
                          </div>
                          {step === 3 && errors && errors.users_business_information && errors.users_business_information[0].image_url  && (
                            <div className="field_error">
                              {errors.users_business_information[0].image_url}
                            </div>
                          )} 
                        </>
                      )}

                      {step === 4 && (
                          <>
                          <h2>Expertise Information</h2>
                          <label>Select Service Type</label>
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="provider_service_type"
                              onChange={(e) => {
                                handleServiceTypeChange(e);
                                handleChange(e);
                              }} // Update the state variable
                            >
                              <option value="" disabled selected>Select the service type</option>
                              <option value="Pet Boarding">
                                Pet Boarding
                              </option>
                              <option value="Dog Walking">Dog Walking</option>
                              <option value="Pet Sitting">Pet Sitting</option>
                              <option value="Pet Grooming">
                                Pet Grooming
                              </option>
                              <option value="Dog Training">
                                Dog Training
                              </option>
                              
                              <option value="Day Care">Day Care</option>
                              <option value="Pet Photography">
                                Pet Photography
                              </option>
                              <option value="Drop In visit">
                             Drop In visit
                             </option>
                             <option value="Therapy Sessions">
                             Therapy Sessions
                             </option>
                            </select>
                          </div>
                          
                          <p>
                            How many years of experience do you have <br /> in
                            pet care?
                          </p>
                          <Field
                            className="form-control"
                            type="text"
                            name="expertise[0].experience"
                            onChange={handleChange}
                            placeholder="Enter you experience"
                          />
                          {step === 4 && errors && errors.expertise && errors.expertise[0].experience && (
                            <div className="field_error">
                              {errors.expertise[0].experience}
                            </div>
                            )}
                          <p>Are you comfortable handling aggressive dogs?</p>
                          {step === 4 && errors && errors.expertise && errors.expertise[0] && errors.expertise[0].handling_aggressive && (
                            <div className="field_error">
                           {errors.expertise[0].handling_aggressive}
                             </div>
                              )}
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="expertise[0].handling_aggressive"
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>Select your preference</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p>
                            What sizes of dogs can you handle? (S, M, L, XL)
                          </p>
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="expertise[0].size_handling"
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>Select your preference</option>
                              <option value="S">Small</option>
                              <option value="M">Medium</option>
                              <option value="L">Large</option>
                              <option value="XL">Extra Large</option>
                            </select>
                          </div>
                          {step === 4 && errors && errors.expertise && errors.expertise[0] && errors.expertise[0].size_handling && (
                            <div className="field_error">
                           {errors.expertise[0].size_handling}
                             </div>
                              )}
                          <p>
                            Are you comfortable caring for both dogs and cats?
                          </p>
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="expertise[0].dogs_and_cats"
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>Select your preference</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {step === 4 && errors && errors.expertise && errors.expertise[0] && errors.expertise[0].dogs_and_cats && (
                            <div className="field_error">
                           {errors.expertise[0].dogs_and_cats}
                             </div>
                              )}
                          <p>
                            Can you provide a brief description of your
                            services <br /> and team members?
                          </p>
                          <Field
                            className="form-control"
                            type="textarea"
                            name="expertise[0].profile_description"
                            onChange={handleChange}
                            placeholder="Enter your services description"
                          />
                            {step === 4 && errors && errors.expertise && errors.expertise[0] && errors.expertise[0].profile_description && (
                            <div className="field_error">
                           {errors.expertise[0].profile_description}
                             </div>
                              )}
                          {(serviceType === 'Pet Boarding' || serviceType === 'Day Care') && (
                          <div>
                          <p>
                          Can you share pictures ?
                          </p>
                         <FileInput
                          name={`expertise[0].property_pictures`}
                         data={{
                         maincategory: 'product',
                         subcategory: 'images',
                           }}
                           />
                          </div>
                          )}
                          <p>
                            Do you have any certifications or <br />
                            qualifications in pet care?
                          </p>
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="expertise[0].certifications_and_accolades"
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>Do you have certification</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          <p>Upload Certifications/Qualifications </p>
                          <FileInput
                            name={`expertise[0].certifications_and_accolades_image_url`}
                            data={{
                              maincategory: "product",
                              subcategory: "images",
                            }}
                          />
                          <p>
                            Do you offer full-time or part-time pet care
                            services? <br /> or currently working ?
                          </p>
                          <Field
                            className="form-control"
                            type="text"
                            name="expertise[0].pet_care_services"
                            onChange={handleChange}
                            placeholder="Enter services type"
                          />
                          <p>
                            
                            How do you determine your rates for pet care
                            services?
                          </p>
                          <Field
                            className="form-control"
                            type="text"
                            name="expertise[0].rates_and_cost"
                            onChange={handleChange}
                            placeholder="Enter details"
                          />
                          <p>
                            What payment methods do you accept for your
                            services?
                          </p>
                          <div className="pb-3">
                            <select
                              className="form-select"
                              name="expertise[0].payment_options"
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>Select the mode of payment</option>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Debit Card">Debit Card</option>
                              <option value="Bank Transfer">
                                Bank Transfer
                              </option>
                              <option value="Cash on Delivery (COD)">
                                Cash on Delivery (COD)
                              </option>
                              <option value="Google Pay">Google Pay</option>
                              <option value="Cheque">Cheque</option>
                            </select>
                          </div>
                          <p>
                            Do you have any informative videos showcasing
                            <br /> your services and facilities?
                          </p>
                          <FileInput
                            name={`expertise[0].videos`}
                            data={{
                              maincategory: "product",
                              subcategory: "images",
                            }}
                          />
                        </>
                        )}

                      {step === 5 && (
                        <>
                        <h2>Address Information</h2>
                          <div className="pt-3 mb-3">
                          <p className="">Note : You can create the shape by tapping on the map after selecting the "Drawing Mode" icon and choosing the "Polygon" tool.</p>
                          <img
                          src={googleMap}
                          alt="My Image"
                          className="image-medium"
                          style={{ height: '250px', width: '500px' }} 
                          />
                          </div>
                          <Map onPolygonComplete={handlePolygonComplete}/>
                        </>
                      )}

                      <button
                        type="button"
                        onClick={previousStep}
                        className="previous-button"
                        style={{ display: step > 1 ? 'inline-block' : 'none' }}
                      >
                        Previous
                      </button>
                      {step < 5 && (
                        <button type="button" onClick={() => {
                          validateForm().then(errors => {
                            console.log(errors,"errors")
                            if (Object.keys(errors).length === 0) {
                              nextStep();
                            } else {
                              setTouched({
                                // company_name: true,
                                // last_name: true,
                                // contact_no: true,
                                // email: true,
                                // password: true
                              });
                            }
                          });
                        }}>
                          Next
                        </button>
                      )}
                      {step === 5 && <button type="submit">Finish</button>}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      {isSubmitted && ( 
       <div className="container msg_div">

      <div className="successMsg">
        <h2 className="text-center">Thank You for filling out the Provider Form !! </h2>
        <h3>We will reach out to you shortly :)</h3>
        <a href="/provider">Back</a>
      </div>
       </div>
    )}
    </StyleContainer>
  );
};

export default CompanyForm;