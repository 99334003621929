import React from "react";
import AllServices from "./AllServices";
import ServicesSlider from "../services/Services";
import Appointment from "../homepage/Appointment";
import HappyCustomers from "../homepage/HappyCustomers";
import SpecialPackage from "./SpecialPackage";
import BlogSlider from "../Blogs/BlogSlider";
import ServiceGalleryPhoto from "./ServiceGalleryPhoto";
import Faq from "../Faq/Faq";
import GallerySlide from "./gallery";
import CollectionPackage from "../homepage/Collection";
import Package from "../homepage/package";
import ServicePackage from "./servicepackage";

function Services() {
  return (
    <div>
      <AllServices />
      {/* <Appointment/> */}
      {/* <Appointment /> */}
      {/* <CollectionPackage header1="Hot Deals On" header2="SUBSCRIPTION" /> */}
      {/* <Package header1="Discount On" header2="SUBSCRIPTION" /> */}
      <ServicePackage header1="Discounts On" header2="SUBSCRIPTIONS" slug="dog-training" />
      {/* <SpecialPackage /> */}
      {/* <ServiceGalleryPhoto /> */}
      {/* <GallerySlide /> */}
      <BlogSlider header1="Our Blogs" header2="& Stories" />
      {/* <Faq /> */}
      {/* <HappyCustomers isCompact={true}/> */}
    </div>
  );
}

export default Services;
