import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import formbg from "../../assets/images/provider-form-bg-banner.png";
import IndividualForm from "./IndividualForm";
import CompanyForm from "./CompanyForm";
import ProviderForm from "./ProviderForm";
import Whychooseusprovider from "./Whychooseusprovider";
import Lovedogs from "./lovedogs";
import NewReviewSlider from "../homepage/new_design_review";
import ProviderView from "./ProviderView";
import ProviderFaq from "./ProviderFAQ";

// previous style
// const StyleContainer = styled.div`
//   .tab-buttons {
//     display: flex;
//     justify-content: center;
//     // margin:20px 0px;
//     gap: 1em;
//     position: relative;
//     top: 3.5em;
//     left: 1%;
//   }

//   .tab-buttons button {
//     padding: 10px 20px;
//     border: none;
//     border-radius: 5px;
//     background-color: #ccc;
//     cursor: pointer;
//   }

//   .tab-buttons button.active {
//     background-color: #114c5f;
//     color: white;
//   }
//   @media(max-width: 768px){
//     .tab-buttons {
//       // top: 34em;
//       left: 0%;
//     } 
//   }
// `;

const StyledContainer = styled.div`
    .slick-slide {
    padding: 20px 15px;
    }
`;

function JustifiedExample() {
  const [selectedTab, setSelectedTab] = useState("individual");
  const [step, setStep] = useState(1);
  const [currentStep, setCurrentStep] = useState(1);

  // Function to handle step change
  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  return (
    // <StyleContainer>
    //   <div className="tab-buttons">
    //   {currentStep === 1 && 
    //   <>
    //     {/* <button
    //       type="button"
    //       onClick={() => setSelectedTab("company")}
    //       className={selectedTab === "company" ? "active" : ""}
    //       >
    //       Company
    //     </button> */}
    //       </>
    //   }
    //   </div>
    //   {step === 1 && selectedTab === "individual" && <IndividualForm onStepChange={handleStepChange} />}
    //   {/* {step === 1 && selectedTab === "company" && <CompanyForm onStepChange={handleStepChange} />} */}
    // </StyleContainer>
    
    <StyledContainer>
      <ProviderForm />
      <Whychooseusprovider header1="Why" header2="Choose us" />
      <Lovedogs />
      <NewReviewSlider header1="Hear from" header2="Pet Provider like you" />
      <ProviderView />
      <ProviderFaq />
    </StyledContainer>
    
  );
}

export default JustifiedExample;