import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { fetchBlogs } from "../../features/web/web-actions";
import { useDispatch, useSelector } from "react-redux";
import icon from "../../assets/images/icon11.png";
import icon2 from "../../assets/images/icon111.png";
import Header from '../../components/Common/ComponentHeader';

const StyledContainer = styled.div`
  background: #f6fffd;
  padding: 2em 0;
  margin-top:0rem;


  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }

  .pagination .page-item {
    margin: 0 5px;
  }

  .pagination .page-link {
    color: #114c5f;
    background-color: transparent;
    border: 1px solid #114c5f;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .pagination .page-link:hover {
    background-color: #114c5f;
    color: #fff;
  }

  .pagination .active .page-link {
    background-color: #55c8db;
    color: #fff;
    border-color: #55c8db;
  }

  .slick-slide {
    padding: 0 25px;
    display: flex;
    justify-content: center;
  }

  .bloglist {
    margin: 4rem 2rem 0rem 0rem;
  }

  .card {
    height: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    overflow: hidden;
  }

  .card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  .card-img-top {
    width: 100%;
    height: 230px;
    margin: auto;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-body {
    padding: 15px;
  }

  .card-body p {
    font-size: 14px;
    font-weight: normal;
    height: auto;
    overflow: hidden;
  }

  .card-body a {
    background: #114c5f;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    text-decoration: none;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }

  .card-body a:hover {
    background: #0e3f4d;
  }

  .slider-title {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  .Dog_Footer2 {
    text-align: end;
  }

  @media screen and (max-width: 768px) {
    .slick-slide {
      padding: 0 15px;
    }

    .card-img-top {
      width: 100%;
      height: auto;
    }

    .card-body p {
      font-size: 12px;
    }

    .card-body a {
      padding: 8px 16px;
    }

    .slider-title {
      font-size: 24px;
    }
  }

   @media screen and (max-width: 425px) {
    .card{
      height: 450px;
    }
   }
`;

function BlogList({ header1, header2}) {
  const dispatch = useDispatch();
  const blogData = useSelector(
    (state) => state.fetchBlogs.data?.data?.rows || []
  );

  const [currentPage, setCurrentPage] = useState(1);
  const blogsPerPage = 10;

  useEffect(() => {
    dispatch(fetchBlogs()).catch((error) => console.error("Error:", error));
  }, [dispatch]);
  let slidesToShow = 3;
  if (blogData && blogData.length <= 2) {
    slidesToShow = blogData.length;
  }

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogData.slice(indexOfFirstBlog, indexOfLastBlog);

  const totalPages = Math.ceil(blogData.length / blogsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <StyledContainer>
      <div className="container">
        <div>
          <Header header1={header1 } header2={ header2} />
        </div>
        <div className="Dog_Footer">
          <img src={icon} alt="" />
        </div>
        <div className="slider-title"></div>
        <div className="row">
          {currentBlogs.map(
            ({ title, profile_url, slug, id, publish_date, profile_alt }) => (
              <div key={id} className="col-sm-6 col-md-6 col-lg-4 mb-4">
                <div className="card">
                  <img
                    src={`${process.env.REACT_APP_API_BASE_URL}/images/${profile_url}`}
                    alt={profile_alt}
                    className="card-img-top"
                  />
                  <div className="card-body">
                    <h5 className="card-title">{title}</h5>
                    <p className="card-text">{publish_date}</p>
                    <a href={`/blog/${slug}`} className="btn btn-primary">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>

        <nav>
          <ul className="pagination">
            {Array.from({ length: totalPages }, (_, i) => (
              <li
                key={i + 1}
                className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
              >
                <button onClick={() => paginate(i + 1)} className="page-link">
                  {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
        <div className="Dog_Footer2">
          <img src={icon2} alt="A dog sitting on a couch" />
        </div>
      </div>
    </StyledContainer>
  );
}

export default BlogList;
