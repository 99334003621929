import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/background_widebanner.jpeg";
import img2 from "../../assets/images/blog-header-image.jpg";
import img3 from "../../assets/images/blogHadder.png";
import icon from "../../assets/images/icon11.png";

const StyledContainer = styled.div`
  .blog_banner_heading {
    margin: 0px auto;
    width: 50%;
    position: absolute;
    top: 35%;
    left: 5%;
  }

  .blog_banner_heading h1 {
    font-family: Montserrat;
    font-size: 3vw;
    font-weight: 600;
    letter-spacing: 2px;
    text-align: center;
    margin: 20px 0px;
    margin-bottom: 2vw;
    text-align: center;
  }

  .blog_banner_heading p {
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 400;
    line-height: 30.48px;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 5vw;
  }

  .blog_banner_heading a {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 18.29px;
    letter-spacing: 2px;
    text-align: center;
    // width: 367px !important;
    // height: 58px !important;
    border-radius: 5px 0px 0px 0px;
    color: white;
    background: black;
    padding: 1vw 4vw;
    border-radius: 5px;
    text-align: center;
  }

  .bannerbg img {
    // background-image: url(${img3});
    width: 100%;
    height: 598px;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background: #66c0e2;
    font-weight: 800;
    text-align: center;
    align-content: center;
    align-item: center;
    color: black;
  }

  .bgImage {
    text-align: end;
    margin: 0;
    padding: 0;
  }
  // .bgImage img {
  // width: 668.39px;
  // height: 445.65px;
  // margin-left: -5vw;
  // }

  .banner_content h1 {
    color: #ffffff;
    font-size: 40px;
    font-weight: 700;
  }

  .banner_content p {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 320px) {
    .banner .buttons a {
      font-size: 10px;
    }
  }

  @media (max-width: 991px) {
    .blog_banner_heading {
      width: 50%;
      position: absolute;
      top: 10%;
      left: 5%;
    }

    .blog_banner_heading h1 {
      font-size: 26px;
    }

    .blog_banner_heading p {
      font-size: 23px;
      line-height: 25px;
    }

    .blog_banner_heading a {
      font-size: 12px;
      border-radius: 5px;
      padding: 6px 12px;
    }
  }

  @media (max-width: 768px) {
    .bannerbg img {
      height: 280px !important;
    }

    .blog_banner_heading {
      width: 50%;
      position: absolute;
      top: 14%;
      left: 5%;
    }

    .blog_banner_heading h1 {
      font-size: 18px;
      margin: 0px;
      margin-bottom: 2vw;
    }

    .blog_banner_heading p {
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 2vw;
    }

    .blog_banner_heading a {
      font-size: 12px;
      font-weight: 700;
      line-height: 0;
      border-radius: 5px;
      padding: 6px 12px;
    }

    .about_us_info h2 {
      font-size: 28px;
      width: 32%;
      // text-align: center;
    }

    .about_us_info h3 {
      font-size: 20px;
      font-weight: 700;
      color: #0000 0;
      margin-bottom: 10px;
      gap: -14px;
    }

    .Dog_Img {
      position: relative;
      top: 0vh;
      right: 0vw;
    }
  }

  @media (max-width: 480px) {
    .bannerbg img {
      height: 217px !important;
    }

    .blog_banner_heading {
      width: 50%;
      position: absolute;
      top: 16%;
      left: 5%;
    }

    .blog_banner_heading h1 {
      font-size: 13px;
      margin: 0px;
      margin-bottom: 2vw;
    }

    .blog_banner_heading p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      margin-bottom: 2vw;
    }

    .blog_banner_heading a {
      font-size: 9px;
      font-weight: 700;
      line-height: 0;
      border-radius: 5px;
      padding: 5px 10px;
    }
  }

  @media (max-width: 320px) {
    .banner_content h1 {
      font-size: 20px;
    }

    .banner_content p {
      font-size: 12px;
    }
  }
`;

function BlogHeader() {
  return (
    // <StyledContainer>
    //   <div className="container-fluid about_us_banner bannerbg">
    //     <div className="row ">
    //       <div className="col-md-7 p-0">
    //         <div className="blog_banner_heading">
    //           <h1>Our Blogs and Stories</h1>
    //           <div className="">
    //             <p>
    //               Discover the Latest Insights and Tips for Pet Care on Our Blog
    //               – Your Go-To Resource for a Happy, Healthy Pet!
    //             </p>
    //           </div>
    //           <a className="banner-btn text-center" href="services">
    //             Check Services
    //           </a>
    //         </div>
    //       </div>
    //       <div className="col-md-5">
    //         <div className="bgImage">
    //           <img src={img3} alt="Blog Banner Image" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </StyledContainer>

    <StyledContainer>
      <div className="blog-banner-container bannerbg">
        <div className="home-banner">
          <img
            src={img3}
            alt="Blog Banner Image"
            style={{ width: "100%", height: "auto" }}
          />
          <div className="hbc text-center blog_banner_heading">
            <div>
              <h1>Our Latest Blogs and Stories</h1>
              {/* <div className=""> */}
              <p>
                Discover the Latest Insights and Tips for Pet Care on Our Blog -
                Your Go-To Resource for a Happy, Healthy Pet!
              </p>
              {/* </div> */}
              <a className="banner-btn text-center" href="services">
                Check Services
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
}

export default BlogHeader;
