import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonials } from "../../features/web/web-actions";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";
import filledstar from "../../assets/images/filled_star.svg";

const StyledContainer = styled.div`
 body {
    background-color: #4A148C;
  }

  .testimonial_header h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
  }

  .customer_review h4 {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    width: 100%;
  }

  .testimonial_header h3 {
    padding-top: 1em;
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: fit-content;
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fffffa;
    height: 270px; /* Reduced fixed height */
    margin: auto;
  }

  .card .card-body {
    padding: 1rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }

  .card-title img {
    width: 40px;
  }

  p {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    line-height: 1.5rem;
  }

  h4 {
    line-height: 0.2 !important;
  }

  .profile {
    margin-top: 25px;
    margin-left: 11px;
  }

  .profile-pic {
    width: 58px;
  }

  .cust-name {
    font-size: 18px;
  }

  .cust-profession {
    font-size: 14px;
  }

  .bone {
    display: flex;
    align-items: center;
  }

  .bone img {
    width: 20px;
    margin-right: 5px;
  }

  .bone p {
    margin-left: 10px;
    font-size: 0.875rem;
  }


  .slick-slide {
    margin: 10px;
  }

  .read-more,
  .read-less {
    color: blue;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card {
      height: auto;
    }

    .card .card-body {
      padding: 0.5rem;
    }

    .cust-name {
      font-size: 16px;
    }

    .bone img {
      width: 16px;
      margin-right: 3px;
    }

    .bone p {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 576px) {
    .profile {
      margin-left: 0;
    }

    .bone {
      justify-content: flex-start;
    }

    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .slick-list {
      padding: 0 15px;
    }
    
    .testimonial_header h3{
    padding-top: 2em;
    font-size: 28px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: fit-content;
    }

   .card {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fffffa;
    height: 350px; 
    // width : 300px;
    width: 100%;
    margin: auto;
  }

  .custom_temp{
  margin-bottom: -20em !important;
  }
  }
`;

const ReviewSlider = () => {
  const dispatch = useDispatch();
  const testimonialData = useSelector(
    (state) => state.fetchTestimonial.data?.data?.rows || []
  );

  useEffect(() => {
    dispatch(fetchTestimonials()).catch((error) =>
      console.error("Error:", error)
    );
  }, [dispatch]);

 const sliderSettings = {
    centerMode: true,
    dots: false,
    infinite: true,
    mobileFirst: true,
    speed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <ArrowRightCircle />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <ArrowLeftCircle />
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const renderDescriptionPopover = (description) => (
    <Popover id="popover-basic">
      <Popover.Body>{description}</Popover.Body>
    </Popover>
  );

  return (
    <StyledContainer>
      <div className="container customer_review">
        <div className="testimonial_header">
          <h3>Happy Customer</h3>
        </div>
        <div className="Card-list custom_temp pt-4" style={{marginBottom: '-15em'}}>
          <Slider {...sliderSettings} className="mx-2">
            {testimonialData.map(({ customer_name, profile_url, description, id }) => (
                 <div key={id} className="card">
                <div className="card-body">
                  <h4 className="card-title">
                    <img src="https://img.icons8.com/ultraviolet/40/000000/quote-left.png" alt="quote" />
                  </h4>
                  <div className="template-demo">
                    <p className="card-text">
                      <span>
                        {description.slice(0, 200)}
                        {description.length > 200 && (
                          <OverlayTrigger
                            trigger="click"
                            placement="right"
                            overlay={renderDescriptionPopover(description)}
                          >
                            <span className="read-more">... Read more</span>
                          </OverlayTrigger>
                        )}
                      </span>
                    </p>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-3">
                      <img
                        className="profile-pic"
                        src="https://img.icons8.com/bubbles/100/000000/edit-user.png"
                        alt="profile"
                      />
                    </div>
                    <div className="col-9">
                      <div className="profile">
                        <h4 className="cust-name">{customer_name}</h4>
                        <div className="bone">
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <img src={filledstar} alt="star" />
                          <p>5/5</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  </div>
                  </div>
            ))}
          </Slider>
        </div>
      </div>
    </StyledContainer>
  );
};

export default ReviewSlider;
