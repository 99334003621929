import React from "react";
import styled from "styled-components";
import cust1 from "../../assets/images/ana.PNG";
import { fetchTestimonials } from "../../features/web/web-actions";
import fbone from "../../assets/images/mdi_bone.png"
// import LadyDog from "../../assets/images/lady-dog.PNG";
// import cust2 from "../../assets/images/lucy.PNG";
// import cust3 from "../../assets/images/joy.PNG";

const StyleContainer = styled.div`
.pet_journey{
    background: #ffffff;
    margin: 2em 0em;
}
  .pet_journey .row {
    display: flex;
    align-items: center;
  }
  .pet_journey h5 {
    font-weight: 700;
  }
  .pet_journey p {
    padding: 1em 0px 2em 0px;
    text-align: justify;
  }
    .pet_journey a {
        background: #114c5f;
        color: #fff !important;
        padding: 10px 20px;
        border-radius: 5px;
        margin: 0px;
    }
    .customer_review a {
        background: #114c5f;
        color: #fff !important;
        padding: 10px 20px;
        border-radius: 5px;
        margin: 0px;
    }
  .customer_review .card {
    border: none;
    border-radius: 0px;
  }
  .customer_review .card-body {
    background: #000;
    color: #fff;
    text-align: center;
  }
  .customer_review .bone {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .customer_review h4{
    font-size: 35px;
    font-weight: 700;
     margin:  0px 0px 20px 40px;
    border-bottom: 5px solid #008DB3;
    width: 20%;
    display: block;
    text-wrap: nowrap;
  }
  @media (max-width: 540px){
    .bgimage {
       width: 350px;
    }
    .row>* {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x)* .5);
      padding-left: calc(var(--bs-gutter-x)* .5);
      margin-top: var(--bs-gutter-y);
      margin-bottom: 1em;
  }
  .customer_review h4{
    font-size: 27px;
    font-weight: 700;
    display: block;
    text-wrap: nowrap;
  }
}
`;
function HappyCustomers({ isCompact }) {
  return (
    <StyleContainer>
       {/* {!isCompact && (
      <div className="pet_journey">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h5>Uncover Our Pet's Journey: Read with Us</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                vitae dolor ut leo facilisis consequat. Ut id felis eget dolor
                bibendum congue. Nulla facilisi.
              </p>
              <a href="">Explore More &gt;</a>
            </div>
            <div className="col-md-6 ">
              <img src={LadyDog} className="bgimage" alt="" />
            </div>
          </div>
        </div>
      </div>
        )} */}
      <div className="customer_review container">
            <h4>Happy Customers</h4>
        <div className="row row-cols-1 row-cols-md-3 g-5">
          <div className="col">
            <div className="card">
              <img src={cust1} className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Ana & Tobby</h5>
                <p className="card-text">
                  Amazing products & delivery on time.
                </p>
                <div className="bone">
                    <img src={fbone} alt="" />
                    <img src={fbone} alt="" />
                    <img src={fbone} alt="" />
                    <img src={fbone} alt="" />
                    <img src={fbone} alt="" />
                    <p>5/5</p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12 text-center">
          <a className="" href="">View More &gt;</a>
          </div> */}
        </div>
      </div>
    </StyleContainer>
  );
}

export default HappyCustomers;
