import React from 'react';
import Card from './InsuranceSliderCard';
import './InsuranceSliderStyle.css';

const cardData = [
    { icon: 'path-to-icon1', label: 'Diagnostic tests' },
    { icon: 'path-to-icon2', label: 'Medication and treatments' },
    { icon: 'path-to-icon2', label: 'Specialist consultations' },
    { icon: 'path-to-icon2', label: 'Palliative care' },
    { icon: 'path-to-icon2', label: 'Emergency surgeries' },
    { icon: 'path-to-icon2', label: 'Elective surgeries' },
    { icon: 'path-to-icon2', label: 'Post-operative care' },
    { icon: 'path-to-icon2', label: 'Rehabilitation and physical therapy' },
    { icon: 'path-to-icon2', label: 'Pre-Hospitalization' },
    { icon: 'path-to-icon2', label: 'Post-Hospitalization Expenses' },
    { icon: 'path-to-icon2', label: 'On-going care' },
    { icon: 'path-to-icon2', label: 'Funeral Arrangements' },
    { icon: 'path-to-icon2', label: 'Memorial Items' },
    { icon: 'path-to-icon2', label: 'Grief Counseling' },
  ];
  
  const InsuranceSlider = () => {
    const fullData = cardData.concat(cardData); 
  
    return (
      <div className="slider-container">
        <div className="slider-section">
          <div className="slider">
            {fullData.map((data, index) => (
              <Card key={index} label={data.label} />
            ))}
          </div>
        </div>
        <div className="slider-section reverse">
          <div className="slider">
            {fullData.map((data, index) => (
              <Card key={index} label={data.label} />
            ))}
          </div>
        </div>
        <div className="slider-section">
          <div className="slider">
            {fullData.map((data, index) => (
              <Card key={index} label={data.label} />
            ))}
          </div>
        </div>
        {/* <div className="slider-section reverse">
          <div className="slider">
            {fullData.map((data, index) => (
              <Card key={index} icon={data.icon} label={data.label} />
            ))}
          </div>
        </div> */}
      </div>
    );
  };

export default InsuranceSlider;
