import React, { useState } from "react";
import styled from "styled-components";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createEnquiry } from "../../features/web/web-actions";
import * as Yup from 'yup';
import { message } from "antd";
import { Envelope, House, Telephone } from "react-bootstrap-icons";
import img1 from "../../assets/images/dogs/contact-bg.jpg";
import DogImg from "../../assets/images/services/trendsetting-pooch-with-sunglasses-holding-smartphone-generative-ai 1.png"
import add from "../../assets/images/Contact/entypo_address.png";
import contactNumber from "../../assets/images/Contact/fluent_contact-card-28-regular.png";
import email from "../../assets/images/Contact/mdi_email-fast-outline.png";

const StyledContainer = styled.div`
  
    .contact_row {
        background: #DDF9FF !important;
        width: 100%;
        height: 731px;
        padding: 0;
        margin: 0;
    }

    .contact_us_form {
        padding: 0;
        margin: 0;
    }

  .contact_row h2 {
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
    text-align: center;
  }

  .contact_heading span {
    width: 16%;
    text-align: center;
    border-bottom: 5px solid #008db3;
    padding: 10px;
  }

  .contact_heading h5 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    padding: 2em 0em;
  }

  .contact-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

//   .contact_us {
//     background: #DDF9FF;
//   }

  .contact_us_form .row {
    // border: 2px solid;
    // border-radius: 20px;
    padding: 6% 3%;
    margin: 0px;
    // background: #bbcfe7;
  }

  .contact_us_form .row .form-control {
    border: 1px solid #000;
    margin-bottom: 20px;
  }
  // .contact_us h5 {
  //   text-align: center;
  //   font-weight: 600;
  //   padding: 2em 8em;
  // }
  .contact_row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contact_row h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 1em;
  }
  .contact_us_form a {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 40px;
    border-radius: 5px;
  }

  .contact_us_form input {
    padding: 10px;
  }

    .contact_us_form .custom_btn {
        width: 240px;
        height: 50px;
        border-radius: 10px;
        background: black;
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 700;
        line-height: 24.38px;
        text-align: center;

    }

  .form-control{
    box-shadow: #E2FBFF 0px 5px 15px;
  }
  .contact_col {
    // background-image: url(${img1});
    // background-size: 100% 100%;
    // height: 530px;
    // border-radius: 20px;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 15px;
    & p {
      padding-bottom: 20px;
      color: #03434e;
      font-size: 21px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: left;
      gap: 1em;
    }
    & h2 {
      padding-top: 2em;
      text-align: center;
      color: #03434e;
      font-size: 28px;
      font-weight: 700;
    }
    & span {
      background: #fff;
      padding-top: 3px;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      text-align: center;
    }
  }

  .Contact-list{
  margin:2em 0em;
  }

  .Contact-list h3 {
    font-weight: 600;
    margin-left: 3vw;
  }

  .Contact-list p {
    text-align: center;
  }

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 0em 2em 0em;
    gap: 1em;
  }
  .contact_form {
    & h2 {
      font-size: 28px;
      font-weight: 700;
      color: #114c5f;
      text-align: center;
    }
  }
  .checkbox p {
    font-size: 12px;
    line-height: 12px;
  }
  .check-col {
    display: flex;
    flex-direction: column;
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .successMsg {
      border: 4px solid #114c5f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 5em;
      gap: 2em;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
      color: #114c5f;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  @media (max-width: 1400px) {
    .contact_heading h5 {
      font-size: 15px;
    }
  }

  @media (max-width: 991px) {
    .contact_row {
    width: 100%;
    height: auto;
    }
}
  
    @media (max-width: 425px) {
    .msg_div .successMsg {
      padding: 2em 2em;
      gap: 1em;
    }

    .Contact-list h3 {
      font-size: 17px;
    }

    .Contact-list p {
      font-size: 15px;
    }

    .Contact-list img {
      margin-top: -10px;
    }

    .contact_row h1 {
      padding: 0.5rem;
    }

    .contact_col p{
    justify-content: center;
    align-item:center;
    }

    .contact_col{
    text-align: center;
    margin-left: -2rem;
    }

    .contact_form {
      font-size: 22px;
    }

    .contact_form h2 {
      font-size: 22px;
    }

    .contact_us_form .row {
      padding: 28px 12px;
      margin: 0.5rem;
    }

    .contact_us_form .row .form-control {
      margin-bottom: 10px;
    }
    .checkbox {
      padding: 0px 0em 2em 0em;
    }
  }
`;

function ConnectServicePage() {
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            if (!values.subject) {
                message.error("Please Enter Subject");
            }
            const response = await createEnquiry({ ...values });
            // console.log("Data : ", response);
            if (response.success) {
                message.success("Your Enquiry has been submitted successfully");
                setIsSubmitted(true);
                resetForm();
            } else {
                message.error(response.message);
            }
        } catch (error) {
            console.error("Error occurred:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string()
          .required('Name is required').min(2, "Invalid Name!.").max(30, "Invalid Name!."),
      email: Yup.string()
          .email('Invalid email address').max(40, "Invalid Email!.")
          .required('Email is required'),
      contact_no: Yup.string()
          .matches(/^\d{10}$/, 'Please enter exactly 10 digits!.')
          .test('no-leading-zero','Contact number must not start with 0 or +91',value => value && !/^0|(\+91)/.test(value))
          .required('Contact number is required'),
      message: Yup.string()
          .required('Message is required')
  });

    return (
        <StyledContainer>
            <div id="ConnectServiceForm" className="row contact_row">
                        <h2 className="m-4">Let’s Connect</h2>
                        <div className="contact-content row justify-content-evenly">
                            <div className="col-lg-6 col-md-12 d-none d-lg-block contact_col">
                                <img src={DogImg} alt="Dog Image"></img>
                            </div>
                            <div className="col-lg-6 col-md-9 contact_us_form">
                                <Formik
                                    initialValues={{
                                        name: "",
                                        email: "",
                                        subject: "Corporate Therapy",
                                        contact_no: "",
                                        message: "",
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <div className="row contact_form">
                                                {/* <h2>Reach Out to Us</h2> */}
                                                <div className="col-md-10 mb-3">
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="name"
                                                        placeholder="Name"
                                                    />
                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                </div>
                                                <div className="col-md-10 mb-3">
                                                    <Field
                                                        className="form-control"
                                                        type="text"
                                                        name="email"
                                                        placeholder="Email"
                                                    />
                                                      <ErrorMessage name="email" component="div" className="text-danger" />
                                                </div>
                                                <div className="col-md-10 mb-3">
                                                    <Field
                                                        className="form-control"
                                                        type="text" maxlength={10}
                                                        name="contact_no"
                                                        placeholder="Contact Number"
                                                        onKeyDown={(e) => {
                                                          if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                                              e.preventDefault();
                                                          }
                                                      }}
                                                    />
                                                      <ErrorMessage name="contact_no" component="div" className="text-danger" />
                                                </div>
                                                <div className="col-md-10 mb-3">
                                                    <Field
                                                        className="form-control"
                                                        as="textarea" rows="5"
                                                        name="message"
                                                        placeholder="Please Write Any Note Here..."
                                                    />
                                                    <ErrorMessage name="message" component="div" className="text-danger" />
                                                </div>
                                                <div className="col-md-10 check-col">
                                                    <div className="">
                                                        <button
                                                            className="custom_btn"
                                                            type="submit"
                                                            disabled={isSubmitting}
                                                        >
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
        </StyledContainer>
    );
}

export default ConnectServicePage;
