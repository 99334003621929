import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_BASE_URL } from "../../utils/Env";

export const getBookingServices = createAsyncThunk(
  "servicesBooking/fetch",
  async () => {
    try {
      const { data, success } = await fetch(
        `${API_BASE_URL}/api/pet/service/booking/get`
      ).then((response) => response.json());
      if (success) {
        return data;
      }
      throw new Error("Request failed");
    } catch (error) {
      console.error("Error fetching services:", error);
      throw error;
    }
  }
);
