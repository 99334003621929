import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import styled from "styled-components";
import {
  fetchDogMaster,
  fetchServicesById,
  loginWithOtp,
} from "../../features/web/web-actions";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ServicesDetails from "./ServicesDetails";

const ModalStyled = styled.div`
  .card_top {
    display: flex;
    align-items: center;
  }
  .package_info {
    padding: 0px 10px;
    font-weight: 500;
  }
  .selling_price {
    font-weight: 700;
    padding-left: 10px;
  }
  .add_actions {
    background: #114c5f;
    color: #fff !important;
    padding: 5px 20px;
    border-radius: 5px;
    margin-right: 1em;
  }
  .card-actions {
    border: 2px solid #114c5f;
    border-radius: 5px;
    margin-right: 10px;
    width: 5.1em;
    text-align: center;
  }
  .btn_actions {
    color: #114c5f;
    font-weight: 700;
    background: transparent;
    padding: 5px 10px;
  }
  .selected-card {
    border: 2px solid #55c8db !important;
  }
  .card_col {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    .custom_btn {
      width: 100%;
      padding: 10px 0;
      font-size: 16px;
    }

    .d-inline-flex {
      flex-direction: column;
      gap: 10px;
    }
  }

  @media (max-width: 480px) {
    .custom_btn {
      font-size: 14px;
    }

    .form-select,
    .form-control {
      font-size: 14px;
    }

    .card_col {
      width: 350px;
      display: flex;
      justify-content: left;
    }
  }
`;

const CardComponent = ({
  title,
  content,
  packagePrice,
  onSelect,
  isSelected,
  packageInfo,
  spotPrice,
}) => {
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleAddToCart = () => {
    setIsAddedToCart(!isAddedToCart); // Toggle isAddedToCart state
    setShowAlert(true);
  };

  return (
    <ModalStyled>
      <div className={`col card_col `} onClick={onSelect}>
        <div className={`card w-75 h-100 ${isSelected ? "selected-card" : ""}`}>
          <div className="card_top">
            <div className="card-body">
              <h5 className="card-title">{title}</h5>
              <p className="card-text">{content}</p>
            </div>
            <div>
              <button className="add_actions" onClick={handleAddToCart}>
                {isSelected ? "Added" : "Add"}
              </button>
            </div>
          </div>
          {packageInfo ? (
            <small className=" package_info">
              {" "}
              <hr className="m-0 pt-1" /> {packageInfo}{" "}
            </small>
          ) : (
            ""
          )}
          <div className="card-footer">
            {spotPrice ? (
              <small className="text-muted">
                {" "}
                <strike>₹ {spotPrice} </strike>{" "}
              </small>
            ) : (
              ""
            )}
            <small className="selling_price"> ₹ {packagePrice}</small>
          </div>
        </div>
      </div>
      {showAlert &&
        message.success(` ${content} Service Successfully added in card!`)}
    </ModalStyled>
  );
};

function ServiceModal({ serviceId, bgColor, serviceName, Btn_name }) {
  const [getServices, setServices] = useState([]);

  const [dogMaster, setDogMaster] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [formData, setFormData] = useState({
    dog_breed: "",
    dog_size: "",
    pet_sex: "",
    pet_age: "",
  });
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const getServicesById = useSelector((state) => state.servicesById.data || []);
  const getDogMasterData = useSelector(
    (state) => state.dogMasterData.data || []
  );
  useEffect(() => {
    dispatch(fetchDogMaster());
  }, [dispatch]);

  useEffect(() => {
    if (getDogMasterData && getDogMasterData.data) {
      setDogMaster(getDogMasterData.data.dog_breed);
    }
  }, [getDogMasterData]);

  useEffect(() => {
    if (getServicesById && getServicesById.data) {
      const dogSizeKey = formData.dog_size.toLowerCase();
      setServices(getServicesById.data.SERVICESWEEKWISEPRICEs[dogSizeKey]);
    }
  }, [getServicesById, formData.dog_size]);

  useEffect(() => {
    console.log("use effect fetch service by Id");
    dispatch(fetchServicesById(serviceId));
  }, [dispatch, serviceId]);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCardSelect = (cardData) => {
    setSelectedCard(cardData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "pet_age") {
      const age = parseInt(value);
      if (age <= 0 || age > 20) {
        message.error("Please enter a valid age of your pet");
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleViewCart = () => {
    if (selectedCard && formData.dog_breed && formData.dog_size) {
      const data = {
        selectedCard: selectedCard,
        formData: {
          dog_breed: formData.dog_breed,
          dog_size: formData.dog_size,
          pet_age: formData.pet_age,
          pet_sex: formData.pet_sex,
        },
      };
      localStorage.setItem("ServiceID", selectedCard.service_id);
      navigate(`/services/${selectedCard.service_id}`, {
        state: { data: data },
      });
    } else {
      message.error(
        "Please select a Service Package, Pet breed, and Pet Size."
      );
    }
  };
  const buttonStyle = {
    backgroundColor: bgColor,
  };

  return (
    <>
      <button
        className="btn black_btn"
        style={buttonStyle}
        onClick={handleShow}
      >
        {/* Book Now */}
        {Btn_name || "Book Now"}
      </button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{serviceName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12 login_col">
                <div className="login">
                  <form onSubmit={handleSubmit}>
                    {step === 1 && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="">Select Dog Breed</label> <br />
                            <select
                              className="form-select"
                              name="dog_breed"
                              value={formData.dog_breed}
                              onChange={handleChange}
                              aria-required
                            >
                              <option value="" selected disabled>
                                Select
                              </option>
                              {dogMaster?.map((e, index) => (
                                <option value={e.name}>{e.name}</option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="">Select Dog Size</label> <br />
                            <select
                              className="form-select"
                              name="dog_size"
                              value={formData.dog_size}
                              onChange={handleChange}
                              aria-required
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="Small">Small</option>
                              <option value="Medium">Medium</option>
                              <option value="Large">Large</option>
                            </select>
                          </div>
                          {/* <div className="col-md-6 pt-3">
                            <label htmlFor="">Select Pet Gender</label> <br />
                            <select
                              className="form-select"
                              name="pet_sex"
                              value={formData.pet_sex}
                              onChange={handleChange}
                            >
                              <option value="" disabled selected>
                                Select
                              </option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                          </div> */}
                          <div className="col-md-6 pt-3">
                            <label htmlFor="">Pet Age</label> <br />
                            <input
                              type="number"
                              name="pet_age"
                              className="form-control"
                              placeholder="Enter your pet age"
                              value={formData.pet_age}
                              onChange={handleChange}
                              aria-required
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <div style={{ height: "67vh", overflowX: "hidden" }}>
                          <div className="row row-cols-1 row-cols-md-1 g-4 pb-4">
                            {getServices.map((cardData, index) => (
                              <CardComponent
                                key={index}
                                title={cardData.week_wise}
                                content={cardData.day_wise}
                                packageInfo={cardData.package_info}
                                spotPrice={cardData.spot_price}
                                packagePrice={cardData.price + " (inc GST)"}
                                onSelect={() => handleCardSelect(cardData)}
                                isSelected={selectedCard === cardData}
                              />
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                    {step < 2 && (
                      <button
                        className="custom-btn mt-4"
                        type="button"
                        onClick={nextStep}
                        disabled={
                          !formData.dog_breed ||
                          !formData.dog_size ||
                          formData.pet_age === ""
                        }
                      >
                        Continue
                      </button>
                    )}
                    {step === 2 && (
                      <div className="d-inline-flex" style={{ gap: "10px" }}>
                        <button
                          type="button"
                          className="custom_btn"
                          onClick={previousStep}
                          style={{ width: "93px" }}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          className="custom_btn"
                          onClick={handleViewCart}
                        >
                          {selectedCard ? selectedCard.packagePrice : ""} View
                          Cart
                        </button>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ServiceModal;
