import React from "react";
import styled from "styled-components";
import Header from "../../components/Common/ComponentHeader";
import circle from "../../assets/images/Provider/circle.png";
import providerImg1 from "../../assets/images/Provider/providerImg1.png";
import providerImg2 from "../../assets/images/Provider/providerImg2.png";
import providerImg3 from "../../assets/images/Provider/providerImg3.png";

const StyledContainer = styled.div`
    .margin{
        margin-top: 8rem;
        margin-bottom: 3rem;
    }
    
    // .whychooseus{
    // margin: 4rem 7rem;
    // }

    row{
        justift-content:center;
    }

    .col-4{
        display: flex;
        justify-content: center;
        flex-wrap: wrap !important;
        text-align: center;
    }

    .col-4 img{
     margin-bottom:3rem;
    }

    @media(max-width: 769px){
    .ChooseUsList{
        width: 95%;
        margin: auto;
    } 
    
    .margin{
        margin-top: 0rem;
    }
  }

`;

const Whychooseusprovider = ({ header1, header2 }) => {
    return (
        <StyledContainer>
            <div className="margin">
                <div className="container">
                    <div className="mb-5">
                        <Header header1={header1} header2={header2} />
                    </div>
                    <div className="whychooseus ">
                        <div className="row d-flex justify-center text-center ChooseUsList">
                            <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                                <div className="mb-5">
                                    <img src={providerImg1} alt="" />
                                </div>

                                <div className="content">
                                    <h5>Expand Your Reach</h5>
                                    <p>Connect with a wide and engaged pet-loving community through Petside India's extensive platform, increasing your visibility and client base.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                                <div className="mb-5">
                                    <img src={providerImg2} alt="" />
                                </div>
                                <div className="content">
                                    <h5>Trusted Reputation</h5>
                                    <p>Join a reputable brand known for quality pet care, which can enhance your professional credibility and attract more clients.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                                <div className="mb-5">
                                    <img src={providerImg3} alt="" />
                                </div>
                                <div className="content">
                                    <h5>Seamless Management</h5>
                                    <p>Benefit from our user-friendly platform that simplifies appointment scheduling, payments, and client communications, allowing you to focus on providing exceptional services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </StyledContainer >

    )
}

export default Whychooseusprovider