import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PencilSquare } from "react-bootstrap-icons";
import { fetchProductBookings } from "../../features/web/web-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useParams  } from "react-router-dom";

const StyledContainer = styled.div`
  .card {
    // overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    // height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .table th,
  .table td {
    padding: 12px;
    text-align: center; /* Center align content */
    vertical-align: middle; /* Vertically center content */
    border-bottom: 1px solid #dee2e6;
  }

  th {
    background-color: #0dcaf1;
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .action-column {
    text-align: center;
    color: #007bff;
    cursor: pointer;
  }

  .action-column:hover {
    color: #0056b3;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px 0px;
      height: auto;
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
      

    .hamburger {
      display: block;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }
  }
`;

function ProductDetails() {

    const { product_order_id } = useParams();

  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    user_id = userData.id;
  }
  
  const dispatch = useDispatch();
  const [getProductBookings, setProductBookings] = useState([]);
  const [getProductDetails, setProductDetails] = useState([]);
  const getServiceBooking = useSelector(
    (state) => state.productBookings.data || []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchProductBookings(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getServiceBooking && getServiceBooking.data) {
      const bookings = getServiceBooking.data.rows;

      console.log("All Booking Data: ", bookings);
      console.log("Product Order Id: ", product_order_id);

      // Find the booking by comparing IDs (convert product_order_id to a number if necessary)
      const selectedBooking = bookings.find(booking => booking.id === Number(product_order_id));
  
      // If a matching booking is found, set its `ORDERPRODUCTs` to the state
      if (selectedBooking) {
        setProductDetails(selectedBooking);
        setProductBookings(selectedBooking.ORDERPRODUCTs);
      } else {
        console.log("No matching booking found.");
      }
    } else {
      console.log("Error: Booking data not available");
    }
  }, [getServiceBooking, product_order_id]); // Dependency array includes `getServiceBooking` and `product_order_id`

  console.log("Product Booking : ", getProductBookings);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <StyledContainer>
      <div className="container-fluid">
        <div className="card">
        <h2 className="fw-semibold"> Order id: {getProductDetails.invoice_prefix}</h2>
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Amount</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
            {getProductBookings.map((e, index) => (
                <tr key={index}>
                    <td>{index + 1 }</td>
                    <td>{e.name}</td>
                    <td>{e.quantity}</td>
                    <td>{e.total}</td>
                    {/* <td className="text-center text-primary">
                    <a href="product-details" className="text-primary"> View more </a>
                    </td> */}
                </tr>
                ))}
            </tbody>
          </Table>
          <Table striped bordered hover>
            <tbody>
                <tr>
                    <td className="text-end fw-semibold">
                        <h6>Total</h6>
                    </td>
                    <td className="fw-semibold">
                        <h6>₹{getProductDetails.total}</h6>
                    </td>
                </tr>
            </tbody>
        </Table>
        </div>
      </div>
    </StyledContainer>
  );
}

export default ProductDetails;
