import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../assets/images/petside-logo.png";
import cart from "../../assets/images/cart.png";
import Phone_logo from "../../assets/images/Home/solar_phone-line-duotone.svg";
import dropdown from "../../assets/images/Home/majesticons_arrow-up-linedropdown.svg";
import { getCart } from "../../features/web/web-actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Login from "../../pages/Auth/Login";
import userIcon from "../../assets/images/user.png";
import { Button, Dropdown, Menu } from "antd";
import { useNavigate, NavLink } from "react-router-dom";
import MobileBottomMenu from "../Footer/MobileMenu";
import MobileNavbar from "../Header/MobileNavbar";

const StyledContainer = styled.div`
  font-size: 15px;
  font-weight: 600;

  .dropdown {
    position: relative;
    display: inline-block;
  }

  .provider{
  margin:0px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 165px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 5;
    border-radius: 5px;
    padding: 0;
  }

  .dropdown-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .dropdown-content li {
    padding: 10px 20px;
    font-weight: 500;
    transition: background-color 0.3s ease;
    white-space: nowrap;
  }

  .dropdown-content li:hover {
    background: #f0f0f0;
  }

  .dropdown-content a {
    text-decoration: none;
    color: #333;
    display: block;
  }

  .dropdown-content a:hover {
    color: #000;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .nav-link {
    // margin:1rem;
  }

  .navbar-nav .nav-link.active {
    border-bottom: 2px solid #12d2ff;
  }

//   .mob_menu {
//       display: none !important;
//     }

//     .desktop_menu {
//       display: block !important;
//     }

//   @media (max-width: 991px) {
//     .mob_menu {
//       display: block !important;
//     }

//     .desktop_menu {
//       display: none !important;
//     }
// }

  @media (max-width: 1200px) {
    .navbar .container {
      padding: 0 15px;
      margin: 0;
    }

    .navbar .nav-link {
      padding: 10px 5px;
      font-size: 14px;
    }

    .header_logo {
      width: 110px;
      height: 45px;
    }

    .navbar .col-lg-2 {
      flex: 0 0 auto;
      max-width: 100%;
    }

    .navbar .col-lg-8 {
      flex: 0 0 auto;
      max-width: 100%;
    }

    .navbar .col-lg-2.d-none.d-lg-block {
      display: block !important;
      text-align: right;
    }

    .Navbar3 {
      margin-left: 2vw;
    }
  }
`;

function Header() {
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let isUserLoggedIn = null;
  let userId = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    isUserLoggedIn = userData.contact_no;
    userId = userData.id;
  }

  const dispatch = useDispatch();
  const cartCount = useSelector((state) => state.cart.data?.count || []);
  useEffect(() => {
    dispatch(getCart(userId)).catch((error) => console.error("Error:", error));
  }, [dispatch]);

  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const menu = (
    <div>
    <div className="mob_menu d-block d-lg-none">
    <Menu>
        <Menu.Item key="1">
            <a href="/auth/profile">My Profile</a>
        </Menu.Item>
        <Menu.Item key="2">
            <a href="/auth/saved-address">Saved Address</a>
        </Menu.Item>
        <Menu.Item key="3">
            <a href="/auth/order-history">Order History</a>
        </Menu.Item>
        <Menu.Item key="4">
            <a href="/auth/pet-profile">Pet Profile</a>
        </Menu.Item>
        <Menu.Item key="5" onClick={logoutHandler}>
            <a href="/">Logout</a>
        </Menu.Item>
    </Menu>
    </div>
    <div className="desktop_menu d-none d-lg-block">
    <Menu>
        <Menu.Item key="1">
            <a href="/auth/profile">My Profile</a>
        </Menu.Item>
        <Menu.Item key="5" onClick={logoutHandler}>
            <a href="/">Logout</a>
        </Menu.Item>
    </Menu>
    </div>
    </div>
);

  return (
    <StyledContainer>
      {/* <Navbar id="NavBar" className="m-0 d-none d-lg-block">
        <Container className="">
          <Navbar.Brand href="/" className="col-lg-2">
            <img
              src={logo}
              alt="Logo"
              className="header_logo"
              width="130"
              height="55"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="col-lg-8">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100%" }}
              navbarScroll
            >
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className="nav-link"
                activeClassName="active"
              >
                About Us
              </NavLink>
              <NavLink to="/services" className="nav-link">
                Services
                <div className="dropdown ps-1">
                  <img src={dropdown} alt="" />
                  <div className="dropdown-content">
                    <ul>
                      <li>
                        <NavLink to="/service/grooming">Grooming</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/pet-sitting">Pet Sitting</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/dog-walking">Dog Walking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/dog-training">
                          Dog Training
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/home-boarding">
                          Home boarding
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/daycare">Daycare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/drop-in-visit">
                          Drop In visit
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to="/blogs"
                className="nav-link"
                activeClassName="active"
              >
                Blog
              </NavLink>
              <NavLink
                to="/contact-us"
                className="nav-link"
                activeClassName="active"
              >
                Contact Us
              </NavLink>
              <NavLink to="/cart" className="nav-link" activeClassName="active">
                <img src={cart} alt="" />
                <span className="badge rounded-pill text-bg-info">
                  {cartCount}
                </span>
              </NavLink>

              {isUserLoggedIn ? (
                <>
                  <Dropdown overlay={menu} placement="bottomRight">
                    <Button className="border-0 pt-2">
                      {" "}
                      <img src={userIcon} width={25} alt="" />
                    </Button>
                  </Dropdown>
                </>
              ) : (
                <div>
                  <Login />
                </div>
              )}
            </Nav>
          </Navbar.Collapse>

          <Navbar className="col-lg-2 d-none d-lg-block text-end">
            <d className="row">
              <div className="col-2 align-middle">
                <img src={Phone_logo} className="" alt="" />
              </div>
              <div className="col-10 align-middle">
                <div>Call us Anytime</div>
                <div className="text-primary">+91 91367 76162</div>
              </div>
            </d>
          </Navbar>
        </Container>
      </Navbar> */}

      <Navbar id="NavBar" className="m-0 d-none d-lg-block remove-print">
        <Container>
          <Navbar.Brand href="/" className="col-lg-2">
            <img
              src={logo}
              alt="Logo"
              className="header_logo"
              width="130"
              height="55"
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" className="col-lg-8">
            <Nav
              className="m-auto my-2 my-lg-0"
              style={{ maxHeight: "100%" }}
              navbarScroll
            >
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
              >
                Home
              </NavLink>
              <NavLink
                to="/about-us"
                className="nav-link"
                activeClassName="active"
              >
                About Us
              </NavLink>
              <NavLink to="/services" className="nav-link">
                Services
                <div className="dropdown ps-1">
                  <img src={dropdown} alt="" />
                  <div className="dropdown-content">
                    <ul>
                      <li>
                        <NavLink to="/service/grooming">Grooming</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/pet-sitting">Pet Sitting</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/dog-walking">Dog Walking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/dog-training"> Dog Training </NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/home-boarding">  Home boarding </NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/daycare">Daycare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/drop-in-visit">  Vets at Home </NavLink>
                      </li>
                      <li>
                        <NavLink to="/service/therapy-sessions"> Corporate Therapy </NavLink>
                      </li>
                      <li>
                        <NavLink to="/pet-insurance"> Pet Insurance </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </NavLink>
              <NavLink
                to="/blogs"
                className="nav-link"
                activeClassName="active"
              >
                Blog
              </NavLink>
              <NavLink
                to="/contact-us"
                className="nav-link"
                activeClassName="active"
              >
                Contact Us
              </NavLink>
              <NavLink to="/cart" className="nav-link" activeClassName="active">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.74238 20.555C4.94238 22 7.17438 22 11.6404 22H12.3604C16.8264 22 19.0594 22 20.2594 20.555M3.74238 20.555C2.54238 19.109 2.95438 16.915 3.77738 12.525C4.36238 9.405 4.65438 7.844 5.76538 6.922M20.2594 20.555C21.4594 19.109 21.0474 16.915 20.2244 12.525C19.6394 9.405 19.3464 7.844 18.2354 6.922M18.2354 6.922C17.1254 6 15.5364 6 12.3614 6H11.6394C8.46438 6 6.87638 6 5.76538 6.922" stroke="black" stroke-width="1.5" />
                  <path d="M9 6V5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V6" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                <span className="badge rounded-pill text-bg-info">
                  {cartCount}
                </span>
              </NavLink>
              {/* <NavLink to="/provider" className="nav-link" activeClassName="active provider">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.24023 9.8574C8.24023 11.1716 9.30919 12.2406 10.623 12.2406C11.185 12.2406 11.6449 11.992 11.9991 11.7433C12.3514 11.992 12.8113 12.2406 13.3777 12.2406C14.6919 12.2406 15.7609 11.1716 15.7609 9.8574C15.7609 8.54316 15.1652 7.251 14.0377 6.10908C13.4953 5.55948 12.7719 5.25708 12.0006 5.25708C11.2292 5.25708 10.5058 5.55948 9.96343 6.10908C8.83639 7.251 8.24023 8.54748 8.24023 9.8574ZM12.0006 6.4566C12.4484 6.4566 12.8684 6.63228 13.1838 6.95148C14.0977 7.8774 14.5609 8.85468 14.5609 9.85692C14.5609 10.5092 14.03 11.0401 13.3777 11.0401C13.153 11.0401 12.9178 10.935 12.59 10.6888C12.4162 10.5582 12.2079 10.4929 11.9991 10.4929C11.7903 10.4929 11.5839 10.5577 11.4102 10.6873C11.0751 10.9379 10.8471 11.0401 10.623 11.0401C9.97063 11.0401 9.44023 10.5092 9.44023 9.85692C9.44023 8.85468 9.90343 7.87692 10.8174 6.95148C11.1322 6.63228 11.5527 6.4566 12.0006 6.4566Z" fill="black" />
                  <path d="M8.92227 5.041C9.24627 5.23732 9.60147 5.33812 9.94947 5.33812C10.1535 5.33812 10.3551 5.30356 10.5466 5.23396C11.066 5.04484 11.474 4.61044 11.6669 4.04212C11.8469 3.5122 11.832 2.90788 11.6256 2.341C11.1826 1.12372 9.97731 0.440677 8.93907 0.818437C7.90083 1.1962 7.41651 2.49412 7.85955 3.7114C8.06595 4.27828 8.44323 4.7506 8.92131 5.041H8.92227ZM9.34995 1.94596C9.41139 1.9234 9.47571 1.91284 9.54147 1.91284C9.91059 1.91284 10.3176 2.25364 10.4986 2.75092C10.6104 3.05764 10.622 3.3874 10.5308 3.6562C10.4866 3.78676 10.3762 4.0186 10.1362 4.10596C9.89619 4.19332 9.66243 4.08628 9.54483 4.01476C9.30243 3.86788 9.09939 3.60724 8.98803 3.30052C8.77491 2.71492 8.94099 2.09476 9.34995 1.94548V1.94596Z" fill="black" />
                  <path d="M6.05638 7.76254C6.41254 7.96846 6.79798 8.06686 7.16566 8.06686C7.78582 8.06686 8.35606 7.78702 8.6551 7.26958C9.13126 6.44494 8.74006 5.3251 7.78294 4.77262C6.82582 4.22014 5.66038 4.44142 5.18422 5.26606C4.70806 6.0907 5.09926 7.21054 6.05638 7.76302V7.76254ZM6.22342 5.86558C6.29446 5.7427 6.46246 5.66542 6.6703 5.66542C6.82726 5.66542 7.0063 5.70958 7.18294 5.81134C7.59334 6.04798 7.74022 6.45358 7.6159 6.6691C7.49158 6.88462 7.06678 6.95998 6.65638 6.72286C6.24598 6.48622 6.0991 6.08062 6.22342 5.8651V5.86558Z" fill="black" />
                  <path d="M13.4541 5.23385C13.6481 5.30441 13.8477 5.33801 14.0474 5.33801C14.9162 5.33801 15.7802 4.70153 16.1407 3.71129C16.5837 2.49401 16.0994 1.19609 15.0612 0.818333C14.5418 0.629213 13.95 0.699773 13.4369 1.01129C12.9583 1.30169 12.5815 1.77401 12.3751 2.34089C12.1687 2.90777 12.1543 3.51209 12.3338 4.04201C12.5268 4.61033 12.9353 5.04521 13.4541 5.23385ZM13.5026 2.75129C13.6145 2.44457 13.817 2.18441 14.0594 2.03705C14.1458 1.98473 14.2941 1.91321 14.4621 1.91321C14.5231 1.91321 14.5869 1.92281 14.6508 1.94585C15.0602 2.09465 15.2263 2.71529 15.0132 3.30089C14.8001 3.88649 14.274 4.25513 13.8645 4.10585C13.6245 4.01849 13.5141 3.78665 13.47 3.65609C13.3788 3.38777 13.3908 3.05801 13.5021 2.75129H13.5026Z" fill="black" />
                  <path d="M15.346 7.2696C15.6451 7.78704 16.2153 8.06688 16.8355 8.06688C17.2032 8.06688 17.5886 7.96848 17.9448 7.76256C18.9014 7.21008 19.2926 6.08976 18.8169 5.2656C18.3408 4.44096 17.1753 4.22016 16.2182 4.77216C15.2616 5.32464 14.8704 6.44496 15.346 7.26912V7.2696ZM16.8182 5.81184C16.9948 5.71008 17.1739 5.66592 17.3308 5.66592C17.5382 5.66592 17.7067 5.7432 17.7777 5.86608C17.902 6.0816 17.7552 6.4872 17.3448 6.72384C16.9348 6.96048 16.51 6.8856 16.3852 6.67008C16.2609 6.45456 16.4078 6.04896 16.8182 5.81232V5.81184Z" fill="black" />
                  <path d="M20.7333 12.4162C20.0133 11.6962 18.8421 11.6962 18.1221 12.4162L15.8733 14.665C15.7864 14.485 15.6693 14.3185 15.5243 14.173C15.184 13.8313 14.7309 13.6431 14.2485 13.6431H9.55984C8.7136 13.6431 7.91776 13.9729 7.3192 14.5714L5.31616 16.5745C4.87648 16.4132 4.36336 16.5082 4.01104 16.8605L3.08944 17.7821C2.8552 18.0164 2.72656 18.3274 2.72656 18.6581C2.72656 18.9889 2.85568 19.3004 3.08944 19.5341L6.47776 22.9225C6.712 23.1567 7.02304 23.2853 7.35376 23.2853C7.68448 23.2853 7.996 23.1562 8.22976 22.9225L9.15136 22.0009C9.47488 21.6773 9.58144 21.2175 9.47152 20.8042C9.87664 20.5215 10.3576 20.3689 10.8621 20.3689H13.8837C14.8557 20.3689 15.7696 19.9901 16.457 19.3028L20.7333 15.0265C21.4533 14.3065 21.4533 13.1353 20.7333 12.4153V12.4162ZM8.30272 21.1527L7.38112 22.0743C7.37824 22.0772 7.3696 22.0858 7.35328 22.0858C7.33696 22.0858 7.32832 22.0772 7.32544 22.0743L3.93712 18.686C3.93424 18.6831 3.9256 18.6745 3.9256 18.6581C3.9256 18.6418 3.93424 18.6332 3.93712 18.6303L4.85872 17.7087C4.8664 17.701 4.87648 17.6972 4.88656 17.6972C4.89664 17.6972 4.90672 17.701 4.9144 17.7087L4.972 17.7663L8.2888 21.0831C8.2888 21.0831 8.2888 21.0831 8.28928 21.0831L8.30272 21.0965C8.31808 21.1119 8.31808 21.1369 8.30272 21.1517V21.1527ZM19.8846 14.1788L15.6083 18.4551C15.1475 18.9159 14.535 19.1693 13.8837 19.1693H10.8621C10.0931 19.1693 9.36064 19.4065 8.74768 19.8457L6.24496 17.3429L8.16736 15.4205C8.53936 15.0485 9.03376 14.8441 9.55936 14.8441H14.248C14.4093 14.8441 14.5605 14.9069 14.6738 15.0207C14.788 15.1354 14.8514 15.289 14.8514 15.4532V15.4834C14.8514 15.7964 14.597 16.0508 14.284 16.0508H9.68272C9.35152 16.0508 9.08272 16.3196 9.08272 16.6508C9.08272 16.982 9.35152 17.2508 9.68272 17.2508H14.284C14.8883 17.2508 15.4226 16.946 15.7418 16.4823C15.7691 16.4631 15.795 16.441 15.8195 16.4165L18.9712 13.2649C19.2232 13.0129 19.6331 13.0129 19.8851 13.2649C20.1371 13.5169 20.1371 13.9268 19.8851 14.1788H19.8846Z" fill="black" />
                </svg>
              </NavLink> */}

              {isUserLoggedIn ? (
                <Dropdown overlay={menu} placement="bottomRight">
                  <Button className="border-0 pt-2">
                    <img src={userIcon} width={25} alt="" />
                  </Button>
                </Dropdown>
              ) : (
                <Login />
              )}
            </Nav>
          </Navbar.Collapse>


          {/* {isUserLoggedIn ? (
              <div className="col-lg-2 d-none d-lg-block Navbar3">
                <div className="row">
                  <div className="col-2 align-middle">
                    <img src={Phone_logo} alt="" />
                  </div>
                  <div className="col-10 align-middle">
                    <div>Call us Anytime</div>
                    <a href="tel:+919136776162" className="text-primary" title="Call us at +91 9136776162">+91 91367 76162</a>
                  </div>
                </div>
              </div> 
          ) : (
              <div className="col-lg-2 d-none d-lg-block Navbar3">
                <button className="btn btn-primary">Become a provider</button>
              </div>
          )} */}

          <div className="col-lg-2 d-none d-lg-block Navbar3">
            <div className="row">
              <div className="col-2 align-middle">
                <img src={Phone_logo} alt="" />
              </div>
              <div className="col-10 align-middle">
                <div>Call us Anytime</div>
                <a href="tel:+919136776162" className="text-primary" title="Call us at +91 9136776162">+91 91367 76162</a>
              </div>
            </div>
          </div>

        </Container>
      </Navbar>

      <MobileNavbar display="col-sm-12" className="remove-print" />

      <MobileBottomMenu display="col-sm-12 d-block d-lg-none" className="remove-print" />
    </StyledContainer>
  );
}

export default Header;
