import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchPackages } from "../../features/web/web-actions";
import { Button } from "react-bootstrap";
import husky from "../../assets/images/Home/Husky.png";
import chowchow from "../../assets/images/Home/chow-chow.png";
import Header from "../../components/Common/ComponentHeader";

// Assuming you have an array of image paths or names
const imagePaths = [
  husky,
  chowchow,
  // Add more image imports as needed
];

const StyledContainer = styled.div`
  .headercontainer {
    margin-left: 10%;
  }
  .header1 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 36.57px;
    letter-spacing: 2px;
  }

  .header2 {
    border-bottom: 5px solid #12d2ff;
    width: fit-content;
    font-size: 2rem;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
  }

  .CardSection2 .CardContent2 {
    display: flex;
    flex-direction: row-reverse;
  }

  .card-title {
    font-size: 1.5rem;
    padding-top: 1.5rem;
    font-style: italic;
    font-weight: 700;
    line-height: 26.86px;
    letter-spacing: 1.762519359588623px;
    text-align: center;
  }

  .card {
    border: 0px;
    border-radius: 15px;
    // max-height: 85%;
    max-height: fit-content;
    font-size: 17.63px;
    font-weight: 600;
    line-height: 21.49px;
    letter-spacing: 1.762519359588623px;
    text-align: left;
  }

  .card1 {
    background-color: #91e8ff;
  }

  .card2 {
    background-color: #d2d2d2;
  }

  .ribbon {
    width: 118px;
    font-size: 14px;
    padding: 4px;
    position: absolute;
    left: -21px;
    top: 9px;
    text-align: center;
    border-radius: 0px;
    transform: rotate(323deg);
    background-color: #ffffff;
    color: black;
  }

  .pricing {
    font-size: 13.22px;
    font-weight: 600;
    line-height: 16.11px;
    letter-spacing: 1.762519359588623px;
    text-align: center;
  }
  .price {
    font-size: 35.25px;
    font-weight: 600;
    line-height: 42.97px;
    letter-spacing: 1.762519359588623px;
    text-align: center;
  }
  button {
    background-color: black;
    border-radius: 0;
    padding: 2% 25%;
  }
  .card1 .Cardimage {
    position: relative;
    right: 30%;
    top: -7%;
  }
  .card2 .Cardimage {
    margin-top: 8%;
    margin-left: 9%;
    width: 186px;
    object-fit: contain;
  }

  .justat {
    font-weight: 600 !important;
  }

  .pricing h6 {
    font-size: 13.22px;
    font-weight: 400;
    text-align: center;
  }

  @media screen and (min-width: 1400px) {
    .card1 {
      height: 420px;
    }
  }

  @media (max-width: 1400px) and (min-width:1200px){
    .card1{
      height: 432px;
    }

    .card1 .Cardimage {
    position: relative;
    right: 60%;
    top: -3%;
    }

    .card2 .Cardimage {
      margin-top: 8%;
      margin-left: 9%;
      width: 195px;
      object-fit: contain;
    }
  }

  @media(max-width:1200px){
    .card1{
      height: 440px;
    }

    .card1 .Cardimage {
    position: relative;
    right: 90%;
    top: -1%;
    }

     .card2 .Cardimage {
      margin-top: 8%;
      margin-left: 9%;
      width: 203px;
      object-fit: contain;
    }
  }

  @media (max-width: 1024px) {
    .card {
      height: fit-content;
    }

    .card1 .Cardimage {
      position: relative !important;
      right: 91% !important;
      top: 5% !important;
    }

     .card2 .Cardimage {
      margin-top: 8%;
      margin-left: 9%;
      width: 215px;
      object-fit: contain;
    }
  }

  @media (max-width: 768px) {
    .card {
      border-radius: 15px;
      max-height: fit-content;
      margin-bottom: 1rem;
      font-size: 15.63px;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 426px) {
    .col-sm-9 {
      width: 75% !important;
    }

    .col-sm-3 {
      width: 25% !important;
    }

    .card {
      width: 360px;
      height: 240px;
      margin: auto;
      margin-bottom: 1.5rem;
      font-size: 11px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.97px;
    }

    .card-body {
      padding: 7px;
    }

    .card-title {
      font-size: 13px;
      padding: 0px;
      margin: 10px 0px 0px 0px;
      font-style: italic;
      font-weight: 700;
      line-height: 15.85px;
      letter-spacing: 0.973086416721344px;
      text-align: center;
    }

    ul {
      padding-left: 2rem;
    }

    .justat {
      font-size: 10px !important;
    }

    .Price {
      font-size: 20px !important;
      font-weight: 600;
      line-height: 24.38px;
      letter-spacing: 0.973086416721344px;
      text-align: center;
    }

    .ribbon {
      width: 94px !important;
      font-size: 9px !important;
      padding: 0px !important;
    }

    button {
      background-color: black;
      font-size: 9px;
      border-radius: 5px;
      padding: 5px 45px;
    }

    img {
      width: 148px;
    }

    .card1 .Cardimage {
      position: absolute !important;
      right: -10px !important;
      top: 68px !important;
    }

    .card2 .Cardimage {
      width: 115px;
      position: relative;
      right: 0px;
      top: 10px;
    }
  }

  @media (max-width: 376px) {
    .col-sm-9 {
      width: 75% !important;
    }

    .col-sm-3 {
      width: 25% !important;
    }

    .card {
      width: 336px;
      height: 232px;
      margin: auto;
      margin-bottom: 1.5rem;
      font-size: 10px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 0.97px;
    }

    .card-body {
      padding: 7px;
    }

    .card-title {
      font-size: 13px;
      padding: 0px;
      margin: 10px 0px 0px 0px;
      font-style: italic;
      font-weight: 700;
      line-height: 15.85px;
      letter-spacing: 0.973086416721344px;
      text-align: center;
    }

    ul {
      padding-left: 2rem;
    }

    .justat {
      font-size: 10px !important;
    }

    .Price {
      font-size: 20px !important;
      font-weight: 600;
      line-height: 24.38px;
      letter-spacing: 0.973086416721344px;
      text-align: center;
    }

    .ribbon {
      width: 94px !important;
      font-size: 9px !important;
      padding: 0px !important;
    }

    button {
      background-color: black;
      font-size: 9px;
      border-radius: 5px;
      padding: 5px 45px;
    }

    img {
      width: 148px;
    }

    .card1 .Cardimage {
      position: absolute !important;
      right: -6px !important;
      top: 59px !important;
    }

    .card2 .Cardimage {
      position: relative;
      right: 10px;
      top: 9px;
    }
  }
`;

const CollectionPackage = ({ header1, header2 }) => {
  const dispatch = useDispatch();
  const [getPremiumPackageData, setPremiumPackageData] = useState([]);
  const premiumPackageData = useSelector((state) => state.packages.data);
  console.log("All package data", premiumPackageData);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPackages());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (premiumPackageData && premiumPackageData.data) {
      // Filter the data to include only the items with the 'premium' attribute
      console.log("Data 1: ", premiumPackageData.data);
      const premiumData = Object.values(premiumPackageData.data).filter(
        (item) => item.premium === 1
      );
      // Slice the filtered data to include only the first two records
      const limitedData = premiumData.slice(0, 2);
      console.log("Premium package data: -->", limitedData);
      setPremiumPackageData(Object.values(limitedData));
    }
  }, [premiumPackageData]);

  return (
    <StyledContainer>
      <div className="container pt-5 mb-5">
        <div>
          <Header header1={header1} header2={header2} />
        </div>
        <div className="row pt-2 CardSectionList">
          {getPremiumPackageData &&
            getPremiumPackageData.map(
              (
                {
                  id,
                  title,
                  discount,
                  support,
                  visits,
                  walks,
                  spa,
                  meta_title,
                  meta_description,
                  price,
                  selling_price,
                  thumbnail_alt,
                },
                idx
              ) => (
                <div
                  key={idx}
                  className={`col-sm-12 col-md-6 col-lg-6 col-lg-6 CardSection${
                    idx + 1
                  }`}
                >
                  <div class={`card card${idx + 1}`}>
                    <div className="card-body">
                      <h5 className="card-title">{title}</h5>
                      <span class="ribbon">{discount}% Off</span>
                    </div>
                    <div className="card-body">
                      <div className={`row CardContent${idx + 1}`}>
                        <div className="col-sm-9 col-md-12 col-lg-8 col-xl-8">
                          <div>
                            <ul>
                              <li>{support}</li>
                              <li>{walks} Dog Walks</li>
                              <li>{visits} Vet Visits</li>
                              <li>{spa}Dog Spa</li>
                            </ul>
                          </div>
                          <div className="pricing">
                            <div className="">
                              <h6 className="justat">Just at</h6>
                              <h1 className="Price">Rs. {selling_price}/-</h1>
                              <h6
                                style={{ textDecorationLine: "line-through" }}
                              >
                                Rs.{price}/-
                              </h6>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="Buynow btn btn-dark"
                              >
                                Buy Now
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-3 d-md-none d-lg-block col-lg-4 col-xl-4">
                          <img
                            src={imagePaths[idx]}
                            className="Cardimage"
                            alt={thumbnail_alt}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
        {/* <div className="row pt-2 CardSectionList">
          <div className="col-sm-12 col-md-6 col-lg-6 col-lg-6 CardSection1">
            <div className="card card1">
              <div className="card-body">
                <h5 className="card-title">Customised Package for Husky</h5>
                <span class="ribbon">25% Off</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-9 col-md-12 col-lg-8 col-xl-8">
                    <div>
                      <ul>
                        <li>Spa and Bath with Organic Kit</li>
                        <li>Daily Walks</li>
                        <li>Grooming Twice Monthly</li>
                        <li>Playing with Healthy Toys</li>
                        <li>Special Training</li>
                      </ul>
                    </div>
                    <div className="pricing">
                      <div className="">
                        <h6 className="justat">Just at</h6>
                        <h1 className="Price">Rs. 1,500/-</h1>
                        <h6 style={{ textDecorationLine: "line-through" }}>
                          Rs.2,500
                        </h6>
                      </div>
                      <div>
                        <button type="button" className="Buynow btn btn-dark">
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 d-md-none d-lg-block col-lg-4 col-xl-4">
                    <img src={husky} className="huskyImage" alt="Husky Image" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-lg-6 CardSection2">
            <div className="card card2">
              <div className="card-body">
                <h5 className="card-title">Customised Package for Chow Chow</h5>
                <span class="ribbon">20% off</span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-3 d-md-none d-lg-block col-lg-4 col-xl-4">
                    <img
                      src={chowchow}
                      className="chowchowimg"
                      alt="Chow Chow Pet Image"
                    />
                  </div>
                  <div className="col-sm-9 col-md-12 col-lg-8 col-xl-8">
                    <div>
                      <ul>
                        <li>Spa and Bath with Organic Kit</li>
                        <li>Daily Walks</li>
                        <li>Grooming Twice Monthly</li>
                        <li>Playing with Healthy Toys</li>
                        <li>Special Training</li>
                      </ul>
                    </div>
                    <div className="pricing">
                      <div className="">
                        <h6 className="justat">Just at</h6>
                        <h1 className="Price">Rs. 3,000/-</h1>
                        <h6 style={{ textDecorationLine: "line-through" }}>
                          Rs.5,000
                        </h6>
                      </div>
                      <div>
                        <button type="button" className="Buynow btn btn-dark">
                          Buy Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </StyledContainer>
  );
};

export default CollectionPackage;
