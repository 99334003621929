import React from 'react'

function TermsConditions() {
  return (
    <div className='container'>
    <div>
      <h1 className='text-center pb-3'>Merchant Terms and Conditions</h1>
      <p>
        This document is an electronic record in terms of Information Technology Act, 2000 and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.
      </p>
      <p>
        This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the Website.
      </p>
      <p>
        These Merchant Terms and Conditions along with the Terms of Use, Privacy Policy and Disclaimer (“Terms”) provided on the Website (as defined below) govern your use of the websites, content, software and services offered on the Website, i.e. <a href="http://www.petside.in">www.petside.in</a>
      </p>
      <p>
        These Terms govern your use of this Website; by using this Website, you accept these Terms in full. If you disagree with these Terms or any part of these Terms, you must not use this Website.
      </p>
      <p>
        The term PETSIDE or “the Company” 'us' or 'we' refers to the owner of the Website whose registered office is R-5/11, Goverdhan Giri, Bangur Nagar, Goregaon West. The term 'you' or ‘Merchant’ refers to the seller and/or vendor using this Website to sell his/her products and/or services through this Website. The use of this Website is subject to the following terms of use:
      </p>
      <p>
        Please review these Terms carefully before using the Services because they affect your rights. By using any of the Services, you accept these Terms and agree to be legally bound by them.
      </p>
      <h2 className='pt-3 pb-3'>1. Alterations to the Terms of Use:</h2>
      <p>
        We may change these Terms at any time without notice. You can review the most current version of these terms by clicking on the "Merchant Terms and Conditions" hypertext link located at the bottom of our web page on the Website. You are responsible for checking these Terms for changes periodically. If you continue to use the Website after we post changes to these Terms, you are signifying your acceptance of the updated Terms.
      </p>
      <h2 className='pt-3 pb-3'>2. Right to Change:</h2>
      <p>
        We reserve the right to discontinue or change any service or feature on the Website at any time and without notice.
      </p>
      <h2 className='pt-3 pb-3'>3. Your Responsibilities/Liabilities:</h2>
      <p>
        You may use the Website for lawful purposes only. You may not submit or transmit through the Website any material, or otherwise engage in any conduct that:
      </p>
      <ul>
        <li>i. violates or infringes the rights of others including, without limitation, patent, trademark, trade secret, copyright, publicity or other proprietary rights;</li>
        <li>ii. is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another's privacy, tortious, or contains explicit or graphic descriptions, or accounts of, sexual acts;</li>
        <li>iii. victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</li>
        <li>iv. impersonates any person, business or entity, including the Company and its employees and agents;</li>
        <li>v. contains viruses or any other computer code, files or programs that interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment, or otherwise permit the unauthorized use of a computer or computer network;</li>
        <li>vi. encourages conduct that would constitute a criminal offense, or that gives rise to civil liability;</li>
        <li>vii. Violates these Terms of Use, guidelines or any policy posted on Website, or interferes with the use of the Website by others.</li>
      </ul>
      <p>
        viii. You may not use the Website in any manner that could damage, disable, overburden, or impair our servers or networks, or interfere with any other party's use and enjoyment of Website. You may not attempt to gain unauthorized access to any services, user/Merchant accounts, computer systems or networks, through hacking, password mining or any other means. We may take any legal and technical remedies to prevent the violation of this provision and to enforce the Terms.
      </p>
      <h2 className='pt-3 pb-3'>4. SPAM:</h2>
      <p>
        You may not use the Website or any of our communication tools to transmit, directly or indirectly, any unsolicited bulk communications (including but not limited to e-mails and instant messages). You may not harvest information about our users for the purpose of sending, or to facilitate the sending, of unsolicited bulk communications. You may not induce or allow others to use the Website to violate the terms of this section. We may terminate your access or use of the Website immediately and take any other legal action if you, or anyone using your access to the Website violates these provisions. We may take any technical remedies to prevent unsolicited bulk communications from entering, utilizing, or remaining within our computer or communications networks.
      </p>
      <h2 className='pt-3 pb-3'>5. PROPRIETARY RIGHTS</h2>
      <p>
        a. We, our suppliers, and our users who lawfully post text, messages, information, software, images, audio and video, etc. ("Content") on the Website own the property rights to that Content. The Content is protected by international treaties, and by copyright, trademark, patent, and trade secret laws and other proprietary rights. For example, we own a copyright in the selection, organization, arrangement, and enhancement of the Content, as well as in our original Content. The look and feel of our colour combinations, button shapes, and other graphical elements on the Website are our trademarks.
      </p>
      <p>
        b. This Website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics and its reproduction is prohibited.
      </p>
      <h2 className='pt-3 pb-3'>6. LICENSE TO USE</h2>
      <p>
        a. Unless otherwise stated, the Company and/or its licensors own the intellectual property rights in the Website and material on the Website. Subject to the license below, all these intellectual property rights are reserved. You may view, download for caching purposes only, and print pages from the Website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
      </p>
      <p>
        b. You must not:
      </p>
      <ul>
        <li>i. republish material from this Website (including republication on another website);</li>
        <li>ii. sell, rent or sub-license material from the Website;</li>
        <li>iii. show any material from the Website in public;</li>
        <li>iv. reproduce, duplicate, copy or otherwise exploit material on this Website for a commercial purpose;</li>
        <li>v. edit or otherwise modify any material on the Website; or</li>
        <li>vi. redistribute material from this Website except for content specifically and expressly made available for redistribution.</li>
      </ul>
    </div>
    </div>
  )
}

export default TermsConditions