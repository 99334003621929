import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeliveryAddressById, updateUserDeliveryAddressById } from "../../features/web/web-actions";
import { useParams, useNavigate } from "react-router-dom";  // import useNavigate
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const StyledContainer = styled.div`
  .card {
    overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  
  }

  h6 {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .custom_btn {
    background: #114c5f;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s;
    display: block;
    width: 100%;
  }

  .custom_btn:hover {
    background: #0a3b43;
  }

  .error-message {
    color: #f44336;
    margin-top: 0.5rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 1em;
  }

  @media (max-width: 768px) {
    .card{
      margin:0rem;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }

    .hamburger {
      display: block;
    }
  }
`;

function EditAddress() {
  const initialFormData = {
    city: "",
    state: "",
    country: "",
    type: "",
    address: "",
    landmark: "",
    house_no: "",
    zip: "",
  };

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();  // initialize useNavigate
  const [formData, setFormData] = useState(initialFormData);
  const getUserAddressById = useSelector((state) => state.deliveryAddressById.data || []);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchDeliveryAddressById(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (getUserAddressById && getUserAddressById.data) {
      setFormData(getUserAddressById.data);
    }
  }, [getUserAddressById]);

  const handleUserSubmit = (formData, id) => {
    return dispatch(updateUserDeliveryAddressById({
      body: formData,
      id: id,
    }));
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const handleSubmit = (formData, { setSubmitting, setErrors }) => {
    handleUserSubmit(formData, id)
      .then((resp) => {
        if (!resp.success) {
          if (resp.responseCode === 422) {
            const newErrors = {};
            Object.entries(resp.data).forEach(([key, value]) => {
              newErrors[key] = value[0];
            });
            setErrors(newErrors);
          }
          window.scrollTo(0, 0);
        } else {
          message.success("Address updated successfully");
          setSubmitting(false);
          navigate("/saved-address");
          // window.location.href = "/saved-address";
        }
      });
  };

  const validate = (values) => {
    const errors = {};

    if (!values.address) {
      errors.address = 'Address is Required';
    } else if (values.address.length < 2) {
      errors.address = 'Address name is too short';
    } else if (values.address.length > 150) {
      errors.address = 'Address name is too long (150 characters)';
    }

    if (!values.house_no) {
      errors.house_no = 'House Number is Required';
    } else if (values.house_no.length < 2) {
      errors.house_no = 'House number is too short';
    } else if (values.house_no.length > 50) {
      errors.house_no = 'House number is too long (50 characters)';
    }

    if (!values.zip) {
      errors.zip = 'PinCode is Required';
    } else if (values.zip.length !== 6) {
      errors.zip = 'PinCode length is 6 digits';
    }

    if (!values.landmark) {
      errors.landmark = 'Landmark is Required';
    } else if (values.landmark.length < 2) {
      errors.landmark = 'Landmark name is too short';
    } else if (values.landmark.length > 50) {
      errors.landmark = 'Landmark name is too long (50 characters)';
    }

    if (!values.city) {
      errors.city = 'City is Required';
    } else if (values.city.length < 2) {
      errors.city = 'City name is too short';
    } else if (values.city.length > 30) {
      errors.city = 'City name is too long (30 characters)';
    }

    if (!values.state) {
      errors.state = 'State is Required';
    } else if (values.state.length < 2) {
      errors.state = 'State name is too short';
    } else if (values.state.length > 30) {
      errors.state = 'State name is too long (30 characters)';
    }

    if (!values.country) {
      errors.country = 'Country is Required';
    } else if (values.country.length < 2) {
      errors.country = 'Country name is too short';
    } else if (values.country.length > 30) {
      errors.country = 'Country name is too long (30 characters)';
    }

    if (!values.type) {
      errors.type = 'Address Type is Required';
    } else if (values.type.length < 2) {
      errors.type = 'Type name is too short';
    } else if (values.type.length > 50) {
      errors.type = 'Type name is too long (50 characters)';
    }

    return errors;
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <StyledContainer>
      <div className="container-fluid">
        <Formik
          initialValues={formData}
          enableReinitialize={true}
          validate={validate}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors }) => (
            <div className="card">
              <div className="card-body">
                <Form>
                  <h2 className="text-center fw-bold pt-2">Edit Address</h2>
                  <h6>Address Type</h6>
                  <Field className="form-control" maxLength={50} type="text" required name="type" />
                  <ErrorMessage name="type" component="div" className="text-danger" />
                  <h6>House No</h6>
                  <Field className="form-control" maxLength={50} type="text" required name="house_no" />
                  <ErrorMessage name="house_no" component="div" className="text-danger" />
                  <h6>Address</h6>
                  <Field className="form-control" maxLength={150} type="text" required name="address" />
                  <ErrorMessage name="address" component="div" className="text-danger" />
                  <h6>Landmark</h6>
                  <Field className="form-control" maxLength={50} type="text" required name="landmark" />
                  <ErrorMessage name="landmark" component="div" className="text-danger" />
                  <h6>Pincode</h6>
                  <Field className="form-control" type="text" maxLength={6} required name="zip" onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                      e.preventDefault();
                    }
                  }} />
                  <ErrorMessage name="zip" component="div" className="text-danger" />
                  <h6>City</h6>
                  <Field className="form-control" maxLength={30} type="text" required name="city" />
                  <ErrorMessage name="city" component="div" className="text-danger" />
                  <h6>State</h6>
                  <Field className="form-control" maxLength={30} type="text" required name="state" />
                  <ErrorMessage name="state" component="div" className="text-danger" />
                  <h6>Country</h6>
                  <Field className="form-control" maxLength={30} type="text" required name="country" />
                  <ErrorMessage name="country" component="div" className="text-danger" />
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="custom_btn mt-4"
                  >
                    Continue
                  </button>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </StyledContainer>
  );
}

export default EditAddress;
