import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import ig from "../../assets/images/footer/prime_instagram.png";
import fb from "../../assets/images/footer/ic_round-facebook.png";
import yt from "../../assets/images/footer/mingcute_youtube-line.png";
import provider from "../../assets/images/footer/animal-sanctuary_6581981 1.png";
import logo from "../../assets/images/petside-logo.png";
import arrow from "../../assets/images/footer/Vectorright-arrow.svg";
import img1 from "../../assets/images/footer/image 19.png";
import img2 from "../../assets/images/footer/image 20.png";
import img3 from "../../assets/images/footer/image 21.png";
import img4 from "../../assets/images/footer/image 22.png";
import { createEnquiry, OfferSignup } from "../../features/web/web-actions";
import { message } from "antd";
import service from "../../pages/services";
import CallingButtons from "../Common/CallingButtons";

const StyledContainer = styled.div`
  background: #f6fafa;
  padding: 40px 0px 10px 0px;
  margin-top: 2em;

  .row div ul li {
    padding: 5px;
  }
  .dropdown-content ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  .logo_head {
    float: left;
  }
  .mail_col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .mail_col h2 {
    font-size: 30px;
    font-weight: 700;
    padding-bottom: 0.8em;
  }
  .footer-mail {
    display: flex;
    justify-content: center;
    align-items: start;
    background: #f6fafa;
    padding: 20px;
  }
  .footer_part2 {
    padding-top: 3%;
    margin-top: 1%;
    margin-bottom: -3%;
  }
  .footer-mail button {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0px;
    width: 100px;
  }
  .footer-mail input {
    margin: 10px;
  }
  .footer_part2 {
    padding: 2em 0px;
  }
  p {
    font-weight: 600 !important;
    color: #000 !important;
  }
  .footer-bottom-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .social_icon {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2em;
  }
  .footer_part2 h6 {
    font-size: 16px;
    font-weight: 700;
  }
  .footer_part2 p {
    font-size: 16px;
  }
  .boarding a {
    color: #008eb4 !important;
  }
  .margins {
    margin-top: -7%;
    margin-bottom: 4%;
  }
  .provider a {
    margin: 5px;
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
  }
  .footer_payment h6 {
    font-size: 16px;
    font-weight: 700;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .container {
      max-width: 95%;
    }
    .payment {
      width: 90%;
    }
  }
  @media (min-width: 871px) and (max-width: 1024px) {
    .container {
      max-width: 90%;
    }
    .payment {
      width: 95%;
    }
    .footer-bottom-row {
      font-size: 13px;
    }

    footer_part2 {
      padding: 1em 0px;
      margin-left: 3%;
    }

    .footer_part2 .col-md-6 {
      width: 40%;
    }
    .footer_part2 .col-md-3 {
      width: 30%;
      font-size: 11px;
    }
  }
  @media (min-width: 769px) and (max-width: 870px) {
    .container {
      max-width: 90%;
    }
    .payment {
      width: 95%;
    }
    .footer-bottom-row {
      font-size: 13px;
    }
    .footer_part2 .col-md-6 {
      width: 40%;
    }
    .footer_part2 .col-md-3 {
      width: 30%;
      font-size: 11px;
    }
  }
  @media (min-width: 641px) and (max-width: 768px) {
    .footer_part2 .col-md-6 {
      width: 30%;
    }
    .footer_part2 .col-md-3 {
      width: 34%;
      font-size: 11px;
    }
  }

  @media (min-width: 541px) and (max-width: 640px) {
    .footer_part2 .col-md-6 {
      width: 40%;
    }

    .social_icon {
      display: flex;
      align-items: center;
      // gap: 2em;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      padding: 4% 0;
    }

    .boarding {
      padding: 5% 0;
    }

    .margins {
      margin-top: 0%;
      margin-bottom: 5%;
    }

    .footer-bottom-row {
      font-size: 10px;
    }

    .footer_part2 {
      text-align: center;
    }

    .footer_part2 .col-md-3 {
      width: 30%;
      font-size: 11px;
    }
  }

  @media (min-width: 376px) and (max-width: 540px) {
    .social_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 2em;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-around;
      padding: 4% 0;
    }

    .boarding {
      padding: 5% 0;
    }

    .margins {
      margin-top: 0%;
      margin-bottom: 5%;
    }

    .footer-bottom-row {
      font-size: 10px;
    }

    .footer_part2 {
      text-align: center;
    }
    .payment {
      width: 355px;
    }
  }

  @media (max-width: 540px) {
    .social_icon {
      display: flex;
      align-items: center;
      // gap: 2em;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      padding: 4% 0;
    }

    .boarding {
      padding: 5% 0;
    }

    .margins {
      margin-top: 0%;
      margin-bottom: 5%;
    }

    .secured {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: center;
    }
    .footer-bottom-row {
      font-size: 9px;
      font-size: 9px;
      padding: 1% 0;
      text-align: center;
      justify-content: center;

      text-align: center;
      justify-content: center;
    }

    .footer_payment h6 {
      text-align: center;
    }

    .footer_part2 {
      text-align: center;
    }
    .payment {
      width: 355px;
    }

    .footer-mail {
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }
    .footer-mail button {
      width: 100%;
      margin-top: 10px;
    }
    .footer-mail input {
      width: calc(100% - 20px);
    }
    .mail_col h2 {
      font-size: 25px !important;
      padding: 0;
      margin: 0;
    }
    .footer-mail a {
      padding: 9px 12px;
      width: 100%;
    }
    .payment {
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    .provider {
      padding: 0rem;
      margin: 0rem;
    }
    .col-md-6 h6 img {
      width: 355px;
    }
  }

  @media (max-width: 768px) {
    .dropdown-content {
      text-align: center;
      padding: 1.5em 0px;
    }
    .dropdown-content ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }
  }
`;

function Footer() {
  const domain = window.location.hostname;
  let providerLoginLink;
  if (domain === 'petside.in') {
    providerLoginLink = "https://provider.petside.in/provider/auth/login";
  } else {
    providerLoginLink = "https://provider.petside.in/provider/auth/login";
  }

  const arrowstyle = {
    height: "8px",
    marginLeft: "11px",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Required")
      .max(40, "Invalid Name!.")
      .min(2, "Invalid Name!."),
    contact_no: Yup.string()
      .required("Required")
      .matches(/^[1-9]\d{9}$/, "Invalid Mobile Number!."),
    email: Yup.string()
      .email("Invalid Email Address!.")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid Email Address!.')
      .required("Required"),
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log("Form data:", values);
      const response = await OfferSignup({ ...values });
      if (response.success) {
        message.success(
          "Your inquiry was successfully submitted. Our Experts Will Get In Touch With You!"
        );
        resetForm();
        setIsSubmitted(true);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <StyledContainer>
      {/* <CallingButtons/>  */}
      <div className="d-none d-lg-block remove-print">
        <div className="container">
          <div className="row footer_mail_row">
            <div className="col-md-12 mail_col">
              <img
                src={logo}
                alt="Logo"
                className="header_logo"
                width="130"
                height="55"
              />
              <h2>Join for special offers.</h2>

              <div className="footer-mail">
                <Formik
                  initialValues={{ name: "", email: "", contact_no: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, errors, touched }) => (
                    <Form>
                      <div className="footer-mail">
                        <div className="mb-3">
                          <div className="col-md-11">
                            <Field
                              className="form-control"
                              type="text"
                              name="name" maxLength={40}
                              placeholder="Enter Your Name"
                            />
                            {errors.name && touched.name ? (
                              <div className="error text-danger" style={{"margin-left": "1rem"}}>{errors.name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="col-md-11">
                            <Field
                              className="form-control"
                              type="text" maxlength={10}
                              name="contact_no"
                              placeholder="Enter Your Mobile No"
                              onKeyDown={(e) => {
                                if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                    e.preventDefault();
                                }
                            }}
                            />
                            {errors.contact_no && touched.contact_no ? (
                              <div className="error text-danger" style={{"margin-left": "1rem"}}>{errors.contact_no}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="col-md-11">
                            <Field
                              className="form-control"
                              type="email"
                              name="email" maxLength={40}
                              placeholder="Enter Your email address"
                            />
                            {errors.email && touched.email ? (
                              <div className="error text-danger" style={{"margin-left": "1rem"}}>{errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-2 align-items-center text-align-center">
                          <div className="col-md-11">
                            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="row footer_part2">
              <div className="col-lg-3 col-md-4">
                <div className="boarding pb-3">
                  <h6>Contact Us</h6>
                  <a href="tel:+919136776162" className="text-dark" title="Call us at +91 9136776162">+91 91367 76162</a>
                </div>
                <h6>Follow on socials</h6>
                <div className="social_icon">
                  <a href="https://www.instagram.com/petside.in/">
                    <img src={ig} alt="instagram-icon" />
                  </a>
                  <a href="https://www.facebook.com/petsideindia/">
                    <img src={fb} alt="facebook-icon" />
                  </a>
                  <a href="https://www.youtube.com/@Petside">
                    <img src={yt} alt="youtube-icon" />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 ">
                {/* <p>Call Now</p> */}
                <div class="dropdown-content">
                  <h6>Services</h6>
                  <div className="d-flex justify-content-evenly">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                      <ul>
                        <li>
                          <a href="/service/grooming">
                            Grooming <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/service/pet-sitting">
                            Pet Sitting <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/service/dog-walking">
                            Dog Walking <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/service/dog-training">
                            Dog Training
                            <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/pet-insurance">
                            Pet Insurance
                            <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-7 col-md-7 col-sm-12">
                      <ul>
                        <li>
                          <a href="/service/home-boarding">
                            Home boarding <img src={arrow} style={arrowstyle} />
                          </a>{" "}
                        </li>
                        <li>
                          <a href="/service/daycare">
                            Daycare <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/service/drop-in-visit">
                            Drop In visit <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                        <li>
                          <a href="/service/therapy-sessions">
                            Corporate Therapy{" "}
                            <img src={arrow} style={arrowstyle} />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <ul>
                  <li><a href="/service/grooming">Grooming</a></li>
                  <li><a href="/service/pet-sitting">Pet Sitting</a></li>
                  <li><a href="/service/dog-walking">Dog Walking</a></li>
                  <li><a href="/service/dog-training">Dog Training</a></li>
                  <li><a href="/service/home-boarding">Home boarding</a></li>
                  <li><a href="/service/daycare">Daycare </a></li>
                  <li><a href="/service/drop-in-visit">Drop In visit </a></li>
                  <li><a href="/service/therapy-sessions">Therapy Sessions </a></li>
                </ul> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 text-center provider">
                <div className="margins">
                  <img src={provider} alt="" />
                  <h6 className="pb-3">Become a Provider</h6>
                  <a type="button" href="/provider">Register Here</a>
                  <a type="button" href={providerLoginLink}>Provider Login</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container footer_payment">
          <div className="row">
            <div className="col-md-6">
              <h6>We accept</h6>
              <img src={img1} className="payment" alt="" />
            </div>
            <div className="col-md-6">
              <h6>Secured</h6>
              <div className="secured">
                <img className="pe-3" src={img2} alt="" />
                <img className="pe-3" src={img3} alt="" />
                <img className="pe-3" src={img4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="container">
          <div className="row">
            <div className="col-md-6 footer-bottom-row">
              <p className="pt-2">
                {" "}
                Copyright &#169; PETSIDE INDIA PRIVATE LIMITED
                <br />
                All Right Reserverd
                {/* <br/> Crafted By<a href="https://www.theyellowstrawberry.com/" target="_blank">The Yellow Strawberry</a> */}
              </p>
            </div>
            <div className="col-md-6 footer-bottom-row">
              <a href="/services">Our Services</a>
              <a href="/about-us">About Us</a>
              <a href="/privacy-policy">Privacy Policy</a>
              <a href="/terms-conditions">Terms & Conditions</a>
              <a href="/contact-us">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
}

export default Footer;
