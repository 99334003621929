import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getBlogByslug, publishBlog } from '../../features/web/web-actions';
import { Helmet } from "react-helmet";

const StyledContainer = styled.div`
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StyledSection = styled.div`
  text-align: left;

  .blog-image {
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .article-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .article-content {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
  }

  @media screen and (max-width: 768px) {
    .article-title {
      font-size: 20px;
    }

    .article-content {
      font-size: 14px;
    }
  }
`;

const BlogDetail = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const { slug = '' } = useParams();
  const [id, setId] = useState('');

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        if (slug) {
          const response = await dispatch(getBlogByslug(slug));
          let blogId = response.payload.data.id;
          if (blogId) {
            setId(blogId.toString());
            setData(response.payload.data);
          }
        }
      } catch (error) {
        console.error('Error fetching blog data:', error);
      }
    };

    fetchBlogData();
  }, [dispatch, slug]);

  const navigate = useNavigate();

  const handlePublish = async () => {
    const response = await dispatch(publishBlog(id));
    const message = response.hasOwnProperty('error')
      ? response.payload
      : 'Blog updated successfully';
    alert(message).then(() => {
      navigate(`/blog/${slug}`);
    });
  };

  const { title, document_url, content, meta_title, meta_description, meta_canonical, meta_keyword, document_alt } = data;
  return (
    <StyledContainer>
      <StyledSection>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <link rel="canonical" href={meta_canonical} />
        <meta name="keywords" content={meta_keyword} />
      </Helmet> 
        <div className="container">
        <div style={{ textAlign: 'center' }}>
          <img className="blog-image" src={`${process.env.REACT_APP_API_BASE_URL}/images/${document_url}`} alt={document_alt} width={"100%"} style={{objectFit:"fill"}}/>
        </div>
        <div className="article-title">{title}</div>
        <div className="article-content" dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </StyledSection>
    </StyledContainer>
  );
};

export default BlogDetail;
