import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import ProfileSidebar from "./ProfileSidebar";
import styled from 'styled-components';
import Home from '../pages/homepage/home';

const StyledContainer = styled.div`
  .sidebar_div {
    display: flex;
  }
  .content {
    flex: 1;
  }
  @media (max-width: 768px) {
    .sidebar_div {
      flex-direction: column;
    }
  }
`;

const ProfileLayout = () => {
  const userInfo =
  localStorage.getItem("userInfo") !== "undefined"
    ? localStorage.getItem("userInfo")
    : null;
let user_id = null;
if (userInfo) {
  const userData = JSON.parse(userInfo);
  user_id = userData.id;
}
  const isAuthenticated = Boolean(user_id);
  if (!user_id) {
    localStorage.clear();
  }
  return (
    <StyledContainer>
      <header>
        <Header />
      </header>
      {isAuthenticated ?
      <div className="sidebar_div">
          <ProfileSidebar />
        <div className="content">
           <Outlet /> 
        </div>
      </div>
      
      
      : <Home />}
      <Footer />
    </StyledContainer>
  );
};

export default ProfileLayout;
