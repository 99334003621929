import React from 'react';
import styled, { keyframes } from 'styled-components';
import successImage from "../../assets/images/paymentSuccess.png";
import { FaPhoneAlt, FaHome } from 'react-icons/fa';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledContainer = styled.div`
  .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    padding: 20px;
    background: #ffffff;
    animation: ${fadeIn} 1s ease-in-out;
  }

  .success-container img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .success-container h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    color: #000000;
    font-weight: 700;
    margin-top: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  }

  .success-container p {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: #000000;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  .success-container .buttons {
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }

  .success-container .buttons-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: #ffffff;
    background-color: #000000;
    border: none;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .success-container .buttons-button:hover {
    background-color: #555555;
  }

  .success-container .buttons-button svg {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    .success-container h1 {
      font-size: 32px;
    }

    .success-container p {
      font-size: 20px;
    }

    .success-container .buttons-button {
      font-size: 16px;
      padding: 8px 16px;
      margin: 8px;
    }
  }

  @media (max-width: 480px) {
    .success-container h1 {
      font-size: 28px;
    }

    .success-container p {
      font-size: 18px;
    }

    .success-container .buttons-button {
      font-size: 14px;
      padding: 6px 12px;
      margin: 6px;
    }
  }
`;

const SuccessPage = () => {
  const contactUs = () => {
    window.location.href = "/contact-us";
  };

  const returnHome = () => {
    window.location.href = "/";
  };

  return (
    <StyledContainer>
      <div className="success-container">
        <img src={successImage} alt="Thank You Image" />
        <h1>THANK YOU!</h1>
        <p>Your Payment has been Confirmed.</p>
        <p>Your information has been sent to our pet counselor. We will reach you soon.</p>
        <div className="buttons">
          <button className="buttons-button" onClick={contactUs}>
            <FaPhoneAlt /> Contact Us
          </button>
          <button className="buttons-button" onClick={returnHome}>
            <FaHome /> Return Home
          </button>
        </div>
      </div>
    </StyledContainer>
  );
};

export default SuccessPage;
