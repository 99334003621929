import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { getServices } from "../../features/web/services-action";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Container, Card } from "react-bootstrap";
import ServiceModal from "./ServiceModal";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";

const StyledContainer = styled.div`
  .care_service {
    padding: 50px 0px 0px 10px;
  }
  .slick-slide {
    padding: 15px 15px;
  }

  .slick-list {
    padding: 0 -15px;
  }

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: none;
  }

  .vm_btn a {
    padding: 10px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
  }
  .care_service h2 {
    font-size: 30px;
    font-weight: 600;
    line-height: 0;
  }

  .care_service h3 {
    font-size: 35px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 25%;
    display: block;
    text-wrap: nowrap;
  }
  .slider_card {
    border: 2px solid #55c8db;
    flex-direction: column !important;
    border-radius: 5px;
    padding: 10px 0px;
  }
  .slider_card a {
    background: #000;
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 5px;
  }
  .slider_card p {
    font-weight: 700;
  }

  .care_service {
    padding: 50px 0 0 10px;
  }

  .slick-slide {
    padding: 15px 15px;
    transition: all 0.4s ease-in-out;
    transform: scale(0.8);
  }

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: cover;
  }

  .slick-center .slider_card {
    transform: scale(1.1);
  }

  .slick-center p {
    color: #fff;
  }

  .slider_card {
    border: 2px solid #55c8db;
    border-radius: 5px;
    padding: 10px 0;
    transition: transform 0.4s ease-in-out;
  }

  .slider_card p {
    font-weight: 700;
  }

  @media (max-width: 768px) {
    .slick-slide {
      padding: 10px 45px;
      width: 360px;
    }

    .vm_btn {
      padding-top: 3em;
    }
    .overlay {
      left: 0;
      padding-left: 2em;
    }
  }
  @media (max-width: 540px) {
    .care_service {
      padding: 30px 0 20px 10px;
    }
    .care_service p {
      font-size: 22px;
    }
    .care_service span {
      font-size: 28px;
      width: 12%;
    }
    .slider_card p {
      font-size: 14px;
    }
  }
`;

const ServicesSlider = () => {
  const dispatch = useDispatch();
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const servicesData = useSelector((state) => state.services.data?.rows || []);
  console.log("Service Data: ", servicesData);

  useEffect(() => {
    dispatch(getServices()).catch((error) => console.error("Error:", error));
  }, [dispatch]);

  const handleServiceSelection = (serviceId) => {
    setSelectedServiceId(serviceId);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <ArrowRightCircle />
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <ArrowLeftCircle />
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledContainer>
      <div className="container">
        <div className="care_service">
          <h2>We Offer</h2>
          <h3>CARE SERVICES</h3>
          <img src="" alt="" />
        </div>
        <div className="slider_content">
          <div className="slider_container">
            {servicesData && !!servicesData.length && (
              <Slider {...sliderSettings}>
                {servicesData.map(({ thumbnail, name, id }, idx) => (
                  <div
                    className="slider_card d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-2"
                    key={idx}
                  >
                    <p>{name}</p>
                    <img
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${thumbnail}`}
                      className="img"
                      width={100}
                      alt=""
                    />
                    <div onClick={() => handleServiceSelection(id)}>
                      <ServiceModal />
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        {selectedServiceId && <ServiceModal serviceId={selectedServiceId} />}
      </div>
    </StyledContainer>
  );
};

export default ServicesSlider;
