import React from "react";
import styled from "styled-components";
import img1 from "../../assets/images/blog-img.png";
import Accordion from "react-bootstrap/Accordion";

const StyledContainer = styled.div`
margin: 2em 0em;
  .sp_head h2 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #Fff;
    width: 16%;
    display: block;
    text-wrap: nowrap;
    margin-left: 2em;
    margin-bottom: 2em;
  }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #fff;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-item {
    color: var(--bs-accordion-color);
    background-color: #e2fbff;
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}
`;

function Faq() {
  return (
    <StyledContainer>
      <div className="container pt-2 pb-5">
        <div className="sp_head">
          <h2>FAQ’s</h2>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Q1. What training methods do you use?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.We primarily utilize positive reinforcement techniques, focusing on rewards and encouragement to shape desired behaviors.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Q2. How often should I groom my pet?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.The frequency of grooming depends on factors such as your pet's breed, coat type, and lifestyle. We can provide personalized recommendations based on your pet's needs.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Q3. Are your dog walkers trained and insured?</Accordion.Header>
            <Accordion.Body>
            <p>Ans. Yes, our dog walkers are trained in safe handling techniques and are fully insured to provide peace of mind for both you and your pet.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Q4. What types of pet care products do you offer?</Accordion.Header>
            <Accordion.Body>
            <p>Ans. We offer a wide range of pet care products including Pet Food, Pet Sanitizers, Paw Cleaners etc.</p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>Q5. What is the additional/free service you provide with the packages?</Accordion.Header>
            <Accordion.Body>
            <p>Ans.  We offer free meal plan & free routine schedule for your pet depending on which package you are choosing.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </StyledContainer>
  );
}

export default Faq;
