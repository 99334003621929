import styled from "styled-components";

const StyledContainer = styled.div`
  .text-head h2 {
    border-bottom: 5px solid #008db3;
    width: 10%;
    font-size: 35px;
    font-weight: BOLD;
  }
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: Arial;
  }

  .header {
    text-align: center;
    padding: 32px;
  }

  .row {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
  }

  /* Create four equal columns that sits next to each other */
  .column {
    -ms-flex: 25%; /* IE10 */
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }

  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }

  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      -ms-flex: 50%;
      flex: 50%;
      max-width: 50%;
    }
  }

  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -ms-flex: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
`;

const GallerySlide = ({ isCompact }) => {
  return (
    <StyledContainer>
      {!isCompact && (
        <section className="py-3 mt-5">
          <div className="pt-5 mt-5 mb-3 container">
            <div className="text-head">
              <h4>Our Pet Care</h4>
              <h2>Gallery</h2>
            </div>
            <div className="gallery">
              <div className="row">
                <div className="column">
                  <img
                    src="https://www.w3schools.com/w3images/wedding.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/rocks.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/falls2.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/nature.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/mist.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="column">
                  <img
                    src="https://www.w3schools.com/w3images/wedding.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/rocks.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/falls2.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/nature.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/mist.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="column">
                  <img
                    src="https://www.w3schools.com/w3images/wedding.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/rocks.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/falls2.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/nature.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/mist.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
                <div className="column">
                  <img
                    src="https://www.w3schools.com/w3images/wedding.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/rocks.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/falls2.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/nature.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/mist.jpg"
                    style={{ width: "100%" }}
                  />
                  <img
                    src="https://www.w3schools.com/w3images/paris.jpg"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </StyledContainer>
  );
};

export default GallerySlide;
