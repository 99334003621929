import { message } from 'antd';
import React, { useEffect } from 'react';

function Map({ onPolygonComplete }) {
  useEffect(() => {
    const initMap = () => {
      // Check if geolocation is supported
      if (navigator.geolocation) {
        // Get current location
        navigator.geolocation.getCurrentPosition(
          position => {
            const currentLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };

            const map = new window.google.maps.Map(document.getElementById("map"), {
              center: currentLocation,
              zoom: 15,
            });

            const drawingManager = new window.google.maps.drawing.DrawingManager({
              drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
              drawingControl: true,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                  window.google.maps.drawing.OverlayType.POLYGON,
                ],
              },
            });

            drawingManager.setMap(map);

            window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
              const path = polygon.getPath();
              const coordinates = [];
              path.forEach(function(latlng) {
                const lat = latlng.lat();
                const lng = latlng.lng();
                coordinates.push({ lat, lng });
              });
              message.success("Location Captured Successfully");
              onPolygonComplete(coordinates);
            });
          },
          error => {
            {
              const map = new window.google.maps.Map(document.getElementById("map"), {
                center: { lat: 19.0330, lng: 73.0297 },
                zoom: 15,
              });

              const drawingManager = new window.google.maps.drawing.DrawingManager({
                drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                  position: window.google.maps.ControlPosition.TOP_CENTER,
                  drawingModes: [
                    window.google.maps.drawing.OverlayType.POLYGON,
                  ],
                },
              });

              drawingManager.setMap(map);

              window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
                const path = polygon.getPath();
                const coordinates = [];
                path.forEach(function(latlng) {
                  const lat = latlng.lat();
                  const lng = latlng.lng();
                  coordinates.push({ lat, lng });
                });
                message.success("Location Captured Successfully");
                onPolygonComplete(coordinates);
              });
            }
          }
        );
      } else {
        const map = new window.google.maps.Map(document.getElementById("map"), {
          center: { lat: 19.0330, lng: 73.0297 },
          zoom: 15,
        });

        const drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON,
            ],
          },
        });

        drawingManager.setMap(map);

        window.google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
          const path = polygon.getPath();
          const coordinates = [];
          path.forEach(function(latlng) {
            const lat = latlng.lat();
            const lng = latlng.lng();
            coordinates.push({ lat, lng });
          });
          message.success("Location Captured Successfully");
          onPolygonComplete(coordinates);
        });
      }
    };

    if (!window.google) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=drawing&callback=initMap`;
      script.defer = true;
      script.async = true;
      window.initMap = initMap;
      document.body.appendChild(script);
    } else {
      initMap();
    }

    return () => {
      window.initMap = null;
    };
  }, [onPolygonComplete]); 

  return (
    <div id="map" style={{ height: '80vh', width: '100%' }} className='mb-5'></div>
  );
}


export default Map;
