import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  ApplyCoupon,
  RemoveCoupons,
  fetchAppliedCoupons,
  fetchDeliveryAddress,
  fetchServicesById,
  fetchUserById,
  servicesBooking,
  updateUserById,
} from "../../features/web/web-actions";

import styled from "styled-components";
// import productImg from "../../assets/images/services/dog-walking_3827145 1.png";
import { useLocation, useParams } from "react-router-dom";
import Login from "../Auth/Login";
import { Card } from "react-bootstrap";
import AddressModal from "../../components/Common/AddressModal";
import SlotModal from "../../components/Common/SlotModal";
import { useDispatch, useSelector } from "react-redux";
import UserBasicDetails from "../../components/Common/BasicDetailModal";
import { message } from "antd";
import { XCircle } from "react-bootstrap-icons";

const StyledContainer = styled.div`
  .booking_btn button {
    background: #114c5f;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0px;
  }
  label {
    padding: 15px 0px 10px 0px;
  }
  .coupon {
    margin: 5px;
    padding: 5px 10px;
    border: 1px dashed #114c5f;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    border-radius: 10px;
  }
  .close_btn {
    cursor: pointer;
  }
  .coupon_btn {
    background: #114c5f !important;
    padding: 4px 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    width: 30%;
  }
  .applied {
    background: #198754 !important;
  }
  input,
  textarea,
  .form-select {
    border: 1px solid #000;
    border-radius: 5px;
  }
  .services_details_row {
    padding: 2em 0px;
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .successMsg {
      border: 4px solid #114c5f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 4em;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
      color: #114c5f;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .card-footer {
    background: #000;
    color: #fff;
    font-weight: 700;
  }
  .your_cart_top {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-bottom: 20px;
  }
  .coupon_div {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    & button {
      background: #000;
    }
  }
  .sub_div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  label {
    font-weight: 600;
  }
  .tot_div {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    & p,
    span {
      font-size: 18px;
      font-weight: 700;
    }
  }
  p,
  span {
    font-weight: 600;
  }
  .del_col {
    display: flex;
    align-items: center;
    gap: 2em;
    border: 1px solid #000;
    max-width: 400px;
    padding-left: 2em;
    border-radius: 5px;
  }
  .cart_header h1 {
    font-size: 30px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 14%;
    display: block;
    text-wrap: nowrap;
  }
  .pay_with {
    display: flex;
    align-item: center;
    gap: 2em;
    & div {
      display: flex;
      gap: 10px;
    }
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & .successMsg {
      border: 4px solid #114c5f;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 4em;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
      color: #114c5f;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }
  .ck_login {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 1em;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 2em 0em;
    margin-top: 5em;
  }
  .contact_us_form {
    border-right: 1px solid grey;
  }
  .payment_card .card {
    margin-bottom: 15px;
  }
  .payment_card .card-body {
    display: flex;
    align-items: center;
    gap: 1em;
    & span {
      font-weight: normal;
    }
    & h6 {
      color: rgb(15, 15, 15);
      font-size: 14px;
      font-weight: 600;
    }
    & p {
      color: rgb(84, 84, 84);
      font-size: 14px;
      font-weight: 400;
    }
  }
  @media (max-width: 768px) {
    .msg_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & .successMsg {
        border: 4px solid #114c5f;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        padding: 1em 1em;
        gap: 0em;
      }
      & h2 {
        font-size: 18px;
        font-weight: 700;
        color: #114c5f;
      }
      & a {
        background: #114c5f;
        color: #fff !important;
        padding: 5px 15px;
        border-radius: 5px;
        margin: 0px;
      }
    }
  }
  .pay_btn {
    text-align: center; /* Centers text horizontally */
    line-height: 40px; /* Centers text vertically (when height is known) */
    background-color: #114c5f;
    border: 1px solid #ccc;
    height: 40px; /* Set height to enable vertical centering */
    padding: 0px;
    cursor: pointer;
    color: white; /* Text color set to white */
    font-weight: bold; /* Optional: bold text for emphasis */
    font-size: 16px;
  }
`;

function ServicesDetails() {
  const location = useLocation();
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let userContact = null;
  let isUserLoggedIn = null;
  let userDetail = null;
  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    isUserLoggedIn = userData.contact_no;
    userContact = userData.contact_no;
    userDetail = userData;
    user_id = userData.id;
  }
  const handleUserSubmit = (formData, userId) => {
    return updateUserById({
      body: formData,
      id: userId,
    }).then((resp) => {
      if (resp) {
        dispatch(fetchUserById(userId));
      }
      return resp;
    });
  };
  const { id } = useParams();
  const [fd, setFd] = useState([]);

  const PackageId = location.state.data.selectedCard.id;
  const name = location.state.data.selectedCard.title;
  const serviceName = location.state.data.selectedCard.service;
  const title = location.state.data.selectedCard.week_wise || location.state.data.selectedCard.title;
  const content = location.state.data.selectedCard.day_wise;
  const price = location.state.data.selectedCard.price || location.state.data.selectedCard.selling_price;
  const total = location.state.data.selectedCard.price || location.state.data.selectedCard.selling_price;
  const gst = location.state.data.selectedCard.gst || 0;
  const breed = location.state.data.formData.dog_breed;
  const size = location.state.data.formData.dog_size;
  const pet_age = location.state.data.formData.pet_age;
  const pet_sex = location.state.data.formData.pet_sex;
  const [step, setStep] = useState(1);
  const [slot, setSlot] = useState();
  const [getServices, setServices] = useState([]);
  const [getDelAdd, setDelAdd] = useState([]);
  const handleAddressSubmit = (addressData) => {
    message.success("Address created successfully");
    if (addressData) {
      dispatch(fetchDeliveryAddress(user_id));
    }
  };
  const handleSlotSubmit = (slotData) => {
    setSlot(slotData);
  };
  const dispatch = useDispatch();
  const getServicesById = useSelector((state) => state.servicesById.data || []);
  const couponData = useSelector((state) => state.coupons.data || {});  
  const getUser = useSelector((state) => state.userById.data || []);
  useEffect(() => {
    dispatch(fetchUserById(user_id));
  }, [dispatch, user_id]);
  useEffect(() => {
    if (getUser && getUser.data) {
      setFd(getUser.data);
    }
  }, [getUser]);

  useEffect(() => {
    dispatch(fetchAppliedCoupons());
  }, [dispatch]);

  console.log("couponData --- ", couponData);

  const applied = couponData.data || {};
  const couponApplied = applied.coupon_id || 0;
  console.log("couponApplied --- ", couponApplied);
  console.log("Type of applied:", typeof applied);

  // Accumulate the total of all applied coupons
  const accumulatedTotal = Object.keys(applied).reduce((acc, key) => {
    const coupon = applied;
    if (coupon) {
      // Check the type of the coupon
      console.log("coupon.coupon_type", coupon.coupon_type);
      if (coupon.coupon_type === "percentage") {
        console.log("Entered");
        // Calculate the percentage value based on product total
        const productTotal = parseFloat(price.replace(/,/g, ""));
        console.log("productTotal --- ", price.replace(/,/g, ""));
        console.log("Coupon Total ---- ", coupon.total);
        const discount = (
          productTotal *
          (parseFloat(coupon.total) / 100)
        ).toFixed(2); // Calculate the discount
        console.log("discount", discount);
        return discount;
      } else {
        // If it's a fixed amount coupon, add its total
        return parseFloat(coupon.total);
      }
    } else {
      return acc;
    }
  }, 0); // Start with a base value of 0

  const getDeliveryAddress = useSelector(
    (state) => state.deliveryAddress.data || []
  );

  useEffect(() => {
    dispatch(fetchUserById(user_id));
  }, [dispatch, user_id]);
  useEffect(() => {
    dispatch(fetchDeliveryAddress(user_id));
  }, [dispatch, user_id]);
  useEffect(() => {
    if (getDeliveryAddress && getDeliveryAddress.data) {
      setDelAdd(getDeliveryAddress.data.rows);
    }
  }, [getDeliveryAddress]);
  useEffect(() => {
    if (getServicesById && getServicesById.data) {
      setServices(getServicesById.data);
    }
  }, [getServicesById]);
  useEffect(() => {
    dispatch(fetchServicesById(id));
  }, [dispatch, id]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  const nextStep = () => {
    setStep(step + 1);
  };
  const navigate = useNavigate();
  const previousStep = () => {
    setStep(step - 1);
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formData = {
    service_id: id,
    location: "",
    time: "",
    no_of_dogs: "",
    date: "",
    contact_no: "",
    dog_walker_preference: "",
    additional_details: "",
    dog_details: "",
    address: "",
    house_no: "",
    landmark: "",
    date_slot: "",
    time_slot: "",
    quantity: "",
    price: "",
    serivice_day_type: "",
    serivice_week_type: "",
    service_week_id: 0,
    coupon_id: "",
  };

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    values.contact_no = userContact;
    values.date_slot = slot ? slot.selectedDate : null;
    values.time_slot = slot ? slot.selectedTime : null;
    values.quantity = 1;
    values.additional_details = getServices.name || name;
    values.serivice_week_type = title;
    values.serivice_day_type = content;
    values.price = price;
    values.total = total;
    values.dog_size = size;
    values.pet_age = pet_age;
    values.pet_sex = pet_sex;
    values.coupon_id = couponApplied;
    values.dog_details = breed;
    values.billing_name = fd ? fd.first_name : null;
    values.billing_tel = fd ? fd.contact_no : null;
    values.billing_email = fd ? fd.email : null;
    values.service_week_id = location.state.data.selectedCard.id;
    if (getDelAdd) {
      const selectedAddress = getDelAdd.find(
        (address) => address.selected_address === 1
      );
      if (parseFloat(accumulatedTotal) > 0) {
        values.total = Math.max(
          0,
          parseFloat(total) - parseFloat(accumulatedTotal)
        );
      }
      if (selectedAddress) {
        values.delivery_address_id = selectedAddress.id;
        values.billing_address = selectedAddress.address;
        values.billing_city = selectedAddress.city;
        values.billing_state = selectedAddress.state;
        values.billing_zip = selectedAddress.zip;
        values.billing_country = selectedAddress.country;
      }
    }
    servicesBooking({ ...values }).then((resp) => {
      if (!resp.success) {
        if (resp.responseCode === 422) {
          const newErrors = {};
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        if (resp.data.payLink) {
          const payLink = resp.data.payLink;
          if (payLink) {
            //window.open(payLink, "_blank");
            window.open(payLink, "_self");
          }
        } else {
          navigate("/success");
        }
        setIsSubmitted(true);
        resetForm();
      }
    });
    setSubmitting(false);
  };
  
  const [appliedCouponId, setAppliedCouponId] = useState(null);

  const applyCoupon = ({ coupon_code, service_id }) => {
    // Check if a coupon is already applied
    if (appliedCouponId === null) {
      // Dispatch Redux action to apply the coupon
      dispatch(ApplyCoupon({ coupon_code, service_id }))
        .unwrap()
        .then((response) => {
          // Provide user feedback on success
          message.success(response.message || 'Coupon has been applied successfully');
          // Set the applied coupon id in the state
          setAppliedCouponId(response.data.id);
          // Reload the page after a delay of 300 milliseconds
          setTimeout(() => {
            window.location.reload(true);
          }, 300);
        })
        .catch((error) => {
          // Provide user feedback on error
          message.error(error.message);
        });
    } else {
      // Inform the user that another coupon is already applied
      message.warning("Another coupon is already applied.");
    }
  };

  const removeCoupon = (id) => {
    // Dispatch Redux action to remove the coupon
    dispatch(RemoveCoupons(id));
    // Reset the appliedCouponId if the removed coupon is the one applied
    if (appliedCouponId === id) {
      setAppliedCouponId(null);
    }

    setTimeout(() => {
      window.location.reload(true);
    }, 300); // 10 milliseconds
  };

  let gstAmount = (price * parseFloat(gst)) / (100 + parseFloat(gst));
  let priceExcludingGst = price - gstAmount;

  return (
    <StyledContainer>
      {!isSubmitted && (
        <div className="container">
          {/* <h1 className="text-center">Services Details Page</h1> */}
          <div className="row services_details_row">
            <div className="cart_header">
              <h1>Checkout</h1>
            </div>
            <div className="col-md-7 contact_us_form">
              {isUserLoggedIn ? (
                <>
                  <Formik initialValues={formData} onSubmit={handleSubmit}>
                    {({ isSubmitting }) => (
                      <Form>
                        <>
                          <div className="payment_card">
                            <div>
                              <Card>
                                <Card.Body>
                                  <div>
                                    <span class="material-symbols-outlined">
                                      contact_mail
                                    </span>
                                  </div>
                                  <div>
                                    <h6>Send booking details to</h6>
                                    <p>+91 {userContact}</p>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                            <div>
                              <Card>
                                <Card.Body>
                                  <div>
                                    <span class="material-symbols-outlined">
                                      location_on
                                    </span>
                                  </div>
                                  <div>
                                    <h6>Basic Details</h6>
                                    {fd && (
                                      <>
                                        <p>
                                          {fd.first_name} - {fd.last_name}
                                        </p>
                                        <p>{fd.email} </p>
                                      </>
                                    )}
                                  </div>
                                </Card.Body>
                                <UserBasicDetails
                                  onSubmit={handleUserSubmit}
                                  userId={userDetail.id}
                                />
                              </Card>
                            </div>
                            <div>
                              <Card>
                                <Card.Body>
                                  <div>
                                    <span class="material-symbols-outlined">
                                      location_on
                                    </span>
                                  </div>
                                  <div>
                                    <h6>Address</h6>
                                    {getDelAdd &&
                                      getDelAdd
                                        .filter(
                                          (address) =>
                                            address.selected_address === 1
                                        )
                                        .map((address, index) => (
                                          <>
                                            <span>{address.type}</span>
                                            <p key={index}>
                                              {address.house_no} -
                                              {address.building_name}-
                                              {address.address} -
                                              {address.landmark}
                                            </p>
                                          </>
                                        ))}
                                  </div>
                                </Card.Body>
                                <AddressModal
                                  onAddressSubmit={handleAddressSubmit}
                                  getAddData={getDelAdd}
                                />
                              </Card>
                            </div>
                            <div>
                              <Card>
                                <Card.Body>
                                  <div>
                                    <span class="material-symbols-outlined">
                                      schedule
                                    </span>
                                  </div>
                                  <div>
                                    <h6>Slot</h6>
                                    {slot && (
                                      <p>
                                        {slot.selectedDate} - {slot.selectedTime}
                                      </p>
                                    )}
                                  </div>
                                </Card.Body>
                                <SlotModal
                                  onSlotSubmit={handleSlotSubmit}
                                  slotId={location.state.data.selectedCard.id}
                                />
                              </Card>
                            </div>
                            <div>
                              <Card>
                                <Card.Body>
                                  <div>
                                    <span class="material-symbols-outlined">
                                      payments
                                    </span>
                                  </div>
                                  <h6>Payment Method</h6>
                                </Card.Body>

                                {slot && getDelAdd.length > 0 && fd.email ? (
                                  <button className="custom_btn1">
                                    Pay Rs.{" "}
                                    {Object.keys(applied).length > 0 &&
                                      parseFloat(accumulatedTotal) > 0 && ( // Ensure there are coupons in the data
                                        <span>
                                          .{" "}
                                          {Math.max(
                                            0,
                                            parseFloat(total) -
                                              parseFloat(accumulatedTotal)
                                          ).toFixed(2)}
                                          /-
                                        </span>
                                      )}
                                    {/* {couponsData.every(coupon => !coupon.applied) && ( // If no coupons applied, show the original total */}
                                    {parseFloat(accumulatedTotal).toFixed(2) ==
                                      0 && (
                                      <span>
                                        {parseFloat(total).toFixed(2)}/-
                                      </span>
                                    )}
                                  </button>
                                ) : (
                                  <div className="pay_btn">
                                    Pay
                                    {Object.keys(applied).length > 0 &&
                                      parseFloat(accumulatedTotal) > 0 && ( // Ensure there are coupons in the data
                                        <span>
                                          .{" "}
                                          {Math.max(
                                            0,
                                            parseFloat(total) -
                                              parseFloat(accumulatedTotal)
                                          ).toFixed(2)}
                                          /-
                                        </span>
                                      )}
                                    {parseFloat(accumulatedTotal) == 0 && (
                                      <span>
                                        {parseFloat(total).toFixed(2)}/-
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Card>
                            </div>
                          </div>
                          <div className="booking_btn pt-4"></div>
                        </>
                      </Form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  <div className="ck_login">
                    <h6>Account</h6>
                    <p>To book the service, please login or sign up</p>
                    <Login />
                  </div>
                </>
              )}
            </div>
            {getServices && (
              <div className="col-md-5">
                <p className="pb-5">Your Cart</p>
                <div className="your_cart_top">
                  <div>
                    <img
                      width={80}
                      src={`${process.env.REACT_APP_API_BASE_URL}/images/${getServices.thumbnail}`}
                      alt=""
                    />
                  </div>
                  <div>
                    <p>
                      {getServices && getServices.name}{name} - {serviceName} - {content}
                    </p>
                    <p>Quantity - 1</p>
                    <p>Dog Breed - {breed}</p>
                    <p>Dog Size - {size}</p>
                    <span>Rs. {price}/-</span>
                  </div>
                </div>
                {/* <input
                    className="form-control"
                    type="text"
                    placeholder="Gift or discount code"
                    />
                  <button>Apply</button> */}
                <div className="coupon_div">
                  <div className="coupon-box">
                    <h6 className="fw-bold">Coupons</h6>
                  </div>
                  {Object.keys(applied).length > 0 ? (
                    <div key={applied.id} className="coupon">
                      <span>{applied.code}</span>
                      <XCircle
                        onClick={() => removeCoupon(applied.id)}
                        className="text-danger close_btn"
                      />
                    </div>
                  ) : (
                    <div className="coupon-box">
                      <div class="mb-4 row align-items-center">
                        <div class="col-md-9 col-lg-9">
                          <input
                            type="text"
                            className="form-control"
                            name="coupon_id"
                            id="coupon_name"
                            placeholder="Apply Coupon"
                          />
                          <p className="error"></p>
                        </div>
                        <div class="col-md-3 col-lg-3">
                          <button
                            className="btn custom_btn"
                            onClick={() =>
                              applyCoupon({
                                coupon_code:
                                  document.getElementById("coupon_name").value,
                                service_id: getServices.id || PackageId,
                              })
                            }
                            style={{ background: "#114C5F" }}
                            id="CouponSub"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <div className="sub_div">
                    <p>Subtotal</p>
                    <span>Rs. {priceExcludingGst.toFixed(2)}/-</span>
                  </div>
                  <div className="sub_div">
                    <p>Shipping</p>
                    <span>Rs. 00</span>
                  </div>
                  <div className="sub_div">
                    <p>GST({gst}%)</p>
                    <span>Rs. {parseFloat(gstAmount).toFixed(2)}</span>
                  </div>
                  <div className="sub_div">
                    <p>Coupons</p>
                    {parseFloat(accumulatedTotal) > 0 ? (
                      <span>Rs. - {accumulatedTotal}</span>
                    ) : (
                      <span>Rs. 00</span> // This is shown if there are no coupons
                    )}
                  </div>
                  <hr />
                  <div className="tot_div">
                    <p>Total</p>
                    {parseFloat(accumulatedTotal) > 0 ? (
                      <span>
                        Rs.{" "}
                        {Math.max(
                          0,
                          parseFloat(total) - parseFloat(accumulatedTotal)
                        ).toFixed(2)}
                        /-
                      </span>
                    ) : (
                      <span>Rs. {parseFloat(total).toFixed(2)}/-</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* {isSubmitted && (
        <div className="container msg_div">
          <div className="successMsg">
            <h2 className="text-center">Thank you for your booking!</h2>
            <a href="/services">Back</a>
          </div>
        </div>
      )} */}
    </StyledContainer>
  );
}

export default ServicesDetails;
