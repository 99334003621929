import React from 'react'
import styled from 'styled-components';
import ServicesDetails from './ServicesDetails'
import PetServices from '../homepage/pet_services'
import BlogSlider from "../Blogs/BlogSlider";
import ServicesSlider from './Services'
import SpecialPackage from './SpecialPackage'
import BlogList from '../Blogs/bloglist'
import Faq from '../Faq/Faq'

const BlogHeader = styled.h1`
  font-weight: 700;
  padding-top: 3rem;
  border-bottom: #008db3 6px solid;
  width: 40%;

  @media (max-width: 768px) {
    width: 80%;
    padding-top: 2rem;
    font-size: 35px;
    width: 75%;
  }
`;

function ServicesDetailsPage() {
  return (
    <div>
      <ServicesDetails />
        <PetServices header1="Best Selling" header2="SERVICES" />
        {/* <ServicesSlider/> */}
        {/* <SpecialPackage/> */}
        
       <BlogSlider header1="Our Blogs" header2="& Stories" />
        {/* <Faq/>  */}
    </div>
  )
}

export default ServicesDetailsPage