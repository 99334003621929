import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import ServiceModal from "../services/ServiceModal";
import TherapyServicePage from "../services/TherapyServicePage";

import banner1 from "../../assets/images/services/petside-home-banner-1.jpg";
import banner2 from "../../assets/images/services/Petside-home-banner-2.jpg";
import Grooming from "../../assets/images/banner-pet-grooming.jpg";
import PetSitting from "../../assets/images/banner-pet-sitting.jpg";
import HBanner from "../../assets/images/home-banner.PNG";

import HomeBanner1 from "../../assets/images/Home/HomeBanner.png";
import HomeBanner2 from "../../assets/images/Home/HomeBanner2.png";
import HomeBanner3 from "../../assets/images/Home/HomeBanner3.png";
import HomeBanner4 from "../../assets/images/Home/HomeBanner4.png";
import HomeBanner5 from "../../assets/images/Home/HomeBanner5.png";
import HomeBanner6 from "../../assets/images/Home/HomeBanner6.png";

const StyledContainer = styled.div`
    .carousel img{
        width : 100%;
        height: 550px;
    }

    .home-banner {
        padding-top: 1em;
        padding-left: 2em;
        padding-right: 10px;
        flex-direction: column-reverse;
    }
        
      // Home Banner:
    .HBanner1 img,
    .HBanner2 img,
    .HBanner3 img,
    .HBanner4 img,
    .HBanner5 img, 
    .HBanner6 img {
      width: 100%;
      height: 602px;
    }

    .HBanner1 .carousel-caption {
      position: absolute;
      bottom: 5em;
      right: auto;
      left: 4%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #fff;
      text-align: center;
      width: 55%;
    }

    .HBanner1 .carousel-caption h2 {
    font-family: 'Montserrat Alternates', sans-serif;
    font-size: 5vw;
    font-weight: 500;
    line-height: 6vw;
    letter-spacing: 2px;
    text-align: center;
    background: linear-gradient(90deg, #FFFFFF 0%, #000000 63%, #000000 100%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px black; 
    margin-bottom: 0.5vw;
    }

    .HBanner1 .carousel-caption h3 {
      font-family: Montserrat;
      font-size: 2.5vw;
      font-style: italic;
      font-weight: 600;
      line-height: 1vw;
      letter-spacing: 2px;
      // text-align: center;
      color: #fff;
      margin-bottom: 11vw;
    }

    .HBanner1 .carousel-caption h3 span {
      font-family: Montserrat;
    font-size: 3.5vw;
    font-style: italic;
    font-weight: 600;
    line-height: 7vw;
    letter-spacing: 2px;
    text-align: center;

    }

    .HBanner1 .carousel-caption .black_btn,
    .HBanner3 .carousel-caption .black_btn {
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 700;
    line-height: 3.5vw;
    letter-spacing: 2px;
    text-align: center;
    padding: 13px 52px;
    background: #fff;
    color: black !important;
    border-radius: 10px
  }

  .HBanner2 .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 5.5rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

  .HBanner2 .carousel-caption h2 {
    font-family: Montserrat Alternates;
    font-size: 3.5vw;
    font-weight: 700;
    line-height: 5.5vw;
    letter-spacing: 2px;
    text-align: center;
    color: black;
    margin-bottom: 0vw;
  }

  .HBanner2 .carousel-caption h3 {
    font-family: Montserrat Alternates;
    font-size: 2vw;
    font-weight: 700;
    line-height: 5vw;
    letter-spacing: 2px;
    text-align: center;
    color: black;
    margin-bottom: 6vw;
}

  .HBanner2 .carousel-caption .black_btn,
  .HBanner4 .carousel-caption .black_btn,
  .HBanner5 .carousel-caption .black_btn,
  .HBanner6 .carousel-caption .black_btn {
    font-family: Montserrat;
    font-size: 1.8vw;
    font-weight: 700;
    line-height: 4.5vw;
    letter-spacing: 2px;
    text-align: center;
    color: white !important;
    background: black;
    border-radius: 10px;
    padding: 13px 52px;
  }

  .HBanner3 .carousel-caption {
    position: absolute;
    bottom: 10rem;
    left: 52%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
    width: 45%;
}

  .HBanner3 .carousel-caption h2 {
    font-family: Montserrat Alternates;
    font-size: 3.6vw;
    font-weight: 700;
    line-height: 6.5vw;
    letter-spacing: 2px;
    text-align: center;
    color: white;
    margin-bottom: 2vw
  }

   .HBanner4 .carousel-caption {
      position: absolute;
      bottom: 7.5rem;
      right: -10%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #000000;
      text-align: center;
    }

  .HBanner4 .carousel-caption h2 {
    font-family: Montserrat Alternates;
    font-size: 2.8vw;
    font-weight: 700;
    line-height: 3.5vw;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 4vw;
  }

  .HBanner4 .carousel-caption p {
    font-family: "Alex Brush";
    font-size: 3vw;
    font-weight: 400;
    line-height: 4vw;
    letter-spacing: 2px;
    text-align: center;
    margin-bottom: 4vw;
  }


  .HBanner4 .carousel-caption h5 {
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 700;
    line-height: 3vw;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 4vw

  }

  .HBanner5 .carousel-caption {
    width:60%;
    position: absolute;
    right: -10%;
    bottom: 8.5rem;
    left: 37%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
  }

    .HBanner5 .carousel-caption h2,
    .HBanner6 .carousel-caption h2 {
    font-family: Montserrat Alternates;
    font-size: 3.5vw;
    font-weight: 700;
    line-height: 5.5vw;
    letter-spacing: 2px;
    text-align: center;
    color: black;
    margin-bottom: 3vw;
  }
  
  .HBanner6 .carousel-caption {
    width: 40%;
    bottom: 7.25rem;
    left: 58%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }

    .banner_body{
        padding-left: 0em
    }
    .hbc{
        padding-bottom: 25px
    }
    .care_service{
        padding: 50px 0px 40px 0px;
    }
    .carousel img{
        width : 100%;
        height: auto;
    }
    }


    @media (max-width: 1024px){
      .HBanner1 .carousel-caption {
        bottom: 2rem !important;
      }
      .HBanner2 .carousel-caption {
        bottom: 3.5rem !important;
      }

      .HBanner3 .carousel-caption {
        bottom: 5rem !important;
      }

      .HBanner4 .carousel-caption {
        bottom: 1.25rem !important;
      }
    }

    @media (max-width: 991px){
      .HBanner6 .carousel-caption {
        bottom: 4.25rem !important;
      }
  }

  @media (max-width: 768px){

    .HBanner1 img,
    .HBanner2 img,
    .HBanner3 img,
    .HBanner4 img,
    .HBanner5 img,
    .HBanner6 img {
      width: 100%;
      height: 280px !important;
    }

    .HBanner2 .carousel-caption {
        bottom: 1.5rem !important;
    }

    .HBanner4 .carousel-caption h2 {
      font-size: 2.5vw !important;
      margin-bottom: 4vw !important;
    }

    .HBanner5 .carousel-caption{
      bottom: 5.5rem !important;
    }

    .HBanner3 .carousel-caption{
      bottom: 4rem !important;
    }
  }

    @media (max-width: 540px){
        .carousel-caption {
            bottom: 0em;
            font-size: 7px;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            color: #fff;
            text-align: left;
        }
        .home-banner{
            margin-right: 0;
            margin-left: 0;
        }
        .home-banner img{
            height: auto;
            width: 100%;
        }
        .hbc {
            align-items: start;
            margin: 12px 0;
        }
        .HBanner5 .carousel-caption{
        bottom: 2.5rem !important;
        display:grid;
        }  

        .HBanner5 .carousel-caption .black_btn {
        font-size: 2vw;
        line-height: 4.5vw;
        letter-spacing: 2px;
        justify-self: center;
        border-radius: 5px !important;
        padding: 4px 18px !important;
      }

    }
    @media (max-width: 480px){

    .HBanner1 img,
    .HBanner2 img,
    .HBanner3 img,
    .HBanner4 img,
    .HBanner5 img,
    .HBanner6 img {
      width: 100%;
      height: 217px !important;
    }

      .HBanner1 .carousel-caption .black_btn,
      .HBanner2 .carousel-caption .black_btn,
      .HBanner3 .carousel-caption .black_btn,
      .HBanner4 .carousel-caption .black_btn,
      .HBanner5 .carousel-caption .black_btn,
      .HBanner6 .carousel-caption .black_btn {
        font-size: 10px !important;
        padding: 1.0vw 5.5vw !important;
        border-radius: 5px !important;
      }

      .HBanner6 .carousel-caption .black_btn {
        padding: 2vw 4vw !important;
        border-radius: 5px !important;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 10px;
      }

      .carousel-indicators [data-bs-target] {
        width: 10px;
        height: 3px;
      }

    }
    @media (max-width: 430px){
        .home-banner h2 {
            font-size: 24px;
            font-weight: 700;
        }
    }
    @media (max-width: 426px){
      .HBanner2 .carousel-caption {
        bottom: 4rem !important;
      }
      .HBanner2 .carousel-caption h2 {
        margin-bottom: 4vw !important;
      }
      .HBanner2 .carousel-caption h3 {
        margin-bottom: 4vw !important;
      }

      // .HBanner3 .carousel-caption {
      //   bottom: 4rem !important;
      // }

      .HBanner4 .carousel-caption {
        bottom: 2.25rem !important;
      }

      .HBanner4 .carousel-caption h2 {
        margin-bottom: 5vw !important;
      }
      .HBanner4 .carousel-caption p {
        margin-bottom: 5vw !important;
      }
    }
    @media (max-width: 320px){
           .home-banner h2 {
            font-size: 22px;
        }
    }

    @media (max-width: 414px), 
        (max-height: 896px) {
        .carousel-item {
          transition: transform 0.7s ease !important; 
        }
}
`;

function HomeBanner() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <StyledContainer>
      <div className="container-fluid p-0">
        <Carousel>
          <Carousel.Item interval={2500} className="HBanner1">
            <img src={HomeBanner1} alt="Home Banner 1" />
            <Carousel.Caption>
              <h2>PAWS & RELAX</h2>
              <h3>
                Get <span>35%</span> Off on Dog Care & Pet Sitting Services
              </h3>
              <a href="/services" className="black_btn">
              Book Now
              </a>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500} className="HBanner2">
            <img src={HomeBanner2} alt="Home Banner 2" />
            <Carousel.Caption>
              <h2>GET CUSTOMISED PACKAGES FOR YOUR FURRY FRIEND</h2>
              <h3>Checkout what all is included!</h3>
              <a href="/services" className="black_btn">
                Book Now
              </a>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500} className="HBanner3">
            <img src={HomeBanner3} alt="Home Banner 3" />
            <Carousel.Caption>
              <h2>
              Buy any service & get a pet sanitizer free
              </h2>
              <a href="/services" className="black_btn">
              Book Now
              </a>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500} className="HBanner4">
            <img src={HomeBanner4} alt="Home Banner 4" />
            <Carousel.Caption>
              <h2>Buy training services and get meal plan free</h2>
              <p>
                Limited Time Offer !! Only For Today !!
              </p>
              <a href="/services" className="black_btn">
              Book Now
              </a>
              <h5>Grab the Deal Now Before it’s too Late !!</h5>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500} className="HBanner5">
            <img src={HomeBanner5} alt="Home Banner 5" />
            <Carousel.Caption>
              <h2>WE OFFER CORPORATE THERAPY FOR YOUR PET</h2>
              <a href="/service/therapy-sessions" className="black_btn">
                Enquire Now
              </a>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={2500} className="HBanner6">
            <img src={HomeBanner6} alt="Home Banner 6" />
            <Carousel.Caption>
              <h2>GET YOUR PET INSURANCE EASILY NOW</h2>
              <a href="/pet-insurance" className="black_btn">
                Enquire Now
              </a>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </StyledContainer>
  );
}

export default HomeBanner;
