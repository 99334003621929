// ServicesDetailsPageSlug.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchServicesBySlug } from "../../features/web/web-actions";
import SpecialPackage from "./SpecialPackage";
import ServicesSlider from "./Services";
import Servicefaq from "../Faq/dynamicfaq";
import ServiceModal from "./ServiceModal";
import PetServices from "../homepage/pet_services";
import OfferSlider from "../homepage/offerslider";
import Package from "../homepage/package";
import ServiceDetailModal from "./ServiceDetailModal";
import { Helmet } from "react-helmet";
import ProductListingSlider from "../homepage/ProductListing";
import ServicePackage from "./servicepackage";

const StyledContainer = styled.div`
  .service_section{
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .article-content {
    line-height: 1.8;
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 30px;
  }
  .service_detail_btn {
    background: #000;
    width: 100%;
  }
  .article-content h2,
  .article-content h3 {
    color: #007bff;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .article-content h1{
    width:30%;
    border-bottom: 5px solid #12D2FF;
    margin-bottom: 30px;
    margin-left:10rem;
  }

  .article-content h3 {
    font-size: 1.5rem;
  }

  .article-content p {
    margin-bottom: 20px;
    text-align: left;
    display: flex;
    justify-content: center;
  }

  .article-content ul {
    margin: 10px 0;
    padding-left: 20px;
  }

  .article-content ul li {
    margin-bottom: 10px;
    list-style-type: disc;
  }

  .article-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
  }

  .article-content blockquote {
    font-style: italic;
    color: #555;
    border-left: 4px solid #007bff;
    padding-left: 15px;
    margin: 20px 0;
  }

  .article-content a {
    color: #007bff;
    text-decoration: underline;
  }

  .details_banner {
    position: relative;
    overflow: hidden;
    height: 602px;
  }

  .details_banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    // opacity: 0.7;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }

  .banner_content {
    width:550px;
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: black;
    // color: #071952;
    z-index: 0;
  }

  .details_banner:hover .banner_content {
    opacity: 1;
  }

  .banner_content h2 {
    font-family: Montserrat;
    font-size: 3vw;
    font-weight: 900;
    line-height: 4.2vw;
    letter-spacing: 2px;
    text-align: justified;
  }

  .banner_content h3 {
    width: 90%;
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 500;
    line-height: 2.3vw;
    letter-spacing: 2px;
    text-align: center;
    margin:auto;
  }
  .banner_content h4 {
    font-family: Montserrat;
    font-size: 2vw;
    font-weight: 500;
    line-height: 2.8vw;
    letter-spacing: 2px;
    text-align: justified;
  }
  .banner_content h5 {
    width: auto;
    font-family: Montserrat;
    text-align: justified;
    align-content: center;
  }
  .banner_content div s {
    font-family: Montserrat;
    font-size: 2.2vw;
    font-weight: 300;
    line-height: 2.8vw;
    letter-spacing: 2px;
    text-align: justified;
    align-content: center;
  }
  .banner_content div span {
    font-family: Montserrat;
    padding-left: 15px;
    font-size: 3.6vw;
    font-weight: 700;
    line-height: 4.2vw;
    letter-spacing: 2px;
    text-align: justified;
    align-content: center;
  }
  .banner_content h6 {
    font-family: Montserrat;
    font-size: 2.2vw;
    font-weight: 600;
    line-height: 2.8vw;
    letter-spacing: 2px;
    text-align: center;
  }

  .banner_content .black_btn {
    padding: 0.8vw 4vw;
    margin-bottom: 1vw;
    font-family: Montserrat;
    font-size: 1.5vw;
    font-weight: 700;
    line-height: 2.2vw;
    letter-spacing: 2px;
    text-align: center;
    color: white;
    background: black !important;
  }

  .black_btn:hover {
    background-color: #0056b3;
  }

  @keyframes slideInUp {
    from {
      transform: translateY(50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  // Service Card:

  .service_section .card {
    // width: 100% !important;
    // height: 65vh !important;
    min-height: 300px;
    align-content: center;
    align-item: center;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
  }

  .service_section .card .card-body {
    display: flex !important;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .service_section .card p {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.38px;
    margin-bottom: 15px;
    letter-spacing: 2px;
    text-align: justified;
  }

  .service_section .card h5 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    letter-spacing: 2px;
    text-align: justified;
  }

  .service_section .card .card-footer {
    background: none;
    border: none;
  }

  .service_section .card .black_btn {
    margin: 0;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    line-height: 30px;
    background: black;
  }

  // Slider Card:
  .slick-slide {
    padding: 15px 15px;
    transition: all 0.4s ease-in-out;
  }

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  @media(max-width: 1280px) {
    .banner_content {
    width: 550px;
    position: absolute;
    top: 45%;
    }

    .banner_content h3 {
    width: 80%;
    }
  }
  @media (max-width: 1024px) {
    .banner_content {
      position: absolute;
      top: 38%;
      left: 70%;
      width: 408px;
    }
  }

  @media (max-width: 860px) {
    .banner_content {
      position: absolute;
      top: 30%;
      left: 70%;
    }
  }

  @media (max-width: 768px) {
    .banner_content {
      width:auto;
      position: absolute;
      position: static;
      transform: translate(0) !important;
      padding-top: 2vw;
      background: #f4f1ea;
    }
    
    .banner_content h3{
      width: 90%;
    }

    .details_banner .banner_img {
      width: 100%;
      height: 280px;
    }

    .banner_content h2 {
      font-size: 5vw;
    }
    .banner_content h3 {
      font-size: 3.5vw;
    }
    .banner_content h4 {
      font-size: 3vw;
    }
    .banner_content h5 {
      font-size: 3vw;
      width: 100%;
      margin-top: 3vw;
      justify-content: center !important;
    }
    .banner_content h5 s {
      padding-right: 4vw;
    }
    .banner_content .black_btn {
      padding: 1.8vw 9vw;
      font-size: 3vw;
      margin: 0;
      margin-bottom: 3vw;
    }
    .banner_content h6 {
      font-size: 3vw;
    }

    .article-content {
      font-size: 1rem;
      padding: 0 10px;
    }

    .article-content h2 {
      font-size: 1.5rem;
    }

    .article-content h3 {
      font-size: 1.2rem;
    }

    .details_banner {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }

  @media (max-width: 480px) {
    .details_banner .banner_img {
      width: 100%;
      height: 217px;
    }

    .article-content {
      font-size: 0.9rem;
      padding: 0 5px;
    }

    .article-content h2 {
      font-size: 1.3rem;
    }

    .article-content h3 {
      font-size: 1rem;
    }

    .service_section .card {
      margin: auto;
      min-height: 275px;
    }

    .service_section .card p {
      font-family: Montserrat;
      font-size: 15px;
      line-height: 24.38px;
      text-align: center;
    }

    .service_section .card h5 {
      font-family: Montserrat;
      font-size: 15px;
      line-height: 24.38px;
      text-align: center;
    }

    .banner_content h3{
      width:100%;
    }
  }
`;

function ServicesDetailsPageSlug() {
  const { slug = "" } = useParams();
  const [servicesData, setServicesData] = useState({});
  const [minPriceRecord, setMinPriceRecord] = useState(null);
  const dispatch = useDispatch();
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCardSelect = (cardData) => {
    setSelectedCard(cardData);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const getServicesBySlug = useSelector((state) => state.servicesBySlug || []);
  useEffect(() => {
    const fetchServicesData = async () => {
      try {
        if (slug) {
          const response = await dispatch(fetchServicesBySlug(slug));
          const data = response.payload.data;
          setServicesData(data);
          const AllPriceRecord = data.SERVICESWEEKWISEPRICEs;

          if (Array.isArray(AllPriceRecord) && AllPriceRecord.length > 0) {
            const flattenedPriceRecords = AllPriceRecord.reduce(
              (acc, record) => {
                if (
                  record.price !== undefined &&
                  record.price !== null &&
                  record.price !== " "
                ) {
                  acc.push(record);
                } else {
                  ["large", "medium", "small"].forEach((size) => {
                    if (Array.isArray(record[size])) {
                      acc.push(
                        ...record[size].filter(
                          (item) =>
                            item.price !== undefined &&
                            item.price !== null &&
                            record.price !== " "
                        )
                      );
                    }
                  });
                }
                return acc;
              },
              []
            );

            if (flattenedPriceRecords.length > 0) {
              const minRecord = flattenedPriceRecords.reduce(
                (min, record) => (record.price < min.price ? record : min),
                flattenedPriceRecords[0]
              );
              setMinPriceRecord(minRecord);
              console.log("Minimum Price Record: ", minRecord);
            } else {
              setMinPriceRecord(null);
            }
          } else {
            setMinPriceRecord(null);
          }
        }
      } catch (error) {
        console.error("Error fetching services data:", error);
      }
    };

    fetchServicesData();
  }, [dispatch, slug]);

  useEffect(() => {
    if (minPriceRecord) {
      console.log("Minimum Record: ", minPriceRecord);
      console.log("Minimum Price: ", minPriceRecord.price);
      console.log("Minimum Spot Price: ", minPriceRecord.spot_price);
    }
  }, [minPriceRecord]);

  const {
    id,
    name,
    banner_header,
    banner_image,
    page_content,
    meta_title,
    meta_description,
    banner_alt,
    meta_canonical,
    meta_keyword,
  } = servicesData;

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "20px",
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "60px",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <StyledContainer>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
        <link rel="canonical" href={meta_canonical} />
        <meta name="keywords" content={meta_keyword} />
      </Helmet> 
      <div className="services_details_container">
        <div className="details_banner">
          <div className="banner_img">
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/images/${banner_image}`}
              alt={banner_alt}
            />
          </div>
          <div className="banner_content">
            <h2 className="mb-4">{name}</h2>
            <h3 className="mb-4">{banner_header}</h3>
            <h4 className="mb-2 ">
              Get it Now- <br /> Just at
            </h4>
            {minPriceRecord && (
              <div className="d-flex justify-content-center mb-4 w-70">
                <s>Rs. {minPriceRecord.spot_price}</s>
                <span>Rs. {minPriceRecord.price}/-</span>
              </div>
            )}

            {/* <h5 className="d-flex justify-content-around">
              <s>Rs.500</s>
              <span> Rs. 320/-</span>
            </h5> */}
            <ServiceModal className="mb-4" bgColor="#114c5f" serviceId={id} />
            <h6>Discount Coupons & Pricing Available !!</h6>
          </div>
        </div>
        <div className="container pt-5">
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: page_content }}
          ></div>
        </div>

        {/* Service Card */}
        {/* <div className="container service_section">
          <div className="Service_card">
            <Slider {...sliderSettings} className="mx-2">
              {servicesData.SERVICESWEEKWISEPRICEs &&
                servicesData.SERVICESWEEKWISEPRICEs.slice(0, 6).map(
                  (
                    {
                      id,
                      gst,
                      price,
                      spot_price,
                      dog_size,
                      day_wise,
                      week_wise,
                    },
                    idx
                  ) => (
                    <div
                      key={idx}
                      className="card d-flex justify-content-between"
                    >
                      <div className="card-body object-fit-contain">
                        <p className="card-text text-center">{week_wise}</p>
                        <h5 className="card-title">{day_wise}</h5>
                      </div>
                      <div className="card-footer">
                        <button
                          className="service_detail_btn"
                          onClick={() =>
                            handleCardSelect({
                              id,
                              gst,
                              price,
                              spot_price,
                              dog_size,
                              day_wise,
                              week_wise,
                            })
                          }
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  )
                )}
            </Slider>
          </div>
          {showModal && (
            <ServiceDetailModal
              selectedCard={selectedCard}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div> */}
      </div>
      {/* <ServicesSlider /> */}

      {/* <Package header1="Discounts On" header2="SUBSCRIPTION" /> */}
      <ServicePackage header1="Discounts On" header2="SUBSCRIPTIONS" slug={slug} />
      
      <ProductListingSlider header1="Pet" header2="PRODUCTS" />
      <PetServices header1="Explore" header2="OTHER SERVICES" />
      {/* <OfferSlider header1="Discount" header2="OFFERS" /> */}
      {/* <Faq slug={slug}/>  */}
      <Servicefaq slug={slug}/> 

      {/* Service Card */}
      {/* <div className="container service_section">
        <div className="Service_card">
          <Slider {...sliderSettings} className="mx-2">
            {servicesData.SERVICESWEEKWISEPRICEs &&
              servicesData.SERVICESWEEKWISEPRICEs.slice(0, 6).map(
                (
                  {
                    id,
                    gst,
                    price,
                    spot_price,
                    dog_size,
                    day_wise,
                    week_wise,
                  },
                  idx
                ) => (
                  <div
                    key={idx}
                    className="card d-flex justify-content-between"
                  >
                    <div className="card-body object-fit-contain">
                      <p className="card-text text-center">{week_wise}</p>
                      <h5 className="card-title">{day_wise}</h5>
                    </div>
                    <div className="card-footer">
                      <button
                        className="service_detail_btn"
                        onClick={() =>
                          handleCardSelect({
                            id,
                            gst,
                            price,
                            spot_price,
                            dog_size,
                            day_wise,
                            week_wise,
                          })
                        }
                      >
                        Select
                      </button>
                    </div>
                  </div>
                )
              )}
          </Slider>
        </div>
        {showModal && (
          <ServiceDetailModal
            selectedCard={selectedCard}
            showModal={showModal}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div> */}
    </StyledContainer>
  );
}

export default ServicesDetailsPageSlug;
