import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { ArrowLeftCircle, ArrowRightCircle } from "react-bootstrap-icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Card } from "react-bootstrap";

import slider1 from "../../assets/images/Credebility/logo 1 for scroll 3.png";
import slider2 from "../../assets/images/Credebility/logo 2 for scroll 3.png";
import slider3 from "../../assets/images/Credebility/logo 3 for scroll 3.png";
import slider4 from "../../assets/images/Credebility/logo 4 for scroll 3.png";

const StyledContainer = styled.div`
  padding-top: 1em;
  .image-container {
    position: relative;
    text-align: center;
    color: white;
    padding: 5px;
  }

  .image {
    display: block;
    width: 100%;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: baseline;
  }

  .overlay {
    & .boarding_middle {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding-bottom: 70%;
    }
    & h2 {
      color: #000;
      font-weight: 700;
    }
    & p {
      color: #000;
      font-weight: 500;
      text-align: left;
      text-wrap: balance;
      padding-right: 50%;
    }
  }
  .vm_btn a {
    padding: 10px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
  }

  .overlay a {
    padding: 10px;
    border: none;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
  }

  .care_service {
    padding: 50px 0px 10px 0px;
  }
  .slick-track {
    padding-bottom: 1em;
  }
  .care_service p {
    font-size: 28px;
    font-weight: 600;
    line-height: 0;
  }
  .care_service span {
    font-size: 36px;
    font-weight: 700;
    border-bottom: 5px solid #008db3;
    width: 16%;
    display: block;
    text-wrap: nowrap;
  }

  .slider_container {
    margin-top: 5vh;
  }

  .slider_card {
    border: 2px solid #55c8db;
    flex-direction: column !important;
    border-radius: 5px;
    padding: 10px 0px;
  }
  .slider_card a {
    background: #000;
    color: #fff !important;
    padding: 5px 15px;
    border-radius: 5px;
  }
  .slider_card p {
    font-weight: 700;
  }
  .slick-slide {
    width: 278px !important;
    padding: 0px 20px;
  }

  .slider_card {
    border: 2px solid #55c8db;
    flex-direction: column !important;
    border-radius: 5px;
    padding: 10px 0px;
    max-width: 30vw;
    height: 15vh;
  }

  // .slider_card {
  //   width: 30vw;
  // }
  .SliderImgdiv img {
    width: 9.5vw;
  }

  @media (max-width: 800px) {
    .SliderImgdiv img {
      width: 12vw;
    }
  }

  @media (max-width: 650px) {
    .slider_card {
      max-width: 50vw;
    }

    .SliderImgdiv img {
      width: 20vw;
    }
  }

  @media (max-width: 450px) {
    .SliderImgdiv img {
      width: 30vw;
    }
  }

  @media (max-width: 768px) {
    .care_service {
      padding: 50px 0px 40px 10px;
    }
    .vm_btn {
      padding-top: 3em;
    }
    .overlay {
      left: 0;
      padding-left: 2em;
    }
  }
  @media (max-width: 540px) {
    .care_service {
      padding: 30px 0 20px 10px;
    }
    .care_service p {
      font-size: 22px;
    }
    .care_service span {
      font-size: 28px;
      width: 12%;
    }
    .slider_card {
      width: 90% !important;
      padding: 5px 0;
      margin-bottom: 10px;
    }
    .slider_card p {
      font-size: 14px;
    }
  }
`;

// const settings = {
//   infinite: true,
//   speed: 500,
//   slidesToShow: 4, // Adjusted to display all four images on the same slide
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 2000,
//   nextArrow: (
//     <div>
//       <div className="next-slick-arrow">
//         <ArrowRightCircle />
//       </div>
//     </div>
//   ),
//   prevArrow: (
//     <div>
//       <div className="prev-slick-arrow">
//         <ArrowLeftCircle />
//       </div>
//     </div>
//   ),
//   responsive: [
//     {
//       breakpoint: 992,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1,
//       },
//     },
//   ],
// };

const Credebility = () => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
        nextArrow: (
        <div>
          <div className="next-slick-arrow">
            <ArrowRightCircle />
          </div>
        </div>
      ),
      prevArrow: (
        <div>
          <div className="prev-slick-arrow">
            <ArrowLeftCircle />
          </div>
        </div>
       ),
    responsive: [
      {
        breakpoint: 768, // Tablets and smaller desktops
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 520, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
          adaptiveHeight: true,
        },
      },
    ],
  };

  const servicesData = [slider1, slider2, slider3, slider4, slider1, slider2];

  return (
    <StyledContainer>
      <div className="container">
        <div className="care_service">
          <span>Trusted & Supported By</span>
        </div>
        <div className="slider_content">
          <div className="slider_container">
            <Slider {...sliderSettings}>
              {servicesData.map((image, index) => (
                <Card
                  className="SliderImgdiv slider_card d-flex flex-column flex-sm-row justify-content-around align-items-center cards-wrapper gap-4"
                  key={index}
                >
                  <img src={image} width={100} alt={`Image ${index + 1}`} />
                </Card>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </StyledContainer>
  );
};

export default Credebility;
