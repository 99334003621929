import styled from "styled-components";
//import NewsSearch from "./NewsSearch";
import Knowledge from "./Knowledge";
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
`;

const KnowledgeMain = () => {
  return (
    <BodyWrapper>
      {/* <NewsSearch /> */}
      {/* <TopTreatments /> */}
      <Knowledge />
    </BodyWrapper>
  );
};

export default KnowledgeMain;
