import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='container'>
      <div>
        <h1 className='text-center pb-3'>Privacy Policy</h1>
        <p style={{textAlign : "justify"}}>
          PetSide India ("we", "us", or "our") operates the website www.petside.in and provides pet care services in Mumbai and Pune. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit our website or use our services.
        </p>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>1. Information We Collect </h2>
          <p>
            We may collect personal information from you when you visit our website, register for our services, or communicate with us. The types of personal information we may collect include but are not limited to:
          </p>
          <br />
          <ul>
            <li>Name</li>
            <li>Contact information (email address, phone number)</li>
            <li>Address</li>
            <li>Pet information (pet's name, age, breed, medical history)</li>
            <li>Payment information</li>
          </ul>
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>2. How We Use Your Information</h2>
          <p>
            We may use the personal information we collect for the following purposes:
          </p>
          <br />
          <ul>
            <li>
              To provide and maintain our services
            </li>
            <li>To communicate with you regarding our services, updates, and promotions</li>
            <li>To process payments and invoices</li>
            <li>To personalize your experience and tailor our services to your preferences</li>
            <li>To improve our website and services</li>
            <li>To comply with legal obligations</li>
          </ul>
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>3. Information Sharing</h2>
          <p className='pb-2'>
            We may share your personal information with third-party service providers who assist us in operating our website and providing our services. These service providers are contractually obligated to keep your information confidential and secure.  
          </p>
          <p>
          We may also disclose your personal information if required by law or in response to a valid legal request.
          </p>
          <br />
        </div>
        
        <div className="pointers">
          <h2 className='pt-3 pb-3'>4. Data Security</h2>
          <p className=''>
          We take the security of your personal information seriously and implement appropriate measures to protect it from unauthorized access, alteration, disclosure, or destruction.
          </p>
          <br />
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>5. Cookies</h2>
          <p className='pb-2'>
            We may use cookies and similar tracking technologies to enhance your experience on our website. You have the option to disable cookies in your browser settings, but please note that some features of our website may not function properly as a result.
          </p>
          <br />
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>6. Your Rights</h2>
          <p className='pb-2'>
            You have the right to access, update, or delete your personal information at any time. If you would like to exercise any of these rights or have any questions about our Privacy Policy, please contact us at hello@petside.in
          </p>
          <br />
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>7. Changes to This Privacy Policy</h2>
          <p className='pb-2'>
            We reserve the right to update or revise this Privacy Policy at any time. Any changes will be posted on this page, and the updated Privacy Policy will indicate the date it was last revised.
          </p>
          <p>
            By using our website or services, you agree to the terms of this Privacy Policy.
          </p>
          <br />
        </div>
        <div className="pointers">
          <h2 className='pt-3 pb-3'>8. Contact Us</h2>
          <p className='pb-2'>
            If you have any questions or concerns about our Privacy Policy or our data practices, please contact us at hello@petside.in
          </p>
          <p>
            By using our website or services, you agree to the terms of this Privacy Policy.
          </p>
          <br />
        </div>
    </div>
    </div>
  )
}

export default PrivacyPolicy