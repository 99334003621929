import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { createPetProfile } from "../../features/web/web-actions";
import FileInput from "../../components/Common/FileInput";
import * as Yup from "yup";
import { Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const StyleContainer = styled.div`
  .card{
    overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .field_error {
    color: red;
  }
  .pet-profile-row {
    & label {
      padding: 2em 0px 0.5em 0px;
    }
    & h3 {
      text-align: center;
      font-size: 22px;
      font-weight: 700;
      padding-top: 1em;
    }
  }
  .pet_btn button {
    background: rgb(17, 76, 95);
    border-radius: 5px;
    color: rgb(255, 255, 255);
    padding: 5px 10px;
    margin-top: 7em;
  }
  .msg_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4em 0em;
    background-size: 100%;
    & .successMsg {
      border: 1px solid #000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      padding: 5em 7em;
      gap: 2em;
      background: #fff;
    }
    & h2 {
      font-size: 30px;
      font-weight: 700;
    }
    & a {
      background: #114c5f;
      color: #fff !important;
      padding: 10px 20px;
      border-radius: 5px;
      margin: 0px;
    }
  }

   .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin: 1em;
  }

  @media (max-width: 768px) {
    .card{
      margin:0rem;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }

    .hamburger {
      display: block;
    }

    .msg_div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4em 0em;
      background-size: 100%;
      & .successMsg {
        padding: 1em 1em;
        gap: 0em;
      }
      & h2 {
        font-size: 18px;
        font-weight: 700;
      }
      & h3 {
        font-size: 16px;
      }
      & a {
        background: #114c5f;
        color: #fff !important;
        padding: 3px 15px;
        border-radius: 5px;
        margin: 0px;
      }
    }
  }
`;

const PetProfileForm = ({ onStepChange }) => {
  const [step, setStep] = useState(1);
  const [getPolygonCoordinates, setPolygonCoordinates] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const formData = {
    name: "",
    profile_image: [],
    type: "",
    weight: "",
    age: "",
    sex: "",
    breed: "",
    userPetAdditionalDetails: [
      {
        microchipped: "",
        spayed_neutered: "",
        house_trained: "",
        friendly_with_childern: "",
        friendly_with_dogs: "",
        friendly_with_cats: "",
        adoption_care: "",
        about_your_pet: "",
      },
    ],
    userPetCareInfo: [
      {
        potty_break_schedule: "",
        engery_level: "",
        feeding_schedule: "",
        can_be_left_alone: "",
        medication: "",
        other_info: "",
      },
    ],
    userPetHealthInfo: [
      {
        veterinary_info: "",
        pet_insurance_provider: "0",
        pet_insurance_provider_name: "",
      },
    ],
    userPetPhotoGallery: [
      {
        image: null,
      },
    ],
  };

  const validationSchemaStep1 = Yup.object().shape({
  name: Yup.string().required("Pet name is required").max(40 ,"Invalid Name!.").min(2 ,"Invalid Name!."),
  age: Yup.string().required("Pet age is required").max(2, "Invalid Age!."),
  type: Yup.string().required("Pet type is required"),
  breed: Yup.string().required("Pet breed type is required"),
  sex: Yup.string().required("Pet gender is required"),

  userPetAdditionalDetails: Yup.array().of(
    Yup.object().shape({
      microchipped: Yup.string().required("Microchipped status is required"),
      spayed_neutered: Yup.string().required("Spayed/Neutered status is required"),
      house_trained: Yup.string().required("House trained status is required"),
      friendly_with_childern: Yup.string().required("Friendly with children status is required"),
      friendly_with_dogs: Yup.string().required("Friendly with dogs status is required"),
      friendly_with_cats: Yup.string().required("Friendly with cats status is required"),
      adoption_care: Yup.string().required("Adoption care is required"),
      about_your_pet: Yup.string().required("About your pet is required"),
    })
  ),

  userPetCareInfo: Yup.array().of(
    Yup.object().shape({
      potty_break_schedule: Yup.string().required("Potty break schedule is required"),
      engery_level: Yup.string().required("Energy level is required"),
      feeding_schedule: Yup.string().required("Feeding schedule is required"),
      can_be_left_alone: Yup.string().required("Can be left alone status is required"),
      medication: Yup.string().required("Medication information is required"),
      other_info: Yup.string().required("Other info is required").max(100, "Invalid Information!."),
    })
  ),

  userPetHealthInfo: Yup.array().of(
    Yup.object().shape({
      veterinary_info: Yup.string().required("Veterinary info is required").max(100, "Invalid Veterinary Information!."),
      pet_insurance_provider_name: Yup.string().required("Pet insurance provider is required").max(50, "Invalid Provider Name!."),
    })
  ),
});


  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    createPetProfile({ ...values }).then((resp) => {
      if (!resp.success) {
        if (resp.responseCode === 422) {
          const newErrors = {};
          Object.entries(resp.data).forEach(([key, value]) => {
            newErrors[key] = value[0];
          });
        }
        window.scrollTo(0, 0);
      } else {
        setIsSubmitted(true);
        resetForm();
        // navigate("../partner/thank-you");
      }
    });
    setSubmitting(false);
  };

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <StyleContainer>
      {!isSubmitted && (
        <div className="container-fluid">
          <div className="row ">
            <div className="pet-profile-row">
              <div className="col-md-12">
                <div className="">
                  <Formik
                    initialValues={formData}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchemaStep1}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                      setFieldValue,
                      dirty,
                      validateForm,
                      setTouched,
                    }) => (
                          <div className="card">
                            <div className="card-body">
                      <Form onSubmit={handleSubmit}>
                        <>
                          <h3 className="text-center">
                            Your Pet's Information
                          </h3>
                          <div>
                            <label htmlFor="">Profile Picture</label>
                            <FileInput
                              name={`profile_image`}
                              data={{
                                maincategory: "product",
                                subcategory: "images",
                              }}
                            />
                            <ErrorMessage name="profile_image" component="div" className="field_error" />
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label>Pet Name</label>
                              <Field
                                className="form-control"
                                type="text" maxLength={40}
                                name="name"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="name" component="div" className="field_error" />
                            </div>
                            <div className="col-md-6">
                              <label>Pet Age</label>
                              <Field
                                className="form-control col-md-6"
                                type="text" maxlength={2}
                                name="age"
                                onKeyDown={(e) => {
                                  if (!/[0-9]/.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
                                      e.preventDefault();
                                  }
                              }}
                                onChange={handleChange}
                              />
                              <ErrorMessage name="age" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Pet Type</label>
                              <Field
                                component="select"
                                name="type"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disable value="">
                                  Select{" "}
                                </option>
                                <option value="Dog">Dog</option>
                                <option value="Cat">Cat</option>
                              </Field>
                              <ErrorMessage name="type" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Pet Breed Type</label>
                              <Field
                                component="select"
                                name="breed"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Bull Dog">Bull Dog</option>
                              </Field>
                              <ErrorMessage name="breed" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Gender</label>
                              <Field
                                component="select"
                                name="sex"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </Field>
                              <ErrorMessage name="sex" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Microchipped ?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].microchipped"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].microchipped" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Spayed/Neutered?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].spayed_neutered"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].spayed_neutered" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>House trained?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].house_trained"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Unsure">Unsure</option>
                                <option value="Depends">Depends</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].house_trained" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Friendly With Children?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].friendly_with_childern"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Unsure">Unsure</option>
                                <option value="Depends">Depends</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].friendly_with_childern" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Friendly With Dogs?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].friendly_with_dogs"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Unsure">Unsure</option>
                                <option value="Depends">Depends</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].friendly_with_dogs" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Friendly With Cats?</label>
                              <Field
                                component="select"
                                name="userPetAdditionalDetails[0].friendly_with_cats"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Unsure">Unsure</option>
                                <option value="Depends">Depends</option>
                              </Field>
                              <ErrorMessage name="userPetAdditionalDetails[0].friendly_with_cats" component="div" className="field_error" />
                            </div>
                            <div className="col-md-6">
                              <label>Adoption Care</label>
                              <Field
                                className="form-control"
                                type="date"
                                name="userPetAdditionalDetails[0].adoption_care"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="userPetAdditionalDetails[0].adoption_care" component="div" className="field_error" />
                            </div>
                            <div className="col-md-6">
                              <label>About Your Pet</label>
                              <Field
                                className="form-control"
                                type="text"
                                name="userPetAdditionalDetails[0].about_your_pet"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="userPetAdditionalDetails[0].about_your_pet" component="div" className="field_error" />
                            </div>
                            <h3>User Pet Care Info</h3>
                            <div className="col-md-4">
                              <label>Potty Break Schedule?</label>
                              <Field
                                component="select"
                                name="userPetCareInfo[0].potty_break_schedule"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Every Hour">Every Hour</option>
                                <option value="2 Hour">2 Hour</option>
                                <option value="4 Hour">4 Hour</option>
                                <option value="8 Hour">8 Hour</option>
                              </Field>
                              <ErrorMessage name="userPetCareInfo[0].potty_break_schedule" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Energy level?</label>
                              <Field
                                component="select"
                                name="userPetCareInfo[0].engery_level"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="High">High</option>
                                <option value="Moderate">Moderate</option>
                                <option value="Low">Low</option>
                              </Field>
                              <ErrorMessage name="userPetCareInfo[0].engery_level" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Feeding Schedule?</label>
                              <Field
                                component="select"
                                name="userPetCareInfo[0].feeding_schedule"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Morning">Morning</option>
                                <option value="Twice A Day">Twice A Day</option>
                                <option value="Custom">Custom</option>
                              </Field>
                              <ErrorMessage name="userPetCareInfo[0].feeding_schedule" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Can Be Left Alone?</label>
                              <Field
                                component="select"
                                name="userPetCareInfo[0].can_be_left_alone"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Field>
                              <ErrorMessage name="userPetCareInfo[0].can_be_left_alone" component="div" className="field_error" />
                            </div>
                            <div className="col-md-4">
                              <label>Medication(select all that apply)</label>
                              <Field
                                component="select"
                                name="userPetCareInfo[0].medication"
                                className="form-select"
                                multiple={false}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                <option value="Pill">Pill</option>
                                <option value="Topical">Topical</option>
                                <option value="Injection">Injection</option>
                              </Field>
                              <ErrorMessage name="userPetCareInfo[0].medication" component="div" className="field_error" />
                            </div>
                            <div className="col-md-6">
                              <label>Anything Else A Sitter Should Know?</label>
                              <Field
                                className="form-control"
                                type="text" maxLength={100}
                                name="userPetCareInfo[0].other_info"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="userPetCareInfo[0].other_info" component="div" className="field_error" />
                            </div>
                            <h3>User Pet Health Info</h3>
                            <div className="col-md-6">
                              <label>Veterinary Info</label>
                              <Field
                                className="form-control"
                                type="text" maxLength={100}
                                name="userPetHealthInfo[0].veterinary_info"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="userPetHealthInfo[0].veterinary_info" component="div" className="field_error" />
                            </div>
                            <div className="col-md-6">
                              <label>Pet Insurance Provider?</label>
                              <Field
                                className="form-control"
                                type="text" maxLength={50}
                                name="userPetHealthInfo[0].pet_insurance_provider_name"
                                onChange={handleChange}
                              />
                              <ErrorMessage name="userPetHealthInfo[0].pet_insurance_provider_name" component="div" className="field_error" />
                            </div>
                            <div className="pet_btn">
                              <button
                                type="submit"
                                onClick={() => {
                                  validateForm().then((errors) => {
                                    if (Object.keys(errors).length === 0) {
                                    } else {
                                      setTouched({
                                        name: true,
                                      });
                                    }
                                  });
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                            <br />
                        </>
                      </Form>
                            </div>
                          </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isSubmitted && (
        <div className="container msg_div">
          <div className="successMsg">
            <h2 className="text-center">
              Your Pet Profile Created SuccesYesully !!
            </h2>
            <a href="/auth/pet-profile">Back</a>
          </div>
        </div>
      )}
    </StyleContainer>
  );
};

export default PetProfileForm;
