import React from 'react'
import ProfileHeader from "./ProfileHeader"


function Product() {
  return (
    <div>
      <ProfileHeader />
    </div>
  )
}

export default Product