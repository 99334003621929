import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PencilSquare } from "react-bootstrap-icons";
import { fetchServicesBookings } from "../../features/web/web-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

const StyledContainer = styled.div`
  .card {
    overflow: scroll;
    margin-right: 9em;
    margin-left: 1em;
    height: 84vh;
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  .table th,
  .table td {
    padding: 12px;
    text-align: center; /* Center align content */
    vertical-align: middle; /* Vertically center content */
    border-bottom: 1px solid #dee2e6;
  }

  th {
    background-color: #0dcaf1;
    color: #fff;
  }

  tbody tr:nth-child(even) {
    background-color: #f8f9fa;
  }

  .action-column {
    text-align: center;
    color: #007bff;
    cursor: pointer;
  }

  .action-column:hover {
    color: #0056b3;
  }

  .hamburger {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .sidebar {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    background-color: #55C8DB;
    padding-top: 60px;
    z-index: 1;
    overflow-x: hidden;
    transition: 0.5s;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar ul {
    list-style: none;
    padding: 0;
  }

  .sidebar li {
    padding: 10px 15px;
  }

  .sidebar a {
    text-decoration: none;
    font-size: 1.25rem;
    color: #f8f9fa;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    background-color: #e9eeff;
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .card {
      margin: 20px 0px;
      height: auto;
      background-color: #f8f9fa;
      padding: 15px;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
      

    .hamburger {
      display: block;
    }

    .sidebar {
      display: block;
      width: 0;
    }

    .sidebar.open {
      width: 250px;
    }
  }
`;

function OrderHistory() {
  const userInfo =
    localStorage.getItem("userInfo") !== "undefined"
      ? localStorage.getItem("userInfo")
      : null;
  let user_id = null;
  if (userInfo) {
    const userData = JSON.parse(userInfo);
    user_id = userData.id;
  }
  const dispatch = useDispatch();
  const [getServiceBookings, setServiceBookings] = useState([]);
  const getServiceBooking = useSelector(
    (state) => state.serviceBookings.data || []
  );
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchServicesBookings(user_id));
  }, [dispatch, user_id]);

  useEffect(() => {
    if (getServiceBooking && getServiceBooking.data) {
      setServiceBookings(getServiceBooking.data.rows);
    }
  }, [getServiceBooking]);


  console.log("Service Booking Data: ", getServiceBookings)

  const logoutHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <StyledContainer>
      <div className="container-fluid">
        <div className="card">
        <h2 className="text-center fw-bold">Service Booking History</h2>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Service</th>
                <th>Date</th>
                <th>Time</th>
                <th>Dog Breed</th>
                <th>Dog Size</th>
                <th>Status</th>
                <th>Invoice</th>
                <th>Amount</th>
                {/* <th>Action</th> */}
              </tr>
            </thead>
            <tbody>
              {getServiceBookings.map((e) => (
                <tr key={e.id}>
                  <td>{e.order_id}</td>
                  <td>{e.additional_details} {e.serivice_week_type} {e.serivice_day_type}</td>
                  <td>{e.date_slot}</td>
                  <td>{e.time_slot}</td>
                  <td>{e.dog_details}</td>
                  <td>{e.dog_size}</td>
                  <td>{e.order_status}</td>
                  <td className="text-center text-primary">
                    <a href={`service-invoice/${e.id}`} className="text-primary"> <svg xmlns="http://www.w3.org/2000/svg" height="28px" viewBox="0 -960 960 960" width="28px" fill="#777777"><path d="M652-645.33v-128H308v128h-66.67V-840h477.34v194.67H652Zm-505.33 66.66h666.66-666.66Zm586 96.67q13.66 0 23.5-9.83 9.83-9.84 9.83-23.5 0-13.67-9.83-23.5-9.84-9.84-23.5-9.84-13.67 0-23.5 9.84-9.84 9.83-9.84 23.5 0 13.66 9.84 23.5Q719-482 732.67-482ZM652-186.67V-368H308v181.33h344ZM718.67-120H241.33v-170.67H80v-244q0-47.03 32-78.85 32-31.81 78.67-31.81h578.66q47.04 0 78.85 31.81Q880-581.7 880-534.67v244H718.67V-120Zm94.66-237.33v-177.48q0-18.86-12.65-31.36t-31.35-12.5H190.67q-18.7 0-31.35 12.65-12.65 12.65-12.65 31.35v177.34h94.66v-77.34h477.34v77.34h94.66Z" /></svg> </a>
                  </td>
                  <td>{e.price}</td>
                  {/* <td className="text-center text-primary">
                    <PencilSquare />
                  </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </StyledContainer>
  );
}

export default OrderHistory;
