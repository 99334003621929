import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { fetchPackages } from "../../features/web/web-actions";
import { FaCheckCircle } from "react-icons/fa";
import ServiceDetailModal from "../services/ServiceDetailModal";
import Header from "../../components/Common/ComponentHeader";
import tickIcon from "../../assets/images/Home/tick-circle-solid.svg";

const StyledContainer = styled.div`
    
    .hide-scrollbar {
        -ms-overflow-style: none; 
        scrollbar-width: none;   
    }

    .hide-scrollbar::-webkit-scrollbar { 
        display: none; 
    }

  .main-container {
    background-color: #ddf9ff;
  }

  .headercontainer {
    margin-left: 10%;
  }

  .header1 {
    font-size: 30px;
    font-weight: 400;
    line-height: 36.57px;
    letter-spacing: 2px;
  }

  .header2 {
    border-bottom: 5px solid #12d2ff;
    width: fit-content;
    font-size: 35px;
    font-weight: 700;
    line-height: 42.67px;
    letter-spacing: 2px;
  }

  .PackageContent {
    margin: 0 !important;
    padding: 20px 0 !important;
    width: 100%;
  }

  .card-header {
    display: flex;
    align-items: center;
    height:75px;
    gap: 20px;
    background-color: #ffffff;
    border: 0;
  }

  .card-header h5 {
    font-size: 4vh;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 2px;
    text-align: left;
  }

  .card-body{
    height:260px;
    overflow:scroll;
  }

  .pricebody h2 {
    font-size: 2em;
    font-weight: 700;
    line-height: 48.76px;
    letter-spacing: 2px;
    text-align: left;
  }

  .thumbnail {
    width: 60px;
    height: 60px;
  }

  .thumbnail img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ul {
    font-size: 0.97em;
    font-weight: 500;
    line-height: 24.38px;
    letter-spacing: 2px;
    text-align: left;
  }

  ul {
    margin: 10% 0%;
  }

  .icon {
    margin: 3%;
  }

  .card-footer {
    background: none;
    border: none;
  }

  .order_now {
    padding: 2% 15%;
    border-radius: 10px;
    background-color: transparent;
    color: black;
    border: 2px black solid;
  }

   /*slick slider setting */

  .slick-slide {
    padding: 15px 20px;
    transition: all 0.4s ease-in-out;
  }


  .slick-slide:nth-of-type(3n+1) .card,
  .slick-slide:nth-child(3n + 1) .card-header {
    background-color: #ffffff;
    color: #000000;
  }

  .slick-slide:nth-of-type(3n+2) .card,
  .slick-slide:nth-child(3n + 2) .card-header {
    background-color: #91e8ff;
    color: #000000;
  }

  .slick-slide:nth-of-type(3n) .card,
  .slick-slide:nth-child(3n) .card-header {
    background-color: #000000;
    color: #ffffff;
  }

  .slick-slide:nth-child(3n) .btn-dark {
    border: 1px solid #ffffff;
    color: white;
  }

  @media (max-width: 1400px) and (min-width:1200px){
    .pricebody h2{
    font-size:1.7em;
    }
  }

  @media (max-width: 1024px){

    .card-header h5 {
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 1.5px;
    }

    .pricebody h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35.76px;
    letter-spacing: 0.8px;
    }

     ul {
    font-size: 17px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 1px;
    }

    .card-body{
      margin-top: 0px;
    }
  }

  @media (max-width: 768px) {

    .pricebody h2 {
      font-size: 25px;
      font-weight: 700;
      line-height: 34.13px;
      letter-spacing: 1.38526px;
      text-align: left;
    }

    ul {
      font-size: 15px;
      font-weight: 500;
      line-height: 15.85px;
      letter-spacing: 1.385263204574585px;
      text-align: left;
    }

  }

  @media (max-width: 425px) {
    .card-body{
    height:230px;
    }
    .thumbnail {
      height: 35px;
    }

    .card-header {
      font-size: 12px;
      font-weight: 700;
      line-height: 14.63px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    .card-body {
      margin: 0px !important;
      padding: 0.5rem 1rem;
    }

    .pricebody h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 34.13px;
      letter-spacing: 1.38526px;
      text-align: left;
    }

    .pricebody h5 {
      font-size: 13px;
      font-weight: 400;
      line-height: 15.85px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    .basePrice {
      font-size: 13px;
      font-weight: 400;
      line-height: 15.85px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    ul {
      font-size: 13px;
      font-weight: 500;
      line-height: 15.85px;
      letter-spacing: 1.385263204574585px;
      text-align: left;
    }

    .order_now {
      padding: 4% 20%;
      border-radius: 5px;
      font-size: 9px;
      font-weight: 500;
      line-height: 10.97px;
    }
  }

  @media (max-width: 376px) {
    .slick-slide {
    width:275px;
  }

    .thumbnail {
      height: 35px;
    }

    .card-header h5{
      font-size: 17px;
      font-weight: 700;
      line-height: 14.63px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    .card-body {
      height:151px;
      margin: 0px !important;
      padding: 0.5rem 1rem;
    }

    .pricebody h2 {
      font-size: 23px;
      font-weight: 700;
      line-height: 34.13px;
      letter-spacing: 1.38526px;
      text-align: left;
    }

    .cpricebody h5 {
      font-size: 13px;
      font-weight: 400;
      line-height: 15.85px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    .basePrice {
      font-size: 13px;
      font-weight: 400;
      line-height: 15.85px;
      letter-spacing: 1.3852px;
      text-align: left;
    }

    ul {
      font-size: 13px;
      font-weight: 500;
      line-height: 15.85px;
      letter-spacing: 1.385263204574585px;
      text-align: left;
    }

    .order_now {
      padding: 4% 20%;
      border-radius: 5px;
      font-size: 9px;
      font-weight: 500;
      line-height: 10.97px;
    }
  }
`;

const Package = ({ header1, header2 }) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]); // Renamed to packages
  const packageData = useSelector((state) => state.packages.data);

  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleCardSelect = (cardData) => {
    setSelectedCard(cardData);
    console.log("Cart Data: ", selectedCard);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchPackages());
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (packageData && packageData.data) {
      setPackages(Object.values(packageData.data)); // Set all packages
    }
  }, [packageData]);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
    autoplay: false,
    autoplaySpeed: 3000,
    nextArrow: (
      <div>
        <div className="next-slick-arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.5 19.6667C39.5 30.2442 30.7775 38.8333 20 38.8333C9.22249 38.8333 0.5 30.2442 0.5 19.6667C0.5 9.0891 9.22249 0.5 20 0.5C30.7775 0.5 39.5 9.0891 39.5 19.6667Z" stroke="black"/>
            <path d="M24.6022 19.5024L16.9393 12.2011C16.8114 12.0792 16.6416 12.0112 16.4649 12.0112C16.2883 12.0112 16.1184 12.0792 15.9905 12.2011L15.9823 12.2094C15.9201 12.2684 15.8706 12.3396 15.8367 12.4184C15.8029 12.4972 15.7854 12.5821 15.7854 12.6679C15.7854 12.7537 15.8029 12.8386 15.8367 12.9174C15.8706 12.9963 15.9201 13.0674 15.9823 13.1265L23.1983 20.0015L15.9823 26.8737C15.9201 26.9328 15.8706 27.0039 15.8367 27.0828C15.8029 27.1616 15.7854 27.2465 15.7854 27.3323C15.7854 27.4181 15.8029 27.503 15.8367 27.5818C15.8706 27.6606 15.9201 27.7318 15.9823 27.7909L15.9905 27.7991C16.1184 27.921 16.2883 27.989 16.4649 27.989C16.6416 27.989 16.8114 27.921 16.9393 27.7991L24.6022 20.4979C24.6696 20.4337 24.7232 20.3564 24.7599 20.2709C24.7965 20.1853 24.8154 20.0932 24.8154 20.0001C24.8154 19.907 24.7965 19.8149 24.7599 19.7294C24.7232 19.6438 24.6696 19.5666 24.6022 19.5024Z" fill="black"/>
          </svg>
        </div>
      </div>
    ),
    prevArrow: (
      <div>
        <div className="prev-slick-arrow">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.5 19.6667C39.5 30.2442 30.7775 38.8333 20 38.8333C9.22249 38.8333 0.5 30.2442 0.5 19.6667C0.5 9.0891 9.22249 0.5 20 0.5C30.7775 0.5 39.5 9.0891 39.5 19.6667Z" fill="white" stroke="black"/>
            <path d="M13.3978 19.5024L21.0607 12.2011C21.1886 12.0792 21.3584 12.0112 21.5351 12.0112C21.7117 12.0112 21.8816 12.0792 22.0095 12.2011L22.0177 12.2094C22.0799 12.2684 22.1294 12.3396 22.1633 12.4184C22.1971 12.4972 22.2146 12.5821 22.2146 12.6679C22.2146 12.7537 22.1971 12.8386 22.1633 12.9174C22.1294 12.9963 22.0799 13.0674 22.0177 13.1265L14.8017 20.0015L22.0177 26.8737C22.0799 26.9328 22.1294 27.0039 22.1633 27.0828C22.1971 27.1616 22.2146 27.2465 22.2146 27.3323C22.2146 27.4181 22.1971 27.503 22.1633 27.5818C22.1294 27.6606 22.0799 27.7318 22.0177 27.7909L22.0095 27.7991C21.8816 27.921 21.7117 27.989 21.5351 27.989C21.3584 27.989 21.1886 27.921 21.0607 27.7991L13.3978 20.4979C13.3304 20.4337 13.2768 20.3564 13.2401 20.2709C13.2035 20.1853 13.1846 20.0932 13.1846 20.0001C13.1846 19.907 13.2035 19.8149 13.2401 19.7294C13.2768 19.6438 13.3304 19.5666 13.3978 19.5024Z" fill="black"/>
          </svg>
        </div>
      </div>
    ),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const featuresToShow = (pkg) => {
    const matches = pkg.feature.match(/'(.*?)'/g);

    if (matches) {
      return matches
        .map(match => match.replace(/'/g, '').trim()) // Remove single quotes and trim whitespace
        .filter(feature => feature); // Filter out any empty features
    }
    return [];
  };


  return (
    <StyledContainer>
      <div className="main-container">
        <div className="container p-5">
          <div>
            <Header header1={header1} header2={header2} />
          </div>

          <Slider {...sliderSettings}>
            {packages.map((pkg, idx) => {
              const { id, title, support, visits, walks, spa, price, thumbnail, thumbnail_alt, tag, selling_price, service } = pkg;
              const numericPrice = parseFloat(price);
              const numericSellingPrice = parseFloat(selling_price);

              return (
                <div key={idx} className="card p-4 rounded-4 border-0">
                  <div className="card-header">
                    <h5 className="card-title">{title}</h5>
                  </div>
                  <div className="pricebody m-3">
                    <h2>
                      Rs. {numericSellingPrice.toLocaleString("en-IN")}/-
                    </h2>
                    <h5
                      className="basePrice"
                      style={{ textDecoration: "line-through" }}
                    >
                      Rs. {numericPrice.toLocaleString("en-IN")}/-
                    </h5>
                  </div>
                  <div className="card-body hide-scrollbar">
                    <ul style={{ listStyle: "none", padding: "0" }}>
                      {featuresToShow(pkg).map((feature, index) => (
                        <li key={index} className="">
                          <FaCheckCircle className="icon fixed-icon me-2" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-footer">
                    <button
                      type="button"
                      className="btn btn-dark order_now"
                      onClick={() => handleCardSelect({ id, title, support, visits, walks, spa, price, thumbnail, thumbnail_alt, tag, selling_price, service })}
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              );
            })}
          </Slider>

          {showModal && (
            <ServiceDetailModal
              selectedCard={selectedCard}
              showModal={showModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

export default Package;
